import React from "react";
// import "./styles.css";
import * as markerjs2 from "markerjs2";
import { useEffect } from "react";

import { useState } from "react";

import Webcam from "react-webcam";
import WebcamVideo from "./WebcamVideo";
import { Button, Grid, IconButton } from "@mui/material";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ImagenTomada from "./ImagenTomada";
import MenuCamara from "./MenuCamara";
import Navegacion from "./Navegacion";
import {
  chequearPermisos,
  desencriptar,
  encriptar,
  enviarPosicionHaciaReactNative,
} from "../../funciones/Funciones";
import EstilosContext from "../../contextos/EstilosContext";
import { useContext } from "react";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import { useRef } from "react";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
// import EstilosContext from "../../contextos/EstilosContext";
// import { useContext } from "react";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  //   facingMode: "user",

  //   width: { min: 480 },
  //   height: { min: 720 },
  aspectRatio: 0.6666666667,
  facingMode: "user", // CAMARA FRONTAL
  //   facingMode: { exact: "environment" },  // CAMARA TRASERA
};

const WebcamPicture = (props) => {
  //   *****************************************
  //   Marker.js
  // let imgRef = React.createRef();

  // const showMarkerArea = () => {
  //   if (imgRef.current !== null) {
  //     // create a marker.js MarkerArea
  //     const markerArea = new markerjs2.MarkerArea(imgRef.current);

  //     // add all marker types
  //     markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

  //     // enable redo, notes, zoom, and clear buttons (hidden by default)
  //     markerArea.uiStyleSettings.redoButtonVisible = true;
  //     markerArea.uiStyleSettings.notesButtonVisible = true;
  //     markerArea.uiStyleSettings.zoomButtonVisible = true;
  //     markerArea.uiStyleSettings.clearButtonVisible = true;

  //     // attach an event handler to assign annotated image back to our image element
  //     markerArea.addEventListener("render", (event) => {
  //       if (imgRef.current) {
  //         imgRef.current.src = event.dataUrl;

  //         const canvas = event.canvas;
  //         console.log("canvas: ", canvas.toDataURL());
  //       }
  //       console.log("state: ", event.state);
  //     });
  //     // launch marker.js
  //     markerArea.show();
  //   }
  // };
  // *************************************************************

  const { screenWidth, isMobile } = useContext(EstilosContext);
  const { privilegios, setPrivilegios } = useContext(PrivilegioContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);

  const [rolDelUsuario, setRolDelUsuario] = useState("ROL_ADMINISTRADOR");
  const [mirrored, setMirrored] = useState(false);
  const [imagen, setImagen] = useState("img/uploads/parrilla.jpg");

  const [picture, setPicture] = useState("");
  const [mostrarImagen, setMostrarImagen] = useState("");

  const [colaDeImagenes, setColaDeImagenes] = useState([]);
  const [verArchivo, setVerArchivo] = useState(false);
  const [maxArchivos, setMaxArchivos] = useState(false);

  const [esBuscarArchivo, setEsBuscarArchivo] = useState(props.buscarArchivo);

  const [contadorIntersticial, setContadorIntersticial] = useState(0);

  const MAXIMO_ARCHIVOS = 5;

  const webcamRef = useRef(null);
  const inputRef = useRef();

  useEffect(() => {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       accion: "takePhoto",
    //       contenido: {},
    //     })
    //   );

    if ((contadorIntersticial + 1) % 3 === 0) {
      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BONIFICADO",
        idPublicidad: "ca-app-pub-9747826938175612/9380320165",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    } else {
      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "INTERSTICIAL",
        idPublicidad: "ca-app-pub-9747826938175612/7909053258",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    }

    let contador = localStorage.getItem("contIntersticial")
      ? localStorage.getItem("contIntersticial")
      : 1;

    setContadorIntersticial(contador);

    // enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});

    // window.ReactNativeWebView.postMessage("takePhoto");

    // if (esBuscarArchivo) {
    //   // inputRef.current.click();

    //   handleFileChange(inputRef.current.click());
    // }

    // return () => {
    //   setContadorIntersticial(1);
    // };
  }, []);

  useEffect(() => {
    let objobra = desencriptar(localStorage.getItem("objobra"));

    // setObra(objobra);
    // console.log("Privilegios: ", objobra.Privilegios);
    setPrivilegios(objobra.Privilegios);

    if (
      chequearPermisos(privilegios, "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR") ===
        false ||
      (chequearPermisos(privilegios, "TODOS") === false &&
        chequearPermisos(privilegios, "ORDENES_DE_SERVICIO_ROL_CAPATAZ") ===
          true)
    ) {
      setRolDelUsuario("ROL_CAPATAZ");
    }
  }, []);

  const capture = React.useCallback(async () => {
    // alert(screenWidth);

    let sum = localStorage.getItem("contIntersticial");
    // alert("sum: " + sum + "\nCondicion: " + (sum % 3));

    if (sum % 3 === 0) {
      sum = 1;

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BONIFICADO",
        idPublicidad: "ca-app-pub-9747826938175612/9380320165",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    } else {
      sum++;

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "INTERSTICIAL",
        idPublicidad: "ca-app-pub-9747826938175612/7909053258",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    }

    localStorage.setItem("contIntersticial", sum);

    setContadorIntersticial(sum);

    if (isMobile && window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({
      //     accion: "takePhoto",
      //     contenido: {},
      //   })
      // );
      // enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});
    } else {
      const pictureSrc = await webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);
    }

    // console.log("pictureSrc", pictureSrc);
  }, [webcamRef]);

  // let processed = false;

  window.addEventListener("message", function (event) {
    // if (processed) {
    //   return;
    // }
    // processed = true;

    // window.ReactNativeWebView.postMessage("FUERA: " + event);

    // if (event.data.type === "myEventType") {
    //   window.ReactNativeWebView.postMessage("myEventType: " + event.data.data);
    //   alert("MSG: " + event.data.data);
    // }

    // alert("mensaje: ", event.data);

    if (event.data.type === "photo") {
      // window.ReactNativeWebView.postMessage("photo: " + event.data.data);

      // const img = document.createElement("img");
      // img.src = `data:image/jpeg;base64,${event.data.data}`;

      // document.body.appendChild(img);

      const pictureSrc = `data:image/jpeg;base64,${event.data.data}`;
      // alert("pictureSrc: ", pictureSrc);
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BANNER",
        idPublicidad: "ca-app-pub-9747826938175612/8150779805",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: false,
      });
    }
  });

  useEffect(() => {
    props.setTitulo("Agregar imagenes/videos");
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    // console.log("colaDeImagenes", colaDeImagenes);
    if (picture !== "") {
      setColaDeImagenes([
        ...colaDeImagenes,
        { index: colaDeImagenes.length, src: picture, anotaciones: "" },
      ]);

      setMaxArchivos(colaDeImagenes.length == MAXIMO_ARCHIVOS - 1);

      let paso1 = {
        rol: rolDelUsuario,
        archivos: [
          ...colaDeImagenes,
          { index: colaDeImagenes.length, src: picture, anotaciones: "" },
        ],
        descripcion: "",
        destinatarios: [],
        prioridad: "",
      };

      // console.log("objordserv: ", paso1);
      localStorage.setItem("objordserv", encriptar(paso1));
    }
  }, [picture]);

  useEffect(() => {
    setMaxArchivos(colaDeImagenes.length == MAXIMO_ARCHIVOS);
  }, [colaDeImagenes]);

  const handleVerArchivo = (img) => {
    setVerArchivo(true);

    setMostrarImagen(img.src);
    // showMarkerArea();
  };

  const handleButton = () => {
    inputRef.current.click();
    // navigate("/buscarArchivos");
  };

  function handleFileChange(event) {
    // alert("PASA");
    const file = event.target.files[0];

    // console.log("file: ", file);

    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;

      // console.log("imageUrl: ", imageUrl);

      if (imageUrl !== "") {
        setColaDeImagenes([
          ...colaDeImagenes,
          { index: colaDeImagenes.length, src: imageUrl, anotaciones: "" },
        ]);

        setMaxArchivos(colaDeImagenes.length == MAXIMO_ARCHIVOS - 1);

        let paso1 = {
          rol: rolDelUsuario,
          archivos: [
            ...colaDeImagenes,
            { index: colaDeImagenes.length, src: imageUrl, anotaciones: "" },
          ],
          descripcion: "",
          destinatarios: [],
          prioridad: "",
        };

        // console.log("objordserv: ", paso1);
        localStorage.setItem("objordserv", encriptar(paso1));
      }

      // setImg(imageUrl);
      // navigate("/agregarArchivos");
      // Aquí puedes utilizar la URL de la imagen para mostrarla o procesarla
    };
    reader.readAsDataURL(file);
  }

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent:
          screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
            ? "left"
            : "center",
        // border: "1px solid #CCCCCC",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
        }}
      >
        {picture == "" && !verArchivo ? (
          <>
            {!isMobile ? (
              esBuscarArchivo ? (
                ""
              ) : (
                <Webcam
                  //   width="99%"
                  height={400}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                  mirrored={mirrored}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={0.95}
                  imageSmoothing={true}
                  // audio={true}
                  video="true"
                  //   sx={{
                  //     alignSelf: "flex-start",
                  //     justifyContent: "flex-start",
                  //     display: "flex",
                  //     alignItems: "flex-start",
                  //     float: "left",
                  //     marginLeft: "0px",
                  //   }}
                />
              )
            ) : (
              ""
            )}
          </>
        ) : (
          //   <MenuCamara />
          <>
            {verArchivo ? (
              <>
                <Grid
                  container
                  style={{
                    display: "flex",
                    // border: "1px solid #CCCCCC",
                    justifyContent:
                      screenWidth === "md" ||
                      screenWidth === "lg" ||
                      screenWidth === "xl"
                        ? "left"
                        : "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent:
                        screenWidth === "md" ||
                        screenWidth === "lg" ||
                        screenWidth === "xl"
                          ? "left"
                          : "center",
                    }}
                  >
                    <ImagenTomada
                      // imgRef={imgRef}
                      picture={
                        mostrarImagen.src === "" ? picture : mostrarImagen
                      }
                      colaDeImagenes={colaDeImagenes}
                      setColaDeImagenes={setColaDeImagenes}
                      setMostrarImagen={setMostrarImagen}
                      setVerArchivo={setVerArchivo}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent:
                        screenWidth === "md" ||
                        screenWidth === "lg" ||
                        screenWidth === "xl"
                          ? "left"
                          : "center",
                    }}
                  >
                    <>
                      <MenuCamara
                        setPicture={setPicture}
                        setMostrarImagen={setMostrarImagen}
                        setVerArchivo={setVerArchivo}
                        fontSize={40}
                        disabled={maxArchivos}
                        MAXIMO_ARCHIVOS={MAXIMO_ARCHIVOS}
                      />
                    </>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent:
                      screenWidth === "md" ||
                      screenWidth === "lg" ||
                      screenWidth === "xl"
                        ? "left"
                        : "center",
                  }}
                >
                  <MenuCamara
                    setPicture={setPicture}
                    setMostrarImagen={setMostrarImagen}
                    setVerArchivo={setVerArchivo}
                    fontSize={80}
                    disabled={maxArchivos}
                    MAXIMO_ARCHIVOS={MAXIMO_ARCHIVOS}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
        }}
      >
        {
          picture == "" && !verArchivo ? (
            //   <Button
            //     variant="contained"
            //     onClick={(e) => {
            //       e.preventDefault();
            //       capture();
            //     }}
            //     // className="btn btn-danger"
            //     startIcon={<CheckCircleOutlineOutlinedIcon sx={{ fontSize: 80 }} />}
            //   ></Button>

            <>
              <IconButton
                color="primary"
                aria-label="tomar foto"
                component="label"
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
              >
                <AddCircleIcon sx={{ fontSize: 80 }} />
              </IconButton>
              &nbsp;
              <IconButton
                color="primary"
                aria-label="buscar foto"
                component="label"
                onClick={(e) => {
                  e.preventDefault();
                  // capture();
                }}
              >
                <ImageSearchOutlinedIcon
                  sx={{
                    fontSize: 80,
                    // borderRadius: "50%",
                    // border: "1px solid gray",
                    // boxShadow: "inset 0px 0px 5px gray",
                    // p: 1,
                    // cursor: "pointer",
                  }}
                  onClick={() => handleButton()}
                />
              </IconButton>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                // onClick={() => alert("CLICK")}
              />
            </>
          ) : (
            ""
          )
          // : (
          //   <Button
          //     variant="contained"
          //     onClick={(e) => {
          //       e.preventDefault();
          //       setPicture("");
          //       setMostrarImagen("");
          //       setVerArchivo(false);
          //     }}
          //     sx={{ marginTop: "20px" }}
          //   >
          //     Retake
          //   </Button>

          // )
        }
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
            // border: "1px solid #CCCCCC",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            sx={{
              display: "flex",
              justifyContent:
                screenWidth === "md" ||
                screenWidth === "lg" ||
                screenWidth === "xl"
                  ? "left"
                  : "center",
            }}
          >
            {colaDeImagenes.map((img, index) => (
              <img
                key={img.index}
                // ref={imgRef}
                src={img.src}
                alt="sample"
                crossOrigin="anonymous"
                style={{
                  maxWidth: "20%",
                  paddingTop: "20px",
                  paddingLeft: "5px",
                }}
                onClick={() => handleVerArchivo(img)}
                // onClick={() => showMarkerArea()}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Navegacion nroPaso={props.nroPaso} />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <input
          type="checkbox"
          checked={mirrored}
          onChange={(e) => setMirrored(e.target.checked)}
        />
        <label>Espejar imagen</label>
      </Grid> */}
    </Grid>
  );
};

export default WebcamPicture;
