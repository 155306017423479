import React from "react";
import { createContext, useState } from "react";

const PresupuestoContext = createContext();

const PresupuestoProvider = ({ children }) => {
  const [presupuesto, setPresupuesto] = useState({});

  const data = { presupuesto, setPresupuesto };

  return (
    <PresupuestoContext.Provider value={data}>
      {children}
    </PresupuestoContext.Provider>
  );
};

export { PresupuestoProvider };
export default PresupuestoContext;
