import React, { useState, useContext, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import MensajesContext from "../../contextos/MensajesContext";
import { blue } from "@mui/material/colors";

import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import ModalInfo from "./ModalInfo";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "140px",
}));

const ModuloSeleccionable = (props) => {
  const { mostrarModal, setMostrarModal } = useContext(MensajesContext);

  const [infoAbierto, setInfoAbierto] = useState(false);

  const styles = {
    moduloNoSeleccionado: {
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
    },
    moduloSeleccionado: {
      backgroundColor: blue[50],
      fontWeight: "bold",
      color: blue[700],
      cursor: "pointer",
    },
    //   iconoNoSeleccionado: {},
    //   iconoSeleccionado: {
    //     color: blue[700],
    //   },

    //   container: {
    //     width: "100%",
    //     flexDirection: "column",
    //     alignItems: "center",
    //     justifyContent: "center",
    //   },
    //   error: {
    //     color: "#FF0000",
    //     marginBottom: 10,
    //     alignItems: "flex-start",
    //     justifyContent: "flex-start",
    //     width: "100%",
    //     // borderWidth: 1,
    //     // borderColor: "#FF0000",
    //   },
  };

  useEffect(() => {
    setInfoAbierto(false);

    // console.log("infoAbierto Modal: " + infoAbierto);
  }, [mostrarModal.mostrar]);

  const moduloClick = (mod) => {
    // console.log("mod: " + mod);

    if (!infoAbierto) {
      // console.log("PASA");
      let activo = props.modulosActivos.includes(mod);

      if (activo) {
        //   let arrFiltro = props.modulosActivos;
        let arrNuevo = props.modulosActivos.filter((item) => item != mod);
        // console.log(arrNuevo);
        props.setModulosActivos(arrNuevo);
      } else {
        props.setModulosActivos([...props.modulosActivos, mod]);
        // console.log(props.modulosActivos);
      }
    }
  };

  const handleInfo = (event, infoClick) => {
    // console.log("infoClick: " + infoClick);

    // props.setInfo(infoClick);
    setInfoAbierto(true);

    switch (infoClick) {
      case "ORDENES_DE_SERVICIO":
        setMostrarModal({
          mostrar: true,
          titulo: "ORDENES DE SERVICIO",
          mensaje:
            "Este módulo te permite delegar tareas a tu equipo de trabajo de manera muy simple. Marcas en el plano el sector, explicas la tarea a realizar, indicando importancia, prioridad, fotos, etc. y se lo envias por Whatsapp al responsable de realizar la tarea. Este recibe el mensaje y confirma al finalizar la tarea.",
        });

        break;

      case "PEDIDO_DE_MATERIALES":
        setMostrarModal({
          mostrar: true,
          titulo: "PEDIDOS",
          mensaje:
            "Este módulo te permite seleccionar el proveedor deseado (por ejemplo una pinturería), seleccionar los materiales que deseas pedir y enviar el pedido. Todos los pedidos enviados desde este módulo quedan registrados para luego poder supervisar su recepción y llevar un control de materiales pedidos para la obra.",
        });
        break;

      case "MATERIALES":
        setMostrarModal({
          mostrar: true,
          titulo: "MATERIALES",
          mensaje: "Este módulo te permite...",
        });
        break;

      case "VOLQUETES":
        setMostrarModal({
          mostrar: true,
          titulo: "VOLQUETES",
          mensaje:
            "Este módulo te permite realizar un pedido de volquete para tu obra, realizar el cambio y llevar el conteo de volquetes pedidos",
        });
        break;

      case "PRESUPUESTOS":
        setMostrarModal({
          mostrar: true,
          titulo: "PRESUPUESTOS",
          mensaje:
            "Este módulo te permite realizar el prespuesto de tu obra importando una lista de precios, la cuantificación y demás.",
        });
        break;

      case "HYS":
        setMostrarModal({
          mostrar: true,
          titulo: "HIGIENE Y SEGURIDAD",
          mensaje:
            "Este módulo te permite dar de alta sociedades y su documentación requerida. La aplicación te informará con el tiempo de anticipación que configures que documentación está por vencer.",
        });
        break;

      default:
        break;
    }

    event.stopPropagation();
  };

  return (
    <Grid item xs={12} sm={12} md={4} key={1}>
      <Item
        style={
          props.modulosActivos.includes(props.codigo)
            ? styles.moduloSeleccionado
            : styles.moduloNoSeleccionado
        }
        onClick={() => moduloClick(props.codigo)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          // border={1}
        >
          {/* <Typography variant="h6">$5</Typography> */}
          {props.titulo}

          <InfoOutlinedIcon
            fontSize="medium"
            onClick={(event) => handleInfo(event, props.codigo)}
          />
        </Grid>
        {/* <br /> */}
        {/* ORDENES DE SERVICIO */}
        <br />
        {props.icono}
        {/* <EngineeringOutlinedIcon sx={{ fontSize: 60 }} /> */}
        <br />
        <br />
      </Item>
    </Grid>
  );
};

export default ModuloSeleccionable;
