import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

const ObraContext = createContext();

const ObraProvider = ({ children }) => {
  const [obra, setObra] = useState(
    desencriptar(localStorage.getItem("objobra"))
  );

  useEffect(() => {
    localStorage.setItem("objobra", encriptar(obra));
  }, [obra]);

  const data = {
    obra,
    setObra,
  };

  return <ObraContext.Provider value={data}>{children}</ObraContext.Provider>;
};

export { ObraProvider };
export default ObraContext;
