import React from "react";
import { useContext } from "react";
import EstilosContext from "../contextos/EstilosContext";
import FiltroConSelect from "./FiltroConSelect";

const Filtros = ({ data }) => {
  const { screenWidth } = useContext(EstilosContext);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent:
          screenWidth === "xs" || screenWidth === "sm" ? "center" : "left",
      }}
      // sm={12}
    >
      {/* <div
        sm={12}
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "#cccccc1f",
          margin: "10px",
          padding: "4px",
          display: "flex",
          justifyContent:
            screenWidth === "xs" || screenWidth === "sm" ? "center" : "left",
          alignItems: "center",
        }}
      > */}
      <FiltroConSelect data={data} />
      {/* </div> */}
    </div>
  );
};

export default Filtros;
