import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import MensajesContext from "../../contextos/MensajesContext";

import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import ModalInfo from "./ModalInfo";
import ModuloSeleccionable from "./ModuloSeleccionable";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import EncabezadoItems from "../../components/EncabezadoItems";
import ServidorContext from "../../contextos/ServidorContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "140px",
}));

const PageSeleccionarModulos = (props) => {
  useEffect(() => {
    props.setTitulo("Suscripción");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  const [modulosActivos, setModulosActivos] = useState([]);
  const [info, setInfo] = useState("");

  const { servidor } = useContext(ServidorContext);

  const {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  } = useContext(MensajesContext);

  let navigate = useNavigate();

  const [user, setUser] = useState([]);

  useEffect(() => {
    let objregusu = desencriptar(localStorage.getItem("objregusu"));
    setUser(objregusu);

    // alert("IDUsuario: " + objregusu.IDUsuario);
    // alert("Clave  : " + objregusu.Clave);

    let arrmods = desencriptar(localStorage.getItem("arrmods"));

    if (arrmods.length > 0) {
      setModulosActivos(arrmods);
    }
  }, []);

  const validarConfirmar = () => {
    if (modulosActivos.length > 0) {
      localStorage.setItem("arrmods", encriptar(modulosActivos));

      actualizar_registro_de_usuario();
    } else {
      setMensaje({ text: "Seleccione al menos un módulo", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const actualizar_registro_de_usuario = async () => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));
      //   alert(user.IDUsuario);

      // console.log({
      //   operacion: "ALTA_MODULOS_USUARIO",
      //   idUsuario: user.IDUsuario,
      //   modulosActivos: modulosActivos,
      // });

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "ALTA_MODULOS_USUARIO",
          idUsuario: user.IDUsuario,
          modulosActivos: modulosActivos,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);
      //   console.log(usuarios.result);

      if (usuarios.result.usuario) {
        // console.log("PASA POR ALTA");
        // console.log(usuarios.result.usuario);

        localStorage.setItem("objregusu", encriptar(usuarios.result.usuario));

        props.setMostrar("FORMULARIO_DE_PAGO");
        navigate("/pago");
      } else {
        setMensaje({
          text: "Error al actualizar el registro del usuario",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }

      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_REGISTRO");
    navigate("/registro");
  };

  return (
    <div>
      <ModalInfo
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        // info={info}
        // setInfo={setInfo}
      />
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          m: 1,
          mt: 2,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          height: "auto",
          borderRadius: 1,
        }}
      >
        <Item
          sx={{
            width: { xs: 350, md: 550, lg: 800 },
            height: { xs: 1300, md: 600 },
          }}
        >
          <EncabezadoItems
            setMostrar={props.setMostrar}
            titulo="HABILITAR MÓDULOS"
            pageBack="FORMULARIO_REGISTRO"
          />
          <br />
          {/* <br />
          <div>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button onClick={() => handlePago("ANUAL")}>Anual</Button>
              <Button onClick={() => handlePago("MENSUAL")}>Mensual</Button>
            </ButtonGroup>
          </div> */}
          <br />
          <Box
            component="form"
            sx={{
              height: 40,
              width: "100%",
              // border: 1,
              marginTop: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <ModuloSeleccionable
                  codigo="ORDENES_DE_SERVICIO"
                  titulo="ORDENES DE SERVICIO"
                  icono={<EngineeringOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />

                <ModuloSeleccionable
                  codigo="PEDIDOS"
                  titulo="PEDIDO DE MATERIALES"
                  icono={<ShoppingCartOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />
                <ModuloSeleccionable
                  codigo="MATERIALES"
                  titulo="MATERIALES"
                  icono={<BubbleChartOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />

                <ModuloSeleccionable
                  codigo="VOLQUETES"
                  titulo="VOLQUETES"
                  icono={<LocalShippingOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />

                <ModuloSeleccionable
                  codigo="PRESUPUESTOS"
                  titulo="PRESUPUESTOS"
                  icono={<QueryStatsOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />

                <ModuloSeleccionable
                  codigo="DOCUMENTACION"
                  titulo="HIGIENE Y SEGURIDAD"
                  icono={<DescriptionOutlinedIcon sx={{ fontSize: 60 }} />}
                  modulosActivos={modulosActivos}
                  setModulosActivos={setModulosActivos}
                  //   info={info}
                  //   setInfo={setInfo}
                />
              </Grid>
            </Box>
            <br />
            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => validarConfirmar()}
            >
              Confirmar
            </Button>
          </Box>
        </Item>
      </Box>
    </div>
  );
};

export default PageSeleccionarModulos;
