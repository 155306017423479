import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";

import XLSX from "xlsx";
import PrivilegioContext from "../../contextos/PrivilegiosContext";

import "../../estilos/table.css";
import {
  chequearPermisos,
  enviarPosicionHaciaReactNative,
} from "../../funciones/Funciones";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";

const PageDashboardPlanificacion = () => {
  const [ejemplo, setEjemplo] = useState([
    {
      Id: "1",
      Nombre: "Fecha de hormigón s/9°",
      Comienzo: "23 junio 2023 09:00 a.m.",
      Fin: "23 junio 2023 07:00 p.m.",
    },
    {
      Id: "2",
      Nombre: "Mampostería piso 5",
      Comienzo: "23 junio 2023 09:00 a.m.",
      Fin: "26 junio 2023 07:00 p.m.",
    },
    {
      Id: "3",
      Nombre: "Replanteo s/10° (piso sala de máquinas)",
      Comienzo: "26 junio 2023 09:00 a.m.",
      Fin: "26 junio 2023 01:00 p.m.",
      Predecesoras: "1",
    },
  ]);
  const [data, setData] = useState([]);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { usuario } = useContext(UsuarioContext);

  const { screenWidth, isMobile } = useContext(EstilosContext);

  async function sendPushNotification(expoPushToken) {
    // console.log("PASA POR 1");

    const message = {
      to: expoPushToken,
      sound: "default",
      title: "Upload",
      body: "Hizo click en upload",
      data: { data: "datos" },
    };

    // console.log("PASA POR 2");

    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
      mode: "no-cors", // Agrega esta línea
    });

    // console.log("PASA POR 3");
  }

  // useEffect(() => {
  //   sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  // }, []);

  // useEffect(() => {
  //   console.log("usuario: ", usuario);
  // }, []);

  const handlePrueba1 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "NOTIFICACION",
      volumen: 0.1,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba2 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "NOTIFICACION_2",
      volumen: 0.1,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba3 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "BITIPI",
      volumen: 0.2,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba4 = () => {
    // enviarPosicionHaciaReactNative(isMobile, "playSound", {
    //   destino: "NOTIFICACION",
    //   volumen: 0.1,
    // });

    enviarPosicionHaciaReactNative(isMobile, "notification", {
      notificacion: {
        titulo: "Notificación de prueba",
        body: "Esto es una prueba del envío de notificaciones",
        destinatarios: "ExponentPushToken[06y0hFB-MFFrTpOeZtjxoo]", // usuario.TokenParaNotificaciones, "ExponentPushToken[F6Sa0UEU3XCG3G8fjBJxE0]",
        link: "dashboard",
      },
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // console.log("worksheet: ", worksheet);
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  function TablaProject({ data }) {
    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Nombre</TableCell>
                <TableCell align="center">Comienzo</TableCell>
                <TableCell align="center">Fin</TableCell>
                <TableCell align="center">Predecesoras</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.length > 0 &&
                data.map((row) => (
                  <TableRow
                    key={row.Id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Id}
                    </TableCell>
                    <TableCell align="left">{row.Nombre}</TableCell>
                    <TableCell align="center">{row.Comienzo}</TableCell>
                    <TableCell align="center">{row.Fin}</TableCell>
                    <TableCell align="center">{row.Predecesoras}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br /> <br />
      </>
    );
  }

  return (
    <div>
      {/* {console.log("privilegios", privilegios)} */}
      {chequearPermisos(privilegios, "PLANIFICACION_IMPORTAR_XLSX") === true ? (
        // <BoxContad
        //   imagen="planificacion.png"
        //   cantidad={0}
        //   titulo="Planificacion"
        //   link="planificacion"
        // />
        <>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            Bimtracker Plus te permite importar la planificación de tu obra
            desde un archivo Microsoft Excel.
          </Typography>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            Para eso, es importante que el Excel a importar tenga el siguiente
            formato:
          </Typography>
          <br /> <br />
          <TablaProject data={ejemplo} />
        </>
      ) : (
        ""
      )}
      <Button variant="contained" component="label">
        Subir archivo
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba1()}>
        Sonido Notificación
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba2()}>
        Sonido Notificación2
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba3()}>
        Sonido BITIPI
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba4()}>
        Notificación
      </Button>
      <br />
      <br />
      <br />
      {data.length > 0 ? <TablaProject data={data} /> : ""}
      {/* {console.log("data: ", data)} */}
    </div>
  );
};

export default PageDashboardPlanificacion;
