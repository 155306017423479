import React, { useContext, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import UsuarioContext from "../../contextos/UsuarioContext";
import ObraContext from "../../contextos/ObraContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import {
  chequearPermisos,
  desencriptar,
  formatearFecha,
} from "../../funciones/Funciones";
import AlertSinPermisos from "../../components/AlertSinPermisos";
import WhatsappEnviarMensaje from "../../components/WhatsappEnviarMensaje";
import AlertValidConfig from "../../components/AlertValidConfig";
import MensajesContext from "../../contextos/MensajesContext";
import ServidorContext from "../../contextos/ServidorContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   linkStyle: {
//     color: "white",
//     textDecoration: "none",
//   },
// }));

const Botonera = (props) => {
  // const classes = useStyles();

  let navigate = useNavigate();

  const { servidor } = useContext(ServidorContext);

  const { mensaje, setMensaje, mostrarMensaje, setMostrarMensaje } =
    useContext(MensajesContext);

  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);
  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const [propsWP, setPropsWP] = useState("");
  const [mensajeWhatsapp, setMensajeWhatsapp] = useState({});

  const [mostrarBotones, setMostrarBotones] = useState(false);

  const [idPedido, setIdPedido] = useState(0);

  useEffect(() => {
    // console.log(obra);
    // console.log("IDObra: " + obra.IDObra);
    // console.log("Privilegios: " + obra.Privilegios);

    // if (obra.IDObra === undefined) {
    // console.log("PASA POR IDObra");
    let objobra = desencriptar(localStorage.getItem("objobra"));
    setObra(objobra);
    setPrivilegios(objobra.Privilegios);
    // alert(objobra.Privilegios);
    // console.log(objobra);
    // } else {
    // console.log(usuario);
    // }

    // console.log("OBR: " + privilegios);

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    // console.log(props.paramsWhatsapp);
    setPropsWP(props.paramsWhatsapp);

    // console.log("AA");
    // console.log(props.paramsWhatsapp);
    // traerMensaje(props.paramsWhatsapp.pedido);

    let objped = desencriptar(localStorage.getItem("objped"));
    setIdPedido(objped.idPedido);

    traerMensaje(objped);

    // console.log("pedido: ", objped);

    // console.log(botones);
  }, []);

  // useEffect(() => {
  //   alert("actualiza privilegios");
  // }, [privilegios]);

  useEffect(() => {
    setMostrarBotones(true);
  }, [propsWP]);

  const handlePresupuestoDetalle = () => {
    if (chequearPermisos(privilegios, "PRESUPUESTOS_DETALLE") === true) {
      navigate("/presupuestoActual");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportListaPrecios = () => {
    if (
      chequearPermisos(
        privilegios,
        "PRESUPUESTOS_IMPORTAR_LISTA_DE_PRECIOS"
      ) === true
    ) {
      navigate("/importarListaPrecios");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportTareas = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_IMPORTAR_TAREAS") === true
    ) {
      navigate("/importarTareas");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportCuantificacion = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_IMPORTAR_CUANTIFICACION") ===
      true
    ) {
      navigate("/importarCuantificacion");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleListadoDeTareas = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_LISTADO_DE_TAREAS") === true
    ) {
      navigate("/listadoDeTareas");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleListadoDePrecios = () => {
    if (
      chequearPermisos(
        privilegios,
        "PRESUPUESTOS_EXPORTAR_LISTA_DE_PRECIOS"
      ) === true
    ) {
      navigate("/listaPrecios");
    } else {
      setMostrarAlert(true);
    }
  };

  function traerMensaje(ped) {
    // console.log("PP:");
    // console.log(ped);

    let msgEnvio = "";

    msgEnvio +=
      "Qué tal " +
      ped.proveedorNombreContacto +
      "?, \nTe paso un pedido para *" +
      ped.obra +
      "*: \n";
    // msgEnvio += "Cant. Volquetes: " + count + "\n\n";
    msgEnvio += "Razón Social: " + ped.razonSocial + "\n";
    msgEnvio += "CUIT: " + ped.sociedadCuit + "\n\n";

    msgEnvio += "Fecha: " + formatearFecha(ped.fechaProgramada, "texto") + "\n";
    msgEnvio += "Hora: " + ped.horaProgramada + "\n\n";
    // msgEnvio += "Para ver el pedido ingresá al siguiente link: \n";
    // msgEnvio +=
    //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   pedido.codigoPedido +
    //   "\n\n";

    msgEnvio += "*PEDIDO N°" + ped.idPedido + "*: \n";
    // msgEnvio += "*";
    // msgEnvio += ped.titulo;
    // msgEnvio += "*: \n";

    ped.lista.map(
      (item, index) =>
        (msgEnvio +=
          item.cantidad + " " + item.unidad + " de " + item.material + "\n")
    );

    msgEnvio += "\n";
    msgEnvio += "\n";

    msgEnvio += "Pedido enviado desde https://plus.bimtracker.com";

    let msgReenvio = "";

    msgReenvio += "Qué tal " + ped.proveedorNombreContacto + "?, \n";

    msgReenvio += "Para la obra *" + ped.obra + "*: \n\n";

    msgReenvio += "Razón Social: " + ped.razonSocial + "\n";
    msgReenvio += "CUIT: " + ped.sociedadCuit + "\n\n";

    msgReenvio +=
      "Quedó pendiente lo siguiente del *PEDIDO N°" + ped.idPedido + "*\n";
    msgReenvio +=
      "Fecha: " + formatearFecha(ped.fechaProgramada, "texto") + "\n";
    msgReenvio += "Hora: " + ped.horaProgramada + "\n\n";

    // msgReenvio += "Para ver el pedido ingresá al siguiente link: \n";
    // msgReenvio +=
    //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   pedido.codigoPedido +
    //   "\n\n";

    // msgReenvio += "*Pendiente*: \n";
    msgReenvio += "*Pendiente*: \n";

    ped.lista
      .filter((mat) => mat.estado !== "ENTREGA_COMPLETA")
      .map((item, index) =>
        item.estado === "NO_ENTREGO"
          ? (msgReenvio +=
              item.cantidad + " " + item.unidad + " de " + item.material + "\n")
          : (msgReenvio +=
              item.cantidad -
              item.cantidadRecibida +
              " " +
              item.unidad +
              " de " +
              item.material +
              "\n")
      );

    msgReenvio += "\n";
    msgReenvio += "\n";

    msgReenvio += "Pedido enviado desde https://plus.bimtracker.com";

    setMensajeWhatsapp({ envio: msgEnvio, reenvio: msgReenvio });
  }

  const handleClick = (func) => {
    if (func === "handleCerrarPedido") {
      cerrar_pedido();
    }
  };

  const cerrar_pedido = async () => {
    const API_ENDPOINT = servidor + `api_pedidos.php`;

    // console.log(codigoPedido);

    let sEstado = "ENTREGADO";

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_PEDIDO",
        idPedido: idPedido,
        estado: sEstado,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      //   console.log(respuesta.result.pedido);
      // setPedido(respuesta.result.pedido);

      setMensaje({ tipo: "success", text: "Pedido cerrado con éxito!" });
      setMostrarMensaje(true);

      setTimeout(() => {
        navigate("/dashboardPedidos");
      }, 1500);
    } else {
      console.log("NO TRAE NADA EN cerrar_pedido()");
      // setState([]);
    }
  };

  return (
    <>
      <AlertSinPermisos mostrarAlert={mostrarAlert} />

      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {/* {botones.map((boton) => {
          {
            // console.log(boton);
          }
        })} */}

        {/* {console.log("NRO. Tel: " + props.botonera.paramsWhatsapp.whatsapp)} */}
        {/* {console.log(props)} */}

        {/* {console.log(botones)} */}

        {mostrarBotones
          ? props.botonera.map((bot) =>
              bot.tipoBoton === "WHATSAPP" ? (
                // console.log(
                //   "params: " +
                //     props.paramsWhatsapp.whatsapp +
                //     "\nboton: " +
                //     bot.paramsWhatsapp.whatsapp
                // )

                // console.log(props.paramsWhatsapp.whatsapp)
                // console.log(props.paramsWhatsapp.mensajeWP)
                // console.log(props.paramsWhatsapp.pedido)

                bot.paramsWhatsapp.tipo === "ENVIAR_PEDIDO" ? (
                  <WhatsappEnviarMensaje
                    // numeroTel="5491158482073"
                    numeroTel={props.paramsWhatsapp.whatsapp}
                    // numeroTel={propsWP.whatsapp}
                    mensaje={mensajeWhatsapp.envio} //{props.paramsWhatsapp.mensajeWP}
                    pedido={bot.paramsWhatsapp.pedido}
                    setMostrarMensaje={bot.paramsWhatsapp.setMostrarMensaje}
                    setMensaje={bot.paramsWhatsapp.setMensaje}
                    tipo={bot.paramsWhatsapp.tipo}
                  />
                ) : (
                  <WhatsappEnviarMensaje
                    // numeroTel="5491158482073"
                    numeroTel={props.paramsWhatsapp.whatsapp}
                    // numeroTel={propsWP.whatsapp}
                    mensaje={mensajeWhatsapp.reenvio} //{props.paramsWhatsapp.mensajeWP}
                    pedido={bot.paramsWhatsapp.pedido}
                    setMostrarMensaje={bot.paramsWhatsapp.setMostrarMensaje}
                    setMensaje={bot.paramsWhatsapp.setMensaje}
                    tipo={bot.paramsWhatsapp.tipo}
                  />
                )
              ) : (
                <>
                  {/* {console.log(bot.funcion)} */}
                  <Button
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={() => handleClick(bot.funcion)}
                  >
                    {bot.titulo}
                  </Button>

                  {/* <Button
                     className={classes.linkStyle}
                     onClick={() => handleClick(bot.funcion)}
                   >
                     {bot.titulo}
                   </Button> */}
                </>
              )
            )
          : ""}
      </ButtonGroup>
    </>
  );
};

export default Botonera;
