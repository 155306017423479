import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../../funciones/Funciones";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidConfig from "../../../components/AlertValidConfig";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const filter = createFilterOptions();

const BoxSectores = ({ setLocalSelected, departamento }) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { planosDeObra, setPlanosDeObra } = useContext(ControlDeAvanceContext);

  const [sectores, setSectores] = useState("");

  const [comboSectores, setComboSectores] = useState([]);
  const [sectorSelected, setSectorSelected] = useState("");
  //   const [paso1, setPaso1] = useState("");

  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    IDSector: "",
    Sector: "",
  });

  const initValid = { sectorSelectedValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);
  const [planosSeleccionados, setPlanosSeleccionados] = useState([]);
  const [planosFiltrados, setPlanosFiltrados] = useState([]);

  useEffect(() => {}, []);

  useEffect(() => {
    traer_sectores();
    traer_planos_filtrados();
  }, [departamento.IDDepartamento]);

  useEffect(() => {
    traer_sectores();
    setChipSelected(sectorSelected);
  }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  useEffect(() => {
    // return () => {
    // let paso1 = desencriptar(localStorage.getItem("objordserv"));
    // let paso2 = { ...paso1, sectorSelected: chipSelected };

    // localStorage.setItem("objordserv", encriptar(paso2));

    setSectorSelected(chipSelected);

    // console.log("chipSelected: ", chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  const guardar_sector = async () => {
    const API_ENDPOINT = servidor + `api_sectores.php`;

    // console.log("idPlano: ", idPlano);
    // console.log("dialogValue.Sector: ", dialogValue.Sector);

    // console.log({
    //   operacion: "GUARDAR_SECTOR",
    //   idObra: obra.IDObra,
    //   idDepartamento: departamento.IDDepartamento,
    //   sector: dialogValue.Sector,
    //   arrPlanosSeleccionados: planosSeleccionados,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_SECTOR",
        idObra: obra.IDObra,
        idDepartamento: departamento.IDDepartamento,
        sector: dialogValue.Sector,
        arrPlanosSeleccionados: planosSeleccionados,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setChipData([...chipData, respuesta.result.sectores[0]]);

      // console.log("sect: ", respuesta.result.sectores);

      if (!respuesta.result.yaExistia) {
        setChipData([...chipData, respuesta.result.sectores[0]]);
      }

      setDialogValue({
        IDSector: respuesta.result.sectores[0]["IDSector"],
        Sector: respuesta.result.sectores[0]["Sector"],
      });

      setSectorSelected("");
      setMensaje({ text: "Sector agregado con éxito!", tipo: "success" });

      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_sector()");
      // setState([]);
    }
  };

  const traer_sectores = async () => {
    const API_ENDPOINT = servidor + `api_sectores.php`;

    // console.log("IDObra: ", obra.IDObra);

    // console.log({
    //   operacion: "TRAER_SECTORES",
    //   idObra: obra.IDObra,
    //   idDepartamento: departamento.IDDepartamento,
    //   idPlano: departamento.IDPlano,
    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_SECTORES",
        idObra: obra.IDObra,
        idDepartamento: departamento.IDDepartamento,
        idPlano: departamento.IDPlano,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("sectores: ", respuesta.result.listaSectores);

    if (respuesta.result.resultado === "OK") {
      setComboSectores(respuesta.result.listaSectoresEnCombo);
      setChipData(respuesta.result.listaSectores);
    } else {
      setComboSectores([]);
      setChipData([]);
      console.log("NO TRAE NADA EN traer_sectores()");
      // setState([]);
    }
  };

  const traer_planos_filtrados = async () => {
    const API_ENDPOINT = servidor + `api_departamentos.php`;

    // console.log("IDObra: ", obra.IDObra);

    // console.log({
    //   operacion: "TRAER_PLANOS_FILTRADOS",
    //   idObra: obra.IDObra,
    //   idDepartamento: departamento.IDDepartamento,
    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_PLANOS_FILTRADOS",
        idObra: obra.IDObra,
        idDepartamento: departamento.IDDepartamento,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.resultado === "OK") {
      let filtro = planosDeObra.filter((item) =>
        respuesta.result.planosFiltrados.some(
          (item2) => item2.IDPlano === item.IDPlano
        )
      );

      setPlanosFiltrados(filtro);
    } else {
      setPlanosFiltrados([]);
      console.log("NO TRAE NADA EN traer_planos_filtrados()");
      // setState([]);
    }
  };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    setLocalSelected(chip);
    setChipSelected([chip]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dialogValue.Sector !== "") {
      if (
        chipData.filter((chip) => chip.Sector === dialogValue.Sector).length > 0
      ) {
        setMensaje({ text: "El sector ya existe.", tipo: "error" });

        setMostrarMensaje(true);
      } else {
        guardar_sector();

        setValue({
          IDSector: parseInt(dialogValue.IDSector, 10),
          Sector: dialogValue.Sector,
        });
        handleClose();
      }
    } else {
      setMensaje({ text: "El sector no puede ser vacío.", tipo: "error" });

      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    setDialogValue({
      IDSector: "",
      Sector: "",
    });
    toggleOpen(false);
  };

  const handleAddSector = () => {
    // console.log("idDepto: ", departamento.IDDepartamento);

    if (
      chequearPermisos(
        obra.Privilegios,
        "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
      ) === true
    ) {
      if (
        departamento.IDDepartamento !== "" &&
        departamento.IDDepartamento !== 0 &&
        departamento.IDDepartamento !== undefined
      ) {
        toggleOpen(true);
      } else {
        setMensaje({
          text: "Seleccione el espacio donde agregar el sector!",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "No tiene permisos!", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const handlePlanos = (event, plano) => {
    // console.log("Plano: ", event.target.checked);

    if (event.target.checked) {
      setPlanosSeleccionados([...planosSeleccionados, plano]);
    } else {
      let filtro = planosSeleccionados.filter(
        (item) => item.IDPlano !== plano.IDPlano
      );
      // console.log("filtro: ", filtro);

      setPlanosSeleccionados(filtro);
    }
  };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      {/* <br /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {chipData.map((data, index) => (
          // <div
          //   style={
          //     {
          //       /*padding: "20px"*/
          //     }
          //   }
          // >
          <Chip
            key={data.IDSector}
            label={data.Sector.toUpperCase()}
            color="primary"
            variant={
              chipSelected.length > 0 &&
              chipSelected.filter((chip) => chip.IDSector === data.IDSector)
                .length > 0
                ? ""
                : "outlined"
            }
            onClick={() => handleClick(data)}
            style={{ fontSize: "25px", padding: "0px" }} // Ajusta estos valores a tu gusto
          />
          // </div>
        ))}

        <Chip
          key="agregar"
          label="+"
          color="primary"
          // variant={
          //   chipSelected.length > 0 &&
          //   chipSelected.filter(
          //     (chip) => chip.IDSector === data.IDSector
          //   ).length > 0
          //     ? ""
          //     : "outlined"
          // }
          variant="outlined"
          onClick={() => handleAddSector()}
          style={{ fontSize: "25px", padding: "10px" }} // Ajusta estos valores a tu gusto
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Agregar Sector</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ejemplos: "Living", "Cocina", etc.
            </DialogContentText>

            <br />
            <Autocomplete
              id="free-solo-dialog-demo"
              // options={chipData}
              options={comboSectores}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDSector: "",
                      Sector: newValue,
                    });

                    setSectores({
                      ...sectores,
                      IDSector: "",
                      Sector: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un sector.");

                  toggleOpen(true);
                  setDialogValue({
                    IDSector: "",
                    Sector: newValue.inputValue,
                  });

                  setSectores({
                    ...sectores,
                    IDSector: "",
                    Sector: newValue.inputValue,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un sector existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDSector: newValue.IDSector,
                      Sector: newValue.Sector,
                    });

                    setSectores({
                      ...sectores,
                      IDSector: newValue.IDSector,
                      Sector: newValue.Sector,
                    });
                  } else {
                    setDialogValue({
                      IDSector: "",
                      Sector: "",
                    });

                    setSectores({
                      ...sectores,
                      IDSector: "",
                      Sector: "",
                    });
                  }
                }

                // setLista([...lista, newValue]);

                setSectorSelected(newValue);
                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, sectorValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (
                  params.inputValue !== "" &&
                  !options.some(
                    (item) =>
                      item.Sector.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    Sector: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Sector) {
                  // console.log("PASA POR C");

                  return option.Sector;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDSector}>
                  {option.Sector}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Sector" variant="standard" />
              )}
            />

            {/* <TextField
              autoFocus
              margin="dense"
              id="sector"
              value={dialogValue.Sector}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Sector: event.target.value,
                });

                setSectores({
                  ...sectores,
                  IDSector: "",
                  Sector: event.target.value,
                });
              }}
              label="Sector"
              type="text"
              variant="standard"
            /> */}
          </DialogContent>
          <DialogTitle>
            Agregar al espacio "{departamento.Departamento}" del:
          </DialogTitle>
          <DialogContent>
            {planosFiltrados
              // .filter((item) => item.IDPlano !== idPlano)
              .map((plano) => (
                <div key={plano.IDPlano}>
                  <Checkbox
                    id={plano.IDPlano}
                    key={plano.IDPlano}
                    icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                    checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                    // checked={seleccionados.some(
                    //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                    // )}
                    onChange={(event) => handlePlanos(event, plano)}
                  />
                  {plano.TipoDePlano.toUpperCase() +
                    " | " +
                    plano.Plano.toUpperCase()}
                </div>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default BoxSectores;
