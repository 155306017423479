import React from "react";
import { createContext, useState } from "react";

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const [dashboard, setDashboard] = useState({});

  const data = { dashboard, setDashboard };

  return (
    <DashboardContext.Provider value={data}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardProvider };
export default DashboardContext;
