import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "react-select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Grid, Input, InputAdornment } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalPicture = ({
  mostrarModal,
  setMostrarModal,
  srcImagen,
  isMobile,
}) => {
  const [posicionImagen, setPosicionImagen] = useState("");

  const handleClose = () => {
    setMostrarModal({ ...mostrarModal, mostrar: false });
  };

  const img = new Image();
  img.src = srcImagen;
  img.onload = () => {
    if (img.width > img.height) {
      //   alert("La imagen está apaisada");
      setPosicionImagen("HORIZONTAL");
    } else {
      //   alert("La imagen está vertical");
      setPosicionImagen("VERTICAL");
    }
  };

  // console.log("img: ", srcImagen);
  return (
    <Modal
      open={mostrarModal.mostrar}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ width: "90%", height: "90%" }}
    >
      <Box
        component="form"
        sx={style}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // border: "1px solid #FF0000",

          width: isMobile ? "90%" : "80%",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #FF0000",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid blue",
              width: "60%",
            }}
          >
            <img
              src={srcImagen}
              onClick={handleClose}
              style={
                posicionImagen === "VERTICAL"
                  ? {
                      width: "50%",
                      //   maxWidth: "100%",
                      //   maxHeight: "100%",
                    }
                  : {
                      width: "70%",
                      maxWidth: "90%",

                      // maxHeight: "50%",
                    }
              }
            />
          </Grid>

          {/* <Grid item></Grid>
          <Grid item>
            <Button
              variant="contained"
              //   endIcon={<CheckOutlinedIcon />}
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid> */}
        </Grid>

        {/* <div style={{ clear: "both" }}>
          <img
            src={srcImagen}
            onClick={handleClose}
            style={{
              maxWidth: "50%",
            }}
          />
        </div>
        <div style={{ clear: "both" }}>
          <Button
            variant="contained"
            //   endIcon={<CheckOutlinedIcon />}
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </div> */}
      </Box>
    </Modal>
  );
};

export default ModalPicture;
