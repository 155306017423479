import { Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ObraContext from "../../../contextos/ObraContext";
import { useContext } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import EstilosContext from "../../../contextos/EstilosContext";
import ServidorContext from "../../../contextos/ServidorContext";
import MensajesContext from "../../../contextos/MensajesContext";

const filter = createFilterOptions();

const SelectComboCategoria = ({ setCategoriaSeleccionada }) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [categorias, setCategorias] = useState([]);

  // *********** AUTOCOMPLETE ******************
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = React.useState({
    IDCategoria: "",
    CodCategoria: "",
    Categoria: "",
  });

  const [camposDisponibles, setCamposDisponibles] = useState({
    tarea: false,
    unidad: false,
    cuantificacion: false,
  });
  // ********** FIN AUTOCOMPLETE ***************

  useEffect(() => {
    listar_categorias();
  }, []);

  //   useEffect(() => {
  //     // console.log("pedido: ");
  //     // console.log(pedido);

  //     traer_obras_del_usuario();
  //   }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const listar_categorias = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_tareas.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_CATEGORIAS",
        idObra: obra.IDObra,
        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaCategorias) {
      // console.log("lista: ", respuesta.result.listaCategorias);
      // console.log("PASA");
      setCategorias(respuesta.result.listaCategorias);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_categorias()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  //   const traer_obras_del_usuario = async () => {
  //     // console.log(texto);
  //     // console.log("PASA POR listar_obras()");

  //     let objusu = desencriptar(localStorage.getItem("objusu"));

  //     const API_ENDPOINT = servidor + `api_obras.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // mode: "no-cors",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_OBRAS",
  //         idUsuario: objusu.IDUsuario,
  //         // operacion: "LISTAR_PRESUPUESTO",
  //         //  idObra: obra.IDObra,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.obras) {
  //       // console.log(respuesta.result.obras);

  //       setListadoObras(respuesta.result.obras);
  //     } else {
  //       setListadoObras([]);
  //       console.log("NO TRAE NADA EN listar_obras()");
  //       // setState([]);
  //     }
  //   };

  //   const handleChange = (_, obr) => {
  //     // console.log(obr);

  //     setObrasSeleccionadas([...obrasSeleccionadas, obr.IDObra]);
  //   };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();

  //     // agregar_categoria_a_db();

  //     setValue({
  //       IDCategoria: parseInt(dialogValue.IDCategoria, 10),
  //       CodCategoria: dialogValue.CodCategoria,
  //       Categoria: dialogValue.Categoria,
  //     });
  //     handleClose();
  //   };

  //   const handleClose = () => {
  //     setDialogValue({
  //       IDCategoria: "",
  //       CodCategoria: "",
  //       Categoria: "",
  //     });
  //     toggleOpen(false);
  //   };

  //   const agregar_categoria_a_db = async () => {
  //     const API_ENDPOINT = servidor + `api_tareas.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "AGREGAR_CATEGORIA",
  //         idObraActual: obra.IDObra,
  //         codCategoria: dialogValue.CodCategoria,
  //         categoria: dialogValue.Categoria,
  //         arrObras: obrasSeleccionadas,
  //         // productos: "VOLQUETES",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.idCategoria) {
  //       // console.log(respuesta.result.proveedores);
  //       // setProveedores(respuesta.result.proveedores);
  //       // setProveedorElegido(respuesta.result.proveedores[0]);
  //       // console.log(respuesta.result.proveedores);
  //       // alert("Lista de precios actualizada.");
  //       setCategoria({
  //         IDCategoria: respuesta.result.idCategoria,
  //         CodCategoria: dialogValue.CodCategoria,
  //         Categoria: dialogValue.Categoria,
  //       });
  //       // props.setActualizarTarea(true);
  //     } else {
  //       console.log("NO TRAE NADA EN agregar_categoria_a_db()");
  //       // setState([]);
  //     }
  //   };

  //   const options = lista.map((option) => {
  //     const categoria = option.Categoria.toUpperCase();
  //     return {
  //       categoria: /[0-9]/.test(categoria) ? "0-9" : categoria,
  //       ...option,
  //     };
  //   });

  //   const GroupHeader = styled("div")(({ theme }) => ({
  //     position: "sticky",
  //     top: "-8px",
  //     padding: "4px 10px",
  //     color: theme.palette.primary.main,
  //     backgroundColor:
  //       theme.palette.mode === "light"
  //         ? lighten(theme.palette.primary.light, 0.85)
  //         : darken(theme.palette.primary.main, 0.8),
  //   }));

  //   const GroupItems = styled("ul")({
  //     padding: 0,
  //   });

  const seleccionarCategoria = (cat) => {
    // console.log("cat: ", cat);

    // console.log("categoriaSelec: ", cat);

    if (cat !== null) {
      if (cat.IDCategoria === undefined) {
        // Agrego la categoría
        // console.log("AGREGO");
        agregar_categoria_a_db(cat);
      } else {
        // console.log("SELECCIONO");
        setCategoriaSeleccionada(cat);
      }
    }
  };

  const agregar_categoria_a_db = async (cat) => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    // console.log("agregar_categoria_a_db(): ", {
    //   operacion: "AGREGAR_CATEGORIA",
    //   idObra: obra.IDObra,
    //   categoria: cat.inputValue,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_CATEGORIA",
        idObra: obra.IDObra,
        categoria: cat.inputValue,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    // console.log("idCategoria: ", respuesta.result.idCategoria);
    if (respuesta.result.idCategoria) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");

      setMensaje({
        text: "La categoría ha sido agregada con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      let sCodCategoria = respuesta.result.codCategoria;

      // console.log("categoria: ", {
      //   IDCategoria: respuesta.result.idCategoria,
      //   CodCategoria: respuesta.result.codCategoria,
      //   Categoria: cat.inputValue,
      // });

      setCategoriaSeleccionada({
        IDCategoria: respuesta.result.idCategoria,
        CodCategoria: respuesta.result.codCategoria,
        Categoria: cat.inputValue,
      });

      // props.setActualizarTarea(true);
    } else {
      setMensaje({
        text: "Error al agregar la categoría.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN agregar_categoria_a_db()");
      // setState([]);
    }
  };
  return (
    <div>
      <Grid container style={{ display: "flex", justifyContent: "left" }}>
        <Grid item xs={9} sm={9} md={9} lg={10}>
          <Stack spacing={1} sx={{ width: "95%" }}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categorias}
              getOptionLabel={(option) => option.Categoria}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Categorías" />
              )}
              onChange={(event, value) => seleccionarCategoria(value)}
            /> */}

            <Autocomplete
              id="free-solo-dialog-demo"
              options={categorias}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDCategoria: "",
                      Categoria: newValue,
                      CodCategoria: "",
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega una categoria.");

                  toggleOpen(true);
                  setDialogValue({
                    IDCategoria: "",
                    Categoria: newValue.inputValue,
                    CodCategoria: "",
                  });

                  // setCamposDisponibles({
                  //   ...camposDisponibles,
                  //   tarea: true,
                  //   unidad: false,
                  //   cuantificacion: false,
                  // });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona una categoría existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDCategoria: newValue.IDCategoria,
                      Tarea: newValue.Categoria,
                      CodCategoria: newValue.CodCategoria,
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   tarea: true,
                    //   unidad: false,
                    //   cuantificacion: false,
                    // });
                  } else {
                    setDialogValue({
                      IDCategoria: "",
                      Categoria: "",
                      CodCategoria: "",
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   unidad: false,
                    //   cuantificacion: false,
                    // });
                  }
                }

                setCategoriaSeleccionada(newValue);

                seleccionarCategoria(newValue);

                // alert(JSON.stringify(newValue));

                // setValidForm({ ...validForm, tareaValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Categoria: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Categoria) {
                  // console.log("PASA POR C");

                  return option.Categoria;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDCategoria}>
                  {option.Categoria}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Categoría" variant="standard" />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectComboCategoria;
