import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Checkbox, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import ConstructionOutlined from "@mui/icons-material/ConstructionOutlined";
import { ContentCutOutlined } from "@mui/icons-material";
import { formatearFecha } from "../../funciones/Funciones";
import ServidorContext from "../../contextos/ServidorContext";

// function createData(
//   codTarea,
//   tarea,
//   categoria,
//   precioM2,
//   subtotalMateriales,
//   subtotalManoDeObra,
//   arrDetalle,
//   arrDetalleMO
// ) {
//   return {
//     codTarea,
//     tarea,
//     categoria,
//     precioM2,
//     subtotalMateriales,
//     subtotalManoDeObra,
//     detalle: arrDetalle,
//     detalleMO: arrDetalleMO,
//   };
// }

let subtotalMateriales = 0;

function Row(props) {
  const { row } = props;

  const { servidor } = useContext(ServidorContext);

  const [open, setOpen] = useState(false);
  //   console.log("row: ", row);
  const [modulos, setModulos] = useState([
    { idModulo: "1", modulo: "Planificación", codigo: "PLANIFICACION" },
    {
      idModulo: "2",
      modulo: "Ordenes de servicio",
      codigo: "ORDENES_DE_SERVICIO",
    },
    { idModulo: "3", modulo: "Pedidos", codigo: "PEDIDOS" },
    { idModulo: "4", modulo: "Volquetes", codigo: "VOLQUETES" },
    { idModulo: "5", modulo: "Documentación", codigo: "DOCUMENTACION" },
    { idModulo: "6", modulo: "Presupuestos", codigo: "PRESUPUESTOS" },
    { idModulo: "7", modulo: "Materiales", codigo: "MATERIALES" },
    { idModulo: "8", modulo: "Control de avance", codigo: "CONTROL_DE_AVANCE" },
  ]);

  const [modulosSolicitados, setModulosSolicitados] = useState([]);
  const [privilegios, setPrivilegios] = useState([]);

  let solicitud = row.SolicitudAceptada == 1 ? true : false;

  const [solicitudAceptada, setSolicitudAceptada] = useState(solicitud);

  useEffect(() => {
    // let mods = row.modulosSolicitados.split("|");
    let mods = row.ModulosSolicitados;

    // console.log("mods: ", mods.split("|"));

    setModulosSolicitados(mods.split("|"));

    let priv = row.Privilegios;
    // console.log("priv: ", priv);

    if (priv !== "" && priv !== null) {
      let comunes =
        "DASHBOARD|OBRAS|CUPONES|CONFIGURACION|CONFIGURACION_USUARIO|CONFIGURACION_GENERAL|CONFIGURACION_EQUIPO|CONFIGURACION_INVITACIONES|CONFIGURACION_TAREAS";

      let arrComunes = comunes.split("|");
      let arrPriv = priv.split("|");

      var final = arrComunes.concat(
        arrPriv.filter((item) => arrComunes.indexOf(item) < 0)
      );
      //   console.log("final: ", final);

      setPrivilegios(final);
    } else {
      setPrivilegios(
        "DASHBOARD|OBRAS|CUPONES|CONFIGURACION|CONFIGURACION_USUARIO|CONFIGURACION_GENERAL|CONFIGURACION_EQUIPO|CONFIGURACION_INVITACIONES|CONFIGURACION_TAREAS".split(
          "|"
        )
      );
    }
  }, []);

  //   console.log("modulosSolicitados: ", modulosSolicitados);

  //   useEffect(() => {
  //     console.log("privilegios A: ", privilegios);
  //   }, [privilegios]);

  const handleChange = async (event, item) => {
    // console.log(item);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
    // console.log("IDDocumentacion: " + item.IDDocumentacion);
    // console.log("checked: " + event.target.checked);

    // console.log("event: ", event.target.checked);
    // console.log("item: ", item);

    // setDocumento(item);

    // setCheck(event.target.checked);

    // if (event.target.checked) {
    //   setOpenModal(true);
    // } else {

    let priv;
    let arrPrivilegios = privilegios;
    let arrNewPriv = [];

    if (event.target.checked) {
      if (arrPrivilegios.length > 0) {
        priv = item.codigo + "|" + arrPrivilegios.join("|");
      } else {
        priv = item.codigo;
      }

      arrNewPriv = priv.split("|");
      setPrivilegios(arrNewPriv);
    } else {
      const index = privilegios.indexOf(item.codigo);

      //   console.log("index: ", index);
      if (index > -1) {
        // only splice array when item is found
        arrNewPriv = arrPrivilegios.filter((pri) => item.codigo !== pri);

        setPrivilegios(arrNewPriv);

        priv = arrNewPriv.join("|");
      } else {
        priv = privilegios.join("|");
      }
    }

    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_MODULOS_ACEPTADOS_DE_INVITADO",
        idUsuario: row.IDUsuario,
        privilegios: priv,
      }),
    });
    const respuesta = await datos.json();

    //   // console.log("PASA POR A");
    //   // console.log(respuesta.result.respuesta);
    //   // console.log("PASA POR B");

    if (respuesta.result.invitados) {
      //   console.log(respuesta.result.invitados);
      // listar_asignaciones();
    } else {
      console.log("NO TRAE NADA EN: handleChange()");
    }
  };

  const handleChangeAceptar = async (event, row) => {
    // console.log("rooow: ", row);
    // console.log("check: ", event.target.checked);

    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_ACEPTACION_DE_INVITACION_DE_USUARIO",
        idUsuario: row.IDUsuario,
        aceptarSolicitud: event.target.checked,
      }),
    });
    const respuesta = await datos.json();

    //   // console.log("PASA POR A");
    // console.log("respuesta: ", respuesta.result.respuesta);
    //   // console.log("PASA POR B");

    // alert("aceptar: " + respuesta.result.aceptar);
    // alert("checked: " + event.target.checked);

    if (respuesta.result.respuesta == "OK") {
      //   console.log(respuesta.result.respuesta);
      setSolicitudAceptada(!solicitudAceptada);

      // listar_asignaciones();
    } else {
      console.log("NO TRAE NADA EN: handleChangeAceptar()");
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="left">{row.IDUsuario}</TableCell>
        <TableCell align="left">{row.Nombre}</TableCell>
        <TableCell align="left">{row.Apellido}</TableCell>
        <TableCell align="left">{row.Whatsapp}</TableCell>
        <TableCell align="left">{row.Email}</TableCell>
        <TableCell align="left">
          {formatearFecha(row.Fecha, "dd/MM/yyyy")}
        </TableCell>
        <TableCell align="left">
          <Checkbox
            id={row.IDUsuario}
            key={row.IDUsuario}
            icon={
              <RadioButtonUncheckedOutlinedIcon
                sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
              />
            }
            checkedIcon={
              <CheckCircleIcon sx={{ fontSize: 40 /*color: "#21AF19"*/ }} />
            }
            checked={solicitudAceptada}
            onChange={(event) => handleChangeAceptar(event, row)}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`${row.Nombre} ${row.Apellido}`}
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                sx={{ width: "450px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Módulos</TableCell>
                    <TableCell align="left">Solicitados</TableCell>
                    <TableCell align="left">Otorgados</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modulos.map((mod, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="left">
                        {mod.modulo}
                      </TableCell>
                      <TableCell align="left">
                        {modulosSolicitados.find((item) => mod.codigo == item)
                          ? "SI"
                          : "NO"}
                      </TableCell>
                      <TableCell center="left">
                        <Checkbox
                          id={mod.idModulo}
                          key={mod.idModulo}
                          icon={
                            <RadioButtonUncheckedOutlinedIcon
                              sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleIcon
                              sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                            />
                          }
                          checked={
                            privilegios &&
                            privilegios.some((item) => mod.codigo == item)
                          }
                          onChange={(event) => handleChange(event, mod)}
                          disabled={!solicitudAceptada}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    IDUsuario: PropTypes.string.isRequired,
    Nombre: PropTypes.string.isRequired,
    Apellido: PropTypes.string.isRequired,
    Whatsapp: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
    Fecha: PropTypes.string.isRequired,
    // Detalle: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     solicitados: PropTypes.string,
    //     otorgados: PropTypes.string,
    //   })
    // ).isRequired,
  }).isRequired,
};

const TablaDesplegable = ({ data }) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);

  //   console.log("data: ", data);

  return data.length > 1 ? (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Nombre</TableCell>
            <TableCell align="left">Apellido</TableCell>
            <TableCell align="left">Whatsapp</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Aceptar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : mostrarProgress ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    ""
  );
};

export default TablaDesplegable;
