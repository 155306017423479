import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles"; // "@mui/styles";
import Botonera from "../../components/Botonera";
import {
  Alert,
  AlertTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import {
  createTheme,
  responsiveFontSizes,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ObraContext, { ObraProvider } from "../../contextos/ObraContext";
import { useContext } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
  formatearFecha,
  getLocalItem,
  numberWithCommas,
} from "../../funciones/Funciones";
import HySContext from "../../contextos/HySContext";
import Titulo from "../../components/Titulo";
import AlertSinPermisos from "../../components/AlertSinPermisos";
import PrivilegioContext from "../../contextos/PrivilegiosContext";

import useMediaQuery from "@mui/material/useMediaQuery";
import EstilosContext from "../../contextos/EstilosContext";
import ServidorContext from "../../contextos/ServidorContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   linkStyle: {
//     color: "white",
//     textDecoration: "none",
//   },

//   // Typography: {
//   //   h6: {
//   //     fontSize: 10,
//   //   },
//   // },
// }));

// let theme = createTheme({
//   typography: {
//     h5: {
//       fontWeight: 800,
//       fontSize: 10,
//     },
//   },
// });
// theme = responsiveFontSizes(theme);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageDashboardHyS = (props) => {
  const [listadoObras, setListadoObras] = useState([]);
  const { servidor } = useContext(ServidorContext);
  const { estilos, screenWidth } = useContext(EstilosContext);

  const { obra, setObra } = useContext(ObraContext);
  const {
    hys,
    setHyS,
    subcontratos,
    setSubcontratos,
    documentos,
    setDocumentos,
    modificar,
    setModificar,
    asignaciones,
    setAsignaciones,
  } = useContext(HySContext);

  // const [mostrarAlert, setMostrarAlert] = useState(false);
  // const [privilegios, setPrivilegios] = useState("");

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  useEffect(() => {
    if (obra == undefined) {
      let objobra = desencriptar(localStorage.getItem("objobra"));

      setObra(objobra);
      setPrivilegios(objobra.Privilegios);
    } else {
      setPrivilegios(obra.Privilegios);
    }
  }, []);

  useEffect(() => {
    listar_contenido_dashboard();
  }, [obra]);

  useEffect(() => {
    props.setTitulo("Dashboard Higiene y Seguridad");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    // console.log("B");
    setHyS({});
  }, []);

  let navigate = useNavigate();

  const handleSettings = (rowClick) => {
    setModificar(rowClick);

    // console.log(encode);
    // console.log(desencriptar(encode));

    let path = "";

    let encode = encriptar(rowClick);

    // console.log(rowClick);

    if (rowClick.IDSubcontrato !== undefined) {
      if (
        chequearPermisos(privilegios, "DOCUMENTACION_SETTINGS_SOCIEDAD") ===
        true
      ) {
        // path = `/settingsSociedad`;
        localStorage.setItem("objsoc", encode);
        navigate("/settingsSociedad");
      } else {
        setMostrarAlert(true);

        // setTimeout(() => {
        //   setMostrarAlert(false);
        // }, 3000);
        // alert("No tiene permisos para acceder a esta sección.");
      }
    } else if (rowClick.IDDocumentacion !== undefined) {
      if (
        chequearPermisos(privilegios, "DOCUMENTACION_SETTINGS_DOCUMENTO") ===
        true
      ) {
        // path = `/settingsDocumento`;
        localStorage.setItem("objdocs", encode);
        navigate("/settingsDocumento");
      } else {
        setMostrarAlert(true);

        // setTimeout(() => {
        //   setMostrarAlert(false);
        // }, 3000);
        // alert("No tiene permisos para acceder a esta sección.");
      }
    }

    // // navigation.navigate("importarListaPrecios");
  };

  const handleEntrar = (rowClick) => {
    setModificar(rowClick);

    let encode = encriptar(rowClick);
    localStorage.setItem("objsoc", encode);

    let path = "";

    if (rowClick.IDSubcontrato !== undefined) {
      // alert(privilegios);
      // DOCUMENTACION_MODIF_DOCS_EN_OBRA
      if (
        chequearPermisos(privilegios, "DOCUMENTACION_ASIGN_DOCS_EN_OBRA") ===
        true
      ) {
        path = `/modificarDocumentosSociedad`;
        navigate(path);
      } else {
        setMostrarAlert(true);

        // setTimeout(() => {
        //   setMostrarAlert(false);
        // }, 3000);
        // alert("No tiene permisos para acceder a esta sección.");
      }
    } else if (rowClick.IDDocumentacion !== undefined) {
      // if (
      //   chequearPermisos(privilegios, "DOCUMENTACION_MODIF_DOCS_EN_OBRA") ===
      //   true
      // ) {
      path = `/modificarDocumento`;
      navigate(path);
      // } else {
      //   setMostrarAlert(true);

      //   setTimeout(() => {
      //     setMostrarAlert(false);
      //   }, 3000);
      //   // alert("No tiene permisos para acceder a esta sección.");
      // }
    }

    // navigate(path);
  };

  const handleAltaSociedad = () => {
    if (chequearPermisos(privilegios, "DOCUMENTACION_ALTA_SOCIEDAD") === true) {
      localStorage.removeItem("objsoc");

      navigate("/altaSociedad");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleAltaDocumento = () => {
    if (
      chequearPermisos(privilegios, "DOCUMENTACION_ALTA_DOCUMENTO") === true
    ) {
      localStorage.removeItem("objdoc");

      navigate("/altaDocumento");
    } else {
      setMostrarAlert(true);

      // setTimeout(() => {
      //   setMostrarAlert(false);
      // }, 3000);
      // alert("No tiene permisos para acceder a esta sección.");
    }
  };

  const listar_contenido_dashboard = async () => {
    // console.log(texto);
    // console.log(obra);
    // console.log("PASA POR listar_contenido_dashboard()");

    const API_ENDPOINT = servidor + `api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_CONTENIDO_DASHBOARD",
        idObra: obra.IDObra,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaHyS) {
      // console.log(respuesta.result.listaHyS);
      // console.log(respuesta.result.subcontratos);
      // console.log(respuesta.result.documentos);
      // console.log(respuesta.result.asignaciones);

      setHyS(respuesta.result.listaHyS);
      setSubcontratos(respuesta.result.subcontratos);
      setDocumentos(respuesta.result.documentos);
      setAsignaciones(
        respuesta.result.asignaciones === 0 ? [] : respuesta.result.asignaciones
      );

      let encode = encriptar(respuesta.result.documentos);
      localStorage.setItem("objdocs", encode);

      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      setHyS([]);
      setSubcontratos([]);
      setDocumentos([]);
      // setAsignaciones([]);
      // setSolicitudes([]);
      console.log("NO TRAE NADA EN listar_contenido_dashboard()");
      // setState([]);
    }
  };

  // const classes = useStyles();

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={theme}> */}
        {/* <span>{`width: ${screenWidth}`}</span> */}
        <Grid container spacing={2}>
          <AlertSinPermisos mostrarAlert={mostrarAlert} />

          <Grid item xs={12}></Grid>

          {asignaciones.length > 0 ? (
            <>
              <Grid item>
                <Titulo
                  texto="Próximos vencimientos"
                  botones={{
                    seccion: "",
                    alta: false,
                    baja: false,
                    modif: false,
                  }}
                  estilos={estilos}
                />
              </Grid>

              <Grid item xs={12}></Grid>

              {asignaciones.map((doc, index) => (
                <Box
                  component="span"
                  key={index}
                  sx={{
                    m: 1,
                    p: 2,
                    border: "1px solid grey",
                    width: "300px",
                    height: "150px",
                    textAlign: "center",
                  }}
                >
                  {/* <img src="volquete.png" style={{ width: "80px" }} /> */}
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "18px",
                      backgroundColor: "#CCCCCC",
                      /*border: "1px solid #FF0000"*/
                    }}
                  >
                    {doc.Documento}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "16px",
                      backgroundColor: "#FFFFFF",
                      textAlign: "right",
                      /*border: "1px solid #FF0000"*/
                    }}
                  >
                    {doc.RazonSocial}
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      border: "1px solid #DDDDDD",
                      fontSize: "16px",
                      marginBottom: "0px",
                    }}
                  >
                    {formatearFecha(doc.FechaVencimiento, "dd/MM/yyyy")}
                  </Typography>
                </Box>
              ))}

              <Grid item xs={12}></Grid>
            </>
          ) : (
            ""
          )}

          <Grid item>
            <Titulo
              texto="Sociedades y subcontratos"
              botones={{
                seccion: "",
                alta: false,
                baja: false,
                modif: false,
              }}
              estilos={estilos}
            />
          </Grid>
          <Grid item style={{ paddingTop: "5px" }}>
            <IconButton
              aria-label="add"
              size="large"
              onClick={() => handleAltaSociedad()}
            >
              <AddCircleOutlineOutlinedIcon
                fontSize="inherit"
                // onClick={() => handleAltaSociedad()}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}></Grid>

          {subcontratos[0] !== undefined ? (
            <Fragment>
              <Grid container spacing={2} style={estilos.posicionNumPad}>
                {subcontratos.map((subcontratosRow) => (
                  <Grid
                    item
                    xs={estilos.columnas}
                    sm={9}
                    md={4}
                    lg={3}
                    xl={3}
                    key={subcontratosRow.IDSubcontrato}
                  >
                    <Item>
                      <Grid container spacing={2} height="200px">
                        {/* <Grid item xs={12}>
                          <Typography variant="h5">Subcontratos</Typography>
                        </Grid> */}
                        <Grid item xs={10} style={{ marginTop: "10px" }}>
                          <Typography variant="h6" style={estilos.titulos}>
                            {subcontratosRow.RazonSocial.toUpperCase()}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            aria-label="add"
                            size="large"
                            onClick={() => handleSettings(subcontratosRow)}
                          >
                            <SettingsIcon
                            // onClick={() => handleSettings(subcontratosRow)}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" style={estilos.textos}>
                            {subcontratosRow.Cuit}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleEntrar(subcontratosRow)}
                          >
                            Entrar
                          </Button>
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          ) : (
            ""
          )}

          {documentos[0] !== undefined ? (
            <Fragment>
              <Grid item xs={12}></Grid>
              <Grid item>
                <Titulo
                  texto="Documentos"
                  botones={{
                    seccion: "",
                    alta: false,
                    baja: false,
                    modif: false,
                  }}
                  estilos={estilos}
                />
              </Grid>
              <Grid item style={{ paddingTop: "5px" }}>
                <IconButton
                  aria-label="add"
                  size="large"
                  onClick={() => handleAltaDocumento()}
                >
                  <AddCircleOutlineOutlinedIcon
                    fontSize="inherit"
                    // onClick={() => handleAltaDocumento()}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={12}></Grid>

              {documentos.map((documentosRow) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={documentosRow.IDDocumentacion}
                >
                  <Item>
                    <Grid
                      container
                      spacing={2}
                      height="100px"
                      style={estilos.posicionNumPad}
                    >
                      {/* <Grid item xs={12}>
                          <Typography variant="h5">Subcontratos</Typography>
                        </Grid> */}
                      <Grid item xs={10}>
                        <Typography variant="h6" style={estilos.titulos}>
                          {documentosRow.Documento}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label="add"
                          size="large"
                          onClick={() => handleSettings(documentosRow)}
                        >
                          <SettingsIcon
                          // onClick={() => handleSettings(documentosRow)}
                          />
                        </IconButton>
                      </Grid>
                      {/* <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="small"
                            // onClick={() => handleEntrar(documentosRow)}
                          >
                            Entrar
                          </Button>
                        </Grid> */}
                    </Grid>
                  </Item>
                </Grid>
              ))}
            </Fragment>
          ) : (
            ""
          )}
        </Grid>
        {/* </ThemeProvider> */}
      </Box>
    </div>
  );
};

export default PageDashboardHyS;
