import React, { useEffect } from "react";
import * as markerjs2 from "markerjs2";
import EstilosContext from "../../contextos/EstilosContext";
import { useContext } from "react";
import { encriptar } from "../../funciones/Funciones";
import { Button, Grid } from "@mui/material";

const ImagenTomada = ({
  // imgRef,
  picture,
  colaDeImagenes,
  setColaDeImagenes,
  setVerArchivo,
  setMostrarImagen,
}) => {
  const { screenWidth } = useContext(EstilosContext);

  let imgRef = React.createRef();

  let maState;

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);

      // add all marker types
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

      // enable redo, notes, zoom, and clear buttons (hidden by default)
      markerArea.uiStyleSettings.redoButtonVisible = true;
      // markerArea.uiStyleSettings.notesButtonVisible = true;
      // markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.clearButtonVisible = true;

      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;

          maState = event.state;

          // console.log("PASA POR 2");

          const updatedItems = colaDeImagenes.map((item) => {
            if (item.src === picture) {
              return {
                ...item,
                src: imgRef.current.src,
                anotaciones: JSON.stringify(event.state),
              };
            }
            return item;
          });

          // console.log("img: ", imgRef.current.src);

          // console.log("event: ", event);

          // console.log("state: ", event.state);

          setColaDeImagenes(updatedItems);

          let paso1 = {
            archivos: [...updatedItems],
            descripcion: "",
            destinatarios: [],
            prioridad: "",
          };

          // console.log("objordserv: ", paso1);
          localStorage.setItem("objordserv", encriptar(paso1));
        }
      });
      // launch marker.js
      markerArea.show();

      // if previous state is present - restore it
      if (maState) {
        markerArea.restoreState(maState);
      }
    }
  };

  const handleEliminar = (arch) => {
    setColaDeImagenes([...colaDeImagenes.filter((item) => item.src !== arch)]);
    setVerArchivo(false);
    setMostrarImagen("");
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <img
            ref={imgRef}
            src={picture}
            alt="sample"
            crossOrigin="anonymous"
            style={{
              maxWidth:
                screenWidth === "xs" || screenWidth === "sm" ? "99%" : "45%",
              paddingTop: "20px",
              marginTop: "20px",
            }}
            onClick={() => showMarkerArea()}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <Button size="small" onClick={() => handleEliminar(picture)}>
            Eliminar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImagenTomada;
