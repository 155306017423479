import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import OrdenesDeServicioContext from "../../contextos/OrdenesDeServicioContext";
import { useContext } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Chip, IconButton, Stack } from "@mui/material";
import { chequearPermisos, encriptar } from "../../funciones/Funciones";
import EnviarPorWhatsapp from "./EnviarPorWhatsapp";
import EstilosContext from "../../contextos/EstilosContext";
import ObraContext from "../../contextos/ObraContext";

const ItemOrdenDeServicio = ({
  setActualizarEnvioPorWP,
  orden,
  index,
  mensajeWP,
}) => {
  const { isMobile } = useContext(EstilosContext);

  const { obra } = useContext(ObraContext);

  const [rolAdministrador, setRolAdministrador] = useState(false);

  const [ordenSeleccionada, setOrdenSeleccionada] = useState({});
  // console.log("Ubicacion: ", orden);
  // const [mensajeWP, setMensajeWP] = useState({
  //   IDOrdenDeServicio: orden.IDOrdenDeServicio,
  //   Ubicacion:
  //     orden.Ubicacion.length > 0 ? orden.Ubicacion[0].PalabraClave : "",
  //   Prioridad: orden.Prioridad,
  //   Descripcion: orden.Descripcion,
  //   ClaveDeMensaje: "ENVIAR_ORDEN_DE_SERVICIO",
  // });

  const [boton, setBoton] = useState({
    icono: "SEND",
    cantidadEnviosPorWhatsapp: "",
    cantidadRespuestasPorWhatsapp: "",
    texto: "",
  });

  const { ordenDeServicioSeleccionada, setOrdenDeServicioSeleccionada } =
    useContext(OrdenesDeServicioContext);

  let navigate = useNavigate();

  const handleClick = (ordenDeServicio) => {
    setOrdenDeServicioSeleccionada(ordenDeServicio);
    setOrdenSeleccionada(ordenDeServicio);
  };

  useEffect(() => {
    if (ordenSeleccionada.IDOrdenDeServicio !== undefined) {
      localStorage.setItem("objods", encriptar(ordenDeServicioSeleccionada));
      navigate("/detalleOrdenDeServicio");
      // navigate("/detalleOrdenDeServicio",  { replace: true });
      // alert("pasa: " + ordenDeServicioSeleccionada.IDOrdenDeServicio);
    }
  }, [ordenSeleccionada]);

  // useEffect(() => {
  //   console.log("ordenDeServicioSeleccionada: ", ordenDeServicioSeleccionada);
  // });

  useEffect(() => {
    // console.log("ORD 2: ", orden);

    chequearPermisos(
      obra.Privilegios,
      "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
    ) === true
      ? setRolAdministrador(true)
      : setRolAdministrador(false);

    // return () => {

    // };
  }, []);

  // useEffect(() => {
  //   const tarea = {
  //     obra: obra.Obra,
  //     // etiquetas: ordenesDeServicio.etiquetas,
  //     prioridad: "ALTAA",
  //   };

  //   // const detalle = `${tarea.obra} - ${tarea.etiquetas} | ${tarea.prioridad}`;
  //   const detalle = `${tarea.obra} | PRIORIDAD ${tarea.prioridad}`;

  //   if (isMobile && window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         accion: "notification",
  //         contenido: {
  //           notificacion: {
  //             titulo: "Tarea asignada",
  //             body: detalle,
  //             destinatarios: "ExponentPushToken[hyKGf6Kp07_wHrAG-auusr]",
  //             link: { idOrdenDeServicio: 112, goTo: "detalleOrdenDeServicio" },
  //           },
  //         },
  //       })
  //     );
  //   }
  // }, [ordenDeServicio]);

  function stringAvatar(nroOrden, prioridad) {
    return {
      sx: {
        bgcolor:
          prioridad === "ALTA"
            ? "red"
            : prioridad === "MEDIA"
            ? "orange"
            : "green",
      },
      children: <span style={{ fontSize: "14px" }}>{nroOrden}</span>,
    };
  }

  return (
    <List
      sx={{ width: "100%", /*maxWidth: 360,*/ bgcolor: "background.paper" }}
      key={index}
      sm={12}
    >
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="arrow"
            onClick={() => handleClick(orden)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        }
      >
        {orden.Archivos.length > 0 ? (
          <ListItemAvatar>
            <Avatar
              alt={orden.IDOrdenDeServicio}
              {...stringAvatar("#" + orden.IDOrdenDeServicio, orden.Prioridad)}
              sx={{
                width: 60,
                height: 60,
                marginRight: "10px",
                backgroundColor:
                  orden.EstadoDeLaOrden === "PENDIENTE"
                    ? orden.Prioridad === "ALTA"
                      ? "red"
                      : orden.Prioridad === "MEDIA"
                      ? "orange"
                      : "green"
                    : "grey",
              }}
              // src={orden.Archivos[0].Archivo}
              // onClick={() => handleClick(orden)}
            />
          </ListItemAvatar>
        ) : (
          ""
        )}
        <ListItemText
          // sx={{ width: "90%" }}
          sx={{
            textDecoration:
              orden.EstadoDeLaOrden === "PENDIENTE" ? "" : "line-through",
          }}
          primary={
            <>
              {orden.Ubicacion !== "" ? (
                <>
                  <Stack
                    spacing={1}
                    alignItems="left"
                    sx={{ marginTop: "10px" }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Chip
                        key={index}
                        label={orden.Ubicacion[0].PalabraClave}
                        color="primary"
                        // variant="outlined"
                        //   onDelete={handleDelete}
                      />
                      {/* &nbsp; | &nbsp; {orden.Titulo} */}
                    </Stack>
                  </Stack>
                  <br />
                  <span>
                    {isMobile
                      ? orden.Titulo.substring(0, 30) + "..."
                      : orden.Titulo.substring(0, 50) + "..."}
                  </span>
                </>
              ) : isMobile ? (
                orden.Titulo.substring(0, 30) + "..."
              ) : (
                orden.Titulo.substring(0, 50) + "..."
              )}
            </>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <span
                  style={{
                    color:
                      orden.EstadoDeLaOrden === "PENDIENTE"
                        ? orden.Prioridad === "ALTA"
                          ? "red"
                          : orden.Prioridad === "MEDIA"
                          ? "orange"
                          : "green"
                        : "grey",
                  }}
                >
                  <strong>PRIORIDAD {orden.Prioridad}</strong>
                </span>
              </Typography>
              <br />
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <span
                  style={{
                    color: "GrayText",
                  }}
                >
                  {orden.PalabrasClaves.length > 0 &&
                    orden.PalabrasClaves.map((item, index) =>
                      index === 0 ? item.PalabraClave : ", " + item.PalabraClave
                    )}
                </span>
              </Typography>
              <br />
              <span>
                {orden.Destinatarios &&
                  orden.Destinatarios.map((dest, index) =>
                    orden.EstadoDeLaOrden === "PENDIENTE" &&
                    rolAdministrador ? (
                      dest.Whatsapp !== null ? (
                        <EnviarPorWhatsapp
                          key={index}
                          setActualizarEnvioPorWP={setActualizarEnvioPorWP}
                          destinatario={dest}
                          mensajeWP={mensajeWP}
                          parametro={encriptar(
                            dest.IDOrdenDeServicio +
                              "|" +
                              dest.IDUsuarioDestinatario
                          )}
                          index={index}
                          setBoton={setBoton}
                          boton={{
                            icono: "SEND",
                            cantidadEnviosPorWhatsapp:
                              dest.CantidadEnviosPorWhatsapp,
                            cantidadRespuestasPorWhatsapp:
                              dest.CantidadRespuestasPorWhatsapp,
                            texto: ` ${dest.Nombre} ${dest.Apellido}`,
                          }}
                        />
                      ) : // `${dest.Nombre} ${dest.Apellido} `
                      index > 0 ? (
                        ` - ${dest.Nombre} ${dest.Apellido}`
                      ) : (
                        ` ${dest.Nombre} ${dest.Apellido}`
                      )
                    ) : index > 0 ? (
                      ` - ${dest.Nombre} ${dest.Apellido}`
                    ) : (
                      ` ${dest.Nombre} ${dest.Apellido}`
                    )
                  )}
              </span>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
};

export default ItemOrdenDeServicio;
