import React from "react";
import { createContext, useState } from "react";

const PlanificacionContext = createContext();

const PlanificacionProvider = ({ children }) => {
  const initOrdenDeServicio = {
    idUsuario: "",
    idObra: "",
    idDelegado: "", // Es el ID del usuario al que se le delega la tarea.
    prioridad: "",
    fechaProgramada: "",
    horaProgramada: "",
  };

  const [ordenDeServicio, setOrdenDeServicio] = useState(initOrdenDeServicio);
  const [
    ordenDeServicioSeleccionada,
    setOrdenDeServicioSeleccionada,
  ] = useState({});
  //   const [codigoPedido, setCodigoPedido] = useState("");

  const data = {
    ordenDeServicio,
    setOrdenDeServicio,
    ordenDeServicioSeleccionada,
    setOrdenDeServicioSeleccionada,
  };

  return (
    <PlanificacionContext.Provider value={data}>
      {children}
    </PlanificacionContext.Provider>
  );
};

export { PlanificacionProvider };
export default PlanificacionContext;
