import React, { useContext, useEffect, useState } from "react";
// import EstilosContext from "../../contextos/EstilosContext";
// import { desencriptar } from "../../funciones/Funciones";

const PageDashboard = ({ setTitulo, setPagina }) => {
  // const { isMobile, setIsMobile } = useState(false);

  // let usu = desencriptar(localStorage.getItem("objusu"));
  // const [usuario, setUsuario] = useState(usu);

  // useEffect(() => {
  //   // alert("window.isNativeApp: " + window.isNativeApp);
  //   const isMobileChrome = /Android.+Chrome/.test(navigator.userAgent);

  //   setIsMobile(window.isNativeApp === true || isMobileChrome ? true : false);
  // }, []);

  useEffect(() => {
    setTitulo("Dashboard");
    setPagina("");

    // console.log("A");
  }, [setTitulo]);

  // useEffect(() => {
  //   console.log("usu: ", usuario);
  //   alert("isMobie: ", isMobile);
  //   alert("usuario: ", usuario);

  //   if (usuario && isMobile) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         accion: "usuario",
  //         contenido: {
  //           usuario: usuario,
  //         },
  //       })
  //     );
  //   }
  // }, [usuario, isMobile]);

  return <div>PageDashboard</div>;
};

export default PageDashboard;
