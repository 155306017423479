import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

const MensajesContext = createContext();

const MensajesProvider = ({ children }) => {
  const initMensaje = { text: "", tipo: "" };

  const [mostrarModal, setMostrarModal] = useState({
    mostrar: false,
    titulo: "",
    mensaje: "",
  });

  const [mostrarMensaje, setMostrarMensaje] = useState(false);
  const [mensaje, setMensaje] = useState(initMensaje);

  if (mostrarMensaje) {
    setTimeout(() => {
      setMensaje(initMensaje);
      setMostrarMensaje(false);
    }, 3000);
  }

  const data = {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  };

  return (
    <MensajesContext.Provider value={data}>{children}</MensajesContext.Provider>
  );
};

export { MensajesProvider };
export default MensajesContext;
