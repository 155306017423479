import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
} from "@mui/material";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useEffect } from "react";
import EstilosContext from "../../../contextos/EstilosContext";
import ServidorContext from "../../../contextos/ServidorContext";
import MensajesContext from "../../../contextos/MensajesContext";
import { useNavigate } from "react-router-dom";
// import {
//   eliminar_sociedad,
//   eliminar_documento,
//   eliminar_invitado,
// } from "../../funciones/FuncionesABM";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  //   container: {
  //     width: "100%",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  //   contenedor: {
  //     // position: "absolute",
  //     // top: "50%",
  //     // left: "50%",
  //     // transform: "translate(-50%, -50%)",
  //     display: "flex",
  //     justifyContent: "center",
  //     width: "auto",
  //     bgcolor: "background.paper",
  //     border: "2px solid #000",
  //     boxShadow: 24,
  //     p: 2,
  //   },
  exito: {
    color: "#13b90b",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
  error: {
    color: "#FF0000",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
};

const ModalPlano = ({
  plano,
  openModal,
  setOpenModal,
  accion,
  nuevaImagen,
  setNuevaImagen,
}) => {
  let navigate = useNavigate();

  //   const [plano, setPlano] = useState("");
  const [leyendas, setLeyendas] = useState({ titulo: "", pregunta: "" });

  const [imagen, setImagen] = useState("");

  //   const [mensaje, setMensaje] = useState({
  //     text: "",
  //     mostrar: false,
  //     error: false,
  //   });

  const { estilos, screenWidth } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { mensaje, setMensaje, setMostrarMensaje } =
    useContext(MensajesContext);

  const eliminar_plano = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ELIMINAR_PLANO",
        idObra: plano.IDObra,
        idPlano: plano.IDPlano,

        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "BAJA_OK") {
      setMensaje({
        text: "El plano ha sido eliminado con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      navigate("/dashboardControlDeAvance");

      // console.log(respuesta.result.respuesta);
      // setAsignaciones(respuesta.result.asignaciones);
      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      setMensaje({
        text: "Ocurrió un error al eliminar el plano!",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN eliminar_plano()");
    }
  };

  const handleClose = () => setOpenModal(false);

  const upload_imagen_de_plano = async () => {
    // event.preventDefault();

    setTimeout(async () => {
      const file = await new Promise((resolve) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.addEventListener("change", (event) =>
          resolve(event.target.files[0])
        );
        fileInput.click();
      });

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // setSelectedFiles([
          //   ...selectedFiles,
          //   {
          //     url: reader.result,
          //     plano: plano,
          //     idTipoPlano: plano.IDTipoDePlano,
          //   },
          // ]);
          // setImagen(reader.result);

          // console.log("reader: ", reader.result);
          setNuevaImagen(reader.result);
          // setImagen("nueva");
        };
        reader.readAsDataURL(file);

        // agregar_plano();
      }
    }, 500);

    // setMostrarMensaje(true);
  };

  const handleAccion = () => {
    if (accion === "CAMBIAR_IMAGEN") {
      upload_imagen_de_plano();
    } else if (accion === "ELIMINAR_PLANO") {
      eliminar_plano();
    }

    // setMensaje({
    //   text: "El plano ha sido eliminado correctamente.",
    //   mostrar: true,
    //   error: false,
    // });

    setTimeout(() => {
      setOpenModal(false);
      //   setEstado("BAJA_OK");
    }, 3000);
  };

  useEffect(() => {
    if (accion === "CAMBIAR_IMAGEN") {
      setLeyendas({
        titulo: "CAMBIAR IMAGEN DEL PLANO",
        pregunta:
          "Confirma que desea cambiar la imagen del plano " + plano.Plano + "?",
      });
    } else if (accion === "ELIMINAR_PLANO") {
      setLeyendas({
        titulo: "ELIMINAR PLANO",
        pregunta:
          "Confirma que desea eliminar el plano " +
          plano.Plano +
          " y todos sus avances?",
      });
    }
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
      <Box
        component="form"
        sx={style}
        style={estilos.modalWidth}
        noValidate
        autoComplete="off"
      >
        {/* <span>{`width: ${screenWidth}`}</span> */}
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {mensaje.mostrar ? (
          <Grid
            item
            xs={12}
            style={mensaje.error ? styles.error : styles.exito}
          >
            <Typography variant="h6" component="h6">
              {mensaje.text}
            </Typography>
          </Grid>
        ) : (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Typography variant="h5" component="h5">
              {leyendas.titulo}
            </Typography>

            {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
            <div>&nbsp;</div>

            <Typography variant="h6" component="h6">
              {leyendas.pregunta}
            </Typography>

            <div>&nbsp;</div>

            <Grid container spacing={2}>
              <Grid item xs={6} sm={8}></Grid>
              <Grid item xs={3} sm={2}>
                <Button
                  variant="contained"
                  endIcon={<CheckOutlinedIcon />}
                  onClick={handleAccion}
                >
                  Sí
                </Button>
                &nbsp;
              </Grid>
              <Grid item xs={3} sm={2}>
                <Button
                  variant="contained"
                  endIcon={<ClearOutlinedIcon />}
                  onClick={handleClose}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        )}
      </Box>
    </Modal>
  );
};

export default ModalPlano;
