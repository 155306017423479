import React, { useEffect } from "react";

const NoPage = (props) => {
  useEffect(() => {
    props.setTitulo("Página no encontrada");
    props.setPagina("");
  }, [props.setTitulo]);

  return <h1>No se ha encontrado la página.</h1>;
};

export default NoPage;
