import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import DashboardContext from "../contextos/DashboardContext";
import { useContext } from "react";
import { encriptar } from "../funciones/Funciones";
import { useNavigate } from "react-router-dom";
import ServidorContext from "../contextos/ServidorContext";

const WhatsappEnviarMensaje = ({
  numeroTel,
  mensaje,
  pedido,
  setMostrarMensaje,
  setMensaje,
  tipo,
  setLimpiarPedido,
  limpiarPedido,
  // ordenDeServicio
}) => {
  let navigate = useNavigate();

  const { servidor } = useContext(ServidorContext);
  const { dashboard, setDashboard } = useContext(DashboardContext);

  const [boton, setBoton] = useState({ icono: "SEND", texto: "Enviar" });

  // console.log("NUMERO TEL: " + numeroTel);
  // console.log("tipo: " + tipo);

  useEffect(() => {
    if (tipo === "PEDIDO_DE_VOLQUETE") {
      setBoton({ icono: "SEND", texto: "Enviar" });
    } else if (tipo === "PEDIDO_DE_MATERIALES") {
      setBoton({ icono: "SEND", texto: "Enviar" });
    } else if (tipo === "ENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
      setBoton({ icono: "", texto: "Enviar pedido" });
    } else if (tipo === "REENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
      setBoton({ icono: "", texto: "Reenviar pedido" });
      // } else if (tipo === "ENVIAR_ORDEN_DE_SERVICIO") {
      //   setBoton({ icono: "SEND", texto: "Confirmar" });
    } else {
    }
  }, []);

  const URL = "https://wa.me";
  // console.log("numeroTel: ", numeroTel);
  //   let num = "5491158482073";
  numeroTel = numeroTel.replace(/[^\w\s]/gi, "").replace(/ /g, "");

  let url = `${URL}/${numeroTel}`;

  //   let message = "HOLA MUNDO";

  if (mensaje) {
    url += `?text=${encodeURI(mensaje)}`;
  }

  const guardar_pedido_de_volquete = async () => {
    const API_ENDPOINT = servidor + `api_volquetes.php`;

    // console.log("pedido: ", pedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_PEDIDO_DE_VOLQUETE",
        idUsuario: pedido.idUsuario,
        idObra: pedido.idObra,
        idProveedor: pedido.idProveedor,
        cantidad: pedido.cantidad,
        fechaProgramada: pedido.fechaProgramada,
        horaProgramada: pedido.horaProgramada,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      setDashboard({
        ...dashboard,
        listaVolquetes: respuesta.result.volquetes,
      });

      const newDashboard = {
        ...dashboard,
        listaVolquetes: respuesta.result.volquetes,
      };

      // console.log("newDashboard: ", newDashboard);
      localStorage.setItem("objdashboard", encriptar(newDashboard));

      setMensaje({ text: respuesta.result.pedido, tipo: "success" });
      setMostrarMensaje(true);

      setTimeout(() => {
        navigate("/dashboardVolquetes");
      }, 3000);
    } else {
      console.log("NO TRAE NADA EN guardar_pedido_de_volquete()");
    }
  };

  const guardar_pedido_de_materiales = async () => {
    const API_ENDPOINT = servidor + `api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_PEDIDO_DE_MATERIALES",
        idUsuario: pedido.idUsuario,
        idObra: pedido.idObra,
        idProveedor: pedido.idProveedor,
        detallePedido: pedido.detalle,
        fechaProgramada: pedido.fechaProgramada,
        horaProgramada: pedido.horaProgramada,
        codigoPedido: pedido.codigoPedido,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.pedido) {
      // setCodigoPedido(respuesta.result.codigoPedido);
      setMensaje({ text: respuesta.result.pedido, tipo: "success" });
      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_pedido_de_materiales()");
    }
  };

  // const enviar_orden_de_servicio = async () => {
  //   let cambios = {
  //     descripcion: descripcion,
  //     prioridad: ordenDeServicioSeleccionada.Prioridad,
  //     destinatarios: destinatarios,
  //     estado: estado,
  //   };

  //   // console.log("cambios: ", cambios);

  //   const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

  //   // console.log(codigoPedido);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "MODIFICAR_ORDEN_DE_SERVICIO",
  //       idOrdenDeServicio: ordenDeServicioSeleccionada.IDOrdenDeServicio,
  //       descripcion: cambios.descripcion,
  //       prioridad: cambios.prioridad,
  //       destinatarios: cambios.destinatarios,
  //       estado: cambios.estado,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.respuesta == "OK") {
  //     setTimeout(() => {
  //       setMensaje({ tipo: "success", text: "Cambios realizados con éxito!" });
  //       setMostrarMensaje(true);

  //       navigate("/dashboardOrdenesDeServicio");
  //     }, 1500);
  //   } else {
  //     console.log("NO TRAE NADA EN handleConfirm()");
  //     // setState([]);
  //   }
  // };

  const handleClick = () => {
    if (tipo === "PEDIDO_DE_VOLQUETE") {
      guardar_pedido_de_volquete();
    } else if (tipo === "PEDIDO_DE_MATERIALES") {
      guardar_pedido_de_materiales();
    } else if (tipo === "ENVIAR_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
    } else if (tipo === "REENVIO_DE_PEDIDO") {
      // NO GRABO EL PEDIDO PORQUE YA ESTÁ GRABADO, SOLO LO ENVÍO POR WHATSAPP
      // } else if (tipo === "ENVIAR_ORDEN_DE_SERVICIO"){
      //   enviar_orden_de_servicio();
    } else {
    }

    window.open(url);

    setLimpiarPedido(true);
  };

  return (
    <Button
      // variant="contained"
      endIcon={boton.icono === "SEND" ? <SendIcon /> : ""}
      onClick={(e) => handleClick()}
    >
      {boton.texto}
    </Button>
  );
};

export default WhatsappEnviarMensaje;
