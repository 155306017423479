import React from "react";
import { createContext, useState } from "react";

const UsuarioContext = createContext();

const UsuarioProvider = ({ children }) => {
  const initUsuario = {
    IDUsuario: 0,
    Nombre: "",
    Apellido: "",
    Usuario: "",
    Email: "",
    Privilegios: "",
    Token: "",
    FechaToken: "",
    Logueado: "NO",
  };

  const [usuario, setUsuario] = useState(initUsuario);
  const [tokenValido, setTokenValido] = useState(false);

  const data = { usuario, setUsuario, tokenValido, setTokenValido };

  return (
    <UsuarioContext.Provider value={data}>{children}</UsuarioContext.Provider>
  );
};

export { UsuarioProvider };
export default UsuarioContext;
