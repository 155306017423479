// import { Typography } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid } from "@mui/material";

const PedidosItem = (props) => {
  return (
    <>
      <Grid container>
        <Grid item>
          {props.material.estado == "ENTREGA_COMPLETA" ? (
            <CheckCircleIcon
              color="primary"
              style={{
                marginRight: "5px",
              }}
            />
          ) : props.material.estado == "ENTREGA_PARCIAL" ? (
            <RadioButtonCheckedIcon
              color="disabled"
              style={{
                marginRight: "5px",
              }}
            />
          ) : props.material.estado == "NO_ENTREGO" ? (
            <HighlightOffIcon
              color="disabled"
              style={{
                marginRight: "5px",
              }}
            />
          ) : (
            <RadioButtonUncheckedIcon
              color="disabled"
              style={{
                marginRight: "5px",
              }}
            />
          )}
        </Grid>
        <Grid item>
          <Typography key={props.material.idMaterial} variant="subtitle1">
            {props.material.cantidad} {props.material.unidad} de{" "}
            {props.material.material}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PedidosItem;
