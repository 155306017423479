import React, { useContext, useEffect } from "react";

import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";
import PageLogin from "../login/PageLogin";
// import Titulo from "../../components/Titulo";
import PedidosLista from "../pedidos/PedidosLista";
import ServidorContext from "../../contextos/ServidorContext";

const PageDashboardPedidos = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  useEffect(() => {
    props.setTitulo("Dashboard Pedidos");
    props.setPagina("altaPedido");
  }, [props.setTitulo]);

  const tokenValido = async (props) => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      let objusu = desencriptar(localStorage.getItem("objusu"));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "CHEQUEAR_TOKEN_VALIDO",
          tok1: objusu.Tok1,
          tok2: objusu.Tok2,
          // tok1: localStorage.getItem("tok1"),
          // tok2: localStorage.getItem("tok2"),
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // signIn(usuarios.result.usuario.email, usuarios.result.usuario.clave);

        props.setUsuario(usuarios.result.usuario[0]);

        // localStorage.setItem("tok1", usuarios.result.usuario[0].Tok1);
        // localStorage.setItem("tok2", usuarios.result.usuario[0].Tok2);
        // localStorage.setItem("fechaTok", usuarios.result.usuario[0].FechaTok);

        // console.log(usuarios.result.usuario[0]);

        // alert(usuario.Logueado);
        // navigate("/dashboard");
      } else {
        console.log("Token inválido.");
      }

      // return usuarios.result;
      return true;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  return (
    <div>
      {
        usuario.Logueado === "SI" || tokenValido() === true ? (
          <>
            {/* <PedidosLista estado="A_SUPERVISAR" texto="Pedidos a supervisar" /> */}

            <PedidosLista estado="PENDIENTE" texto="Pedidos pendientes" />

            {/* <PedidosLista estado="ENTREGADOS" texto="Pedidos entregados" /> */}
          </>
        ) : (
          ""
        )

        // usuario.Logueado !== "SI"
        //   ?  <PageLogin />

        //       <PedidosLista estado="A_SUPERVISAR" texto="Pedidos a supervisar" />

        // <PedidosLista estado="PENDIENTES" texto="Pedidos pendientes" />

        // <PedidosLista estado="ENTREGADOS" texto="Pedidos entregados" />

        // : ""

        //   : ""
      }
    </div>
  );
};

export default PageDashboardPedidos;
