import React from "react";
import { createContext, useState } from "react";

const InvitadosContext = createContext();

const InvitadosProvider = ({ children }) => {
  const initInvitado = { nya: "", email: "", valid: false };

  const [invitado, setInvitado] = useState(initInvitado);

  const [nombre, setNombre] = useState({
    text: "",
    valid: false,
    error: "Complete el nombre",
  });

  const [email, setEmail] = useState({
    text: "",
    valid: false,
    error: "Complete el E-mail",
  });

  const [permitidos, setPermitidos] = useState([]);

  //   useEffect(() => {
  //     localStorage.setItem("objobra", encriptar(obra));
  //   }, [obra]);

  const data = {
    invitado,
    setInvitado,
    nombre,
    setNombre,
    email,
    setEmail,
    permitidos,
    setPermitidos,
  };

  return (
    <InvitadosContext.Provider value={data}>
      {children}
    </InvitadosContext.Provider>
  );
};

export { InvitadosProvider };
export default InvitadosContext;
