import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
} from "@mui/material";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useEffect } from "react";
import { desencriptar } from "../../funciones/Funciones";
// import {
//   eliminar_sociedad,
//   eliminar_documento,
//   eliminar_invitado,
// } from "../../funciones/FuncionesABM";
import EstilosContext from "../../contextos/EstilosContext";
import ServidorContext from "../../contextos/ServidorContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  //   container: {
  //     width: "100%",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  //   contenedor: {
  //     // position: "absolute",
  //     // top: "50%",
  //     // left: "50%",
  //     // transform: "translate(-50%, -50%)",
  //     display: "flex",
  //     justifyContent: "center",
  //     width: "auto",
  //     bgcolor: "background.paper",
  //     border: "2px solid #000",
  //     boxShadow: 24,
  //     p: 2,
  //   },
  exito: {
    color: "#13b90b",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
  error: {
    color: "#FF0000",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
};

const ModalEliminar = (props) => {
  const [sociedad, setSociedad] = useState("");
  const [leyendas, setLeyendas] = useState({ titulo: "", pregunta: "" });

  const [mensaje, setMensaje] = useState({
    text: "",
    mostrar: false,
    error: false,
  });

  const { estilos, screenWidth } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);

  const eliminar_sociedad = async () => {
    let objobra = desencriptar(localStorage.getItem("objobra"));
    let objsoc = desencriptar(localStorage.getItem("objsoc"));

    const API_ENDPOINT = servidor + `api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "BAJA_SOCIEDAD_EN_HYS",
        idObra: objobra.IDObra,
        idSubcontrato: objsoc.IDSubcontrato,

        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "BAJA_OK") {
      // console.log(respuesta.result.respuesta);

      localStorage.removeItem("objsoc");

      // setAsignaciones(respuesta.result.asignaciones);
      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      console.log("NO TRAE NADA EN eliminar_sociedad()");
    }
  };

  const eliminar_documento = async () => {
    let objobra = desencriptar(localStorage.getItem("objobra"));
    let objdocs = desencriptar(localStorage.getItem("objdocs"));

    const API_ENDPOINT = servidor + `api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "BAJA_DOCUMENTO_EN_HYS",
        idObra: objobra.IDObra,
        idDocumentacion: objdocs.IDDocumentacion,

        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "BAJA_OK") {
      // console.log(respuesta.result.respuesta);

      localStorage.removeItem("objdocs");

      // setAsignaciones(respuesta.result.asignaciones);
      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      console.log("NO TRAE NADA EN eliminar_documento()");
    }
  };

  const eliminar_invitado = async (invitado) => {
    let objobra = desencriptar(localStorage.getItem("objobra"));
    // let objsoc = desencriptar(localStorage.getItem("objsoc"));

    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "BAJA_INVITADO_DE_OBRA",
        idObra: objobra.IDObra,
        idUsuario: invitado.IDUsuario,

        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "BAJA_OK") {
      // console.log(respuesta.result.respuesta);
      // localStorage.removeItem("objsoc");
      // setAsignaciones(respuesta.result.asignaciones);
      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      console.log("NO TRAE NADA EN eliminar_invitado()");
    }
  };

  const handleClose = () => props.setOpenModalEliminar(false);

  const handleEliminar = () => {
    switch (props.seccion) {
      case "SETTINGS-SOCIEDAD":
        eliminar_sociedad();

        setMensaje({
          text: "La sociedad ha sido eliminada correctamente.",
          mostrar: true,
          error: false,
        });

        break;

      case "SETTINGS-DOCUMENTO":
        eliminar_documento();

        setMensaje({
          text: "El documento ha sido eliminado correctamente.",
          mostrar: true,
          error: false,
        });

        break;

      case "ELIMINAR_INVITADO":
        eliminar_invitado(props.invitado);

        setMensaje({
          text: "El invitado ha sido eliminado correctamente.",
          mostrar: true,
          error: false,
        });

        props.setSeccion("");

        break;

      default:
        break;
    }

    setTimeout(() => {
      props.setOpenModalEliminar(false);
      props.setEstado("BAJA_OK");
    }, 3000);
  };

  useEffect(() => {
    switch (props.seccion) {
      case "SETTINGS-SOCIEDAD":
        let objsoc = desencriptar(localStorage.getItem("objsoc"));
        // setSociedad(objsoc);

        setLeyendas({
          titulo: "ELIMINAR SOCIEDAD",
          pregunta:
            "Confirma que desea eliminar la sociedad " +
            objsoc.RazonSocial +
            "?",
        });

        break;

      case "SETTINGS-DOCUMENTO":
        let objdocs = desencriptar(localStorage.getItem("objdocs"));
        // console.log(objdocs);
        setLeyendas({
          titulo: "ELIMINAR DOCUMENTO",
          pregunta:
            "Confirma que desea eliminar el documento " +
            objdocs.Documento +
            "?",
        });

        break;

      case "ELIMINAR_INVITADO":
        setLeyendas({
          titulo: "ELIMINAR INVITADO",
          pregunta:
            "Confirma que desea eliminar el invitado " +
            props.invitado.Nombre +
            " " +
            props.invitado.Apellido +
            "?",
        });

      default:
        break;
    }
  }, []);

  //   const handleEliminar = async () => {
  //     const API_ENDPOINT = servidor + `api_hys.php`;

  //     let formEliminar = {
  //       operacion: "BAJA_RELACION_TAREA_RECURSO",
  //       codigoTarea: props.codTarea,
  //       codigoRecurso: props.codRecurso,
  //     };

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formEliminar),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);
  //     // alert(respuesta.result.guardado);
  //     if (respuesta.result.eliminado === "BAJA_OK") {
  //       console.log(respuesta.result.eliminado);
  //       // setLista(respuesta.result.listaRecursos);

  //       props.setEstado("BAJA_OK");

  //       handleClose();

  //       // alert("Lista de precios actualizada.");
  //     } else {
  //       console.log("ERROR AL ELIMINAR.");
  //       // setState([]);
  //     }
  //   };

  return (
    <Modal
      open={props.openModalEliminar}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
      <Box
        component="form"
        sx={style}
        style={estilos.modalWidth}
        noValidate
        autoComplete="off"
      >
        {/* <span>{`width: ${screenWidth}`}</span> */}
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {mensaje.mostrar ? (
          <Grid
            item
            xs={12}
            style={mensaje.error ? styles.error : styles.exito}
          >
            <Typography variant="h6" component="h6">
              {mensaje.text}
            </Typography>
          </Grid>
        ) : (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Typography variant="h5" component="h5">
              {leyendas.titulo}
            </Typography>

            {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
            <div>&nbsp;</div>

            <Typography variant="h6" component="h6">
              {leyendas.pregunta}
            </Typography>

            <div>&nbsp;</div>

            <Grid container spacing={2}>
              <Grid item xs={6} sm={8}></Grid>
              <Grid item xs={3} sm={2}>
                <Button
                  variant="contained"
                  endIcon={<CheckOutlinedIcon />}
                  onClick={handleEliminar}
                >
                  Sí
                </Button>
                &nbsp;
              </Grid>
              <Grid item xs={3} sm={2}>
                <Button
                  variant="contained"
                  endIcon={<ClearOutlinedIcon />}
                  onClick={handleClose}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        )}
      </Box>
    </Modal>
  );
};

export default ModalEliminar;
