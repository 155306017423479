import { Checkbox, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import EstilosContext from "../../contextos/EstilosContext";
import ServidorContext from "../../contextos/ServidorContext";

const PageAgregarDestinatarios = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { screenWidth } = useContext(EstilosContext);

  const [destinatarios, setDestinatarios] = useState([]);
  const [destinatariosSeleccionados, setDestinatariosSeleccionados] = useState(
    []
  );

  useEffect(() => {
    props.setTitulo("Agregar destinatarios");
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    // setPaso2(desencriptar(localStorage.getItem("objordserv")));

    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    traer_destinatarios();
  }, []);

  useEffect(() => {
    return () => {
      let paso4 = desencriptar(localStorage.getItem("objordserv"));

      // console.log("paso2", paso2);

      let paso5 = { ...paso4, destinatarios: destinatariosSeleccionados };

      localStorage.setItem("objordserv", encriptar(paso5));
    };
  }, [destinatariosSeleccionados]);

  const traer_destinatarios = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    // console.log("obra: ", obra.IDObra);
    // console.log("usuario: ", usuario.IDUsuario);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_DESTINATARIOS",
        idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.destinatarios) {
      setDestinatarios(respuesta.result.destinatarios);
    } else {
      console.log("NO TRAE NADA EN traer_destinatarios()");
      // setState([]);
    }
  };

  const handleChange = async (event, item) => {
    // console.log("item", item);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
    // console.log("IDDocumentacion: " + item.IDDocumentacion);
    // console.log("checked: " + event.target.checked);

    setDestinatariosSeleccionados([...destinatariosSeleccionados, item]);

    // setCheck(event.target.checked);
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <div style={{ marginBottom: "30px", fontSize: "20px" }}>
            Seleccione a quién delegará la tarea:
          </div>
        </Grid>

        {destinatarios.map((dest, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent:
                screenWidth === "xs" ||
                screenWidth === "sm" ||
                screenWidth === "md" ||
                screenWidth === "lg" ||
                screenWidth === "xl"
                  ? "left"
                  : "center",
              alignItems: "center",
              //   border: "1px solid #CCCCCC",
            }}
          >
            {/* <div key={dest.IDUsuario} style="width: 30px"> */}
            <Checkbox
              id={dest.IDUsuario}
              key={dest.IDUsuario}
              icon={
                <HighlightOffOutlinedIcon
                  sx={{ fontSize: 40, color: "#FF0000" }}
                />
              }
              checkedIcon={
                <CheckCircleIcon sx={{ fontSize: 40 /*color: "#21AF19"*/ }} />
              }
              // checked={dest.some(
              //   (item) =>
              //     item.IDUsuario == dest.IDUsuario &&
              //     item.Asignado = 1
              // )}
              onChange={(event) => handleChange(event, dest)}
            />
            {dest.Nombre.toUpperCase()} {dest.Apellido.toUpperCase()}
            {/* </div> */}
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Navegacion nroPaso={props.nroPaso} />
        </Grid>
      </Grid>
    </>
  );
};

export default PageAgregarDestinatarios;
