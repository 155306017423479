import React, { useEffect } from "react";

const PageDashboardMateriales = (props) => {
  useEffect(() => {
    props.setTitulo("Administración de materiales");
    props.setPagina("altaMateriales");
  }, [props.setTitulo]);

  return <div style={{ fontSize: 100 }}>Materiales</div>;
};

export default PageDashboardMateriales;
