import React from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import { ThemeProvider, makeStyles, useTheme } from "@mui/material/styles";
import { ObraProvider } from "./contextos/ObraContext";
import { UsuarioProvider } from "./contextos/UsuarioContext";
import PageLogin from "./pages/login/PageLogin";
import { PrivilegioProvider } from "./contextos/PrivilegiosContext";
import { MensajesProvider } from "./contextos/MensajesContext";

// const theme = createTheme();

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { EstilosProvider } from "./contextos/EstilosContext";

import PageConfirmarPedido from "./pages/proveedores/PageConfirmarPedido";
import { useState } from "react";
import { DashboardProvider } from "./contextos/DashboardContext";
import { useEffect } from "react";
import { useContext } from "react";
import { Container } from "@mui/material";
import { ServidorProvider } from "./contextos/ServidorContext";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const App = () => {
  // useEffect(() => {
  //   if (window.ReactNativeWebView) {
  //     // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there

  //     // window.ReactNativeWebView.postMessage(
  //     //   "Hola desde la página web! MENSAJE 2"
  //     // );
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         accion: "notification",
  //         contenido: {
  //           notificacion: {
  //             titulo: "Este es el TITULO",
  //             body: "Este es el BODY",
  //           },
  //         },
  //       })
  //     );
  //   }
  // }, []);

  // let sendMessageToNative = (msg) => {
  //   console.log("msg: " + msg);
  //   window.ReactNativeWebView &&
  //     window.ReactNativeWebView.postMessage(msg, "*");
  // };
  // setTimeout(function() {
  //   sendMessageToNative("Bienvenido Usuario!");
  //   // window.ReactNativeWebView.postMessage("Bienvenido!");
  // }, 2000);

  // const [mostrar, setMostrar] = useState("");
  // const [params, setParams] = useState("");
  // const [titulo, setTitulo] = useState("");
  // const [pagina, setPagina] = useState("");

  // useEffect(() => {
  //   let arrURL = window.location.href.split("/");

  //   if (arrURL.length > 3) {
  //     if (arrURL[3] === "confirmarPedido") {
  //       setMostrar("FORMULARIO_CONFIRMAR_PEDIDO");
  //       setParams(arrURL[4]);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   // const data = { message: "Hola desde la página web" };
  //   // window.ReactNativeWebView.postMessage("Hola desde la página web!");

  //   if (window.ReactNativeWebView) {
  //     // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there

  //     window.ReactNativeWebView.postMessage(
  //       "Hola desde la página web! MENSAJE 2"
  //     );
  //   }

  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ServidorProvider>
          <MensajesProvider>
            <UsuarioProvider>
              <PrivilegioProvider>
                <ObraProvider>
                  <EstilosProvider>
                    <DashboardProvider>
                      {/* {console.log("mostrar: " + mostrar)}
                  {mostrar === "FORMULARIO_CONFIRMAR_PEDIDO" ? (
                    <>
                      <Routes>
                        <Route
                          path="/confirmarPedido"
                          element={
                            // <PedidoProvider>

                            <PageConfirmarPedido
                              setTitulo={setTitulo}
                              setPagina={setPagina}
                            />
                            // </PedidoProvider>
                          }
                        />
                      </Routes>
                    </>
                  ) : ( */}

                      <PageLogin />

                      {/* )} */}
                    </DashboardProvider>
                  </EstilosProvider>
                </ObraProvider>
              </PrivilegioProvider>
            </UsuarioProvider>
          </MensajesProvider>
        </ServidorProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
