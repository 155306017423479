import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Botonera from "../../components/Botonera";
import TablaDeTareas from "./TablaDeTareas";
import SendIcon from "@mui/icons-material/Send";
import ObraContext from "../../contextos/ObraContext";
import { useNavigate } from "react-router-dom";
import Encabezado from "./Encabezado";

const PageListadoDeTareas = (props) => {
  const { obra, setObra } = useContext(ObraContext);

  let navigate = useNavigate();

  useEffect(() => {
    props.setTitulo("Listado de tareas");
    props.setPagina("");

    if (obra.IDObra === undefined) {
      navigate("/presupuestosBTP");
    }
  }, [props.setTitulo]);

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      <Grid container columns={{ xs: 12 }} border={0}>
        <Grid item xs={12} border={0}>
          <TablaDeTareas />
        </Grid>

        <Grid item xs={12} border={0}></Grid>

        {/* <Grid item xs={10} border={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => enviarListaDePrecios()}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default PageListadoDeTareas;
