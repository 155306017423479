import React, { useContext, useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import UsuarioContext from "../../contextos/UsuarioContext";
import { Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  contieneCodigoLaEntrada,
  desencriptar,
  encriptar,
  enviarPosicionHaciaReactNative,
} from "../../funciones/Funciones";
import PageRegistro from "../general/PageRegistro";
import Navbar from "../../components/Navbar";
import Logo from "../../components/Logo";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import ServidorContext from "../../contextos/ServidorContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageFormularioLogin = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { setMostrarMensaje, setMensaje, mostrarMensaje, mensaje } =
    useContext(MensajesContext);

  const { isMobile } = useContext(EstilosContext);

  let navigate = useNavigate();

  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [clave, setClave] = useState({ text: "", valid: false, error: "" });

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  const validarFormulario = () => {
    if (email.valid && clave.valid) {
      chequearUsuario();
    } else {
      // alert("Datos incorrectos.");
      setMensaje({ text: "Datos incorrectos", tipo: "error" });

      setMostrarMensaje(true);
    }
  };

  const validarEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    if (!contieneCodigoLaEntrada(text)) {
      // VALIDACIÓN DE USUARIO
      if (text.length > 2) {
        setEmail({ text: text, valid: true, error: "" });
      } else {
        setEmail({
          text: text,
          valid: false,
          error: "Email o usuario incorrecto",
        });
      }
    } else {
      setEmail({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }

    // VALIDACIÓN DE MAIL
    // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    // if (reg.test(text) === false) {
    //     if (text.length == 0){
    //         // Email vacío
    //         setEmail({ text: text, valid: false, error: '' })
    //     }else{
    //         setEmail({ text: text, valid: false, error: 'Email incorrecto' })
    //     }

    //     return false;
    // }
    // else {
    //     // console.log(text.length);

    //     // console.log("Email is Correct");
    //     setEmail({ text: text, valid: true, error: '' });
    // }
  };

  const validarClave = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Clave vacía.
        setClave({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 3) {
        // console.log("Clave muy corta.");
        setClave({
          text: text,
          valid: false,
          error: "Contraseña muy corta",
        });
      } else {
        // console.log("Clave OK.");
        setClave({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setClave({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const chequearUsuario = async () => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // mode: "no-cors",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "GET_USUARIO_POR_EMAIL_Y_CLAVE",
          email: email.text,
          clave: clave.text,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log("usuario: ", usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        if (usuarios.result.invitacionOK == "SI") {
          // signIn(usuarios.result.usuario.email, usuarios.result.usuario.clave);

          setUsuario(usuarios.result.usuario[0]);

          // localStorage.setItem("tok1", usuarios.result.usuario[0].Tok1);
          // localStorage.setItem("tok2", usuarios.result.usuario[0].Tok2);
          // alert("PASA");
          // let userdata = {
          //   IDUsuario: usuarios.result.usuario[0].IDUsuario,
          //   Usuario: usuarios.result.usuario[0].Usuario,
          //   Email: usuarios.result.usuario[0].Email,
          //   Privilegios: usuarios.result.usuario[0].Privilegios,
          // };

          let encrip = encriptar(usuarios.result.usuario[0]);

          // if (window.ReactNativeWebView) {
          //   // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
          //   // window.ReactNativeWebView.postMessage(encrip);
          // } else {
          localStorage.setItem("objusu", encrip);
          // }

          // enviarPosicionHaciaReactNative(isMobile, "usuario", {
          //   usuario: usuarios.result.usuario[0],
          //   sacarFoto: false,
          // });

          enviarPosicionHaciaReactNative(isMobile, "banner", {
            usuario: usuarios.result.usuario[0],
            tipoDeBanner: "BANNER",
            idPublicidad: "ca-app-pub-9747826938175612/8150779805",
            idBanner: "ca-app-pub-9747826938175612/8150779805",
            sacarFoto: false,
          });

          // if (isMobile && window.ReactNativeWebView) {
          //   window.ReactNativeWebView.postMessage(
          //     JSON.stringify({
          //       accion: "usuario",
          //       contenido: {
          //         usuario: usuario,
          //       },
          //     })
          //   );
          // }

          // let usu = desencriptar(localStorage.getItem("objusu"));

          // console.log(usu);

          // localStorage.setItem("fechaTok", usuarios.result.usuario[0].FechaTok);

          // console.log(usuarios.result.usuario[0]);

          // alert(usuario.Logueado);
          // navigate("/dashboard");
        } else {
          setMensaje({
            text: "La invitación solicitada está siendo evaluada. Esto puede demorar hasta 24hs.\n ",
            tipo: "error",
          });

          setMostrarMensaje(true);
        }
      } else {
        // alert("E-mail o contraseña incorrectos");
        setMensaje({
          text: "E-mail o contraseña incorrectos",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }

      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleRegistro = () => {
    // console.log("PASA POR REGISTRO");

    props.setMostrar("FORMULARIO_REGISTRO");

    navigate("/registro");
  };

  const handleOlvideClave = () => {
    // console.log("PASA POR OLVIDÉ MI CLAVE");

    props.setMostrar("FORMULARIO_OLVIDE_CLAVE");

    navigate("/olvideClave");
  };

  const handleQuieroSerProveedor = () => {
    // console.log("PASA POR REGISTRO");

    props.setMostrar("QUIERO_SER_PROVEEDOR");

    navigate("/quieroSerProveedor");
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          m: 1,
          mt: 20,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          height: 300,
          borderRadius: 1,
        }}
      >
        <Item sx={{ width: { xs: 300, md: 350 } }}>
          {/* <Box
            component="img"
            sx={{
              height: 48,
              width: 226,
              maxHeight: { xs: 48, md: 72 },
              maxWidth: { xs: 226, md: 339 },
              mt: 1,
            }}
            alt="Bimtracker"
            src="bimtracker.png"
          /> */}
          <Logo />
          <Box
            component="form"
            sx={{
              height: 40,
              width: "100%",
              // border: 1,
              marginTop: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              required
              id="standard-required"
              label="Usuario o E-mail"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={validarEmail}
            />
            <div style={styles.error}>{email.error}</div>

            {/* <br />
            <br /> */}
            <TextField
              required
              id="standard-password-input"
              label="Password"
              type="password"
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              variant="standard"
              style={{ width: "80%" }}
              onChange={validarClave}
            />
            <div style={styles.error}>{clave.error}</div>
            {/* <br />
            <br />
            <br /> */}
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => validarFormulario()}
            >
              Ingresar
            </Button>
          </Box>
        </Item>
      </Box>
      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          // m: 1,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          // height: 300,
          borderRadius: 1,
        }}
      >
        <Button size="small" onClick={() => handleRegistro()}>
          Registro
        </Button>{" "}
        |
        <Button size="small" onClick={() => handleOlvideClave()}>
          Olvidé mi contraseña
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          // m: 1,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          // height: 300,
          borderRadius: 1,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleQuieroSerProveedor()}
        >
          QUIERO SER PROVEEDOR
        </Button>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          // flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          p: 1,
          // m: 1,
          // border: 1,
          bgcolor: "background.paper",
          // maxWidth: 380,
          // width: { xs: 300, md: 400 },
          // height: 300,
          borderRadius: 1,
        }}
      >
        <img src="./banner-prueba-728x90.png" alt="" />
      </Box> */}
    </div>
  );
};

export default PageFormularioLogin;
