import React, { useRef } from "react";
import { useEffect } from "react";

import { useState } from "react";

import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { SettingsRemote } from "@mui/icons-material";
// import { useMediaQuery } from "react-responsive";

const PageAltaOrdenDeServicio = (props) => {
  const inputRef = useRef();

  const [img, setImg] = useState("");

  useEffect(() => {
    props.setTitulo("Ordenes de servicios");
    props.setPagina("");
  }, [props.setTitulo]);

  //   useEffect(() => {
  //     console.log("PASO", 1);
  //   }, []);

  let navigate = useNavigate();

  //   const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButton = (btn) => {
    if (btn === 1) {
      navigate("/agregarArchivos");
    } else if (btn === 2) {
      navigate("/grabarVideo");
    } else if (btn === 3) {
      // inputRef.current.click();
      // navigate("/buscarArchivos");
      navigate("/buscarArchivos");
    }
  };

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;
      // console.log("imageUrl: ", imageUrl);

      // setImg(imageUrl);
      navigate("/agregarArchivos");
      // Aquí puedes utilizar la URL de la imagen para mostrarla o procesarla
    };
    reader.readAsDataURL(file);
  }

  return (
    // <Box
    //   component="span"
    //   sx={{
    //     pt: 2,
    //     border: "1px solid grey",
    //     width: "150px",
    //     height: "150px",
    //     textAlign: "center",
    //     marginLeft: "5px",
    //     marginRight: "5px",
    //     marginTop: "5px",
    //   }}
    // >
    // <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     // alignItems: "flex-start",
    //     height: "100vh",
    //   }}
    // >

    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        // border: "1px solid #CCCCCC",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <ImageSearchOutlinedIcon
          sx={{
            fontSize: 80,
            borderRadius: "50%",
            border: "1px solid gray",
            boxShadow: "inset 0px 0px 5px gray",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => handleButton(3)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button variant="text" onClick={() => handleButton(3)}>
              BUSCAR
            </Button>
          </Typography>
        </Typography>
        {/* <input
          type="file"
          accept="image/*"
          ref={inputRef}
          style={{ display: "none" }}
          // onChange={handleFileChange}
          onClick={() => handleButton(3)}
          // onClick={() => alert("CLICK")}
        /> */}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CameraAltOutlinedIcon
          sx={{
            fontSize: 80,
            borderRadius: "50%",
            border: "1px solid gray",
            boxShadow: "inset 0px 0px 5px gray",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => handleButton(1)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button variant="text" onClick={() => handleButton(1)}>
              FOTO
            </Button>
          </Typography>
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <VideocamOutlinedIcon
          sx={{
            fontSize: 80,
            borderRadius: "50%",
            border: "1px solid gray",
            boxShadow: "inset 0px 0px 5px gray",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => handleButton(2)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button variant="text" onClick={() => handleButton(2)}>
              VIDEO
            </Button>
          </Typography>
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={img} />
      </Grid>
    </Grid>

    //  <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
    //     <Typography variant="h6" style={{ fontSize: "16px" }}>
    //       <Button variant="text" onClick={() => handleButton(1)}>
    //         FOTO
    //       </Button>
    //     </Typography>
    //   </Typography>
    // </Box>

    // <div
    //   style={{
    //     display: "grid",
    //     placeItems: "center",
    //     gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    //   }}
    // >
    //   <CameraAltOutlinedIcon
    //     sx={{
    //       fontSize: 80,
    //       borderRadius: "50%",
    //       border: "1px solid gray",
    //       boxShadow: "inset 0px 0px 5px gray",
    //       p: 1,
    //       cursor: "pointer",
    //     }}
    //     onClick={() => handleButton(1)}
    //   />
    //   <VideocamOutlinedIcon
    //     sx={{
    //       fontSize: 80,
    //       borderRadius: "50%",
    //       border: "1px solid gray",
    //       boxShadow: "inset 0px 0px 5px gray",
    //       p: 1,
    //       cursor: "pointer",
    //     }}
    //     onClick={() => handleButton(2)}
    //   />
    // </div>
  );
};

export default PageAltaOrdenDeServicio;
