import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const TareasConfig = () => {
  let navigate = useNavigate();

  const handleImportarTareas = () => {
    navigate("/configImportarTareas");
  };
  return (
    <>
      <Button
        // variant="contained"
        onClick={() => handleImportarTareas()}
        sx={{ mt: 1, mr: 1 }}
      >
        Importar tareas de otra obra
      </Button>
    </>
  );
};

export default TareasConfig;
