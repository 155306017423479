import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import Encabezado from "./Encabezado";
// import { makeStyles } from "@mui/styles";
import ObraContext from "../../contextos/ObraContext";
import ServidorContext from "../../contextos/ServidorContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   linkStyle: {
//     color: "white",
//     textDecoration: "none",
//   },
// }));

const PageListaPrecios = (props) => {
  // const classes = useStyles();

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [mostrarBotonDescarga, setMostrarBotonDescarga] = useState(false);
  const [archExcel, setArchExcel] = useState("");

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  useEffect(() => {
    props.setTitulo("Lista de precios");
    props.setPagina("");

    generar_excel();
  }, []);

  const generar_excel = async () => {
    // console.log(texto);
    // console.log("listo presupuesto");

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GENERAR_EL_EXCEL_LISTA_DE_PRECIOS",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.archExcel) {
      // console.log("archExcel: " + respuesta.result.archExcel);
      //   setTexto("");

      setArchExcel(servidor + respuesta.result.archExcel);
      setMostrarBotonDescarga(true);
    } else {
      setMostrarBotonDescarga(false);
      setArchExcel("");
      console.log("NO TRAE NADA EN generar_excel()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      {mostrarProgress ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        ""
      )}

      {mostrarBotonDescarga ? (
        <Button variant="contained" href={archExcel}>
          Descargar lista de precios
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default PageListaPrecios;
