import React from "react";
import { createContext, useState } from "react";

const OrdenesDeServicioContext = createContext();

const OrdenesDeServicioProvider = ({ children }) => {
  const initOrdenDeServicio = {
    idUsuario: "",
    idObra: "",
    idDelegado: "", // Es el ID del usuario al que se le delega la tarea.
    prioridad: "",
    fechaProgramada: "",
    horaProgramada: "",
  };

  const [ordenDeServicio, setOrdenDeServicio] = useState(initOrdenDeServicio);
  const [ordenDeServicioSeleccionada, setOrdenDeServicioSeleccionada] =
    useState({});

  const [lstOrdenesDeServicio, setLstOrdenesDeServicio] = useState([]);
  const [todasLasOrdenes, setTodasLasOrdenes] = useState([]);
  const [filtro, setFiltro] = useState([])

  //   const [codigoPedido, setCodigoPedido] = useState("");

  const data = {
    ordenDeServicio,
    setOrdenDeServicio,
    ordenDeServicioSeleccionada,
    setOrdenDeServicioSeleccionada,
    lstOrdenesDeServicio,
    setLstOrdenesDeServicio,
    todasLasOrdenes,
    setTodasLasOrdenes,
filtro, setFiltro
  };

  return (
    <OrdenesDeServicioContext.Provider value={data}>
      {children}
    </OrdenesDeServicioContext.Provider>
  );
};

export { OrdenesDeServicioProvider };
export default OrdenesDeServicioContext;
