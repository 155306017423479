import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import UsuarioContext from "../../contextos/UsuarioContext";

import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import {
  contieneCodigoLaEntrada,
  desencriptar,
  encriptar,
} from "../../funciones/Funciones";
import { registerLocale } from "react-datepicker";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import PageSeleccionarModulos from "./PageSeleccionarModulos";
import EncabezadoItems from "../../components/EncabezadoItems";
import ServidorContext from "../../contextos/ServidorContext";

const steps = ["Paso 1", "Paso 2"];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const styles = {
  container: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "#FF0000",
    marginBottom: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    // borderWidth: 1,
    // borderColor: "#FF0000",
  },
};

const PageRegistro = (props) => {
  var objregusu = desencriptar(localStorage.getItem("objregusu"));

  useEffect(() => {
    props.setTitulo("Registro");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  const [idUsuario, setIdUsuario] = useState("");
  const [nombre, setNombre] = useState({ text: "", valid: false, error: "" });
  const [apellido, setApellido] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [whatsapp, setWhatsapp] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [user, setUser] = useState({ text: "", valid: false, error: "" });
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [clave, setClave] = useState({ text: "", valid: false, error: "" });

  const [disable, setDisable] = useState({ usuario: false, email: false });

  // useEffect(() => {
  //   console.log(user.valid);
  //   console.log(email.valid);
  // }, [user.valid, email.valid]);

  useEffect(() => {
    // let objregusu = desencriptar(localStorage.getItem("objregusu"));

    if (objregusu.IDUsuario != undefined) {
      setDisable({ usuario: true, email: true });
      setIdUsuario(objregusu.IDUsuario);
      setNombre({ text: objregusu.Nombre, valid: true, error: "" });
      setApellido({ text: objregusu.Apellido, valid: true, error: "" });
      setWhatsapp({ text: objregusu.Whatsapp, valid: true, error: "" });
      setUser({ text: objregusu.Usuario, valid: true, error: "" });
      setEmail({ text: objregusu.Email, valid: true, error: "" });
      setClave({ text: objregusu.Clave, valid: true, error: "" });
    }
  }, []);

  const { servidor } = useContext(ServidorContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  let navigate = useNavigate();

  const validarFormulario = () => {
    // chequearSiUsuarioExiste();

    // chequearSiEmailExiste();

    if (
      nombre.valid &&
      apellido.valid &&
      whatsapp.valid &&
      user.valid &&
      email.valid &&
      clave.valid
    ) {
      //   let objregusu = desencriptar(localStorage.getItem("objregusu"));

      if (objregusu.IDUsuario != undefined) {
        modificacion_usuario();
      } else {
        alta_usuario();
      }
    } else {
      if (!nombre.valid) {
        setMensaje({ text: "Nombre incorrecto", tipo: "error" });
      } else if (!apellido.valid) {
        setMensaje({ text: "Apellido incorrecto", tipo: "error" });
      } else if (!whatsapp.valid) {
        setMensaje({ text: "Whatsapp incorrecto", tipo: "error" });
      } else if (!user.valid) {
        setMensaje({ text: "Usuario incorrecto", tipo: "error" });
      } else if (!email.valid) {
        setMensaje({ text: "E-mail incorrecto", tipo: "error" });
      } else if (!clave.valid) {
        setMensaje({ text: "Contraseña incorrecta", tipo: "error" });
      } else {
        setMensaje({ text: "Datos incorrectos", tipo: "error" });
      }

      setMostrarMensaje(true);
    }
  };

  const validarNombre = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Nombre vacío.
        setNombre({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 3) {
        // console.log("Nombre muy corto.");
        setNombre({
          text: text,
          valid: false,
          error: "Nombre muy corto",
        });
      } else {
        // console.log("Nombre OK.");
        setNombre({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setNombre({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const validarApellido = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Apellido vacío.
        setApellido({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 3) {
        // console.log("Apellido muy corto.");
        setApellido({
          text: text,
          valid: false,
          error: "Apellido muy corto",
        });
      } else {
        // console.log("Apellido OK.");
        setApellido({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setApellido({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const validarWhatsapp = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Usuario vacío.
        setWhatsapp({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 8) {
        // console.log("Usuario muy corto.");
        setWhatsapp({
          text: text,
          valid: false,
          error: "Whatsapp muy corto",
        });
      } else {
        // console.log("Whatsapp OK.");
        setWhatsapp({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setWhatsapp({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const validarUser = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Usuario vacío.
        setUser({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 3) {
        // console.log("Usuario muy corto.");
        setUser({
          text: text,
          valid: false,
          error: "Usuario muy corto",
        });
      } else {
        // console.log("Usuario OK.");
        setUser({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setUser({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const validarEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE USUARIO
    // if (text.length > 2) {
    //   setEmail({ text: text, valid: true, error: "" });
    // } else {
    //   setEmail({
    //     text: text,
    //     valid: false,
    //     error: "Email o usuario incorrecto",
    //   });
    // }

    if (!contieneCodigoLaEntrada(text)) {
      // VALIDACIÓN DE MAIL
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(text) === false) {
        if (text.length == 0) {
          // Email vacío
          setEmail({ text: text, valid: false, error: "" });
        } else {
          setEmail({ text: text, valid: false, error: "Email incorrecto" });
        }

        return false;
      } else {
        // console.log(text.length);

        // console.log("Email is Correct");
        setEmail({ text: text, valid: true, error: "" });
      }
    } else {
      setEmail({ text: "", valid: false, error: "Entrada incorrecta" });
    }
  };

  const validarClave = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Clave vacía.
        setClave({
          text: text,
          valid: false,
          error: "",
        });
      } else if (text.length < 3) {
        // console.log("Clave muy corta.");
        setClave({
          text: text,
          valid: false,
          error: "Contraseña muy corta",
        });
      } else {
        // console.log("Clave OK.");
        setClave({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setClave({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const chequearSiUsuarioExiste = async () => {
    try {
      if (
        objregusu.IDUsuario === undefined ||
        (objregusu.IDUsuario !== undefined && objregusu.Usuario !== user.text)
      ) {
        const API_ENDPOINT = servidor + `api_usuarios.php`;

        const datos = await fetch(API_ENDPOINT, {
          method: "POST",
          // headers: {
          //   Accept: "application/json",
          //   "Content-type": "application/json",
          // },
          body: JSON.stringify({
            operacion: "CHEQUEAR_DISPONIBILIDAD_DE_USUARIO",
            usuario: user.text,
          }),
        });

        const usuarios = await datos.json();

        // console.log(usuarios.result.usuario);

        if (usuarios.result.usuario != undefined) {
          setUser({
            text: user.text,
            valid: false,
            error: "Nombre de usuario no disponible",
          });

          return false;
        } else {
          setUser({
            text: user.text,
            valid: true,
            error: "",
          });

          return true;
        }

        // return usuarios.result;
      } else {
        setUser({
          text: user.text,
          valid: true,
          error: "",
        });
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }

    return "ERROR";
  };

  const chequearSiEmailExiste = async () => {
    try {
      //   console.log(objregusu.Email + " == " + email.text);
      if (
        objregusu.IDUsuario === undefined ||
        (objregusu.IDUsuario !== undefined && objregusu.Email !== email.text)
      ) {
        const API_ENDPOINT = servidor + `api_usuarios.php`;

        const datos = await fetch(API_ENDPOINT, {
          method: "POST",
          // headers: {
          //   Accept: "application/json",
          //   "Content-type": "application/json",
          // },
          body: JSON.stringify({
            operacion: "CHEQUEAR_DISPONIBILIDAD_DE_EMAIL",
            email: email.text,
          }),
        });

        const usuarios = await datos.json();

        // console.log(usuarios.result.usuario);

        if (usuarios.result.usuario != undefined) {
          //   console.log("PASA POR 1");
          setEmail({
            text: email.text,
            valid: false,
            error: "Ya existe un usuario con este E-mail",
          });
          // return false;
        } else {
          //   console.log("PASA POR 2");
          // setEmail({
          //   text: email.text,
          //   valid: true,
          //   error: "",
          // });
          // return true;
        }

        return usuarios.result;
      } else {
        // setEmail({
        //   text: email.text,
        //   valid: true,
        //   error: "",
        // });
        // return true;
      }
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const alta_usuario = async () => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "ALTA_USUARIO",
          nombre: nombre.text,
          apellido: apellido.text,
          whatsapp: whatsapp.text,
          usuario: user.text,
          email: email.text,
          clave: clave.text,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // console.log(usuarios.result.usuario);

        localStorage.setItem("objregusu", encriptar(usuarios.result.usuario));

        props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
        navigate("/seleccionarModulos");
      } else {
        setMensaje({ text: "Error en el registro de usuario", tipo: "error" });

        setMostrarMensaje(true);
      }

      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const modificacion_usuario = async () => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      //   console.log("idUsuario: " + idUsuario);
      //   console.log("nombre: " + nombre.text);
      //   console.log("apellido: " + apellido.text);
      //   console.log("usuario: " + user.text);
      //   console.log("email: " + email.text);
      //   console.log("clave: " + clave.text);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "MODIFICACION_USUARIO",
          idUsuario: idUsuario,
          nombre: nombre.text,
          apellido: apellido.text,
          whatsapp: whatsapp.text,
          usuario: user.text,
          email: email.text,
          clave: clave.text,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const usuarios = await datos.json();

      // console.log(usuarios.result.usuario);

      if (usuarios.result.usuario != undefined) {
        // console.log(usuarios.result.usuario);

        localStorage.setItem("objregusu", encriptar(usuarios.result.usuario));

        props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
        navigate("/seleccionarModulos");
      } else {
        setMensaje({
          text: "Error en la modificación del registro de usuario",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }

      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_LOGIN");
    navigate("/");
  };

  return (
    <>
      <div>
        <AlertValidConfig
          mostrarMensaje={mostrarMensaje}
          mensaje={mensaje.text}
          tipo={mensaje.tipo}
        />
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            p: 1,
            m: 1,
            mt: 2,
            // border: 1,
            bgcolor: "background.paper",
            // maxWidth: 380,
            // width: { xs: 300, md: 400 },
            height: "auto",
            borderRadius: 1,
          }}
        >
          <Item sx={{ width: { xs: 350, md: 350, height: "550px" } }}>
            <EncabezadoItems
              setMostrar={props.setMostrar}
              titulo="REGISTRO"
              pageBack="FORMULARIO_LOGIN"
            />
            <Box
              component="form"
              sx={{
                height: 40,
                width: "100%",
                // border: 1,
                marginTop: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                required
                id="standard-required"
                label="Nombre"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarNombre}
                value={nombre.text}
              />
              <div style={styles.error}>{nombre.error}</div>
              <TextField
                required
                id="standard-required"
                label="Apellido"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarApellido}
                value={apellido.text}
              />
              <div style={styles.error}>{apellido.error}</div>
              <TextField
                required
                id="standard-required"
                label="Whatsapp"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarWhatsapp}
                value={whatsapp.text}
              />
              <div style={styles.error}>{whatsapp.error}</div>
              <TextField
                required
                id="standard-required"
                label="Usuario"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarUser}
                onBlur={chequearSiUsuarioExiste}
                value={user.text}
                disabled={disable.usuario ? "disabled" : ""}
              />
              <div style={styles.error}>{user.error}</div>
              <TextField
                required
                id="standard-required"
                label="E-mail"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarEmail}
                onBlur={chequearSiEmailExiste}
                value={email.text}
                disabled={disable.email ? "disabled" : ""}
              />
              <div style={styles.error}>{email.error}</div>
              {/* <br />
            <br /> */}
              <TextField
                required
                id="standard-password-input"
                label="Contraseña"
                type="password"
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarClave}
                value={clave.text}
              />
              <div style={styles.error}>{clave.error}</div>
              <br />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => validarFormulario()}
              >
                Confirmar
              </Button>
            </Box>
          </Item>
        </Box>
      </div>
    </>
  );
};

export default PageRegistro;
