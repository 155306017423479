import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import { encriptar } from "../../../funciones/Funciones";
import { useContext } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FiltroTiposDePlanos = ({ data }) => {
  const { filtro, setFiltro } = useContext(ControlDeAvanceContext);

  const handleChange = (event) => {
    // console.log("event: ", event.target.value);

    // const {
    //   target: { value },
    // } = event;
    // setFiltro(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );

    const value = event.target.value;
    let newFiltro;

    // console.log("value: ", value);

    // console.log("ES ARRAY");

    // if (value.length > 0) {
    //   if (filtro.length > 0) {
    //     value.forEach((item) => {
    //       if (filtro.some((i) => i.IDTipoDePlano === item.IDTipoDePlano)) {
    //         console.log("LO ENCUENTRA: ", item.TipoDePlano);

    //         // Si el item ya está en filtro, lo eliminamos
    //         newFiltro = filtro.filter(
    //           (i) => i.IDTipoDePlano !== item.IDTipoDePlano
    //         );
    //       } else {
    //         console.log("NO LO ENCUENTRA");

    //         // Si el item no está en filtro, lo agregamos
    //         newFiltro = [...filtro, item];
    //       }
    //     });
    //   } else {
    //     newFiltro = [value[0]];
    //   }
    // } else {
    //   newFiltro = [];
    // }

    // console.log("NEW FILTRO: ", newFiltro);

    setFiltro(value);

    // localStorage.setItem("objFilPlanos", encriptar(newFiltro));

    // localStorage.setItem(
    //   "objFil",
    //   encriptar(typeof value === "string" ? value.split(",") : value)
    // );
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-checkbox-label">Filtros</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        variant="standard"
        multiple
        value={filtro}
        onChange={handleChange}
        // onChange={(event) => setFiltro(event.target.value)}
        input={<OutlinedInput label="Filtros" />}
        renderValue={(selected) =>
          selected.length > 0
            ? selected.map((item) => item.TipoDePlano).join(", ")
            : "VER TODOS"
        }
        MenuProps={MenuProps}
      >
        {data.map((item) => (
          <MenuItem key={item.IDTipoDePlano} value={item}>
            {/* {console.log(
              "Palabra (" + item.PalabraClave + "): ",
              filtro.indexOf(item) + " | " + JSON.stringify(item)
            )} */}
            <Checkbox
              checked={filtro.some(
                (i) => i.IDTipoDePlano === item.IDTipoDePlano
              )}
            />

            {/* <Checkbox checked={filtro.indexOf(item) > -1} /> */}
            <ListItemText primary={item.TipoDePlano} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FiltroTiposDePlanos;
