import { Button, Chip, Grid, Stack, TextField } from "@mui/material";
import { width } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";
import ServidorContext from "../../contextos/ServidorContext";

const PalabrasClaves = ({ setPalabrasClaves, palabrasClaves }) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [palabraClave, setPalabraClave] = useState("");
  //   const [paso1, setPaso1] = useState("");

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState(palabrasClaves);

  //   useEffect(() => {
  // setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  useEffect(() => {
    traer_palabras_claves();
    // console.log("chipSelected: ", chipSelected);
    // setChipSelected(palabrasClaves);
  }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  useEffect(() => {
    // return () => {
    let paso2 = desencriptar(localStorage.getItem("objordserv"));
    let paso3 = { ...paso2, palabrasClaves: chipSelected };

    localStorage.setItem("objordserv", encriptar(paso3));

    setPalabrasClaves(chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  const handlePalabraClave = (e) => {
    setPalabraClave(e.target.value);
  };

  const handleAddChip = () => {
    // console.log("chipData: ", chipData);

    if (palabraClave !== "") {
      if (
        chipData.filter((chip) => chip.PalabraClave === palabraClave).length > 0
      ) {
        alert("Ya existe la palabra clave");
      } else {
        guardar_palabra_clave();
      }
    } else {
      alert("La palabra clave no puede ser vacía.");
    }
  };

  const guardar_palabra_clave = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_PALABRA_CLAVE",
        idObra: obra.IDObra,
        palabraClave: palabraClave,
        esUbicacion: "NO",

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado == "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);
      setChipData([...chipData, respuesta.result.palabrasClaves[0]]);

      setPalabraClave("");
      setMensaje({
        text: "Palabra clave agregada con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_palabra_clave()");
      // setState([]);
    }
  };

  const traer_palabras_claves = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_PALABRAS_CLAVES",
        idObra: obra.IDObra,
        esUbicacion: "NO",

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.palabrasClaves) {
      setChipData(respuesta.result.palabrasClaves);
    } else {
      console.log("NO TRAE NADA EN traer_palabras_claves()");
      // setState([]);
    }
  };

  const handleClick = (chipClick) => {
    let found;

    if (chipSelected) {
      found = chipSelected.filter(
        (chip) => chip.IDPalabraClave === chipClick.IDPalabraClave
      );
    } else {
      found = [];
    }

    // console.log("found: ", found);

    let newChip = "";
    if (found.length > 0) {
      newChip = chipSelected.filter(
        (chip) => chip.IDPalabraClave !== chipClick.IDPalabraClave
      );
      setChipSelected(newChip);
    } else {
      // newChip = { IDPalabraClave: idPalabraClave };
      // console.log("chipiiiii: ", chipSelected);
      chipSelected
        ? setChipSelected([...chipSelected, chipClick])
        : setChipSelected([chipClick]);
    }
  };

  useEffect(() => {
    // console.log("chipSelected: ", chipSelected);
  }, [chipSelected]);

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <TextField
            id="txtPalabraClave"
            label="ej. Piso, Contrapiso, Muro, etc."
            // multiline
            // rows={12}
            // fullWidth
            sx={
              screenWidth === "xs" || screenWidth === "sm"
                ? { width: "95%" }
                : { width: "50%" }
            }
            onChange={(e) => handlePalabraClave(e)}
            value={palabraClave}
            // defaultValue="Default Value"
          />
          &nbsp;<Button onClick={handleAddChip}>Agregar</Button>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>

        {/* <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}> */}
        {chipData.map((data, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-start",
              width:
                screenWidth === "xs" ||
                screenWidth === "sm" ||
                screenWidth === "md"
                  ? "100%"
                  : "40%",
              //   justifyContent:
              //     screenWidth === "md" ||
              //     screenWidth === "lg" ||
              //     screenWidth === "xl"
              //       ? "left"
              //       : "center",
            }}
          >
            <Chip
              key={data.IDPalabraClave}
              label={data.PalabraClave}
              color="primary"
              variant={
                chipSelected &&
                chipSelected.filter(
                  (chip) => chip.IDPalabraClave === data.IDPalabraClave
                ).length > 0
                  ? ""
                  : "outlined"
              }
              onClick={() => handleClick(data)}
              //   onDelete={handleDelete}
            />
          </Grid>
        ))}
        {/* </Stack>
          </Stack> */}
      </Grid>
    </>
  );
};

export default PalabrasClaves;
