import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import OrdenesDeServicioContext from "../../contextos/OrdenesDeServicioContext";
import { chequearPermisos, desencriptar } from "../../funciones/Funciones";
import ModalPicture from "./ModalPicture";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ObraContext from "../../contextos/ObraContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import UsuarioContext from "../../contextos/UsuarioContext";
import PageAgregarUbicacion from "./PageAgregarUbicacion";
import Ubicacion from "./Ubicacion";
import PalabrasClaves from "./PalabrasClaves";
import ServidorContext from "../../contextos/ServidorContext";

const PageDetalleOrdenDeServicio = (props) => {
  let navigate = useNavigate();

  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const { ordenDeServicioSeleccionada, setOrdenDeServicioSeleccionada } =
    useContext(OrdenesDeServicioContext);

  const {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  } = useContext(MensajesContext);

  const { isMobile } = useContext(EstilosContext);
  const { screenWidth } = useContext(EstilosContext);
  const { privilegios } = useContext(PrivilegioContext);

  const [ods, setOds] = useState([]);
  const [descripcion, setDescripcion] = useState(
    ordenDeServicioSeleccionada.Descripcion
  );
  const [idsDelegados, setIdsDelegados] = useState([]);
  const [ubicacion, setUbicacion] = useState(
    ordenDeServicioSeleccionada.Ubicacion
  );
  const [palabrasClaves, setPalabrasClaves] = useState(
    ordenDeServicioSeleccionada.PalabrasClaves
  );
  const [destinatarios, setDestinatarios] = useState([]);
  const [destinatariosTotales, setDestinatariosTotales] = useState("");
  const [estado, setEstado] = useState("");

  const prioridades = { baja: "BAJA", media: "MEDIA", alta: "ALTA" };

  const [modificarCampos, setModificarCampos] = useState({
    ubicacion: false,
    palabrasClaves: false,
    descripcion: false,
    prioridad: false,
    destinatarios: false,
  });

  useEffect(() => {
    let objods = desencriptar(localStorage.getItem("objods"));

    // console.log("objods: ", objods);
    // console.log("ordenDeServicioSeleccionada: ", ordenDeServicioSeleccionada);

    setOrdenDeServicioSeleccionada(objods);
    setOds(objods.Archivos);

    if (
      objods.IDOrdenDeServicio !== undefined &&
      objods.IDOrdenDeServicio > 0
    ) {
      // setOrdenDeServicioSeleccionada(objods);

      // console.log("objods.Destinatarios: ", objods.Destinatarios);

      let idsDest = [];
      let usr = [];
      objods.Destinatarios.map((dest) => {
        idsDest.push(dest.IDUsuarioDestinatario);
        usr.push(dest);
      });

      setIdsDelegados(idsDest);

      // console.log("destinatarios: ", objods.Destinatarios);
      let delegados = objods.Destinatarios.map((dest, index) =>
        index !== 0
          ? ", " + dest.Nombre + " " + dest.Apellido
          : dest.Nombre + " " + dest.Apellido
      );

      // let delegados = objods.Destinatarios.map(
      //   (dest, index) => dest.Nombre + " " + dest.Apellido
      // );

      // console.log("varDelegados: ", objods.Destinatarios);

      setDestinatarios(objods.Destinatarios);
      // console.log("UBICACION: ", objods.Ubicacion);
      setUbicacion(objods.Ubicacion);
    }

    setEstado(objods.EstadoDeLaOrden);
  }, []);

  useEffect(() => {
    // console.log("modal: ", mostrarModal.mostrar);
    setMostrarModal({ ...mostrarModal, mostrar: false });
  }, []);

  useEffect(() => {
    props.setTitulo(
      `Orden de servicio #${ordenDeServicioSeleccionada.IDOrdenDeServicio}`
    );
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    // console.log("ODSSeleccionada: ", ordenDeServicioSeleccionada);
  }, [ordenDeServicioSeleccionada]);

  // useEffect(() => {
  //   console.log("palabrasClaves: ", palabrasClaves);
  //   console.log("orden: ", ordenDeServicioSeleccionada);
  // }, [palabrasClaves]);

  useEffect(() => {
    //   console.log("palabrasClaves: ", palabrasClaves);
    //   console.log("orden: ", ordenDeServicioSeleccionada);

    setOrdenDeServicioSeleccionada({
      ...ordenDeServicioSeleccionada,
      Ubicacion: ubicacion,
    });
  }, [ubicacion]);

  useEffect(() => {
    //   console.log("palabrasClaves: ", palabrasClaves);
    //   console.log("orden: ", ordenDeServicioSeleccionada);

    setOrdenDeServicioSeleccionada({
      ...ordenDeServicioSeleccionada,
      PalabrasClaves: palabrasClaves,
    });
  }, [palabrasClaves]);

  const [isExpand, setIsExpand] = useState(false);
  const [imagen, setImagen] = useState("");

  const showModal = (img) => {
    setIsExpand(true);
    setImagen(img);
    setMostrarModal({ ...mostrarModal, mostrar: true });
  };

  const hideModal = () => {
    setIsExpand(false);
  };

  const handleChangeEstado = (idEstado) => {
    // console.log("checked: " + idEstado);

    // console.log("ESTADO: ", estado);

    setEstado(idEstado);
  };

  const handleConfirm = async () => {
    let cambios = {
      ubicacion: ubicacion,
      palabrasClaves: palabrasClaves,
      descripcion: descripcion,
      prioridad: ordenDeServicioSeleccionada.Prioridad,
      destinatarios: destinatarios,
      estado: estado,
    };

    // console.log("cambios: ", cambios);

    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "MODIFICAR_ORDEN_DE_SERVICIO",
        idOrdenDeServicio: ordenDeServicioSeleccionada.IDOrdenDeServicio,
        ubicacion: cambios.ubicacion,
        palabrasClaves: cambios.palabrasClaves,
        descripcion: cambios.descripcion,
        prioridad: cambios.prioridad,
        destinatarios: cambios.destinatarios,
        estado: cambios.estado,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.respuesta == "OK") {
      setTimeout(() => {
        setMensaje({ tipo: "success", text: "Cambios realizados con éxito!" });
        setMostrarMensaje(true);

        navigate("/dashboardOrdenesDeServicio");
      }, 1500);
    } else {
      console.log("NO TRAE NADA EN handleConfirm()");
      // setState([]);
    }
  };

  const handleModificarCampos = (campo) => {
    if (campo === "UBICACION") {
      setModificarCampos({
        ...modificarCampos,
        ubicacion: !modificarCampos.ubicacion,
      });
    } else if (campo === "PALABRAS_CLAVES") {
      setModificarCampos({
        ...modificarCampos,
        palabrasClaves: !modificarCampos.palabrasClaves,
      });
    } else if (campo === "DESCRIPCION") {
      setModificarCampos({
        ...modificarCampos,
        descripcion: !modificarCampos.descripcion,
      });
    } else if (campo === "PRIORIDAD") {
      setModificarCampos({
        ...modificarCampos,
        prioridad: !modificarCampos.prioridad,
      });
    } else if (campo === "DESTINATARIOS") {
      setModificarCampos({
        ...modificarCampos,
        destinatarios: !modificarCampos.destinatarios,
      });
    }
  };

  const handleModifPrioridad = (prioridad) => {
    // console.log("prioridad: ", prioridad);

    setOrdenDeServicioSeleccionada({
      ...ordenDeServicioSeleccionada,
      Prioridad: prioridad,
    });
  };

  const handleChangeDestinatarios = (event, item) => {
    // console.log("item", item);

    let nuevoItem = {
      IDUsuarioDestinatario: item.IDUsuario,
      Nombre: item.Nombre,
      Apellido: item.Apellido,
    };

    if (event.target.checked) {
      setIdsDelegados((prevIdsDelegados) => [
        ...prevIdsDelegados,
        item.IDUsuario,
      ]);

      setDestinatarios([...destinatarios, nuevoItem]);

      // setDestinatarios()
    } else {
      setIdsDelegados((prevIdsDelegados) =>
        prevIdsDelegados.filter((id) => id !== item.IDUsuario)
      );

      setDestinatarios((prevDestinatario) =>
        prevDestinatario.filter(
          (usr) => usr.IDUsuarioDestinatario !== item.IDUsuario
        )
      );
    }
  };

  // useEffect(() => {
  //   // console.log("destinatariosss: ", destinatarios);
  // }, [destinatarios]);

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    traer_todos_los_destinatarios();
  }, []);

  const traer_todos_los_destinatarios = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_DESTINATARIOS",
        idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.destinatarios) {
      setDestinatariosTotales(respuesta.result.destinatarios);
    } else {
      console.log("NO TRAE NADA EN traer_todos_los_destinatarios()");
      // setState([]);
    }
  };

  const estaEnSeleccionados = (id) => {
    // console.log("id: " + id + " | " + idsDelegados.includes(id));

    return idsDelegados.includes(id);
  };

  return (
    <div>
      <ImageList
        sx={{ width: isMobile ? "90%" : "1024px" }}
        cols={isMobile ? 1 : 3}
      >
        {ods.map((item) => (
          <ImageListItem key={item.IDOrdenDeServicioArchivo}>
            <img
              // src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={item.Archivo}
              alt={item.TipoDeArchivo}
              loading="lazy"
              onClick={() => showModal(item.Archivo)}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ModalPicture
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        srcImagen={imagen}
        isMobile={isMobile}
      />
      <Grid
        container
        // wrap="nowrap"
        spacing={2}
        // direction="row"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        justifyContent="space-between"
        width={isMobile ? "100%" : "35%"}
        // border="1px solid #FF0000"
      >
        {/* <Grid item>
          <Avatar>{props.pedido.idPedido}</Avatar>
        </Grid> */}
        {/* <Grid item sm={1} style={{ border: "0px solid #FF0000" }}>
          &nbsp;
        </Grid> */}
        <Grid
          item
          sm={12}
          style={{
            width: "95%",
          }}
        >
          <strong>Ubicación: </strong>
          {chequearPermisos(
            privilegios,
            "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
          ) === true ? (
            <IconButton
              color="primary"
              aria-label="editar"
              onClick={() => handleModificarCampos("UBICACION")}
            >
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ""
          )}
          <br />

          {modificarCampos.ubicacion ? (
            <Ubicacion setUbicacion={setUbicacion} ubicacion={ubicacion} />
          ) : ordenDeServicioSeleccionada.Ubicacion ? (
            ordenDeServicioSeleccionada.Ubicacion[0].PalabraClave
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          sm={12}
          style={{
            width: "95%",
          }}
        >
          <strong>Palabras claves: </strong>
          {chequearPermisos(
            privilegios,
            "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
          ) === true ? (
            <IconButton
              color="primary"
              aria-label="editar"
              onClick={() => handleModificarCampos("PALABRAS_CLAVES")}
            >
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ""
          )}
          <br />

          {modificarCampos.palabrasClaves ? (
            <PalabrasClaves
              setPalabrasClaves={setPalabrasClaves}
              palabrasClaves={palabrasClaves}
            />
          ) : ordenDeServicioSeleccionada.PalabrasClaves ? (
            ordenDeServicioSeleccionada.PalabrasClaves.map((pal, index) =>
              index === 0 ? pal.PalabraClave : ", " + pal.PalabraClave
            )
          ) : (
            ""
          )}
        </Grid>

        <Grid
          item
          sm={12}
          style={{
            width: "100%",
          }}
        >
          <strong>Descripción: </strong>
          {chequearPermisos(
            privilegios,
            "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
          ) === true ? (
            <IconButton
              color="primary"
              aria-label="editar"
              onClick={() => handleModificarCampos("DESCRIPCION")}
            >
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ""
          )}
          <br />

          {modificarCampos.descripcion ? (
            <TextField
              id="txtDescripcion"
              label="Editar descripción"
              multiline
              rows={3}
              fullWidth
              sx={{ width: "95%" }}
              // onChange={(e) => handleDescripcion(e)}
              onChange={(e) => setDescripcion(e.target.value)}
              defaultValue={ordenDeServicioSeleccionada.Descripcion}
            />
          ) : (
            // ordenDeServicioSeleccionada.Descripcion
            descripcion
          )}
        </Grid>
        <Grid
          item
          sm={12}
          style={{
            width: "100%",
          }}
        >
          <strong>Prioridad: </strong>
          {chequearPermisos(
            privilegios,
            "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
          ) === true ? (
            // <Button
            //   variant="contained"
            //   size="small"
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //   }}
            //   onClick={() => handleModificarCampos("DESCRIPCION")}
            // >
            //   MODIFICAR
            // </Button>
            <IconButton
              color="primary"
              aria-label="editar"
              onClick={() => handleModificarCampos("PRIORIDAD")}
            >
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ""
          )}
          <br />
          {modificarCampos.prioridad ? (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={prioridades.baja}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={prioridades.baja}
                  control={
                    <Radio
                      icon={
                        <RadioButtonUncheckedOutlinedIcon
                          sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                        />
                      }
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                        />
                      }
                      onClick={() => handleModifPrioridad(prioridades.baja)}
                      checked={
                        ordenDeServicioSeleccionada.Prioridad ===
                        prioridades.baja
                      }
                    />
                  }
                  label={prioridades.baja}
                />
                <FormControlLabel
                  value={prioridades.media}
                  control={
                    <Radio
                      icon={
                        <RadioButtonUncheckedOutlinedIcon
                          sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                        />
                      }
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                        />
                      }
                      onClick={() => handleModifPrioridad(prioridades.media)}
                      checked={
                        ordenDeServicioSeleccionada.Prioridad ===
                        prioridades.media
                      }
                    />
                  }
                  label={prioridades.media}
                />
                <FormControlLabel
                  value={prioridades.alta}
                  control={
                    <Radio
                      icon={
                        <RadioButtonUncheckedOutlinedIcon
                          sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                        />
                      }
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                        />
                      }
                      onClick={() => handleModifPrioridad(prioridades.alta)}
                      checked={
                        ordenDeServicioSeleccionada.Prioridad ===
                        prioridades.alta
                      }
                    />
                  }
                  label={prioridades.alta}
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <span
              style={{
                color:
                  ordenDeServicioSeleccionada.Prioridad === "ALTA"
                    ? "red"
                    : ordenDeServicioSeleccionada.Prioridad === "MEDIA"
                    ? "orange"
                    : "green",
              }}
            >
              {ordenDeServicioSeleccionada.Prioridad}
            </span>
          )}
        </Grid>
        <Grid
          item
          sm={12}
          style={{
            width: "100%",
          }}
        >
          <strong>Destinatarios: </strong>
          {chequearPermisos(
            privilegios,
            "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
          ) === true ? (
            // <Button
            //   variant="contained"
            //   size="small"
            //   style={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //   }}
            //   onClick={() => handleModificarCampos("DESCRIPCION")}
            // >
            //   MODIFICAR
            // </Button>
            <IconButton
              color="primary"
              aria-label="editar"
              onClick={() => handleModificarCampos("DESTINATARIOS")}
            >
              <EditOutlinedIcon />
            </IconButton>
          ) : (
            ""
          )}
          <br />
          {modificarCampos.destinatarios
            ? destinatariosTotales.map((dest) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  key={dest.IDUsuario}
                  sx={{
                    display: "flex",
                    justifyContent:
                      screenWidth === "xs" ||
                      screenWidth === "sm" ||
                      screenWidth === "md" ||
                      screenWidth === "lg" ||
                      screenWidth === "xl"
                        ? "left"
                        : "center",
                    alignItems: "center",
                    //   border: "1px solid #CCCCCC",
                  }}
                >
                  {/* <div key={dest.IDUsuario} style="width: 30px"> */}
                  <Checkbox
                    id={dest.IDUsuario}
                    key={dest.IDUsuario}
                    icon={
                      // <HighlightOffOutlinedIcon
                      //   sx={{ fontSize: 40, color: "#FF0000" }}
                      // />
                      <RadioButtonUncheckedOutlinedIcon
                        sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                      />
                    }
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                      />
                    }
                    onChange={(event) => handleChangeDestinatarios(event, dest)}
                    // checked={
                    //   dest.Nombre + " " + dest.Apellido === destinatarios
                    // }

                    checked={estaEnSeleccionados(dest.IDUsuario)}
                    // checked={true}
                  />
                  {dest.Nombre.toUpperCase()} {dest.Apellido.toUpperCase()}
                  {/* {console.log("i: ", idsDelegados)} */}
                  {/* </div> */}
                </Grid>
              ))
            : destinatarios.map((usr, index) =>
                index !== 0
                  ? ", " + usr.Nombre + " " + usr.Apellido
                  : usr.Nombre + " " + usr.Apellido
              )}
        </Grid>

        <Grid
          item
          sm={12}
          style={{
            width: "100%",
          }}
        >
          <Grid
            container
            // wrap="nowrap"
            spacing={2}
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={12}>
              <br />
              <strong>Estado: </strong>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent:
                  screenWidth === "xs" ||
                  screenWidth === "sm" ||
                  screenWidth === "md" ||
                  screenWidth === "lg" ||
                  screenWidth === "xl"
                    ? "left"
                    : "center",
                alignItems: "center",
                //   border: "1px solid #CCCCCC",
              }}
            >
              <FormControl sx={{ pl: 1 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="PENDIENTE"
                    key="PENDIENTE"
                    control={
                      <Radio
                        // checked={index === 0}
                        icon={
                          <RadioButtonUncheckedOutlinedIcon
                            sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                          />
                        }
                        onChange={() => handleChangeEstado("PENDIENTE")}
                        checked={estado === "PENDIENTE"}
                      />
                    }
                    label="PENDIENTE"
                  />

                  <FormControlLabel
                    value="FINALIZADO"
                    key="FINALIZADO"
                    control={
                      <Radio
                        // checked={index === 0}
                        icon={
                          <RadioButtonUncheckedOutlinedIcon
                            sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                          />
                        }
                        onChange={() => handleChangeEstado("FINALIZADO")}
                        checked={estado === "FINALIZADO"}
                      />
                    }
                    label="FINALIZADO"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent:
                  screenWidth === "xs" ||
                  screenWidth === "sm" ||
                  screenWidth === "md" ||
                  screenWidth === "lg" ||
                  screenWidth === "xl"
                    ? "left"
                    : "center",
                alignItems: "center",
                //   border: "1px solid #CCCCCC",
              }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleConfirm}
              >
                CONFIRMAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageDetalleOrdenDeServicio;
