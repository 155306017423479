import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatearFecha } from "../../funciones/Funciones";

const EncabezadoSmartphone = ({ encabezadoPedido }) => {
  return (
    <Grid item xs={12} border={0} mb={2}>
      <Box
        sx={{
          width: "100%",
          // height: "auto",
          backgroundColor: "primary.dark",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          p: 2,
          color: "#FFFF",
          flexGrow: 1,
          display: "flex",

          // "&:hover": {
          //   backgroundColor: "primary.main",
          //   opacity: [0.9, 0.8, 0.7],
          // },
        }}
      >
        <Grid container sx={{ justifyContent: "flex-end" }}>
          <Grid
            item
            xs={12}
            //   m={1}
            //   p={2}

            // m={2}
            // mt={2}
            // mr={2}

            p={2}
            // pr={2}
            // pb={2}
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
            }}
          >
            {/* <Item> */}
            <Typography component="div">
              <Box
                sx={{
                  fontWeight: "normal",
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>FECHA PROGRAMADA:</div>
                <div style={{ color: "#CCCCCC" }}>
                  {formatearFecha(
                    encabezadoPedido.FechaProgramada,
                    "dd/MM/yyyy"
                  )}
                </div>
                {/* </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
              </Box>
            </Typography>
            {encabezadoPedido.FechaConfirmacion !== null ? (
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>FECHA CONFIRMADA:</div>
                  <div style={{ color: "#CCCCCC" }}>
                    {formatearFecha(encabezadoPedido.FechaConfirmacion)}
                  </div>
                  {/* </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                > */}
                </Box>
              </Typography>
            ) : (
              ""
            )}

            {encabezadoPedido.Proveedor !== null &&
            encabezadoPedido.Proveedor !== undefined ? (
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div> PROVEEDOR: </div>
                  <div style={{ color: "#CCCCCC" }}>
                    {encabezadoPedido.Proveedor.toUpperCase()}
                  </div>
                  {/* </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
                </Box>
              </Typography>
            ) : (
              ""
            )}

            {encabezadoPedido.NombreContacto !== null &&
            encabezadoPedido.NombreContacto !== undefined ? (
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div> CONTACTO: </div>
                  <div style={{ color: "#CCCCCC" }}>
                    {encabezadoPedido.NombreContacto.toUpperCase()}
                  </div>
                  {/* </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
                </Box>
              </Typography>
            ) : (
              ""
            )}

            {encabezadoPedido.Telefono !== null &&
            encabezadoPedido.Telefono !== undefined ? (
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div> TELÉFONO: </div>
                  <div style={{ color: "#CCCCCC" }}>
                    {encabezadoPedido.Telefono}
                  </div>
                  {/* </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
                </Box>
              </Typography>
            ) : (
              ""
            )}

            {encabezadoPedido.Email !== null &&
            encabezadoPedido.Email !== undefined ? (
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div> E-MAIL: </div>
                  <div style={{ color: "#CCCCCC" }}>
                    {encabezadoPedido.Email}
                  </div>
                  {/* </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
                </Box>
              </Typography>
            ) : (
              ""
            )}
            {/* </Item> */}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default EncabezadoSmartphone;
