import { Typography } from "@mui/material";
import React from "react";
import BotoneraABM from "./BotoneraABM";

const Titulo = ({ estilos, texto, botones }) => {
  // console.log(props.estilos.tituloEncabezado);
  let style = estilos !== undefined ? estilos : "";

  return (
    <Typography variant="h4" style={style.tituloEncabezado}>
      {/* <h1> */}
      {texto}
      {"   "}
      {botones ? <BotoneraABM botones={botones} /> : ""}
      {/* </h1> */}
    </Typography>
  );
};

export default Titulo;
