import React from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { grey } from "@mui/material/colors";
import UsuarioContext from "../contextos/UsuarioContext";
import { useContext } from "react";
import { useEffect } from "react";
import { desencriptar } from "../funciones/Funciones";
import { useState } from "react";
import ServidorContext from "../contextos/ServidorContext";

const ListaInvitaciones = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const [invitaciones, setInvitaciones] = useState([]);

  useEffect(() => {
    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    listar_invitaciones();
  }, []);

  const listar_invitaciones = async () => {
    // console.log(texto);
    // console.log("PASA POR listar_obras()");

    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_INVITACIONES_DE_USUARIO",
        email: usuario.Email,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.invitaciones) {
      // console.log(respuesta.result.invitaciones);

      setInvitaciones(respuesta.result.invitaciones);
    } else {
      setInvitaciones([]);
      console.log("NO TRAE NADA EN listar_invitaciones()");
      // setState([]);
    }
  };

  const handleAceptar = async (invitacion) => {
    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACEPTAR_INVITACION",
        idObra: invitacion.IDObra,
        idUsuario: usuario.IDUsuario,
        idInvitado: invitacion.IDInvitado,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.invitaciones) {
      // console.log(respuesta.result.invitaciones);

      setInvitaciones(respuesta.result.invitaciones);
    } else {
      setInvitaciones([]);
      console.log("NO TRAE NADA EN handleAceptar()");
      // setState([]);
    }
  };

  const handleCancelar = async (invitacion) => {
    // console.log(invitacion);

    const API_ENDPOINT = servidor + `api_usuarios.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "CANCELAR_INVITACION",
        idInvitado: invitacion.IDInvitado,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.invitaciones) {
      // console.log(respuesta.result.invitaciones);

      setInvitaciones(respuesta.result.invitaciones);
    } else {
      setInvitaciones([]);
      console.log("NO TRAE NADA EN handleCancelar()");
      // setState([]);
    }
  };

  return (
    <>
      {invitaciones.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            // height: "auto",
            backgroundColor: "primary.dark",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            p: 2,
            color: "#FFFF",
            flexGrow: 1,
            display: "flex",

            // "&:hover": {
            //   backgroundColor: "primary.main",
            //   opacity: [0.9, 0.8, 0.7],
            // },
          }}
        >
          {invitaciones.map((inv) => (
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "flex-end" }}
              key={inv.IDInvitado}
            >
              <Grid
                item
                xs={2}
                //   m={1}
                //   p={2}

                mt={2}
                mr={2}
                pr={2}
                pb={2}
                sx={{
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                }}
              >
                <Typography component="div">
                  <Box
                    sx={{
                      fontWeight: "normal",
                      fontSize: 24,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    INVITACIÓN A OBRA
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "normal",
                      fontSize: 18,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {inv.Obra}
                  </Box>
                  {/* <Box
                    sx={{
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Invitado por {inv.Nombre + " " + inv.Apellido}
                  </Box> */}
                  <Box
                    sx={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <IconButton
                        aria-label="aceptar"
                        size="large"
                        onClick={() => handleAceptar(inv)}
                      >
                        <DoneOutlinedIcon sx={{ color: grey[400] }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={() => handleCancelar(inv)}
                      >
                        <DeleteOutlinedIcon sx={{ color: grey[400] }} />
                      </IconButton>
                    </Stack>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default ListaInvitaciones;
