import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

const ControlDeAvanceContext = createContext();

const ControlDeAvanceProvider = ({ children }) => {
  const [controlDeAvance, setControlDeAvance] = useState(
    desencriptar(localStorage.getItem("objava"))
  );

  const [tareasAsignadas, setTareasAsignadas] = useState([]);
  const [tareasFiltradas, setTareasFiltradas] = useState([]);
  const [tareasFiltradasCoincidentes, setTareasFiltradasCoincidentes] =
    useState([]);

  const [planosDeObra, setPlanosDeObra] = useState([]);
  const [filtro, setFiltro] = useState([]);

  useEffect(() => {
    localStorage.setItem("objava", encriptar(controlDeAvance));
  }, [controlDeAvance]);

  const data = {
    controlDeAvance,
    setControlDeAvance,
    tareasAsignadas,
    setTareasAsignadas,
    tareasFiltradas,
    setTareasFiltradas,
    tareasFiltradasCoincidentes,
    setTareasFiltradasCoincidentes,
    planosDeObra,
    setPlanosDeObra,
    filtro,
    setFiltro,
  };

  return (
    <ControlDeAvanceContext.Provider value={data}>
      {children}
    </ControlDeAvanceContext.Provider>
  );
};

export { ControlDeAvanceProvider };
export default ControlDeAvanceContext;
