import { Alert, AlertTitle, Snackbar, Stack } from "@mui/material";
import React from "react";

const AlertSinPermisos = (props) => {
  return (
    <>
      {props.mostrarAlert ? (
        // <Stack sx={{ width: "100%" }} spacing={2}>
        //   <Alert severity="error">
        //     <AlertTitle>Error</AlertTitle>
        //     No tiene permisos para acceder a esta sección.
        //   </Alert>
        // </Stack>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={props.mostrarAlert}
          //   severity="error"
          //   onClose={handleClose}
          //   message="No tiene permisos para acceder a esta sección."
          //   key={vertical + horizontal}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            No tiene permisos para acceder a esta sección.
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </>
  );
};

export default AlertSinPermisos;
