import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ObraContext from "../../contextos/ObraContext";
import ServidorContext from "../../contextos/ServidorContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import MensajesContext from "../../contextos/MensajesContext";

const ConfigImportarTareas = ({ setTitulo, setPagina }) => {
  const { servidor } = useContext(ServidorContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [comboObras, setComboObras] = useState([]);
  const [idObraSeleccionada, setIdObraSeleccionada] = useState(0);
  const [listaTareas, setListaTareas] = useState([]);
  const [tareasSeleccionadas, setTareasSeleccionadas] = useState([]);
  const [confirmaImportacion, setConfirmaImportacion] = useState(false);

  useEffect(() => {
    setTitulo("Importar tareas");
    setPagina("");

    // console.log("A");
  }, [setTitulo]);

  useEffect(() => {
    traer_obras();
  }, []);

  useEffect(() => {
    // console.log("idObraSeleccionada: ", idObraSeleccionada);

    if (idObraSeleccionada > 0) {
      listar_tareas();
    }
  }, [idObraSeleccionada]);

  useEffect(() => {
    if (confirmaImportacion) {
      importar_tareas();
    }

    // return () => {
    //   setConfirmaImportacion(false)
    // }
  }, [confirmaImportacion]);

  // useEffect(() => {
  //   console.log("tareasSeleccionadas: ", tareasSeleccionadas);
  // }, [tareasSeleccionadas]);

  const traer_obras = async () => {
    const API_ENDPOINT = servidor + `api_obras.php`;

    // console.log({
    //   operacion: "TRAER_DEPARTAMENTOS",
    //   idPlano: idPlano,
    //   idObra: obra.IDObra,

    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_OBRAS",
        // idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("departamentosEnCombo: ", respuesta.result.respuesta);

    if (respuesta.result.respuesta === "OK") {
      let obrasFiltradas = respuesta.result.obras.filter(
        (item) => item.IDObra !== obra.IDObra
      );

      setComboObras(obrasFiltradas);
      // console.log("obras: ", obrasFiltradas);
    } else {
      setComboObras([]);
      console.log("NO TRAE NADA EN traer_obras()");
      // setState([]);
    }
  };

  const listar_tareas = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    // console.log({
    //   operacion: "TRAER_DEPARTAMENTOS",
    //   idPlano: idPlano,
    //   idObra: obra.IDObra,

    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS",
        idObra: idObraSeleccionada,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("resultado: ", respuesta.result.resultado);

    if (respuesta.result.resultado === "OK") {
      setListaTareas(respuesta.result.listaTareas);
      // console.log("listaTareas: ", respuesta.result.listaTareas);
    } else {
      setListaTareas([]);
      console.log("NO TRAE NADA EN listar_tareas()");
      // setState([]);
    }
  };

  const handleSelectObra = (event) => {
    setIdObraSeleccionada(event.target.value);
    // console.log(event.target.value);
  };

  const handleImportarTareas = () => {
    // ABRIR POPUP PREGUNTANDO SI QUIERE IMPORTAR LAS TAREAS SELECCIONADAS DE A OBRA xxx A LA OBRA yyy
  };

  const importar_tareas = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "IMPORTAR_TAREAS_A_OTRA_OBRA",
        idObra: idObraSeleccionada,
        tareasSeleccionadas: tareasSeleccionadas,
      }),
    });
    const respuesta = await datos.json();

    // console.log("resultado: ", respuesta.result.resultado);

    if (respuesta.result.resultado === "OK") {
      setMensaje({
        text: "Las tareas han sido importadas con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);
    } else {
      setMensaje({
        text: "Error al importar las tareas.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN importar_tareas()");
      // setState([]);
    }
  };

  // Función para agrupar tareas por categoría
  function agruparTareasPorCategoria(tareas) {
    const categorias = {};
    tareas.forEach((tarea) => {
      if (!categorias[tarea.Categoria]) {
        categorias[tarea.Categoria] = [];
      }
      categorias[tarea.Categoria].push(tarea);
    });
    return categorias;
  }

  const seleccionarTarea = (idTarea) => {
    // tareasSeleccionadas.some((tarea) => tarea.IDTarea === idTarea);

    // console.log("selec: ", tareasSeleccionadas.includes(idTarea));

    let selec = tareasSeleccionadas.includes(idTarea);

    if (selec) {
      setTareasSeleccionadas(tareasSeleccionadas.filter((t) => t !== idTarea));
    } else {
      setTareasSeleccionadas([...tareasSeleccionadas, idTarea]);
    }

    // console.log("tareasSeleccionadas: ", [...tareasSeleccionadas, idTarea]);
  };

  // Componente para mostrar las tareas por categoría
  function TareasPorCategoria({ tareas }) {
    return (
      <>
        {Object.entries(tareas).map(([categoria, tareasCategoria]) => (
          <div key={categoria}>
            <br />
            <h3>{categoria}</h3>

            <Grid container spacing={2}>
              {tareasCategoria.map((item, index) => (
                <Grid
                  item
                  style={{
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "left",
                    // border: "1px solid #FF0000",
                  }}
                >
                  <FormControlLabel
                    value={item.IDTarea}
                    key={item.IDTarea}
                    control={
                      <Checkbox
                        icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                        checked={tareasSeleccionadas.includes(item.IDTarea)}
                        onChange={() => seleccionarTarea(item.IDTarea)}
                      />
                    }
                    label={"(" + item.CodTarea + ") - " + item.Tarea}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </>
    );
  }

  const tareasPorCategoria = agruparTareasPorCategoria(listaTareas);

  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: "160px" }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          Seleccione la obra
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={idObraSeleccionada}
          onChange={handleSelectObra}
          autoWidth
          label="Obra"
        >
          {comboObras.map((obra) => (
            <MenuItem value={obra.IDObra}>{obra.Obra} </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <TareasPorCategoria tareas={tareasPorCategoria} />

      <Button
        // variant="contained"
        onClick={() => handleImportarTareas()}
        sx={{ mt: 1, mr: 1 }}
      >
        Importar
      </Button>
    </>
  );
};

export default ConfigImportarTareas;
