import React from "react";
// import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";
import { Box } from "@mui/material";
import PedidoContext from "../../contextos/PedidoContext";
import { useContext } from "react";
import { useEffect } from "react";

const ItemPedido = (props) => {
  // const { pedido, setPedido } = useContext(PedidoContext);

  // useEffect(() => {
  //   console.log("props: ");
  //   console.log(props);
  //   console.log("pedido: ");
  //   console.log(pedido);
  // }, []);

  return (
    // <Typography key={props.material.idMaterial} variant="subtitle1">
    //   {props.material.cantidad} x {props.material.material}
    // </Typography>

    <>
      <List
        sx={{
          maxWidth: "100%",
          bgcolor: "background.paper",
          // border: "1px solid #FF0000",
        }}
      >
        <ListItem alignItems="flex-start">
          {/* <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar> */}

          <Stack direction="row" spacing={2} sx={{ bgcolor: blue[700] }}>
            {/* <Avatar sx={{ bgcolor: blue[700] }}>
              {" "}
              {props.material.cantidad}
            </Avatar> */}

            <Box
              sx={{
                width: 60,
                height: 60,
                backgroundColor: blue[700], // "primary.dark",
                "&:hover": {
                  backgroundColor: blue[700], // "primary.main",
                  opacity: [0.9, 0.8, 0.7],
                },
                // pt: 1,
                pb: 2,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ display: "inline", bgcolor: blue[700] }}
                component="span"
                variant="h6"
                color="white"
              >
                {props.material.cantidad}
              </Typography>
              <br />
              <Typography
                sx={{ display: "inline", bgcolor: blue[700] }}
                component="span"
                fontSize={10}
                color="white"
              >
                {props.material.unidad}
              </Typography>
            </Box>
          </Stack>
          <ListItemText
            style={{ paddingLeft: "20px" }}
            primary={props.material.material}
            secondary={
              <React.Fragment>
                {props.proveedorElegido.Proveedor}
              </React.Fragment>
            }
          />
        </ListItem>
        <div style={{ paddingLeft: "20px" }}>
          <Divider variant="inset" component="li" />
        </div>
      </List>
    </>
  );
};

export default ItemPedido;
