import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../../funciones/Funciones";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidConfig from "../../../components/AlertValidConfig";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

const filter = createFilterOptions();

const BoxUnidadesFuncionales = ({ idPlano, setUfSelected }) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { planosDeObra, setPlanosDeObra } = useContext(ControlDeAvanceContext);

  const [departamentos, setDepartamentos] = useState("");
  const [comboDepartamentos, setComboDepartamentos] = useState([]);

  const [departamentoSelected, setDepartamentoSelected] = useState("");
  //   const [paso1, setPaso1] = useState("");

  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    IDDepartamento: "",
    Departamento: "",
  });

  const initValid = { departamentoSelectedValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);
  const [planosSeleccionados, setPlanosSeleccionados] = useState([]);

  //   useEffect(() => {
  // setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  useEffect(() => {
    // console.log("planosSeleccionados: ", planosSeleccionados);
  }, [planosSeleccionados]);

  useEffect(() => {
    traer_departamentos();
    setChipSelected(departamentoSelected);
  }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  useEffect(() => {
    // return () => {
    // let paso1 = desencriptar(localStorage.getItem("objordserv"));
    // let paso2 = { ...paso1, departamentoSelected: chipSelected };

    // localStorage.setItem("objordserv", encriptar(paso2));

    setDepartamentoSelected(chipSelected);

    // console.log("chipSelected: ", chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  const guardar_departamento = async () => {
    const API_ENDPOINT = servidor + `api_departamentos.php`;

    // console.log("idPlano: ", idPlano);
    // console.log("dialogValue.Departamento: ", dialogValue.Departamento);

    // console.log({
    //   operacion: "GUARDAR_DEPARTAMENTO",
    //   idObra: obra.IDObra,
    //   idPlano: idPlano,
    //   departamento: dialogValue.Departamento,
    //   arrPlanosSeleccionados: planosSeleccionados,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_DEPARTAMENTO",
        idObra: obra.IDObra,
        idPlano: idPlano,
        departamento: dialogValue.Departamento,
        arrPlanosSeleccionados: planosSeleccionados,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado == "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);
      // console.log("yaExistia: ", respuesta.result.yaExistia);
      if (!respuesta.result.yaExistia) {
        setChipData([...chipData, respuesta.result.departamentos[0]]);
      }

      setComboDepartamentos([
        ...comboDepartamentos,
        respuesta.result.departamentos[0],
      ]);

      setDepartamentoSelected("");
      setPlanosSeleccionados([]);
      setMensaje({ text: "Espacio agregado con éxito!", tipo: "success" });

      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_departamento()");
      // setState([]);
    }
  };

  const traer_departamentos = async () => {
    const API_ENDPOINT = servidor + `api_departamentos.php`;

    // console.log({
    //   operacion: "TRAER_DEPARTAMENTOS",
    //   idPlano: idPlano,
    //   idObra: obra.IDObra,

    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_DEPARTAMENTOS",
        idPlano: idPlano,
        idObra: obra.IDObra,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("departamentosEnCombo: ", respuesta.result.respuesta);

    if (respuesta.result.respuesta === "OK") {
      setComboDepartamentos(respuesta.result.listaDepartamentosEnCombo);
      setChipData(respuesta.result.listaDepartamentos);
    } else {
      setComboDepartamentos([]);
      setChipData([]);
      console.log("NO TRAE NADA EN traer_departamentos()");
      // setState([]);
    }
  };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    setUfSelected(chip);
    setChipSelected([chip]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dialogValue.Departamento !== "") {
      // if (
      //   chipData.filter(
      //     (chip) => chip.Departamento === dialogValue.Departamento
      //   ).length > 0
      // ) {
      // setMensaje({ text: "El espacio ya existe.", tipo: "error" });

      // setMostrarMensaje(true);
      // } else {

      if (planosSeleccionados.length > 0) {
        guardar_departamento();

        setValue({
          IDDepartamento: parseInt(dialogValue.IDDepartamento, 10),
          Departamento: dialogValue.Departamento,
        });
        handleClose();
      } else {
        setMensaje({
          text: "Seleccione los planos donde agregar el espacio.",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "El espacio no puede ser vacío.", tipo: "error" });

      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    setDialogValue({
      IDDepartamento: "",
      Departamento: "",
    });
    toggleOpen(false);
  };

  const handleAddDepartamento = () => {};

  const handlePlanos = (event, plano) => {
    // console.log("Plano: ", event.target.checked);

    if (event.target.checked) {
      setPlanosSeleccionados([...planosSeleccionados, plano]);
    } else {
      let filtro = planosSeleccionados.filter(
        (item) => item.IDPlano !== plano.IDPlano
      );
      // console.log("filtro: ", filtro);

      setPlanosSeleccionados(filtro);
    }
  };

  const handleToggle = () => {
    if (
      chequearPermisos(
        obra.Privilegios,
        "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
      ) === true
    ) {
      toggleOpen(true);
    } else {
      setMensaje({ text: "No tiene permisos!", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      {/* <br /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
          // justifyContent: "center",
        }}
      >
        {chipData.map((data, index) => (
          // <div
          //   style={
          //     {
          //       /*padding: "20px"*/
          //     }
          //   }
          // >
          <Chip
            key={data.IDDepartamento}
            label={data.Departamento}
            color="primary"
            variant={
              chipSelected.length > 0 &&
              chipSelected.filter(
                (chip) => chip.IDDepartamento === data.IDDepartamento
              ).length > 0
                ? ""
                : "outlined"
            }
            onClick={() => handleClick(data)}
            style={{ fontSize: "25px", padding: "0px" }} // Ajusta estos valores a tu gusto
          />
          // </div>
        ))}

        <Chip
          key="agregar"
          label="+"
          color="primary"
          // variant={
          //   chipSelected.length > 0 &&
          //   chipSelected.filter(
          //     (chip) => chip.IDDepartamento === data.IDDepartamento
          //   ).length > 0
          //     ? ""
          //     : "outlined"
          // }
          variant="outlined"
          onClick={() => handleToggle()}
          style={{ fontSize: "25px", padding: "10px" }} // Ajusta estos valores a tu gusto
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Agregar espacio</DialogTitle>
          <DialogContent>
            <DialogContentText>Ejemplos: "A", "B", etc.</DialogContentText>

            {/* <TextField
              autoFocus
              margin="dense"
              id="uf"
              value={dialogValue.Departamento}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Departamento: event.target.value,
                });

                setDepartamentos({
                  ...departamentos,
                  IDDepartamento: "",
                  Departamento: event.target.value,
                });
              }}
              label="UF"
              type="text"
              variant="standard"
            /> */}

            <Autocomplete
              id="free-solo-dialog-demo"
              // options={chipData}
              options={comboDepartamentos}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDDepartamento: "",
                      Departamento: newValue,
                    });

                    setDepartamentos({
                      ...departamentos,
                      IDDepartamento: "",
                      Departamento: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un departamento.");

                  toggleOpen(true);
                  setDialogValue({
                    IDDepartamento: "",
                    Departamento: newValue.inputValue,
                  });

                  setDepartamentos({
                    ...departamentos,
                    IDDepartamento: "",
                    Departamento: newValue.inputValue,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un departamento existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDDepartamento: newValue.IDDepartamento,
                      Departamento: newValue.Departamento,
                    });

                    setDepartamentos({
                      ...departamentos,
                      IDDepartamento: newValue.IDDepartamento,
                      Departamento: newValue.Departamento,
                    });
                  } else {
                    setDialogValue({
                      IDDepartamento: "",
                      Departamento: "",
                    });

                    setDepartamentos({
                      ...departamentos,
                      IDDepartamento: "",
                      Departamento: "",
                    });
                  }
                }

                // setLista([...lista, newValue]);

                setDepartamentoSelected(newValue);
                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, DepartamentoValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (
                  params.inputValue !== "" &&
                  !options.some(
                    (item) =>
                      item.Departamento.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    Departamento: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Departamento) {
                  // console.log("PASA POR C");

                  return option.Departamento;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDDepartamento}>
                  {option.Departamento}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Espacio" variant="standard" />
              )}
            />
          </DialogContent>
          <DialogTitle>Agregar tambien en:</DialogTitle>
          <DialogContent>
            {planosDeObra
              // .filter((item) => item.IDPlano !== idPlano)
              .map((plano) => (
                <div key={plano.IDPlano}>
                  <Checkbox
                    id={plano.IDPlano}
                    key={plano.IDPlano}
                    icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                    checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                    // checked={seleccionados.some(
                    //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                    // )}
                    onChange={(event) => handlePlanos(event, plano)}
                  />
                  {plano.TipoDePlano.toUpperCase() +
                    " | " +
                    plano.Plano.toUpperCase()}
                </div>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default BoxUnidadesFuncionales;
