import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from "@mui/material";
import React from "react";
import styled from "@mui/system/styled";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import TomarFoto from "../controlDeAvance/PagePlano/TomarFoto";
import { useState } from "react";
import {
  enviarPosicionHaciaReactNative,
  formatearFecha,
  formatearHora,
} from "../../funciones/Funciones";
import Webcam from "react-webcam";

import { useContext } from "react";
import UsuarioContext from "../../contextos/UsuarioContext";
import ObraContext from "../../contextos/ObraContext";
import EstilosContext from "../../contextos/EstilosContext";
import ServidorContext from "../../contextos/ServidorContext";
import { useRef } from "react";
import MensajesContext from "../../contextos/MensajesContext";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const videoConstraints = {
  width: 400,
  height: 400,
  //   facingMode: "user",

  //   width: { min: 480 },
  //   height: { min: 720 },
  aspectRatio: 0.6666666667,
  facingMode: "user", // CAMARA FRONTAL
  //   facingMode: { exact: "environment" },  // CAMARA TRASERA
};

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
}));

const ModalVerFotos = ({
  row,
  detalle,
  openVerFotos,
  setOpenVerFotos,
  fotos,
  setFotos,
  onUpdateItems,
}) => {
  const webcamRef = useRef(null);

  const [contadorIntersticial, setContadorIntersticial] = useState(0);
  const [picture, setPicture] = useState("");
  const [mostrarImagen, setMostrarImagen] = useState("");
  const [fotoTomada, setFotoTomada] = useState("");

  const [mostrarCamara, setMostrarCamara] = useState(false);
  const [mirrored, setMirrored] = useState(false);

  const [listaDeFotos, setListaDeFotos] = useState([]);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);
  const { screenWidth, isMobile } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);

  useEffect(() => {
    if (fotoTomada !== "" && fotoTomada !== null) {
      guardar_foto();
    }
  }, [fotoTomada]);

  useEffect(() => {
    // console.log("mostrar: ", mostrarCamara);

    // if ((!isMobile && webcamRef.current) || isMobile) {

    if (mostrarCamara) {
      capture();
    }

    // }
  }, [mostrarCamara]);

  useEffect(() => {
    setListaDeFotos(fotos);
  }, []);

  const handleClose = () => {
    setOpenVerFotos(false);
  };

  const guardar_foto = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("fotoTomada: ", fotoTomada);
    // console.log("row: ", row);
    // console.log("detalle: ", detalle);

    // console.log({
    //   operacion: "GUARDAR_FOTO_DE_AVANCE",
    //   foto: fotoTomada,
    //   anotaciones: "",
    //   idAsignacion: detalle.IDAsignacion,
    //   idUsuario: usuario.IDUsuario,
    // });
    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // console.log("idUsuario: ", usuario.IDUsuario);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_FOTO_DE_AVANCE",
        foto: fotoTomada,
        anotaciones: "",
        idAsignacion: detalle.IDAsignacion,
        idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);
      // console.log("id: ", detalle.IDAsignacion);
      // console.log("fotos: ", fotos);
      // console.log("listaDeFotos: ", listaDeFotos);

      let newFotos = [
        {
          IDFotoDeTarea: respuesta.result.idFoto,
          Foto: fotoTomada,
          Anotaciones: "",
          IDAsignacion: detalle.IDAsignacion,
          IDUsuario: usuario.IDUsuario,
          Fecha: respuesta.result.fecha,
          Publicar: 1,
        },
        ...listaDeFotos,
      ];

      setListaDeFotos(newFotos);

      setFotos(newFotos);

      // Luego de actualizar fotos, llamamos al callback
      onUpdateItems({
        ...row,
        detalle: row.detalle.map((det) =>
          det.IDAsignacion === detalle.IDAsignacion
            ? { ...det, fotos: newFotos }
            : det
        ),
      });

      // console.log("listaDeFotos 2: ", listaDeFotos);

      setMensaje({ text: "Foto guardada con éxito!", tipo: "success" });

      setMostrarMensaje(true);
      // setOpenDialog(false);
      setFotoTomada("");
      setMostrarCamara(false);
    } else {
      console.log("NO TRAE NADA EN guardar_foto()");
      // setState([]);
    }
  };

  const capture = React.useCallback(async () => {
    // alert(screenWidth);
    console.log("PASA POR CAPTURE");

    // console.log("mostrarCamara: ", mostrarCamara);

    // if (mostrarCamara) {
    let sum = localStorage.getItem("contIntersticial");
    // alert("sum: " + sum + "\nCondicion: " + (sum % 3));

    if (sum % 3 === 0) {
      sum = 1;

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BONIFICADO",
        idPublicidad: "ca-app-pub-9747826938175612/9299862830",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    } else {
      sum++;

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "INTERSTICIAL",
        idPublicidad: "ca-app-pub-9747826938175612/9108291141",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: true,
      });
    }

    localStorage.setItem("contIntersticial", sum);

    setContadorIntersticial(sum);

    if (isMobile && window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({
      //     accion: "takePhoto",
      //     contenido: {},
      //   })
      // );
      // enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});
    } else {
      // console.log("webcamRef: ", webcamRef);

      // if (webcamRef.current) {
      const pictureSrc = await webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      setFotoTomada(pictureSrc);
      // console.log("picture: ", pictureSrc);
      // setMostrarCamara(false);

      // } else {
      //   setPicture("");
      //   setMostrarImagen("");

      //   setFotoTomada("");
      // }

      // console.log("pictureSrc: ", pictureSrc);
    }

    // }

    // console.log("pictureSrc", pictureSrc);
  }, [webcamRef]);

  window.addEventListener("message", function (event) {
    // if (processed) {
    //   return;
    // }
    // processed = true;

    // window.ReactNativeWebView.postMessage("FUERA: " + event);

    // if (event.data.type === "myEventType") {
    //   window.ReactNativeWebView.postMessage("myEventType: " + event.data.data);
    //   alert("MSG: " + event.data.data);
    // }

    // alert("mensaje: ", event.data);

    if (event.data.type === "photo") {
      // window.ReactNativeWebView.postMessage("photo: " + event.data.data);

      // const img = document.createElement("img");
      // img.src = `data:image/jpeg;base64,${event.data.data}`;

      // document.body.appendChild(img);

      const pictureSrc = `data:image/jpeg;base64,${event.data.data}`;
      // alert("pictureSrc: ", pictureSrc);
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      // console.log("pictureSrc: ", pictureSrc);

      setFotoTomada(pictureSrc);

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BANNER",
        idPublicidad: "ca-app-pub-9747826938175612/8150779805",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: false,
      });

      setMostrarCamara(false);
    }
  });

  const handleEliminarImagen = async (idFotoDeTarea) => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("fotoTomada: ", fotoTomada);
    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idFotoDeTarea: ", idFotoDeTarea);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ELIMINAR_FOTO_DE_TAREA",
        idFotoDeTarea: idFotoDeTarea,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);

      // setListaDeFotos([
      //   listaDeFotos.filter((imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea),
      // ]);

      let filtro = fotos.filter(
        (imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea
      );

      setListaDeFotos(filtro);
      setFotos(filtro);

      // Luego de actualizar fotos, llamamos al callback
      onUpdateItems({
        ...row,
        detalle: row.detalle.map((det) =>
          det.IDAsignacion === detalle.IDAsignacion
            ? { ...det, fotos: filtro }
            : det
        ),
      });
      // console.log("filtro: ", filtro);

      setMensaje({
        text: "La foto ha sido eliminada con éxito!",
        tipo: "error",
      });

      setMostrarMensaje(true);
      // setOpenDialog(false);
      setFotoTomada("");
      setMostrarCamara(false);
    } else {
      console.log("NO TRAE NADA EN handleEliminarImagen()");
      // setState([]);
    }
  };

  return (
    <Dialog open={openVerFotos} onClose={handleClose}>
      <form>
        <DialogTitle>
          {row.Tarea.toUpperCase()}

          <DialogContentText sx={{ mt: "-8px" }}>
            {detalle.Plano +
              " | " +
              detalle.Departamento +
              " | " +
              detalle.Sector +
              " | " +
              detalle.Grupo}
          </DialogContentText>
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText>Fotos del avance</DialogContentText> */}
          <br />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              // border: "4px solid #000000",
            }}
          >
            <Grid item style={{ width: "300px" }}>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: 300,
                  marginTop: "50px",
                  marginBottom: "50px",
                  border: "1px solid #FF0000",
                }}
              > */}
              {!mostrarCamara ? (
                <Button
                  variant="outlined"
                  startIcon={
                    <CameraAltOutlinedIcon
                      sx={
                        {
                          // p: 1,
                          // cursor: "pointer",
                          // display: "flex",
                          // alignItems: "center",
                        }
                      }
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   capture();
                      // }}
                      // onClick={() => handleTomarFoto()}
                    />
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setMostrarCamara(true);
                    // console.log("PASA POR 1");
                    // capture();
                  }}
                >
                  TOMAR FOTO
                </Button>
              ) : (
                ""
              )}

              {!isMobile && mostrarCamara ? (
                <>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      border: "4px solid #000000",
                    }}
                  >
                    <Grid item style={{ width: "100%" }}>
                      <Webcam
                        width="99%"
                        // height={400}
                        ref={webcamRef}
                        videoConstraints={videoConstraints}
                        mirrored={mirrored}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={0.95}
                        imageSmoothing={true}
                        // audio={true}
                        video="true"
                        //   sx={{
                        //     alignSelf: "flex-start",
                        //     justifyContent: "flex-start",
                        //     display: "flex",
                        //     alignItems: "flex-start",
                        //     float: "left",
                        //     marginLeft: "0px",
                        //   }}
                      />
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <Button
                        variant="outlined"
                        startIcon={
                          <CameraAltOutlinedIcon
                            sx={
                              {
                                // p: 1,
                                // cursor: "pointer",
                                // display: "flex",
                                // alignItems: "center",
                              }
                            }
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   capture();
                            // }}
                            // onClick={() => handleTomarFoto()}
                          />
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          // setMostrarCamara(true);
                          // console.log("PASA POR 2");

                          capture();
                        }}
                      >
                        CAPTURAR
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* </Box> */}
            </Grid>
          </Grid>
          <br />
          {/* {listaDeFotos.map((imagen) => (
            <img src={imagen.Foto} width="100%" />
          ))} */}

          <Grid container spacing={2}>
            {/* {console.log("listaDeFotos:", listaDeFotos)} */}

            {listaDeFotos.map((imagen) => (
              <Grid
                item
                style={{
                  width: "100%",
                  // border: "1px solid #FF0000",
                  position: "relative", // Agrega esta línea
                  marginBottom: "10px",
                }}
              >
                <Item>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      sx={{
                        marginTop: "5px",
                        position: "absolute",
                        right: "15px",
                      }}
                    >
                      <Chip label={imagen.IDFotoDeTarea} color="primary" />
                      <Chip label={imagen.IDAsignacion} color="primary" />
                      <Chip
                        label={formatearFecha(imagen.Fecha, "dd/MM/yyyy")}
                        color="primary"
                      />
                      <Chip
                        label={formatearHora(imagen.Fecha)}
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                  {/* <span style={{ top: "200px" }}>
                    {formatearFecha(imagen.Fecha, "dd/MM/yyyy") +
                      " " +
                      formatearHora(imagen.Fecha)}
                  </span> */}
                  <img src={imagen.Foto} width="100%" />
                  <br />
                  <Button
                    onClick={() => handleEliminarImagen(imagen.IDFotoDeTarea)}
                    startIcon={
                      <DeleteIcon
                        sx={
                          {
                            // p: 1,
                            // cursor: "pointer",
                            // display: "flex",
                            // alignItems: "center",
                          }
                        }
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   capture();
                        // }}
                        // onClick={() => handleTomarFoto()}
                      />
                    }
                  >
                    Eliminar foto
                  </Button>
                </Item>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default ModalVerFotos;
