import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfigContext from "../../../contextos/ConfigContext";
import ServidorContext from "../../../contextos/ServidorContext";
import MensajesContext from "../../../contextos/MensajesContext";
import ObraContext from "../../../contextos/ObraContext";
import { desencriptar } from "../../../funciones/Funciones";

const BoxPresupuestos = ({ pagina, refresh, setRefresh }) => {
  const { servidor } = useContext(ServidorContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { obra } = useContext(ObraContext);

  const { config, setConfig } = useContext(ConfigContext);

  const [beneficioMO, setBeneficioMO] = useState("");
  const [beneficioMateriales, setBeneficioMateriales] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState("");

  useEffect(() => {
    // console.log("configuracion: ", config);

    if (Object.keys(config).length === 0) {
      let objconfig = desencriptar(localStorage.getItem("objconfig"));

      setConfig(objconfig);

      setBeneficioMO(objconfig.PresupuestosBeneficioMO);
      setBeneficioMateriales(objconfig.PresupuestosBeneficioMateriales);
      setMostrarDetalle(objconfig.PresupuestosCheckMostrarDetalle);
    } else {
      setBeneficioMO(config.PresupuestosBeneficioMO);
      setBeneficioMateriales(config.PresupuestosBeneficioMateriales);
      setMostrarDetalle(config.PresupuestosCheckMostrarDetalle);
    }
  }, []);

  const handleModif = (event) => {
    // console.log(event.target.value);
    // console.log(event);

    switch (event.target.id) {
      case "txtPresupuestosBeneficioMO":
        setBeneficioMO(event.target.value);
        config.PresupuestosBeneficioMO = event.target.value;

        break;

      case "txtPresupuestosBeneficioMateriales":
        setBeneficioMateriales(event.target.value);
        config.PresupuestosBeneficioMateriales = event.target.value;

        break;

      case "chkPresupuestosMostrarDetalle":
        let resp = event.target.checked === true ? 1 : 0;
        setMostrarDetalle(resp);

        config.PresupuestosCheckMostrarDetalle = resp;

        break;

      default:
        break;
    }

    setConfig(config);
    // console.log("id: ", id);
  };

  const modificar_configuracion = async () => {
    const API_ENDPOINT = servidor + `api_config.php`;

    // console.log({
    //   operacion: "ACTUALIZAR_CONFIGURACION_PRESUPUESTO",
    //   idObra: obra.IDObra,
    //   presupuestosBeneficioMO: config.PresupuestosBeneficioMO,
    //   presupuestosBeneficioMateriales: config.PresupuestosBeneficioMateriales,
    //   presupuestosCheckMostrarDetalle: config.PresupuestosCheckMostrarDetalle,

    //   // campo: modifConfig.campo,
    //   // valor: modifConfig.valor,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_CONFIGURACION_PRESUPUESTO",
        idObra: obra.IDObra,
        presupuestosBeneficioMO: config.PresupuestosBeneficioMO,
        presupuestosBeneficioMateriales: config.PresupuestosBeneficioMateriales,
        presupuestosCheckMostrarDetalle: config.PresupuestosCheckMostrarDetalle,

        // campo: modifConfig.campo,
        // valor: modifConfig.valor,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.resultado === "OK") {
      // console.log(respuesta.result.asignaciones);
      // setModifConfig(initConfig);

      setMensaje({
        text: "Configuración modificada con éxtio!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      setRefresh(true);
    } else {
      setMensaje({
        text: "Error al modificar la configuración!",
        tipo: "error",
      });

      setMostrarMensaje(true);
      console.log("NO TRAE NADA EN: modificar_configuracion()");
      // setState([]);
    }
  };

  const handleSubmit = () => {
    // console.log("guardar");

    modificar_configuracion();
  };

  return (
    <>
      <Box p={1}>
        <strong>PRESUPUESTOS:</strong>
        <br />
      </Box>

      <Box p={1} border={0}>
        <Grid
          container
          // border={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid
            item
            // border={1}
            style={{ height: "auto", fontSize: "20px", paddingTop: "1px" }}
          >
            Beneficio por mano de obra:
          </Grid>
          <Grid
            item
            // border={1}
            style={{
              /*paddingBottom: "12px"*/
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                // m: 1,
                // mt: 3,
                width: "15ch",
                marginLeft: "20px",
              }}
            >
              <Input
                id="txtPresupuestosBeneficioMO"
                style={{ fontSize: "26px", paddingBottom: "0px" }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  "aria-label": "porcentaje",
                }}
                onChange={handleModif}
                value={beneficioMO}
              />
              {/* <FormHelperText id="standard-weight-helper-text">
                Porcentaje mano de obra
              </FormHelperText> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box p={1} border={0}>
        <Grid
          container
          // border={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid
            item
            // border={1}
            style={{ height: "auto", fontSize: "20px", paddingTop: "1px" }}
          >
            Beneficio por materiales:
          </Grid>
          <Grid
            item
            // border={1}
            style={{
              /*paddingBottom: "12px"*/
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                // m: 1,
                // mt: 3,
                width: "15ch",
                marginLeft: "20px",
              }}
            >
              <Input
                id="txtPresupuestosBeneficioMateriales"
                style={{ fontSize: "26px", paddingBottom: "0px" }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  "aria-label": "porcentaje",
                }}
                onChange={handleModif}
                value={beneficioMateriales}
              />
              {/* <FormHelperText id="standard-weight-helper-text">
                Porcentaje mano de obra
              </FormHelperText> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box p={1} border={0}>
        <Grid
          container
          // border={1}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid
            item
            // border={1}
            style={{ height: "auto", fontSize: "20px", paddingTop: "1px" }}
          >
            Mostrar detalle en presupuesto:
            <Checkbox
              id="chkPresupuestosMostrarDetalle"
              key="mostrarPresupuesto"
              icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
              checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
              // checked={asignaciones.some(
              //   (check) => check.IDDocumentacion == docs.IDDocumentacion
              // )}

              // checked={config.presupuestos.some(
              //   (item) => item.IDDocumentacion == docs.IDDocumentacion
              // )}
              checked={mostrarDetalle}
              onChange={(event) => handleModif(event)}
              // onChange={(event) =>
              //   handleCheck(event, "PRESUPUESTOS_MOSTRAR_BENEFICIO")
              // }
            />
          </Grid>
        </Grid>
      </Box>

      {pagina !== "GENERAL_CONFIG" ? (
        <Box p={1} border={0} width={600}>
          <Grid
            container
            // border={1}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Grid
              item
              // border={1}
              style={{
                /*paddingBottom: "12px"*/
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button onClick={handleSubmit}>Confirmar</Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default BoxPresupuestos;
