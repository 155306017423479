import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BoxContador = ({ imagen, cantidad, titulo, link }) => {
  let navigate = useNavigate();

  const handleButton = () => {
    navigate("/" + link);
  };
  return (
    <Box
      component="span"
      sx={{
        pt: 2,
        border: "1px solid grey",
        width: "150px",
        height: "150px",
        textAlign: "center",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
      }}
    >
      <img src={imagen} style={{ width: "80px" }} />
      <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
        {cantidad}
        <Typography variant="h6" style={{ fontSize: "16px" }}>
          {link !== undefined ? (
            <Button variant="text" onClick={() => handleButton()}>
              {titulo.toUpperCase()}
            </Button>
          ) : (
            titulo.toUpperCase()
          )}
        </Typography>
      </Typography>
    </Box>
  );
};

export default BoxContador;
