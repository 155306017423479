import { Box, Grid, Typography } from "@mui/material";

import React, { useState } from "react";
import { formatearFecha } from "../../funciones/Funciones";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./BoxCalendario.css";

import GrupoBadges from "./GrupoBadges";

const BoxCalendario = ({
  ordenesDeServicio,
  volquetes,
  pedidos,
  documentacion,
}) => {
  const [fechaMostrada, setFechaMostrada] = useState(new Date());
  const [touchStartX, setTouchStartX] = useState(null);
  const [animationClass, setAnimationClass] = useState("");

  const cambiarFecha = (dias) => {
    setFechaMostrada((fechaActual) => {
      const nuevaFecha = new Date(fechaActual);
      nuevaFecha.setDate(nuevaFecha.getDate() + dias);
      return nuevaFecha;
    });
    setAnimationClass(dias > 0 ? "slide-left" : "slide-right");
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      cambiarFecha(1);
    } else if (touchEndX - touchStartX > 50) {
      cambiarFecha(-1);
    }
  };

  return (
    <Box
      component="span"
      sx={{
        pt: 2,
        border: "1px solid grey",
        width: "100%",
        textAlign: "center",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "40px",
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <ArrowBackIosNewIcon
            style={{ fontSize: 40 }}
            size={50}
            onClick={() => cambiarFecha(-1)}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            gutterBottom
            style={{ textAlign: "center" }}
            className={animationClass}
            onAnimationEnd={() => setAnimationClass("")}
          >
            {formatearFecha(fechaMostrada, "textoResumen")}
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              CALENDARIO
            </Typography>
          </Typography>
          <GrupoBadges fechaMostrada={fechaMostrada} />
        </Grid>

        <Grid item>
          <ArrowForwardIosIcon
            style={{ fontSize: 40 }}
            size={50}
            onClick={() => cambiarFecha(1)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxCalendario;
