import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatearFecha } from "../../funciones/Funciones";

const EncabezadoWeb = ({ encabezadoPedido }) => {
  return (
    <Grid item xs={12} border={0} mb={2}>
      <Box
        sx={{
          width: "100%",
          // height: "auto",
          backgroundColor: "primary.dark",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          p: 2,
          color: "#FFFF",
          flexGrow: 1,
          display: "flex",

          // "&:hover": {
          //   backgroundColor: "primary.main",
          //   opacity: [0.9, 0.8, 0.7],
          // },
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Grid
            item
            xs={2}
            //   m={1}
            //   p={2}

            mt={2}
            mr={2}
            pr={2}
            pb={2}
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
            }}
          >
            {/* <Item> */}
            <Typography component="div">
              <Box
                sx={{
                  fontWeight: "normal",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {encabezadoPedido.FechaProgramada !== null &&
                encabezadoPedido.FechaProgramada !== undefined
                  ? formatearFecha(
                      encabezadoPedido.FechaProgramada,
                      "dd/MM/yyyy"
                    )
                  : ""}
              </Box>
              <Box
                sx={{
                  fontSize: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                FECHA PROGRAMADA
              </Box>
            </Typography>
            {/* </Item> */}
          </Grid>

          {encabezadoPedido.FechaConfirmacion !== null &&
          encabezadoPedido.FechaConfirmacion !== undefined ? (
            <Grid
              item
              xs={2}
              //   m={1}
              //   p={2}

              mt={2}
              mr={2}
              pr={2}
              pb={2}
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {/* <Item> */}
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {encabezadoPedido.FechaConfirmacion !== null &&
                  encabezadoPedido.FechaConfirmacion !== undefined
                    ? formatearFecha(
                        encabezadoPedido.FechaConfirmacion,
                        "dd/MM/yyyy"
                      )
                    : ""}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  FECHA CONFIRMADA
                </Box>
              </Typography>
              {/* </Item> */}
            </Grid>
          ) : (
            ""
          )}

          {encabezadoPedido.Proveedor !== null &&
          encabezadoPedido.Proveedor !== undefined ? (
            <Grid
              item
              xs={2}
              //   m={1}
              //   p={2}

              mt={2}
              mr={2}
              pr={2}
              pb={2}
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {/* <Item> */}
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {encabezadoPedido.Proveedor.toUpperCase()}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  PROVEEDOR
                </Box>
              </Typography>
              {/* </Item> */}
            </Grid>
          ) : (
            ""
          )}

          {encabezadoPedido.NombreContacto !== null &&
          encabezadoPedido.NombreContacto !== undefined ? (
            <Grid
              item
              xs={2}
              //   m={1}
              //   p={2}

              mt={2}
              mr={2}
              pr={2}
              pb={2}
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {/* <Item> */}
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {encabezadoPedido.NombreContacto.toUpperCase()}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  CONTACTO
                </Box>
              </Typography>
              {/* </Item> */}
            </Grid>
          ) : (
            ""
          )}

          {encabezadoPedido.Telefono !== null &&
          encabezadoPedido.Telefono !== undefined ? (
            <Grid
              item
              xs={2}
              //   m={1}
              //   p={2}

              mt={2}
              mr={2}
              pr={2}
              pb={2}
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {/* <Item> */}
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {encabezadoPedido.Telefono}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  TELÉFONO
                </Box>
              </Typography>
              {/* </Item> */}
            </Grid>
          ) : (
            ""
          )}

          {encabezadoPedido.Email !== null &&
          encabezadoPedido.Email !== undefined ? (
            <Grid
              item
              xs={2}
              //   m={1}
              //   p={2}

              mt={2}
              mr={2}
              pr={2}
              pb={2}
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {/* <Item> */}
              <Typography component="div">
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontSize: 18,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {encabezadoPedido.Email}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  E-MAIL
                </Box>
              </Typography>
              {/* </Item> */}
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default EncabezadoWeb;
