import { Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import EstilosContext from "../../contextos/EstilosContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";

const PageAgregarDescripcion = (props) => {
  const { screenWidth } = useContext(EstilosContext);

  const [descripcion, setDescripcion] = useState("");
  //   const [paso1, setPaso1] = useState("");

  useEffect(() => {
    props.setTitulo("Agregar descripción");
    props.setPagina("");
  }, [props.setTitulo]);

  //   useEffect(() => {
  //     setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  useEffect(() => {
    return () => {
      let paso3 = desencriptar(localStorage.getItem("objordserv"));
      let paso4 = { ...paso3, descripcion: descripcion };

      localStorage.setItem("objordserv", encriptar(paso4));
    };
  }, [descripcion]);

  const handleDescripcion = (e) => {
    setDescripcion(e.target.value);
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Descripción del problema"
            multiline
            rows={3}
            fullWidth
            sx={{ width: "95%" }}
            onChange={(e) => handleDescripcion(e)}
            // defaultValue="Default Value"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Navegacion nroPaso={props.nroPaso} />
        </Grid>
      </Grid>
    </>
  );
};

export default PageAgregarDescripcion;
