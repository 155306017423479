import React, { useRef } from "react";
import { useEffect, useState } from "react";

import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import ImageSearchTwoToneIcon from "@mui/icons-material/ImageSearchTwoTone";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamTwoToneIcon from "@mui/icons-material/VideocamTwoTone";

import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import EstilosContext from "../../contextos/EstilosContext";
import { useContext } from "react";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
// import { useMediaQuery } from "react-responsive";

const MenuCamara = ({
  setPicture,
  setMostrarImagen,
  setVerArchivo,
  fontSize,
  disabled,
  MAXIMO_ARCHIVOS,
}) => {
  const inputRef = useRef();

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  useEffect(() => {
    // console.log("screenWidth", screenWidth);
  }, []);

  const { screenWidth } = useContext(EstilosContext);

  let navigate = useNavigate();

  //   const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButton = (btn) => {
    if (btn === 1) {
      setPicture("");
      setMostrarImagen("");
      setVerArchivo(false);
    } else if (btn === 2) {
      navigate("/grabarVideo");
    } else if (btn === 3) {
      inputRef.current.click();
      // navigate("/buscarArchivos");
    }
  };

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;
      // console.log("imageUrl: ", imageUrl);

      // setImg(imageUrl);
      navigate("/agregarArchivos");
      // Aquí puedes utilizar la URL de la imagen para mostrarla o procesarla
    };
    reader.readAsDataURL(file);
  }

  return (
    // <Box
    //   component="span"
    //   sx={{
    //     pt: 2,
    //     border: "1px solid grey",
    //     width: "150px",
    //     height: "150px",
    //     textAlign: "center",
    //     marginLeft: "5px",
    //     marginRight: "5px",
    //     marginTop: "5px",
    //   }}
    // >
    // <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     // alignItems: "flex-start",
    //     height: "100vh",
    //   }}
    // >

    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <Grid
        container
        style={{
          display: "flex",
          // border: "1px solid #CCCCCC",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          sx={{
            display: "flex",
            // border: "1px solid #CCCCCC",
            justifyContent:
              screenWidth === "xs" || screenWidth === "sm" ? "center" : "right",
            paddingRight:
              screenWidth === "xs" || screenWidth === "sm" ? "0px" : "5px",
            paddingBottom: "10px",
          }}
        >
          {disabled == true ? (
            <ImageSearchTwoToneIcon
              sx={{
                fontSize: fontSize,
                borderRadius: "50%",
                border: "1px solid gray",
                // boxShadow: "inset 0px 0px 5px gray",
                boxShadow: "inset 0px 0px 0px 9999px rgba(128,128,128,0.5)",
                p: 1,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                setMensaje({
                  text: "Máximo de archivos permitidos: " + MAXIMO_ARCHIVOS,
                  tipo: "error",
                });
                setMostrarMensaje(true);
              }}
            />
          ) : (
            <>
              <ImageSearchOutlinedIcon
                sx={{
                  fontSize: fontSize,
                  borderRadius: "50%",
                  border: "1px solid gray",
                  boxShadow: "inset 0px 0px 5px gray",
                  p: 1,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleButton(3);
                }}
              />
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                // onClick={() => alert("CLICK")}
              />
            </>
          )}
        </Grid>
        {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                handleButton(3);
              }}
            >
              BUSCAR
            </Button>
          </Typography>
        </Typography>
      </Grid> */}

        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          sx={{
            display: "flex",
            // border: "1px solid #CCCCCC",
            justifyContent:
              screenWidth === "xs" || screenWidth === "sm" ? "center" : "right",
            paddingRight:
              screenWidth === "xs" || screenWidth === "sm" ? "0px" : "5px",
            paddingBottom: "10px",
          }}
        >
          {disabled == true ? (
            <CameraAltTwoToneIcon
              sx={{
                fontSize: fontSize,
                borderRadius: "50%",
                border: "1px solid gray",
                // boxShadow: "inset 0px 0px 5px gray",
                boxShadow: "inset 0px 0px 0px 9999px rgba(128,128,128,0.5)",
                p: 1,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                setMensaje({
                  text: "Máximo de archivos permitidos: " + MAXIMO_ARCHIVOS,
                  tipo: "error",
                });
                setMostrarMensaje(true);
              }}
            />
          ) : (
            <CameraAltOutlinedIcon
              sx={{
                fontSize: fontSize,
                borderRadius: "50%",
                border: "1px solid gray",
                boxShadow: "inset 0px 0px 5px gray",
                p: 1,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleButton(1);
              }}
            />
          )}
        </Grid>
        {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button
              variant="text"
              onClick={(e) => {
                e.preventDefault();
                handleButton(1);
              }}
            >
              FOTO
            </Button>
          </Typography>
        </Typography>
      </Grid> */}

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            // border: "1px solid #CCCCCC",
            justifyContent:
              screenWidth === "xs" || screenWidth === "sm" ? "center" : "left",
            paddingLeft:
              screenWidth === "xs" || screenWidth === "sm" ? "0px" : "5px",
            paddingBottom: "10px",
          }}
        >
          {disabled == true ? (
            <VideocamTwoToneIcon
              sx={{
                fontSize: fontSize,
                borderRadius: "50%",
                border: "1px solid gray",
                // boxShadow: "inset 0px 0px 5px gray",
                boxShadow: "inset 0px 0px 0px 9999px rgba(128,128,128,0.5)",
                p: 1,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                setMensaje({
                  text: "Máximo de archivos permitidos: " + MAXIMO_ARCHIVOS,
                  tipo: "error",
                });
                setMostrarMensaje(true);
              }}
            />
          ) : (
            <VideocamOutlinedIcon
              sx={{
                fontSize: fontSize,
                borderRadius: "50%",
                border: "1px solid gray",
                boxShadow: "inset 0px 0px 5px gray",
                p: 1,
                cursor: "pointer",
              }}
              onClick={() => handleButton(2)}
            />
          )}
        </Grid>
        {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            <Button variant="text" onClick={() => handleButton(2)}>
              VIDEO
            </Button>
          </Typography>
        </Typography>
      </Grid> */}
      </Grid>
    </>
    //  <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
    //     <Typography variant="h6" style={{ fontSize: "16px" }}>
    //       <Button variant="text" onClick={() => handleButton(1)}>
    //         FOTO
    //       </Button>
    //     </Typography>
    //   </Typography>
    // </Box>

    // <div
    //   style={{
    //     display: "grid",
    //     placeItems: "center",
    //     gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    //   }}
    // >
    //   <CameraAltOutlinedIcon
    //     sx={{
    //       fontSize: 80,
    //       borderRadius: "50%",
    //       border: "1px solid gray",
    //       boxShadow: "inset 0px 0px 5px gray",
    //       p: 1,
    //       cursor: "pointer",
    //     }}
    //     onClick={() => handleButton(1)}
    //   />
    //   <VideocamOutlinedIcon
    //     sx={{
    //       fontSize: 80,
    //       borderRadius: "50%",
    //       border: "1px solid gray",
    //       boxShadow: "inset 0px 0px 5px gray",
    //       p: 1,
    //       cursor: "pointer",
    //     }}
    //     onClick={() => handleButton(2)}
    //   />
    // </div>
  );
};

export default MenuCamara;
