import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DashboardContext from "../../contextos/DashboardContext";
import ObraContext from "../../contextos/ObraContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import {
  chequearPermisos,
  desencriptar,
  formatearFecha,
} from "../../funciones/Funciones";
import BoxBadge from "./BoxBadge";

const GrupoBadges = ({ fechaMostrada }) => {
  const { dashboard, setDashboard } = useContext(DashboardContext);
  const { obra, setObra } = useContext(ObraContext);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const [cantOrdenesDeServicio, setCantOrdenesDeServicio] = useState(0);
  const [cantVolquetes, setCantVolquetes] = useState(0);
  const [cantPedidos, setCantPedidos] = useState(0);
  const [cantDocumentacion, setCantDocumentacion] = useState(0);

  useEffect(() => {
    let objobra = desencriptar(localStorage.getItem("objobra"));

    setObra(objobra);
  }, []);

  useEffect(() => {
    // console.log("DASHBOARD: ", dashboard);

    // const fechaHoy = formatearFecha(new Date());
    const fecha = formatearFecha(fechaMostrada);

    if (dashboard && dashboard.listaOrdenesDeServicio) {
      let ordenesPendientes = dashboard.listaOrdenesDeServicio.filter(
        (item) => item.Estado === "PENDIENTE" && item.IDObra === obra.IDObra
      );

      setCantOrdenesDeServicio(ordenesPendientes.length);
    }

    if (dashboard && dashboard.listaVolquetes) {
      let volquetesPendientes = dashboard.listaVolquetes.filter(
        (item) => item.FechaProgramada === fecha && item.IDObra === obra.IDObra
      );

      let sumaVolquetes = volquetesPendientes.reduce(
        (total, item) => total + parseInt(item.Cantidad),
        0
      );

      setCantVolquetes(sumaVolquetes);
    }

    if (dashboard && dashboard.listaPedidos) {
      let pedidosPendientes = dashboard.listaPedidos.filter(
        (item) => item.FechaProgramada === fecha && item.IDObra === obra.IDObra
      );

      setCantPedidos(pedidosPendientes.length);
    }

    if (dashboard && dashboard.listaDocumentacion) {
      let documentacionesPendientes = dashboard.listaDocumentacion.filter(
        (item) => item.FechaVencimiento === fecha && item.IDObra === obra.IDObra
      );

      setCantDocumentacion(documentacionesPendientes.length);
    }
  }, [dashboard, fechaMostrada]);

  return (
    <Grid container>
      <Grid item>
        {chequearPermisos(
          privilegios,
          "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
        ) === true ? (
          <BoxBadge
            imagen="tareas.png"
            cantidad={
              cantOrdenesDeServicio
              // dashboard.ordenesDeServicio.length
            }
            titulo="Tareas"
            link="dashboardOrdenesDeServicio"
          />
        ) : (
          // <BoxContador
          //   imagen="tareas.png"
          //   cantidad={
          //     cantOrdenesDeServicio
          //     // dashboard.ordenesDeServicio.length
          //   }
          //   titulo="TAREAS"
          //   link="dashboardOrdenesDeServicio"
          // />
          // console.log("DASH: ", dashboard)
          ""
        )}
      </Grid>
      <Grid item>
        {chequearPermisos(privilegios, "VOLQUETES") === true ? (
          <BoxBadge
            imagen="volquete.png"
            cantidad={cantVolquetes}
            titulo="Volquetes"
            link="dashboardVolquetes"
          />
        ) : (
          // <BoxContador
          //   imagen="volquete.png"
          //   cantidad={cantVolquetes}
          //   titulo="Volquetes"
          //   link="dashboardVolquetes"
          // />
          ""
        )}
      </Grid>
      <Grid item>
        {chequearPermisos(privilegios, "PEDIDOS") === true ? (
          <BoxBadge
            imagen="pedidos.png"
            cantidad={cantPedidos}
            titulo="Pedidos"
            link="dashboardPedidos"
          />
        ) : (
          // <BoxContador
          //   imagen="pedidos.png"
          //   cantidad={cantPedidos}
          //   titulo="Pedidos"
          //   link="dashboardPedidos"
          // />
          ""
        )}
      </Grid>
      <Grid item>
        {chequearPermisos(privilegios, "DOCUMENTACION") === true ? (
          <BoxBadge
            imagen="documentacion.png"
            cantidad={cantDocumentacion}
            titulo="Documentacion"
            link="documentacion"
          />
        ) : (
          // <BoxContador
          //   imagen="documentacion.png"
          //   cantidad={cantDocumentacion}
          //   titulo="Documentacion"
          //   link="documentacion"
          // />
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default GrupoBadges;
