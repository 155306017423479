import React, { useContext, useEffect, useState } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import Botonera from "../../components/Botonera";
import Titulo from "../../components/Titulo";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";
import BotoneraConfig from "./BotoneraConfig";
import ServidorContext from "../../contextos/ServidorContext";

const PageObraConfig = (props) => {
  useEffect(() => {
    props.setTitulo("Configuración");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
    }

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
    }
  }, []);

  const [mostrarProgress, setMostrarProgress] = useState(false);

  // const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  // const {
  //   privilegios,
  //   setPrivilegios,
  //   mostrarAlert,
  //   setMostrarAlert,
  // } = useContext(PrivilegioContext);

  // const listar_privilegios = async () => {
  //   // console.log(lista);

  //   setMostrarProgress(true);

  //   // console.log("IDObra: " + obra.IDObra);
  //   // console.log("IDSubcontrato: " + modificar.IDSubcontrato);

  //   const API_ENDPOINT = servidor + `api_config.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       operacion: "LISTAR_PRIVILEGIOS",
  //       idObra: obra.IDObra,
  //       idUsuario: usuario.IDUsuario,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.privilegios) {
  //     setPrivilegios(respuesta.result.privilegios);
  //     // alert("Lista de precios actualizada.");
  //   } else {
  //     console.log("NO TRAE NADA EN: listar_privilegios()");
  //     // setState([]);
  //   }

  //   setMostrarProgress(false);
  // };

  return (
    <div>
      <Titulo
        texto="Configuración general"
        botones={{
          seccion: "SETTINGS-GENERAL",
          alta: false,
          baja: false,
          modif: false,
        }}
      />
      <br />
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <BotoneraConfig />

      {/* <Titulo
        texto="Privilegios de acceso"
        botones={{
          seccion: "SETTINGS-USUARIO",
          alta: false,
          baja: false,
          modif: false,
        }}
      />

      <div style={{ marginBottom: "30px", fontSize: "20px" }}>
        Seleccione los privilegios de acceso para este usuario:
      </div>

      {documentos.map((docs) => (
        <div key={docs.IDDocumentacion}>
          <Checkbox
            id={docs.IDDocumentacion}
            key={docs.IDDocumentacion}
            icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
            checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
            // checked={asignaciones.some(
            //   (check) => check.IDDocumentacion == docs.IDDocumentacion
            // )}

            checked={seleccionados.some(
              (item) => item.IDDocumentacion == docs.IDDocumentacion
            )}
            onChange={(event) => handleChange(event, docs)}
          />
          {docs.Documento.toUpperCase()}
        </div>
      ))} */}
    </div>
  );
};

export default PageObraConfig;
