import React, { useEffect } from "react";
import { createContext, useState } from "react";

const PedidoContext = createContext();

const PedidoProvider = ({ children }) => {
  const initPedido = {
    codigoPedido: "",
    idUsuario: "",
    idObra: "",
    idProveedor: "",
    proveedor: "",
    detalle: [],
    fechaProgramada: "",
    horaProgramada: "",
  };

  const [pedido, setPedido] = useState(initPedido);
  const [codigoPedido, setCodigoPedido] = useState("");
  const [limpiarPedido, setLimpiarPedido] = useState(false);

  useEffect(() => {
    if (pedido === "INIT") {
      setPedido(initPedido);
    }
  }, [pedido]);

  const data = {
    pedido,
    setPedido,
    codigoPedido,
    setCodigoPedido,
    limpiarPedido,
    setLimpiarPedido,
  };

  return (
    <PedidoContext.Provider value={data}>{children}</PedidoContext.Provider>
  );
};

export { PedidoProvider };
export default PedidoContext;
