import { Button, Grid } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import EstilosContext from "../../contextos/EstilosContext";

import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";

const Navegacion = ({ nroPaso, setFinNavegacion }) => {
  const { screenWidth } = useContext(EstilosContext);

  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  let navigate = useNavigate();

  const CANTIDAD_DE_PASOS = 6;

  const [pasoActual, setPasoActual] = useState("");
  const [disableNav, setDisableNav] = useState({
    anterior: true,
    siguiente: false,
  });

  const handlePasoAnterior = (pasoActual) => {
    setPasoActual(pasoActual - 1);
  };

  const handlePasoSiguiente = (pasoActual) => {
    if (pasoActual < CANTIDAD_DE_PASOS) {
      setPasoActual(pasoActual + 1);
    } else if (pasoActual === CANTIDAD_DE_PASOS) {
      setDisableNav({ anterior: false, siguiente: true });

      setFinNavegacion(true);
    }
  };

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    // console.log("Paso: ", nroPaso);
    setPasoActual(nroPaso);
  }, []);

  //   useEffect(() => {
  //     let objobra = localStorage.getItem("objobra");

  //     setObra(desencriptar(objobra));

  //     if (usuario.IDUsuario === undefined) {
  //       // console.log("PASA POR IDObra");
  //       let objusu = desencriptar(localStorage.getItem("objusu"));
  //       setUsuario(objusu);
  //       // console.log(objusu);
  //     } else {
  //       // console.log(usuario);
  //     }
  //   }, []);

  useEffect(() => {
    if (pasoActual === 1) {
      setDisableNav({ anterior: true, siguiente: false });
      // } else if (pasoActual === CANTIDAD_DE_PASOS) {
      //   setDisableNav({ anterior: false, siguiente: false });

      // } else if (pasoActual === CANTIDAD_DE_PASOS+1) {
      //   setDisableNav({ anterior: false, siguiente: true });
    } else {
      setDisableNav({ anterior: false, siguiente: false });
    }

    if (pasoActual === 1) {
      navigate("/agregarArchivos");
    } else if (pasoActual === 2) {
      navigate("/agregarUbicacion");
    } else if (pasoActual === 3) {
      navigate("/agregarPalabrasClaves");
    } else if (pasoActual === 4) {
      navigate("/agregarDescripcion");
    } else if (pasoActual === 5) {
      navigate("/agregarDestinatarios");
    } else if (pasoActual === 6) {
      navigate("/agregarPrioridad");
    }
  }, [pasoActual]);

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          marginTop: "20px",
          //   border: "1px solid #CCCCCC",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              handlePasoAnterior(pasoActual);
            }}
            disabled={disableNav.anterior}
            // sx={{ marginTop: "20px" }}
          >
            Volver
          </Button>
          <div style={{ marginTop: "5px" }}>
            &nbsp; PASO {pasoActual} de {CANTIDAD_DE_PASOS} &nbsp;
          </div>
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              handlePasoSiguiente(pasoActual);
            }}
            disabled={disableNav.siguiente}
            // sx={{ marginTop: "20px" }}
          >
            {pasoActual === CANTIDAD_DE_PASOS ? "Finalizar" : "Siguiente"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Navegacion;
