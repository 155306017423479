import React, { useContext } from "react";
import TituloGrupos from "./TituloGrupos";
import Tarea from "./Tarea";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};

const ListadoDeTareas = ({ grupos }) => {
  const {
    tareasFiltradas,
    tareasAsignadas,
    setTareasAsignadas,
    tareasFiltradasCoincidentes,
  } = useContext(ControlDeAvanceContext);

  // console.log("tareasFiltradassss: ", tareasFiltradas);

  // console.log("tareasFiltradasCoincidentes: ", tareasFiltradasCoincidentes);

  return (
    // Luego, usamos este nuevo array en nuestro renderizado
    tareasFiltradasCoincidentes.length > 0 &&
    tareasFiltradasCoincidentes.map((grupo, index) => (
      <>
        <TituloGrupos grupo={{ IDGrupo: grupo.IDGrupo, Grupo: grupo.Grupo }} />

        {grupo.Tareas.map((tarea) => (
          <>
            <Tarea
              // setTareasAsignadas={setTareasAsignadas}
              // tareasAsignadas={tareasAsignadas}
              grupo={grupo}
              tarea={tarea}
              style={Object.assign({})}
            />
          </>
        ))}
        <br />
      </>
    ))
  );
};

export default ListadoDeTareas;
