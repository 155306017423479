import React, { useState, useEffect, useContext } from "react";
// import { Grid } from "@mui/material";

import Pedido from "./Pedido";
import Titulo from "../../components/Titulo";
import Grid from "@mui/material/Grid";
import UsuarioContext from "../../contextos/UsuarioContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";
import PrimerPaso from "../general/PrimerPaso";
import ProgressBar from "../general/ProgressBar";
import ServidorContext from "../../contextos/ServidorContext";

const PedidosLista = (props) => {
  // const pedidos = [
  //   {
  //     idPedido: 1,
  //     titulo: "Pedido #1",
  //     fecha: "2022-06-16 15:44:18",
  //     lista: [
  //       { idMaterial: 1, material: "Material #1", cantidad: 5 },
  //       { idMaterial: 2, material: "Material #2", cantidad: 2 },
  //     ],
  //   },
  //   {
  //     idPedido: 2,
  //     titulo: "Pedido #2",
  //     fecha: "2022-06-16 15:45:20",
  //     lista: [
  //       { idMaterial: 17, material: "Material #1", cantidad: 5 },
  //       { idMaterial: 12, material: "Material #2", cantidad: 2 },
  //       { idMaterial: 11, material: "Material #3", cantidad: 3 },
  //     ],
  //   },
  // ];

  const [pedidos, setPedidos] = useState([]);
  const [mostrarProgressBar, setMostrarProgressBar] = useState(false);

  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);

  const obtenerPedidos = async () => {
    // console.log("pedido: " + JSON.stringify(props.pedido));

    const API_ENDPOINT = servidor + `api_pedidos.php`;

    setMostrarProgressBar(true);

    // console.log("estado: ", props.estado);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        // operacion: "POST_PEDIDOS_PENDIENTES",
        operacion: "MOSTRAR_PEDIDOS",
        estado: props.estado,
        idUsuario: usuario.IDUsuario,
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();
    // console.log("PEDIDOS EN PEDIDOSLISTA: ");
    // console.log(respuesta.result.pedidos);
    if (respuesta.result.pedidos) {
      setPedidos(respuesta.result.pedidos);
    } else {
      setPedidos([]);
    }

    setMostrarProgressBar(false);
    // alert(respuesta.result);

    // console.log(respuesta.result.pedidos);
  };

  // const obtenerPedidos = async () => {
  //   // console.log("pedido: " + JSON.stringify(props.pedido));

  //   const API_ENDPOINT = `https://materiales.bimtracker.com/backend/api_pedidos.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       // operacion: "POST_PEDIDOS_PENDIENTES",
  //       operacion: "POST_MOSTRAR_PEDIDOS",
  //       estado: props.estado,
  //       idUsuario: 2,
  //       idObra: 1,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   if (respuesta.result.pedidos) {
  //     setPedidos(respuesta.result.pedidos);
  //   } else {
  //     setPedidos([]);
  //   }

  //   // alert(respuesta.result);

  //   // console.log(respuesta.result.pedidos);
  // };

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));
    // console.log(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    obtenerPedidos();
  }, []);

  return (
    <div style={{ marginTop: "30px" }}>
      {!mostrarProgressBar ? (
        <Grid container spacing={2}>
          {pedidos.length > 0 ? (
            <Titulo
              texto={props.texto}
              botones={{ seccion: "", alta: false, baja: false, modif: false }}
            />
          ) : null}
          <br />
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            paddingTop={2}
            // style={{ border: "1px solid #FF0000" }}
          >
            {pedidos.map((ped) => {
              return <Pedido key={ped.idPedido} pedido={ped} />;
            })}
          </Grid>
          {!mostrarProgressBar && pedidos.length <= 0 ? (
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <PrimerPaso texto="HACÉ TU PRIMER PEDIDO" link="/altaPedido" />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        <ProgressBar mostrar={mostrarProgressBar} />
      )}
    </div>
  );
};

export default PedidosLista;
