import {
  createTheme,
  Grid,
  IconButton,
  responsiveFontSizes,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
// import { useTheme } from "@emotion/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { traer_color } from "../../colores/Colores";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EstilosContext from "../../contextos/EstilosContext";

const PrimerPaso = ({ texto, link }) => {
  //   let theme = createTheme({
  //     palette: {
  //       primary: {
  //         main: "#2979ff",
  //       },
  //       secondary: {
  //         main: "#2196f3", // Define el color primario aquí
  //       },
  //     },
  //     typography: {
  //       h5: {
  //         fontWeight: 800,
  //       },
  //     },
  //   });
  //   theme = responsiveFontSizes(theme);

  //   const tema = useTheme();

  let navigate = useNavigate();

  const { screenWidth } = useContext(EstilosContext);

  const [color, setColor] = useState("");
  const [mostrarPrimerPaso, setMostrarPrimerPaso] = useState(false);

  useEffect(() => {
    const primaryColor = traer_color("blue", 500);
    const secondaryColor = traer_color("blue", 700);

    setColor({ primary: primaryColor, secondary: secondaryColor });

    setTimeout(() => {
      setMostrarPrimerPaso(true);
    }, 1000);
  }, []);

  return mostrarPrimerPaso ? (
    <Box
      //   style={{ borderColor: theme.palette.primary.main }}
      style={{ borderColor: color.primary }}
      border={2}
      padding={screenWidth === "xs" || screenWidth === "sm" ? 1 : 6}
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // sx={{ border: "1px solid #FF0000" }}
      >
        <span style={{ marginBottom: "0px", color: color.primary }}>
          <strong>{texto}:</strong> &nbsp;&nbsp;&nbsp;
        </span>
        <IconButton
          //   color={theme.palette.primary.main}
          style={{ color: color.secondary }}
          aria-label="agregar obra"
          onClick={() => navigate(link)}
        >
          <AddCircleIcon
            //   style={{ color: theme.palette.secondary.main }}
            sx={{ fontSize: 40 }}
          />
        </IconButton>
      </Grid>
    </Box>
  ) : (
    ""
  );
};

export default PrimerPaso;
