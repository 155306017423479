import { Chip, Grid, MenuItem, Select, Slider } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { array_colores, traer_color } from "../../colores/Colores";
import EstilosContext from "../../contextos/EstilosContext";

const SeleccionDeColor = ({ setColor, color, setColorNumero, colorNumero }) => {
  const { screenWidth } = useContext(EstilosContext);

  let colors = array_colores();

  const [colores, setColores] = useState(colors);
  //   const [colorSeleccionado, setColorSeleccionado] = useState(colors[14]);
  const [sliderValue, setSliderValue] = useState(500);

  useEffect(() => {
    setColor(colors[14]);
    setColorNumero(500);
  }, []);

  const handleChange = (event) => {
    // console.log("event: ", event.target.value);
    // setColorSeleccionado(event.target.value);
    setColor(event.target.value);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const handleSliderChange = (event, newValue) => {
    // setSliderValue(newValue);
    setColorNumero(newValue);

    // console.log("colorSeleccionado 1: ", colorSeleccionado);
    // console.log("newValue: ", newValue);
    // console.log("colors[14] = ", colors[14]);
    // let col = traer_color(colorSeleccionado, newValue);

    // setColorSeleccionado(col);
  };

  //   useEffect(() => {
  //     console.log("colorSeleccionado 2: ", colorSeleccionado);
  //   }, [colorSeleccionado]);

  return (
    <>
      <Select value={color} onChange={handleChange}>
        {colores.map((col, index) => (
          <MenuItem key={index} value={col}>
            <Chip
              // label={color}
              sx={{ bgcolor: traer_color(col, colorNumero), width: "120px" }}
              variant={"outlined"}
            />
            {/* &nbsp;&nbsp;
          {color} */}
          </MenuItem>
        ))}
      </Select>
      &nbsp;
      <Slider
        aria-label="Color"
        defaultValue={500}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={100}
        marks
        min={100}
        max={900}
        sx={{ width: "50%" }}
        onChange={handleSliderChange}
      />
    </>
  );
};

export default SeleccionDeColor;
