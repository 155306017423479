import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HySContext from "../../contextos/HySContext";
import ObraContext from "../../contextos/ObraContext";
import Titulo from "../../components/Titulo";
import { desencriptar, formatearFecha } from "../../funciones/Funciones";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ModalDocumento from "./ModalDocumento";
import ServidorContext from "../../contextos/ServidorContext";
import MensajesContext from "../../contextos/MensajesContext";

const PageSociedad = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);
  const [documento, setDocumento] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [check, setCheck] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [items, setItems] = useState([]);

  // const [startDate, setStartDate] = useState(new Date());

  const { servidor } = useContext(ServidorContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const {
    documentos,
    setDocumentos,
    asignaciones,
    setAsignaciones,
    modificar,
    setModificar,
  } = useContext(HySContext);

  const { obra, setObra } = useContext(ObraContext);

  useEffect(() => {
    props.setTitulo("Sociedades y subcontratos");
    props.setPagina("");

    // console.log(obra.IDObra);
    // console.log(modificar.IDSubcontrato);

    if (documentos.IDDocumentacion === undefined) {
      // console.log("PASA POR IDDocumentacion");
      let objdocs = localStorage.getItem("objdocs");
      setDocumentos(desencriptar(objdocs));
    }

    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = localStorage.getItem("objobra");
      setObra(desencriptar(objobra));
    }

    if (modificar.IDSubcontrato === undefined) {
      // console.log("PASA POR IDSubcontrato");
      let objsoc = localStorage.getItem("objsoc");
      setModificar(desencriptar(objsoc));
    }
  }, []);

  useEffect(() => {
    listar_asignaciones();
  }, [documentos, obra, modificar]);

  useEffect(() => {
    listar_asignaciones();
  }, [refresh]);

  const listar_asignaciones = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);

    const API_ENDPOINT = servidor + `api_hys.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_ASIGNACIONES",
        idObra: obra.IDObra,
        idSubcontrato: modificar.IDSubcontrato,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.asignaciones) {
      // console.log(respuesta.result.asignaciones);
      setItems(respuesta.result.asignaciones);

      // setSeleccionados(respuesta.result.asignaciones);

      // alert("Lista de precios actualizada.");
    } else {
      setItems([]);
      console.log("NO TRAE NADA EN: listar_asignaciones()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleChange = async (event, item) => {
    // console.log(item);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDSubcontrato: " + modificar.IDSubcontrato);
    // console.log("IDDocumentacion: " + item.IDDocumentacion);
    // console.log("checked: " + event.target.checked);

    setDocumento(item);

    setCheck(event.target.checked);

    if (event.target.checked) {
      setOpenModal(true);
    } else {
      const API_ENDPOINT = servidor + `api_hys.php`;

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "BAJA_DOCUMENTO_EN_OBRA",
          idObra: obra.IDObra,
          idSubcontrato: modificar.IDSubcontrato,
          idDocumentacion: item.IDDocumentacion,
          fechaImpreso: "",
          fechaVencimiento: "",
          diasAntes: 1,
        }),
      });
      const respuesta = await datos.json();

      // console.log("PASA POR A");
      // console.log(respuesta.result.respuesta);
      // console.log("PASA POR B");

      if (respuesta.result.respuesta) {
        // console.log(respuesta.result.respuesta);

        eliminar_notificaciones_del_documento();

        listar_asignaciones();
      } else {
        console.log("NO TRAE NADA EN: handleChange()");
      }
    }
  };

  const eliminar_notificaciones_del_documento = async () => {
    const API_ENDPOINT = servidor + `api_notificaciones.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "BAJA_NOTIFICACIONES_PROGRAMADAS_DEL_DOCUMENTO",
        idObra: obra.IDObra,
        idDocumentacion: documento.IDDocumentacion,
      }),
    });
    const respuesta = await datos.json();

    // console.log("PASA POR 1");
    // console.log(respuesta.result.respuesta);
    // console.log("PASA POR 2");

    if (respuesta.result.resultado === "OK") {
      setMensaje({
        text: "Notificaciones eliminadas con éxito.",
        tipo: "success",
      });

      setMostrarMensaje(true);
    } else {
      setMensaje({
        text: "Error al eliminar las notificaciones.",
        tipo: "error",
      });

      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN: eliminar_notificaciones_del_documento()");
      // setState([]);
    }
  };

  // console.log(documentos);
  // console.log(asignaciones);

  return (
    <div>
      {/* <Titulo texto={modificar.RazonSocial.toUpperCase()} /> */}
      <Titulo
        texto={modificar.RazonSocial}
        botones={{ seccion: "", alta: false, baja: false, modif: false }}
      />
      <div style={{ marginBottom: "30px", fontSize: "20px" }}>
        Seleccione la documentación presente en obra:
      </div>

      {items.map((docs) => (
        <div key={docs.IDDocumentacion}>
          <Checkbox
            id={docs.IDDocumentacion}
            key={docs.IDDocumentacion}
            icon={
              <HighlightOffOutlinedIcon
                sx={{ fontSize: 40, color: "#FF0000" }}
              />
            }
            checkedIcon={
              <CheckCircleIcon sx={{ fontSize: 40 /*color: "#21AF19"*/ }} />
            }
            checked={items.some(
              (item) =>
                item.IDDocumentacion == docs.IDDocumentacion &&
                item.EstaEnObra == 1
            )}
            onChange={(event) => handleChange(event, docs)}
          />
          {docs.FechaVencimiento !== "0000-00-00"
            ? docs.Documento.toUpperCase() +
              " (" +
              formatearFecha(docs.FechaVencimiento, "dd/MM/yyyy") +
              ")"
            : docs.Documento.toUpperCase()}
          {/* <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          /> */}
        </div>
      ))}

      {openModal ? (
        <ModalDocumento
          openModal={openModal}
          setOpenModal={setOpenModal}
          obra={obra}
          subcontrato={modificar}
          documento={documento}
          refresh={refresh}
          setRefresh={setRefresh}
          check={check}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PageSociedad;
