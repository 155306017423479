import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
} from "@mui/material";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useEffect } from "react";
import { blue } from "@mui/material/colors";
import EstilosContext from "../../contextos/EstilosContext";

const style = {};

const styles = {
  contenedor: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    // border: "2px solid",
    color: blue[800],
    boxShadow: 24,
    p: 4,
  },
  exito: {
    color: "#13b90b",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
  error: {
    color: "#FF0000",
    marginBottom: 10,
    alignItems: "flex-start",
    width: "70%",
    // borderWidth: 1,
    // borderColor: '#FFFFFF',
  },
};

const ModalInfo = (props) => {
  const { estilos } = useContext(EstilosContext);

  const handleClose = () => {
    props.setMostrarModal({ mostrar: false, titulo: "", mensaje: "" });
    // props.setInfo("");
  };
  return (
    <Modal
      open={props.mostrarModal.mostrar}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

      <Box
        component="form"
        sx={styles.contenedor}
        style={estilos.modalWidth}
        noValidate
        autoComplete="off"
      >
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            {props.mostrarModal.titulo}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>{props.mostrarModal.mensaje}</Typography>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalInfo;
