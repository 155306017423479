import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const CuadroImportarTareas = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          <TableCell align="center">
            <Typography variant="h6">No hay tareas importadas.</Typography>
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CuadroImportarTareas;
