import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ObraContext from "../../contextos/ObraContext";

import Select from "react-select";
import ServidorContext from "../../contextos/ServidorContext";

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalAsignTarea = (props) => {
  const handleClose = () => {
    props.setOpenModal(false);
  };

  const defaultValueEnSelect = {
    label:
      "(" + props.tarea.CodTarea.replace("\n", "") + ") " + props.tarea.Tarea,
    value: props.tarea.CodTarea.replace("\n", ""),
  };

  const [codTarea, setCodTarea] = useState(defaultValueEnSelect);
  const [lista, setLista] = useState([]);
  const [cantidad, setCantidad] = useState("");

  const { servidor } = useContext(ServidorContext);

  const { obra, setObra } = useContext(ObraContext);

  const handleChange = (event) => {
    setCodTarea(event.target.value);
  };

  useEffect(() => {
    listar_tareas_sin_cuantificacion();
  }, []);

  const listar_tareas_sin_cuantificacion = async () => {
    // console.log("PASA POR 1");
    // console.log(lista);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS_SIN_CUANTIFICACION",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      // console.log(respuesta.result.listaTareas);
      setLista(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN listar_tareas_sin_cuantificacion()");
      // setState([]);
    }
  };

  const validCantidad = (value) => {
    setCantidad(value.target.value);
  };

  // const validCosto = (value) => {
  //   setCosto(value.target.value);
  // };

  const handleGuardar = async () => {
    props.setRefresh(!props.refresh);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    let formAlta = {
      operacion: "ALTA_CUANTIFICACION",
      idObra: obra.IDObra,
      codigoTarea: codTarea.value,
      cantidad: cantidad,
    };

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(formAlta),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);
    // alert(respuesta.result.guardado);
    if (respuesta.result.resultado === "ALTA_OK") {
      //   console.log(respuesta.result.resultado);
      // setLista(respuesta.result.listaRecursos);

      setCodTarea("");
      setCantidad("");
      props.setEstado("ALTA_OK");

      // setObra(
      //   "Actualizo con un texto para que se actualice la variable del contexto y se vuelva a cargar con las obras"
      // );
      // setCosto("");
      handleClose();

      // alert("Lista de precios actualizada.");
    } else {
      console.log("ERROR AL GUARDAR.");
      // setState([]);
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" sx={style} noValidate autoComplete="off">
        <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
          <Typography variant="h5" component="h5">
            ASIGNAR TAREA
          </Typography>

          <div>&nbsp;</div>

          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // labelId="demo-simple-select-standard-label"
            // id="demo-simple-select-standard"
            value={props.codTarea}
            label="Tarea"
            defaultValue={props.codTarea}
            onChange={handleChange}
          >
            {lista.map((rowTarea) => (
              <MenuItem value={rowTarea.CodTarea} key={rowTarea.IDTarea}>
                {"(" + rowTarea.CodTarea + ") " + rowTarea.Tarea}
              </MenuItem>
            ))}
          </Select> */}
          <div>&nbsp;</div>
          <Select
            // defaultValue={codTarea}
            onChange={handleChange}
            options={lista}
            value={defaultValueEnSelect}
            // value={lista.filter((option) => option.label === "00-DEM-020")}
          />

          <div>&nbsp;</div>

          <TextField
            id="cantidad"
            label="Cantidad"
            variant="standard"
            onChange={validCantidad}
            defaultValue={props.tarea.Cantidad}
            // value={props.tarea.Cantidad}

            // onChange={validConsumo}
          />

          <div>&nbsp;</div>

          {/* <TextField
            id="costo"
            label="Costo"
            variant="standard"
            onChange={validCosto}
          />

          <div>&nbsp;</div> */}

          <Button
            variant="contained"
            endIcon={<CheckOutlinedIcon />}
            onClick={handleGuardar}
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalAsignTarea;
