import { Badge } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BoxBadge = ({ imagen, cantidad, titulo, link }) => {
  let navigate = useNavigate();

  const handleButton = () => {
    navigate("/" + link);
  };
  return (
    <Badge badgeContent={cantidad} color="primary" sx={{ margin: "15px" }}>
      <img src={imagen} style={{ width: "40px" }} />
    </Badge>
  );
};

export default BoxBadge;
