import React, { useContext } from "react";

import {
  Paper,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

// import Paper from "@mui/material/Paper";
// import { Box, Divider, Grid, IconButton } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
import PedidosItem from "./PedidosItem";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { traer_color } from "../../colores/Colores";

import { createTheme } from "@mui/material/styles";
import PageVerificarPedido from "./PageVerificarPedido";
import PedidoContext from "../../contextos/PedidoContext";
import { encriptar } from "../../funciones/Funciones";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     overflow: "hidden",
//     padding: theme.spacing(0, 3),
//   },
//   paper: {
//     maxWidth: 500,
//     margin: `${theme.spacing(1)} auto`,
//     padding: theme.spacing(2),
//   },
//   marginBottom10: {
//     marginBottom: "10px",
//   },
//   fecha: {
//     fontSize: 14,
//     // marginTop: 5,
//     // border: "1px solid #FF0000",
//   },
//   arrow: {
//     // flexGrow: 1,
//     border: "1px solid",
//     // flex: 1,
//     justifyContent: "flex-end",
//   },
// }));

const Pedido = (props) => {
  // const classes = useStyles();

  let navigate = useNavigate();

  // console.log("PROPS EN PEDIDO: ");
  // console.log(props);

  const { setCodigoPedido } = useContext(PedidoContext);

  let proveedorColor = traer_color(
    props.pedido.proveedorColor,
    props.pedido.proveedorColorNumero
  );

  // console.log(proveedorColor);

  const verificarPedido = () => {
    // setCodigoPedido("b21db20f8445cf35f29a4b1f33016f82");
    setCodigoPedido(props.pedido.codigoPedido);

    localStorage.setItem("objped", encriptar(props.pedido));
    // console.log(localStorage.getItem("objped"));
    navigate("/verificarPedido");
  };

  let theme = createTheme({
    typography: {
      h5: {
        fontWeight: 800,
      },
    },
  });

  return (
    <Paper
      style={{
        maxWidth: 500,
        margin: `${theme.spacing(1)} auto`,
        padding: theme.spacing(2),
      }}
      elevation={3}
      // style={{ border: "1px solid #FF0000" }}
    >
      {/* <Paper
      className={classes.paper}
      elevation={3}
      // style={{ border: "1px solid #FF0000" }}
    > */}
      <Grid
        container
        // wrap="nowrap"
        spacing={2}
        // direction="row"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        justifyContent="space-between"
      >
        {/* <Grid item>
          <Avatar>{props.pedido.idPedido}</Avatar>
        </Grid> */}
        <Grid item>
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip
                label={props.pedido.proveedor.toUpperCase()}
                // color="primary"
                sx={{
                  backgroundColor: proveedorColor,
                  // color: "#FFFFFF",
                }}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h6">{props.pedido.titulo}</Typography>

          <Typography style={{ fontSize: 14 }}>
            {props.pedido.fechaProgramada
              .split(" ")[0]
              .split("-")
              .reverse()
              .join("/")}
          </Typography>
          {/* <Typography className={classes.fecha}>
            {props.pedido.fechaProgramada
              .split(" ")[0]
              .split("-")
              .reverse()
              .join("/")}
          </Typography> */}
        </Grid>
        {/* <Grid item>
          <Typography className={classes.fecha}>
            {props.pedido.fechaProgramada
              .split(" ")[0]
              .split("-")
              .reverse()
              .join("/")}
          </Typography>
        </Grid> */}
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          // style={{ paddingTop: "10px" }}
        >
          {/* <Box display="flex" justifyContent="flex-end"> */}
          <IconButton size="large">
            <ArrowForwardIosOutlinedIcon onClick={() => verificarPedido()} />
          </IconButton>
          {/* </Box> */}
        </Grid>

        <Grid item xs={12}>
          <Divider
            style={{
              marginBottom: "10px",
            }}
          />
          {/* <Divider className={classes.marginBottom10} /> */}

          {props.pedido.lista.map((item) => {
            return <PedidosItem key={item.idMaterial} material={item} />;
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Pedido;
