import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { encriptar } from "../funciones/Funciones";
import OrdenesDeServicioContext from "../contextos/OrdenesDeServicioContext";
import { useContext } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FiltroConSelect = ({ data }) => {
  // const [selectedItems, setSelectedItems] = useState([]);

  const { filtro, setFiltro } = useContext(OrdenesDeServicioContext);

  // const handleChange = (event) => {
  //   const item = event.target.value;
  //   let newFiltro;

  //   if (filtro.some((i) => i.IDPalabraClave === item.IDPalabraClave)) {
  //     // Si el item ya está en filtro, lo eliminamos
  //     newFiltro = filtro.filter(
  //       (i) => i.IDPalabraClave !== item.IDPalabraClave
  //     );
  //   } else {
  //     // Si el item no está en filtro, lo agregamos
  //     newFiltro = [...filtro, item];
  //   }

  //   setFiltro(newFiltro);
  // };

  const handleChange = (event) => {
    // console.log("event: ", event.target.value);

    // const {
    //   target: { value },
    // } = event;
    // setFiltro(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );

    const value = event.target.value;
    let newFiltro;

    // console.log("value: ", value);

    if (value.length > 0) {
      if (Array.isArray(value)) {
        // Si value es un array, lo usamos directamente
        // newFiltro = value;

        value.forEach((item) => {
          if (filtro.some((i) => i.IDPalabraClave === item.IDPalabraClave)) {
            // Si el item ya está en filtro, lo eliminamos
            newFiltro = filtro.filter(
              (i) => i.IDPalabraClave !== item.IDPalabraClave
            );
          } else {
            // Si el item no está en filtro, lo agregamos
            newFiltro = [...filtro, item];
          }
        });
      } else {
        // Si value es un solo item, lo agregamos o eliminamos de filtro
        if (filtro.some((i) => i.IDPalabraClave === value.IDPalabraClave)) {
          // Si el item ya está en filtro, lo eliminamos
          newFiltro = filtro.filter(
            (i) => i.IDPalabraClave !== value.IDPalabraClave
          );
        } else {
          // Si el item no está en filtro, lo agregamos
          newFiltro = [...filtro, value];
        }
      }
    } else {
      newFiltro = [];
    }

    setFiltro(newFiltro);
    localStorage.setItem("objFil", encriptar(newFiltro));

    // localStorage.setItem(
    //   "objFil",
    //   encriptar(typeof value === "string" ? value.split(",") : value)
    // );
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-checkbox-label">Filtros</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        variant="standard"
        multiple
        value={filtro}
        onChange={handleChange}
        // onChange={(event) => setFiltro(event.target.value)}
        input={<OutlinedInput label="Filtros" />}
        renderValue={(selected) =>
          selected.length > 0
            ? selected.map((item) => item.PalabraClave).join(", ")
            : "VER TODOS"
        }
        MenuProps={MenuProps}
      >
        {data.map((item) => (
          <MenuItem key={item.IDPalabraClave} value={item}>
            {/* {console.log(
              "Palabra (" + item.PalabraClave + "): ",
              filtro.indexOf(item) + " | " + JSON.stringify(item)
            )} */}
            <Checkbox
              checked={filtro.some(
                (i) => i.IDPalabraClave === item.IDPalabraClave
              )}
            />

            {/* <Checkbox checked={filtro.indexOf(item) > -1} /> */}
            <ListItemText primary={item.PalabraClave} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FiltroConSelect;
