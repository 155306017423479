import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ObraContext from "../../contextos/ObraContext";
import {
  chequearPermisos,
  desencriptar,
  encriptar,
  enviarPosicionHaciaReactNative,
  formatearFecha,
  numberWithCommas,
} from "../../funciones/Funciones";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import UsuarioContext from "../../contextos/UsuarioContext";
// import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import { grey } from "@mui/material/colors";

import PageSelectObra from "../general/PageSelectObra";
import ListaInvitaciones from "../../components/ListaInvitaciones";
import BoxCupon from "../../components/BoxCupon";
import MensajesContext from "../../contextos/MensajesContext";
import MetodoDePago from "../general/MetodoDePago";
import EstilosContext from "../../contextos/EstilosContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import BoxContador from "../general/BoxContador";
import DashboardContext from "../../contextos/DashboardContext";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import BoxCalendario from "../general/BoxCalendario";
import BoxBadge from "../general/BoxBadge";
import ServidorContext from "../../contextos/ServidorContext";
import ConfigContext from "../../contextos/ConfigContext";

const PageDashboardGeneral = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const { config, setConfig } = useContext(ConfigContext);

  const { obra, setObra } = useContext(ObraContext);

  const { dashboard, setDashboard } = useContext(DashboardContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { isMobile, estilos, screenWidth } = useContext(EstilosContext);

  const [mostrar, setMostrar] = useState("FORMULARIO_LOGIN");

  const [ordenesDeServicio, setOrdenesDeServicio] = useState(0);
  const [volquetes, setVolquetes] = useState(0);
  const [pedidosPendientes, setPedidosPendientes] = useState(0);
  const [documentosPorVencer, setDocumentosPorVencer] = useState(0);

  // const [titulo, setTitulo] = useState("");
  // const [pagina, setPagina] = useState("");
  // const [invitaciones, setInvitaciones] = useState([]);

  useEffect(() => {
    // console.log(screenWidth);
  });

  useEffect(() => {
    props.setTitulo("Dashboard general");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    enviarPosicionHaciaReactNative(isMobile, "banner", {
      usuario: usuario,
      obra: obra,
      tipoDeBanner: "BANNER",
      idPublicidad: "ca-app-pub-9747826938175612/8150779805",
      idBanner: "ca-app-pub-9747826938175612/8150779805",
      sacarFoto: false,
    });
  }, []);

  useEffect(() => {
    let joinPrivilegios = "";

    let objobra = desencriptar(localStorage.getItem("objobra"));

    setObra(objobra);
    // setPrivilegios(objobra.Privilegios);

    // console.log("Priv: ", objobra.Privilegios);
    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);

      joinPrivilegios = objusu.Privilegios.concat("|" + objobra.Privilegios);
    } else {
      // console.log(usuario);

      joinPrivilegios = usuario.Privilegios.concat("|" + objobra.Privilegios);
    }

    setPrivilegios(joinPrivilegios);

    traer_configuracion_general_de_obra();
    traer_informacion_dashboard();
    traer_cantidad_de_volquetes();

    // console.log("privilegios: ", privilegios);
  }, []);

  const traer_configuracion_general_de_obra = async () => {
    const API_ENDPOINT = servidor + `api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_CONFIGURACION_DE_OBRA",
        idObra: obra.IDObra,
        // idObra: obra.IDObra,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado) {
      // console.log(respuesta.result.dashboard);

      setConfig(respuesta.result.config[0]);

      // console.log("config: ", respuesta.result.config[0]);

      localStorage.setItem("objconfig", encriptar(respuesta.result.config[0]));

      // setConfig({
      //   presupuestosBeneficioMO: respuesta.result.presupuestosBeneficioMO,
      //   presupuestosBeneficioMateriales:
      //     respuesta.result.presupuestosBeneficioMateriales,
      //   presupuestosCheckMostrarDetalle:
      //     respuesta.result.presupuestosCheckMostrarDetalle,
      // });
    } else {
      console.log("NO TRAE NADA EN traer_configuracion_general_de_obra()");
      // setState([]);
    }
  };

  const traer_informacion_dashboard = async () => {
    const API_ENDPOINT = servidor + `api_dashboard.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_INFORMACION_DASHBOARD",
        idUsuario: usuario.IDUsuario,
        // idObra: obra.IDObra,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.dashboard) {
      // console.log(respuesta.result.dashboard);

      localStorage.setItem(
        "objdashboard",
        encriptar(respuesta.result.dashboard[0])
      );

      // console.log("DASHBOARD: ", respuesta.result.dashboard[0]);

      setDashboard(respuesta.result.dashboard[0]);

      setOrdenesDeServicio(
        respuesta.result.dashboard[0].listaOrdenesDeServicio.filter(
          (doc) => doc.IDObra == obra.IDObra
        )
      );

      setPedidosPendientes(
        respuesta.result.dashboard[0].listaPedidos.filter(
          (ped) => ped.IDObra == obra.IDObra && ped.Estado == "PENDIENTE"
        ).length
      );

      setDocumentosPorVencer(
        respuesta.result.dashboard[0].listaDocumentacion.filter(
          (doc) => doc.IDObra == obra.IDObra
        )
      );
    } else {
      console.log("NO TRAE NADA EN traer_informacion_dashboard()");
      // setState([]);
    }
  };

  const traer_cantidad_de_volquetes = async () => {
    // console.log(texto);
    // console.log("PASA POR listar_obras()");

    const API_ENDPOINT = servidor + `api_volquetes.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_VOLQUETES_DE_LA_OBRA",
        idObra: obra.IDObra,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.volquetes) {
      // console.log(respuesta.result.invitaciones);

      setVolquetes({
        cantidad: respuesta.result.cantidad,
        volquetes: respuesta.result.volquetes,
      });
    } else {
      setVolquetes({ cantidad: 0, volquetes: [] });
      console.log("NO TRAE NADA EN traer_cantidad_de_volquetes()");
      // setState([]);
    }
  };

  // useEffect(() => {
  //   if (usuario.IDUsuario === undefined) {
  //     // console.log("PASA POR IDObra");
  //     let objusu = desencriptar(localStorage.getItem("objusu"));
  //     setUsuario(objusu);
  //     // console.log(objusu);
  //   } else {
  //     // console.log(usuario);
  //   }

  //   listar_invitaciones();
  // }, []);

  // const listar_invitaciones = async () => {
  //   // console.log(texto);
  //   // console.log("PASA POR listar_obras()");

  //   const API_ENDPOINT = servidor + `api_usuarios.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "LISTAR_INVITACIONES_DE_USUARIO",
  //       email: usuario.Email,
  //       // operacion: "LISTAR_PRESUPUESTO",
  //       //  idObra: obra.IDObra,
  //       // lista:
  //       //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.invitaciones) {
  //     // console.log(respuesta.result.invitaciones);

  //     setInvitaciones(respuesta.result.invitaciones);
  //   } else {
  //     setInvitaciones([]);
  //     console.log("NO TRAE NADA EN listar_invitaciones()");
  //     // setState([]);
  //   }
  // };

  // const handleAceptar = async (invitacion) => {
  //   const API_ENDPOINT = servidor + `api_usuarios.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "ACEPTAR_INVITACION",
  //       idObra: invitacion.IDObra,
  //       idUsuario: usuario.IDUsuario,
  //       idInvitado: invitacion.IDInvitado,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.invitaciones) {
  //     // console.log(respuesta.result.invitaciones);

  //     setInvitaciones(respuesta.result.invitaciones);
  //   } else {
  //     setInvitaciones([]);
  //     console.log("NO TRAE NADA EN handleAceptar()");
  //     // setState([]);
  //   }
  // };

  // const handleCancelar = async (invitacion) => {
  //   // console.log(invitacion);

  //   const API_ENDPOINT = servidor + `api_usuarios.php`;

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "CANCELAR_INVITACION",
  //       idInvitado: invitacion.IDInvitado,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.invitaciones) {
  //     // console.log(respuesta.result.invitaciones);

  //     setInvitaciones(respuesta.result.invitaciones);
  //   } else {
  //     setInvitaciones([]);
  //     console.log("NO TRAE NADA EN handleCancelar()");
  //     // setState([]);
  //   }
  // };

  const fechaHoy = formatearFecha(new Date());

  return (
    <>
      <ListaInvitaciones />

      <br />
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Box
        component="form"
        sx={{
          height: 40,
          width: "100%",
          // border: 1,
          marginTop: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // border={1}
            columns={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {chequearPermisos(privilegios, "CALENDARIO") === true ? (
              <>
                <BoxCalendario />
                <br />
                <br />
              </>
            ) : (
              ""
            )}

            {chequearPermisos(
              privilegios,
              "ORDENES_DE_SERVICIO_ROL_ADMINISTRADOR"
            ) === true ? (
              <BoxContador
                imagen="tareas.png"
                cantidad={ordenesDeServicio.length}
                titulo="TAREAS"
                link="dashboardOrdenesDeServicio"
              />
            ) : (
              ""
            )}

            {chequearPermisos(privilegios, "VOLQUETES") === true ? (
              <BoxContador
                imagen="volquete.png"
                cantidad={volquetes.cantidad}
                titulo="Volquetes"
                link="dashboardVolquetes"
              />
            ) : (
              ""
            )}

            {chequearPermisos(privilegios, "PEDIDOS") === true ? (
              <BoxContador
                imagen="pedidos.png"
                cantidad={pedidosPendientes}
                titulo="Pedidos"
                link="dashboardPedidos"
              />
            ) : (
              ""
            )}

            {chequearPermisos(privilegios, "DOCUMENTACION") === true ? (
              <BoxContador
                imagen="documentacion.png"
                cantidad={documentosPorVencer.length}
                titulo="Documentacion"
                link="documentacion"
              />
            ) : (
              ""
            )}

            <MetodoDePago
              origen="DASHBOARD"
              metodo="CUPON"
              setMostrar={setMostrar}
              setMostrarMensaje={setMostrarMensaje}
              setMensaje={setMensaje}
            />
          </Grid>
        </Box>
        {/* <br />
            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => chequearCuponIngresado()}
            >
              Confirmar
            </Button> */}
      </Box>
      {/* <br />
      <PageSelectObra
        setTitulo={setTitulo}
        setPagina={setPagina}
        invitaciones={invitaciones}
      /> */}
    </>
  );
};

export default PageDashboardGeneral;
