import React from "react";

const BuscarArchivo = ({ props }) => {
  return (
    <input
      type="file"
      accept="image/*"
      onChange={(event) => {
        const file = event.target.files[0];
        // Aquí puedes manejar el archivo seleccionado por el usuario
      }}
      onClick={() => alert("CLICK")}
    />
  );
};

export default BuscarArchivo;
