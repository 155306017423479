import React, { useState } from "react";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Checkbox, Grid } from "@mui/material";
import ConstructionOutlined from "@mui/icons-material/ConstructionOutlined";
import ItemPedido from "./ItemPedido";
import { useContext } from "react";
import { useEffect } from "react";
import PedidoContext from "../../contextos/PedidoContext";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { desencriptar } from "../../funciones/Funciones";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlertValidForms from "../../components/AlertValidForms";
import MensajesContext from "../../contextos/MensajesContext";
import ServidorContext from "../../contextos/ServidorContext";

const filter = createFilterOptions();

const MaterialItem = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { pedido, setPedido } = useContext(PedidoContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [count, setCount] = React.useState(1);
  const [invisible, setInvisible] = React.useState(false);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  // const [pedido, setPedido] = useState([]);
  const [material, setMaterial] = useState("");
  const [cantidad, setCantidad] = useState(0);

  const [materialValues, setMaterialValues] = useState([]);
  const [cantidadValue, setCantidadValue] = useState("");

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const [listadoObras, setListadoObras] = useState([]);
  const [obrasSeleccionadas, setObrasSeleccionadas] = useState("");

  const initValid = { materialValid: false, cantidadValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const handleClose = () => {
    setDialogValue({
      Material: "",
      Unidad: "",
      IDMaterial: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    Material: "",
    Unidad: "",
    IDMaterial: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    agregar_material_a_db();

    setValue({
      Material: dialogValue.Material,
      Unidad: dialogValue.Unidad,
      IDMaterial: parseInt(dialogValue.IDMaterial, 10),
    });
    handleClose();
  };

  useEffect(() => {
    // console.log("pedido: ");
    // console.log(pedido);

    traer_obras_del_usuario();
  }, []);

  useEffect(() => {
    // alert(JSON.stringify(material));

    if (material !== null) {
      if (material.Material === "") {
        setValidForm({ ...validForm, materialValid: false });
      } else {
        setValidForm({ ...validForm, materialValid: true });
      }

      if (cantidad > 0) {
        setValidForm({ ...validForm, cantidadValid: true });
      } else {
        setValidForm({ ...validForm, cantidadValid: false });
      }
    }
  }, [material]);

  const traer_obras_del_usuario = async () => {
    // console.log(texto);
    // console.log("PASA POR listar_obras()");

    let objusu = desencriptar(localStorage.getItem("objusu"));

    const API_ENDPOINT = servidor + `api_obras.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_OBRAS",
        idUsuario: objusu.IDUsuario,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.obras) {
      // console.log(respuesta.result.obras);

      setListadoObras(respuesta.result.obras);
    } else {
      setListadoObras([]);
      console.log("NO TRAE NADA EN listar_obras()");
      // setState([]);
    }
  };

  const agregar_material_a_db = async () => {
    const API_ENDPOINT = servidor + `api_materiales.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_MATERIAL",
        arrObras: obrasSeleccionadas,
        material: dialogValue.Material,
        unidad: dialogValue.Unidad,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.idMaterial) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");
      setMaterial({
        IDMaterial: respuesta.result.idMaterial,
        Material: dialogValue.Material,
        Unidad: dialogValue.Unidad,
      });
      props.setActualizarMateriales(true);
    } else {
      console.log("NO TRAE NADA EN agregar_material_a_db()");
      // setState([]);
    }
  };

  function actualizarPedido(arr, newObj) {
    return newObj.cantidad == 0
      ? [...arr.filter((obj) => obj.idMaterial !== newObj.idMaterial)]
      : [
          ...arr.filter((obj) => obj.idMaterial !== newObj.idMaterial),
          { ...newObj },
        ];
  }

  const addMaterial = () => {
    // let arrPedido = [...pedido.detalle];

    // console.log("addMaterial: ");
    // console.log("pedido: ");
    // console.log(pedido);

    if (validForm.materialValid && validForm.cantidadValid) {
      let arr = [...pedido.detalle];

      let newArr = actualizarPedido(arr, {
        idMaterial: material.IDMaterial,
        material: material.Material,
        unidad: material.Unidad,
        cantidad: cantidad,
      });

      setPedido({
        ...pedido,

        detalle: [
          // ...pedido.detalle,
          ...newArr,
          // {
          //   idMaterial: material.IDMaterial,
          //   material: material.Material,
          //   cantidad: cantidad,
          // },
        ],
      });

      if (cantidad == 0) {
        setMensaje({
          text: "El material fue eliminado del pedido",
          tipo: "error",
        });
        setMostrarMensaje(true);
      } else {
        setMensaje({
          text: "El material fue agregado al pedido",
          tipo: "success",
        });
        setMostrarMensaje(true);
      }

      setMaterial("");
      setCantidad("");

      setValue(null);
      setMaterialValues([]);
      setCantidadValue("");
    } else {
      //   alert(valid.materialValid);
      if (!validForm.materialValid) {
        setMensaje({
          text: "Seleccione o agregue un nuevo material",
          tipo: "error",
        });
        setMostrarMensaje(true);
      } else {
        if (!validForm.cantidadValid) {
          setMensaje({
            text: "La cantidad debe ser mayor o igual a cero",
            tipo: "error",
          });
          setMostrarMensaje(true);
        }
      }
    }
  };

  // const changeMaterial = (mat) => {
  //   console.log("material: ");
  //   // console.log(mat);
  //   // alert(JSON.stringify(mat));
  //   setMaterial(mat);
  //   // console.log(mat.Material);
  //   setMaterialValues(mat.IDMaterial);
  // };

  const changeCantidad = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    setCantidad(text);
    setCantidadValue(text);

    if (text > 0) {
      setValidForm({ ...validForm, cantidadValid: true });
    } else if (text == 0) {
      setValidForm({ ...validForm, cantidadValid: true });
    } else {
      setValidForm({ ...validForm, cantidadValid: false });
    }
  };

  const handleChange = (_, obr) => {
    // console.log(obr);

    setObrasSeleccionadas([...obrasSeleccionadas, obr.IDObra]);
  };

  return (
    <>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid container style={{ display: "flex", justifyContent: "left" }}>
        <Grid item xs={9} sm={9} md={9} lg={10}>
          <Stack spacing={1} sx={{ width: "95%" }}>
            {/* <Autocomplete
              // {...flatProps}
              id="flat-demo"
              options={props.materiales}
              getOptionLabel={(option) => (option ? option.Material : "")}
              value={materialValues}
              // getOptionSelected={(option) =>
              //   option.IDMaterial === materialValues
              // }
              //   onChange={changeMaterial}

              onChange={(event, value) => changeMaterial(value)}
              // onChange={(event, value) => {
              //   alert(JSON.stringify(value));
              // }}
              renderInput={(params) => (
                <TextField {...params} label="Material" variant="standard" />
              )}
            /> */}

            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);
                    setDialogValue({
                      Material: newValue,
                      IDMaterial: "",
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  toggleOpen(true);
                  setDialogValue({
                    Material: newValue.inputValue,
                    IDMaterial: "",
                  });
                } else {
                  setValue(newValue);
                }

                setMaterial(newValue);
                // alert(JSON.stringify(newValue));
                setValidForm({ ...validForm, materialValid: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log(params.inputValue);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Material: `Agregar "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              id="free-solo-dialog-demo"
              options={props.materiales}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.Material;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => (
                <li {...props} key={option.IDMaterial}>
                  {option.Material}{" "}
                  {option.Unidad !== null && option.Unidad !== undefined
                    ? `(en ${option.Unidad})`
                    : ""}
                </li>
              )}
              // sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Material" variant="standard" />
              )}
            />

            <Dialog open={open} onClose={handleClose}>
              <form /*onSubmit={handleSubmit}*/>
                <DialogTitle>Agregar material</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Agregá un material a la lista sin olvidar colocar la unidad!
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue.Material}
                    onChange={(event) => {
                      setDialogValue({
                        ...dialogValue,
                        Material: event.target.value,
                      });

                      setMaterial({
                        ...material,
                        IDMaterial: "",
                        Material: event.target.value,
                      });
                    }}
                    label="Material"
                    type="text"
                    variant="standard"
                  />
                  &nbsp;
                  <TextField
                    autoFocus
                    margin="dense"
                    id="unit"
                    // value={dialogValue.Material}
                    onChange={(event) => {
                      setDialogValue({
                        ...dialogValue,
                        Unidad: event.target.value,
                      });

                      setMaterial({
                        ...material,
                        Unidad: event.target.value,
                      });
                    }}
                    label="Unidad"
                    type="text"
                    variant="standard"
                  />
                  <br />
                  <br />
                  <br />
                  {listadoObras.map((obra) => (
                    <div key={obra.IDObra}>
                      <Checkbox
                        id={obra.IDObra}
                        key={obra.IDObra}
                        icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                        // checked={seleccionados.some(
                        //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                        // )}
                        onChange={(event) => handleChange(event, obra)}
                      />
                      {obra.Obra.toUpperCase()}
                    </div>
                  ))}
                  {/* <Autocomplete
                    // {...flatProps}
                    id="flat-demo"
                    options={props.proveedores}
                    getOptionLabel={(option) =>
                      option ? option.Proveedor : ""
                    }
                    // value={materialValues}
                    // getOptionSelected={(option) =>
                    //   option.IDMaterial === materialValues
                    // }
                    //   onChange={changeMaterial}

                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        IDProveedor: event.target.value,
                      })
                    }
                    // onChange={(event, value) => {
                    //   alert(JSON.stringify(value));
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Proveedor"
                        variant="standard"
                      />
                    )}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button onClick={handleSubmit} /*type="submit"*/>
                    Agregar
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </Stack>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <TextField
            label="Cantidad"
            variant="standard"
            value={cantidadValue}
            onChange={changeCantidad}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} style={{ paddingTop: "15px" }}>
          <IconButton color="primary" aria-label="add to shopping cart">
            <AddShoppingCartIcon onClick={() => addMaterial()} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={9} sm={9} md={9} lg={10}>
          <Stack spacing={1} sx={{ width: "95%" }}>
            {pedido.detalle.map((item, index) => (
              <>
                {/* <div>
                  MAT #{index}: {JSON.stringify(item)}
                </div>
                <div>PROV: {props.proveedorElegido.Proveedor}</div> */}

                <ItemPedido
                  key={index}
                  material={item}
                  proveedorElegido={props.proveedorElegido}
                />
              </>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default MaterialItem;
