import { Button, Chip, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";
import ServidorContext from "../../contextos/ServidorContext";

const Ubicacion = ({ setUbicacion, ubicacion }) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [palabraClave, setPalabraClave] = useState("");
  //   const [paso1, setPaso1] = useState("");

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);

  //   useEffect(() => {
  // setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  useEffect(() => {
    traer_ubicaciones();
    setChipSelected(ubicacion);
  }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  useEffect(() => {
    // return () => {
    let paso1 = desencriptar(localStorage.getItem("objordserv"));
    let paso2 = { ...paso1, ubicacion: chipSelected };

    localStorage.setItem("objordserv", encriptar(paso2));

    setUbicacion(chipSelected);

    // console.log("chipSelected: ", chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  const handleUbicacion = (e) => {
    setPalabraClave(e.target.value);
  };

  const handleAddChip = () => {
    // console.log("chipData: ", chipData);

    if (palabraClave !== "") {
      if (
        chipData.filter((chip) => chip.PalabraClave === palabraClave).length > 0
      ) {
        alert("Ya existe la ubicación");
      } else {
        guardar_ubicacion();
      }
    } else {
      alert("La ubicación no puede ser vacía.");
    }
  };

  const guardar_ubicacion = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_PALABRA_CLAVE",
        idObra: obra.IDObra,
        palabraClave: palabraClave,
        esUbicacion: "SI",

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado == "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);
      setChipData([...chipData, respuesta.result.palabrasClaves[0]]);

      setPalabraClave("");
      setMensaje({ text: "Ubicación agregada con éxito!", tipo: "success" });

      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_ubicacion()");
      // setState([]);
    }
  };

  const traer_ubicaciones = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_PALABRAS_CLAVES",
        idObra: obra.IDObra,
        esUbicacion: "SI",

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.palabrasClaves) {
      setChipData(respuesta.result.palabrasClaves);
    } else {
      console.log("NO TRAE NADA EN traer_ubicaciones()");
      // setState([]);
    }
  };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    setChipSelected([chip]);
  };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #FF0000",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
        >
          <TextField
            id="txtUbicacion"
            label="Ubicación (ej. Piso 1°A)"
            // multiline
            // rows={12}
            // fullWidth
            sx={
              screenWidth === "xs" || screenWidth === "sm"
                ? { width: "95%" }
                : { width: "50%" }
            }
            onChange={(e) => handleUbicacion(e)}
            value={palabraClave}
            // defaultValue="Default Value"
          />
          &nbsp;<Button onClick={handleAddChip}>Agregar</Button>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        {chipData.map((data, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-start",
              width:
                screenWidth === "xs" ||
                screenWidth === "sm" ||
                screenWidth === "md"
                  ? "100%"
                  : "40%",
              // justifyContent:
              //   screenWidth === "md" ||
              //   screenWidth === "lg" ||
              //   screenWidth === "xl"
              //     ? "left"
              //     : "center",
            }}
          >
            {/* <Stack spacing={1} alignItems="center" wrap="wrap">
              <Stack direction="row" spacing={1} wrap="wrap"> */}

            <Chip
              key={data.IDPalabraClave}
              label={data.PalabraClave}
              color="primary"
              variant={
                chipSelected.length > 0 &&
                chipSelected.filter(
                  (chip) => chip.IDPalabraClave === data.IDPalabraClave
                ).length > 0
                  ? ""
                  : "outlined"
              }
              onClick={() => handleClick(data)}
              //   onDelete={handleDelete}
            />
            {/* </Stack>
            </Stack> */}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Ubicacion;
