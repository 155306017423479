import React, { useContext, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { chequearPermisos, desencriptar } from "../funciones/Funciones";
import UsuarioContext from "../contextos/UsuarioContext";
import ObraContext from "../contextos/ObraContext";
import PrivilegioContext from "../contextos/PrivilegiosContext";
import AlertSinPermisos from "./AlertSinPermisos";
import EstilosContext from "../contextos/EstilosContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   linkStyle: {
//     color: "white",
//     textDecoration: "none",
//   },
// }));

const LinkStyle = styled("a")({
  color: "white",
  textDecoration: "none",
});

const Botonera = () => {
  // const classes = useStyles();

  let navigate = useNavigate();

  const [ancho, setAncho] = useState("xs");
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);
  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { isMobile, screenWidth } = useContext(EstilosContext);

  useEffect(() => {
    // console.log("screenWidth: ", screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    // console.log(obra);
    // console.log("IDObra: " + obra.IDObra);
    // console.log("Privilegios: " + obra.Privilegios);

    // if (obra.IDObra === undefined) {
    // console.log("PASA POR IDObra");
    let objobra = desencriptar(localStorage.getItem("objobra"));
    setObra(objobra);
    setPrivilegios(objobra.Privilegios);
    // alert(objobra.Privilegios);
    // console.log(objobra);
    // } else {
    // console.log(usuario);
    // }

    // console.log("OBR: " + privilegios);

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }
  }, []);

  // useEffect(() => {
  //   alert("actualiza privilegios");
  // }, [privilegios]);

  const handlePresupuestoDetalle = () => {
    if (chequearPermisos(privilegios, "PRESUPUESTOS_DETALLE") === true) {
      navigate("/presupuestoActual");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportListaPrecios = () => {
    if (
      chequearPermisos(
        privilegios,
        "PRESUPUESTOS_IMPORTAR_LISTA_DE_PRECIOS"
      ) === true
    ) {
      navigate("/importarListaPrecios");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportTareas = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_IMPORTAR_TAREAS") === true
    ) {
      navigate("/importarTareas");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleImportCuantificacion = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_IMPORTAR_CUANTIFICACION") ===
      true
    ) {
      navigate("/importarCuantificacion");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleListadoDeTareas = () => {
    if (
      chequearPermisos(privilegios, "PRESUPUESTOS_LISTADO_DE_TAREAS") === true
    ) {
      navigate("/listadoDeTareas");
    } else {
      setMostrarAlert(true);
    }
  };

  const handleListadoDePrecios = () => {
    if (
      chequearPermisos(
        privilegios,
        "PRESUPUESTOS_EXPORTAR_LISTA_DE_PRECIOS"
      ) === true
    ) {
      navigate("/listaPrecios");
    } else {
      setMostrarAlert(true);
    }
  };

  return (
    <>
      <AlertSinPermisos mostrarAlert={mostrarAlert} />

      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {/* <LinkStyle onClick={() => handlePresupuestoDetalle()}>
          Presupuesto
        </LinkStyle>

        <LinkStyle onClick={() => handleImportListaPrecios()}>
          Importar lista de precios
        </LinkStyle>

        <LinkStyle onClick={() => handleImportTareas()}>
          Importar tareas
        </LinkStyle>

        <LinkStyle onClick={() => handleImportCuantificacion()}>
          Importar cuantificación
        </LinkStyle>

        <LinkStyle onClick={() => handleListadoDeTareas()}>
          Listado de Tareas
        </LinkStyle>

        <LinkStyle onClick={() => handleListadoDePrecios()}>
          Lista de precios
        </LinkStyle> */}

        <Button
          sx={{
            color: "white",
            textDecoration: "none",
          }}
          onClick={() => handlePresupuestoDetalle()}
        >
          Presupuesto
        </Button>

        {screenWidth !== "xs" && screenWidth !== "sm" ? (
          <>
            <Button
              sx={{
                color: "white",
                textDecoration: "none",
              }}
              onClick={() => handleImportListaPrecios()}
            >
              Importar lista de precios
            </Button>

            <Button
              sx={{
                color: "white",
                textDecoration: "none",
              }}
              onClick={() => handleImportTareas()}
            >
              Importar tareas
            </Button>

            <Button
              sx={{
                color: "white",
                textDecoration: "none",
              }}
              onClick={() => handleImportCuantificacion()}
            >
              Importar cuantificación
            </Button>
          </>
        ) : (
          ""
        )}

        <Button
          sx={{
            color: "white",
            textDecoration: "none",
          }}
          onClick={() => handleListadoDeTareas()}
        >
          Listado de Tareas
        </Button>

        <Button
          sx={{
            color: "white",
            textDecoration: "none",
          }}
          onClick={() => handleListadoDePrecios()}
        >
          Lista de precios
        </Button>

        {/* 
        <Button
          className={classes.linkStyle}
          onClick={() => handlePresupuestoDetalle()}
        >
          Presupuesto
        </Button>

        <Button
          className={classes.linkStyle}
          onClick={() => handleImportListaPrecios()}
        >
          Importar lista de precios
        </Button>

        <Button
          className={classes.linkStyle}
          onClick={() => handleImportTareas()}
        >
          Importar tareas
        </Button>

        <Button
          className={classes.linkStyle}
          onClick={() => handleImportCuantificacion()}
        >
          Importar cuantificación
        </Button>

        <Button
          className={classes.linkStyle}
          onClick={() => handleListadoDeTareas()}
        >
          Listado de Tareas
        </Button>

        <Button
          className={classes.linkStyle}
          onClick={() => handleListadoDePrecios()}
        >
          Lista de precios
        </Button> */}
      </ButtonGroup>
    </>
  );
};

export default Botonera;
