import { Dialog, Paper } from "@mui/material";
import React from "react";
import BoxPresupuestos from "../configuracion/papers/BoxPresupuestos";
import { useEffect } from "react";

const ModalConfig = ({
  openModalConfig,
  setOpenModalConfig,
  refresh,
  setRefresh,
}) => {
  useEffect(() => {
    if (refresh) {
      handleClose();
    }
  }, [refresh]);

  const handleClose = () => {
    setOpenModalConfig(false);
  };

  return (
    <Dialog open={openModalConfig} onClose={handleClose}>
      <Paper elevation={3} sx={{ pt: "20px", pr: "20px" }}>
        <BoxPresupuestos
          pagina="PRESUPUESTOS"
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Paper>
    </Dialog>
  );
};

export default ModalConfig;
