import React from "react";
import { useState } from "react";
import { traer_color } from "../../../colores/Colores";
import { Navigate, useNavigate } from "react-router-dom";

const CartelPlanoActual = ({ screenWidth, nombrePlano }) => {
  let navigate = useNavigate();

  const color = traer_color("blue", 50);

  const [bgColor, setBgColor] = useState(color);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        // border: "1px solid #FF0000",
        backgroundColor: bgColor,
        fontSize:
          screenWidth === "xs" || screenWidth === "sm" ? "26px" : "60px",
      }}
    >
      {nombrePlano !== undefined
        ? nombrePlano.toUpperCase()
        : navigate("/dashboardControlDeAvance")}
    </div>
  );
};

export default CartelPlanoActual;
