import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import Botonera from "../../components/Botonera";
import ObraContext from "../../contextos/ObraContext";

const Encabezado = () => {
  const { obra, setObra } = useContext(ObraContext);

  return (
    <Grid container columns={{ xs: 12 }} border={0}>
      <Grid item xs={10} border={0}></Grid>
      {/* <Grid
        item
        xs={12}
        border={0}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography variant="h6">{obra.Obra}</Typography>
      </Grid> */}
      <Grid item xs={12} border={0}>
        <Botonera />
      </Grid>
    </Grid>
  );
};

export default Encabezado;
