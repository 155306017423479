import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

const ServidorContext = createContext();

const ServidorProvider = ({ children }) => {
  const server = {
    local: "http://localhost/",
    publico: "https://backend.bimtracker.com/",
  };

  const [servidor, setServidor] = useState(server.publico);

  useEffect(() => {
    localStorage.setItem("objser", encriptar(servidor));

    // return () => {
    //   localStorage.clear();
    // };
  }, [servidor]);

  const data = {
    servidor,
    setServidor,
  };

  return (
    <ServidorContext.Provider value={data}>{children}</ServidorContext.Provider>
  );
};

export { ServidorProvider };
export default ServidorContext;
