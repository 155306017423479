import { Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Titulo from "../../components/Titulo";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HySContext from "../../contextos/HySContext";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { desencriptar } from "../../funciones/Funciones";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import ServidorContext from "../../contextos/ServidorContext";

const PrivilegiosConfig = (props) => {
  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [secciones, setSecciones] = useState([]);

  const [permitidos, setPermitidos] = useState([]);

  const { servidor } = useContext(ServidorContext);

  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  const initConfig = {
    usuario: true,
    obras: false,
    pedidos: false,
    materiales: false,
    volquetes: false,
    presupuestos: false,
    documentacion: false,
  };

  const [config, setConfig] = useState(initConfig);

  const {
    documentos,
    setDocumentos,
    asignaciones,
    setAsignaciones,
    modificar,
    setModificar,
  } = useContext(HySContext);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  useEffect(() => {
    listar_secciones();
    listar_secciones_permitidas();
  }, [documentos, modificar]);

  useEffect(() => {
    setConfig({ ...config, obras: true });
  }, [privilegios]);

  useEffect(() => {
    if (obra.IDObra === undefined) {
      // console.log("PASA POR IDObra");
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setObra(objobra);
      //   console.log(objobra);
      setPrivilegios(objobra.Privilegios);
    } else {
      setPrivilegios(obra.Privilegios);
    }

    // console.log(privilegios);

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
    }
  }, []);

  const listar_secciones = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDUsuario: " + usuario.IDUsuario);

    const API_ENDPOINT = servidor + `api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TODOS_LOS_PRIVILEGIOS",
        // idObra: obra.IDObra,
        // idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.privilegios) {
      //   console.log(respuesta.result.privilegios);

      setSecciones(respuesta.result.privilegios);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_secciones()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const listar_secciones_permitidas = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    // console.log("IDObra: " + obra.IDObra);
    // console.log("IDUsuario: " + usuario.IDUsuario);

    const API_ENDPOINT = servidor + `api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_SECCIONES_PERMITIDAS",
        idObra: obra.IDObra,
        idUsuario: props.invitadoElegido.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.privilegios) {
      // console.log(respuesta.result.privilegios);
      // setAsignaciones(respuesta.result.asignaciones);

      let arrSeccionesPermitidas =
        respuesta.result.privilegios[0].Privilegios !== null
          ? respuesta.result.privilegios[0].Privilegios.split("|")
          : [];
      setPermitidos(arrSeccionesPermitidas);

      //   console.log("1: permitidos ******************");
      //   console.log(
      //     respuesta.result.privilegios[0].Privilegios.split("|").filter((fil) =>
      //       fil.includes("OBRAS")
      //     )
      //   );

      //   console.log("2: usuario.Privilegios ******************");
      //   console.log(usuario.Privilegios);

      //   console.log("3: obra.Privilegios ******************");
      //   console.log(obra.Privilegios);

      //   console.log("4: obra ******************");
      //   console.log(obra);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_secciones_permitidas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleChange = async (event, item) => {
    // console.log("checked: " + event.target.checked);
    // console.log(item);
    // console.log(item.CodPrivilegio);

    const API_ENDPOINT = servidor + `api_config.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: event.target.checked ? "ALTA_PRIVILEGIO" : "BAJA_PRIVILEGIO",
        idObra: obra.IDObra,
        idUsuario: props.invitadoElegido.IDUsuario,
        codPrivilegio: item.CodPrivilegio,
        privilegios: permitidos.join("|"),
      }),
    });
    const respuesta = await datos.json();

    // // console.log("PASA POR 1");
    // // console.log(respuesta.result.asignaciones);
    // // console.log("PASA POR 2");

    if (respuesta.result.privilegios) {
      setObra({
        ...obra,
        Privilegios: respuesta.result.privilegios[0].Privilegios,
      });

      let arrPrivilegios =
        respuesta.result.privilegios[0].Privilegios.split("|");
      setPermitidos(arrPrivilegios);
      //   console.log(obra);

      // alert("Lista de precios actualizada.");
    } else {
      setPermitidos([]);

      console.log("NO TRAE NADA EN: handeChange()");
      // setState([]);
    }
  };

  return (
    <div>
      {secciones.filter((filtro) =>
        filtro.CodPrivilegio.includes(props.seccion + "_")
      ).length > 0 ? (
        <div style={{ marginBottom: "30px", fontSize: "20px" }}>
          Seleccione los privilegios de acceso para la obra{" "}
          <strong>{obra.Obra}</strong>:
        </div>
      ) : (
        <div style={{ marginBottom: "30px", fontSize: "20px" }}>
          No hay secciones disponibles.
        </div>
      )}

      {secciones
        .filter((filtro) =>
          filtro.CodPrivilegio.includes(
            props.seccion + "_"
            // obra.IDUsuarioCreador === usuario.IDUsuario ? "OBRAS" : "OBRAS_"
          )
        )
        .map((seccion) => (
          <div key={seccion.IDPrivilegio}>
            <Checkbox
              id={seccion.IDPrivilegio}
              key={seccion.IDPrivilegio}
              icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
              checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
              checked={permitidos
                .filter((fil) => fil.includes(props.seccion))
                .some((sec) => sec === seccion.CodPrivilegio)}
              onChange={(event) => handleChange(event, seccion)}
            />
            {seccion.Privilegio.toUpperCase()}
          </div>
        ))}
    </div>
  );
};

export default PrivilegiosConfig;
