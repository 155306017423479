import { Grid } from "@mui/material";
import React from "react";
import Logo from "./Logo";
import VolverALogin from "./VolverALogin";

const EncabezadoItems = (props) => {
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={1}>
          <VolverALogin
            setMostrar={props.setMostrar}
            pageBack={props.pageBack}
          />
        </Grid>
        <Grid item xs={10}>
          <Logo />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <div style={{ fontWeight: "bold", fontSize: "18px" }}>{props.titulo}</div>
    </>
  );
};

export default EncabezadoItems;
