import React from "react";
import { createContext, useState } from "react";

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  // const initConfig = {
  //   presupuestosBeneficioMO: "",
  //   presupuestosBeneficioMateriales: "",
  //   presupuestosCheckMostrarDetalle: 0,
  // };

  const initConfig = {};
  const [config, setConfig] = useState(initConfig);

  const data = { config, setConfig };

  return (
    <ConfigContext.Provider value={data}>{children}</ConfigContext.Provider>
  );
};

export { ConfigProvider };
export default ConfigContext;
