import { Button, Chip, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar, encriptar } from "../../funciones/Funciones";
import Navegacion from "./Navegacion";
import PalabrasClaves from "./PalabrasClaves";

const PageAgregarPalabrasClaves = (props) => {
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [palabrasClaves, setPalabrasClaves] = useState([]);

  // const [palabraClave, setPalabraClave] = useState("");
  // //   const [paso1, setPaso1] = useState("");

  // const [chipData, setChipData] = useState([]);
  // const [chipSelected, setChipSelected] = useState([]);

  useEffect(() => {
    props.setTitulo("Agregar palabras claves");
    props.setPagina("");
  }, [props.setTitulo]);

  // useEffect(() => {
  //   traer_palabras_claves();
  // }, []);

  // useEffect(() => {
  //   console.log("chipData: ", chipData);
  // }, [chipData]);

  // useEffect(() => {
  //   // return () => {
  //   let paso2 = desencriptar(localStorage.getItem("objordserv"));
  //   let paso3 = { ...paso2, palabrasClaves: chipSelected };

  //   localStorage.setItem("objordserv", encriptar(paso3));

  //   // console.log("paso2: ", paso2);
  //   // };
  // }, [chipSelected]);

  // const handleUbicacion = (e) => {
  //   setPalabraClave(e.target.value);
  // };

  // const handleAddChip = () => {
  //   // console.log("chipData: ", chipData);

  //   if (palabraClave !== "") {
  //     if (
  //       chipData.filter((chip) => chip.PalabraClave === palabraClave).length > 0
  //     ) {
  //       alert("Ya existe la palabra clave");
  //     } else {
  //       guardar_palabra_clave();
  //     }
  //   } else {
  //     alert("La palabra clave no puede ser vacía.");
  //   }
  // };

  // const guardar_palabra_clave = async () => {
  //   const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

  //   // console.log(codigoPedido);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "GUARDAR_PALABRA_CLAVE",
  //       idObra: obra.IDObra,
  //       palabraClave: palabraClave,
  //       esUbicacion: "NO",

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("respuesta: ", respuesta);

  //   if (respuesta.result.resultado == "OK") {
  //     // console.log("result: ", respuesta.result.palabrasClaves[0]);
  //     setChipData([...chipData, respuesta.result.palabrasClaves[0]]);

  //     setPalabraClave("");
  //     setMensaje({
  //       text: "Palabra clave agregada con éxito!",
  //       tipo: "success",
  //     });

  //     setMostrarMensaje(true);
  //   } else {
  //     console.log("NO TRAE NADA EN guardar_palabra_clave()");
  //     // setState([]);
  //   }
  // };

  // const traer_palabras_claves = async () => {
  //   const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

  //   // console.log(codigoPedido);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "TRAER_PALABRAS_CLAVES",
  //       idObra: obra.IDObra,
  //       esUbicacion: "NO",

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.palabrasClaves) {
  //     setChipData(respuesta.result.palabrasClaves);
  //   } else {
  //     console.log("NO TRAE NADA EN traer_palabras_claves()");
  //     // setState([]);
  //   }
  // };

  // const handleClick = (idPalabraClave) => {
  //   let found = chipSelected.filter(
  //     (chip) => chip.IDPalabraClave === idPalabraClave
  //   );
  //   console.log("found: ", found);

  //   let newChip = "";
  //   if (found.length > 0) {
  //     newChip = chipSelected.filter(
  //       (chip) => chip.IDPalabraClave !== idPalabraClave
  //     );
  //     setChipSelected(newChip);
  //   } else {
  //     newChip = { IDPalabraClave: idPalabraClave };
  //     setChipSelected([...chipSelected, newChip]);
  //   }
  // };

  // useEffect(() => {
  //   console.log("chipSelected: ", chipSelected);
  // }, [chipSelected]);

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #CCCCCC",
        }}
      >
        <Grid item>
          <PalabrasClaves
            setPalabrasClaves={setPalabrasClaves}
            palabrasClaves={palabrasClaves}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Navegacion nroPaso={props.nroPaso} />
        </Grid>
      </Grid>
    </>
  );
};

export default PageAgregarPalabrasClaves;
