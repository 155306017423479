import React, { useContext, useEffect } from "react";
import * as markerjs2 from "markerjs2";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import ModalPlano from "./ModalPlano";
import MensajesContext from "../../../contextos/MensajesContext";
import ServidorContext from "../../../contextos/ServidorContext";

// import "./styles.css";

const Imagen = ({ screenWidth, item }) => {
  const [openModal, setOpenModal] = useState(false);
  const [accion, setAccion] = useState("");
  const [nuevaImagen, setNuevaImagen] = useState("");

  const { servidor } = useContext(ServidorContext);
  const { mensaje, setMensaje, setMostrarMensaje } =
    useContext(MensajesContext);

  let imgRef = React.createRef();

  let maState;

  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);

      // add all marker types
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

      // enable redo, notes, zoom, and clear buttons (hidden by default)
      markerArea.uiStyleSettings.redoButtonVisible = true;
      // markerArea.uiStyleSettings.notesButtonVisible = true;
      // markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.clearButtonVisible = true;

      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;

          maState = event.state;

          // console.log("PASA POR 2");

          //   const updatedItems = colaDeImagenes.map((item) => {
          //     if (item.src === picture) {
          //       return {
          //         ...item,
          //         src: imgRef.current.src,
          //         anotaciones: JSON.stringify(event.state),
          //       };
          //     }
          //     return item;
          //   });

          // console.log("img: ", imgRef.current.src);

          // console.log("event: ", event);

          // console.log("state: ", event.state);

          // console.log("objordserv: ", paso1);
          //   localStorage.setItem("objordserv", encriptar(paso1));
        }
      });
      // launch marker.js
      markerArea.styles.marginLeft = "200px";
      markerArea.show();

      // if previous state is present - restore it
      if (maState) {
        markerArea.restoreState(maState);
      }
    }
  };

  // console.log("screenWidth: ", screenWidth);

  useEffect(() => {
    if (!openModal) {
      setAccion("");
    }
  }, [openModal]);

  useEffect(() => {
    if (accion !== "") {
      setOpenModal(true);
    }
  }, [accion]);

  useEffect(() => {
    // console.log("nuevaImagen: ", nuevaImagen);

    if (nuevaImagen !== "") {
      cambiar_imagen_de_plano();
    }
  }, [nuevaImagen]);

  const cambiar_imagen_de_plano = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "CAMBIAR_IMAGEN_DE_PLANO",
    //   idObra: item.IDObra,
    //   idPlano: item.IDPlano,
    //   imagen: nuevaImagen,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "CAMBIAR_IMAGEN_DE_PLANO",
        idObra: item.IDObra,
        idPlano: item.IDPlano,
        imagen: nuevaImagen,

        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      setMensaje({
        text: "La imagen del plano ha sido cambiada con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      // navigate("/dashboardControlDeAvance");

      // console.log(respuesta.result.respuesta);
      // setAsignaciones(respuesta.result.asignaciones);
      // setSolicitudes(respuesta.result.solicitudes);
    } else {
      setMensaje({
        text: "Ocurrió un error al cambiar la imagen del plano!",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN cambiar_imagen_de_plano()");
    }
  };

  const handleCambiarImagen = () => {
    // console.log("Cambiar imagen");
    // console.log("item: ", item);
    setAccion("CAMBIAR_IMAGEN");

    // setOpenModal(true);
  };

  const handleEliminarPlano = () => {
    // console.log("Eliminar plano");
    // console.log("item: ", item);

    setAccion("ELIMINAR_PLANO");
    // setOpenModal(true);
  };

  // const handleAgregarAgrupamiento = () => {
  //   console.log("Agregar agrupamiento");
  // };

  // const handleEditarAgrupamiento = () => {
  //   console.log("Editar agrupamiento");
  // };

  return (
    <>
      <div
        id="div"
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "xs" || screenWidth === "sm" ? "center" : "left",
          // width: screenWidth === "xs" || screenWidth === "sm" ? "100%" : "60%",
          // width: "100vw",
          // height: "100vh",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <img
          id="plano"
          ref={imgRef}
          src={nuevaImagen !== "" ? nuevaImagen : item.Imagen}
          alt="sample"
          crossOrigin="anonymous"
          style={{
            maxWidth:
              screenWidth === "xs" || screenWidth === "sm" ? "99%" : "90%",
            // paddingTop: "20px",
            // marginTop: "20px",
          }}
          onClick={() => showMarkerArea()}
        />
      </div>

      <div
        id="div"
        style={{
          maxWidth:
            screenWidth === "xs" || screenWidth === "sm" ? "99%" : "90%",

          display: "flex",
          justifyContent: "right",
          // screenWidth === "xs" || screenWidth === "sm" ? "center" : "right",
          // width: screenWidth === "xs" || screenWidth === "sm" ? "100%" : "60%",
          // width: "100vw",
          // height: "100vh",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <Typography variant="h6">
          {/* <Button onClick={() => handleAgregarAgrupamiento()}>
            Agregar agrupamiento
          </Button>
          <Button onClick={() => handleEditarAgrupamiento()}>
            Editar agrupamiento
          </Button> */}
          <Button onClick={() => handleCambiarImagen()}>Cambiar imagen</Button>
          <Button onClick={() => handleEliminarPlano()}>Eliminar plano</Button>
        </Typography>
      </div>

      {openModal ? (
        <ModalPlano
          plano={item}
          openModal={openModal}
          setOpenModal={setOpenModal}
          accion={accion}
          nuevaImagen={nuevaImagen}
          setNuevaImagen={setNuevaImagen}
          // estado={estado}
          // setEstado={setEstado}
          // seccion={seccion}
          // setSeccion={setSeccion}
          // invitado={user}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Imagen;
