import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

// import { OutTable, ExcelRenderer } from "react-excel-renderer";
import SendIcon from "@mui/icons-material/Send";
import Botonera from "../../components/Botonera";
import ObraContext from "../../contextos/ObraContext";
import { Navigate, useNavigate } from "react-router-dom";
import Encabezado from "./Encabezado";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ModalAsignTarea from "./ModalAsignTarea";
import CuadroImportarTareas from "./CuadroImportarTareas";
import {
  chequearPermisos,
  desencriptar,
  numberWithCommas,
} from "../../funciones/Funciones";
import BoxResumenPresupuesto from "./BoxResumenPresupuesto";
import UsuarioContext from "../../contextos/UsuarioContext";
import ServidorContext from "../../contextos/ServidorContext";
import MensajesContext from "../../contextos/MensajesContext";
import ModalModifAsign from "./ModalModifAsign";
import AlertValidConfig from "../../components/AlertValidConfig";
import ModalDesasignarTarea from "./ModalDesasignarTarea";
import ModalVerFotos from "./ModalVerFotos";
import ConfigContext from "../../contextos/ConfigContext";
import ModalConfig from "./ModalConfig";

const PagePresupuestoActual = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { config, setConfig } = useContext(ConfigContext);

  const [refresh, setRefresh] = useState(false);
  const [mostrarTodo, setMostrarTodo] = useState(false);

  const [items, setItems] = useState([]);
  const [subtotalMateriales, setSubtotalMateriales] = useState(0);
  const [subtotalTrabajadores, setSubtotalTrabajadores] = useState(0);
  const [beneficioMateriales, setBeneficioMateriales] = useState(0);
  const [beneficioTrabajadores, setBeneficioTrabajadores] = useState(0);
  const [beneficio, setBeneficio] = useState(0);
  const [total, setTotal] = useState(0);

  // const [codTarea, setCodTarea] = useState();
  const [tarea, setTarea] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [estado, setEstado] = useState("ALTA");
  const [mostrarPresupuesto, setMostrarPresupuesto] = useState(false);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  const [mostrarBotonDescarga, setMostrarBotonDescarga] = useState(false);
  const [openModalConfig, setOpenModalConfig] = useState(false);
  const [archExcel, setArchExcel] = useState("");

  const initTarea = {
    idAsignacion: "",
    cuantificacion: "",
    unidad: "",
    avance: "",
    actualizar: false,
  };
  const [editarTarea, setEditarTarea] = useState(initTarea);

  let navigate = useNavigate();

  useEffect(() => {
    // console.log("pasa por useEffect");
    props.setTitulo("Presupuesto al día de hoy");
    props.setPagina("");

    if (obra.IDObra === undefined) {
      navigate("/presupuestosBTP");
    }

    // console.log("config: ", config);

    listar_presupuesto();
  }, []);

  useEffect(() => {
    // console.log("PASA: ", refresh);

    if (refresh) {
      listar_presupuesto();
    }
  }, [refresh]);

  useEffect(() => {
    if (
      editarTarea.idAsignacion !== "" &&
      estado === "MODIF" &&
      editarTarea.actualizar
    ) {
      actualizar_asignacion_de_tarea();
    }
  }, [editarTarea]);

  useEffect(() => {
    // console.log("editarTarea: ", editarTarea);
    // console.log("estado: ", estado);

    if (estado === "BAJA_OK") {
      // Itera sobre cada objeto en el array
      // Recorre cada objeto en el array
      let updatedItems = items.map((item) => {
        // Filtra los detalles para excluir el objeto con IDAsignacion deseado
        item.detalle = item.detalle.filter(
          (detalle) => detalle.IDAsignacion !== editarTarea.idAsignacion
        );
        return item;
      });

      // Actualiza el estado con los nuevos items
      setItems(updatedItems);
    }
  }, [estado]);

  const listar_presupuesto = async () => {
    // console.log(texto);
    // console.log("listo presupuesto");

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_PRESUPUESTO_BTP",
        idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.items) {
      // console.log("items: " + respuesta.result.items);
      //   setTexto("");

      let itemsPresupuesto = mostrarTodo
        ? respuesta.result.items
        : respuesta.result.items.filter(
            (item) => item.IDAsignacion !== null && item.Publicar === 1
          );

      // console.log("fotos: ", itemsPresupuesto[0].detalle);

      setMostrarPresupuesto(true);
      setItems(itemsPresupuesto);
      setSubtotalMateriales(respuesta.result.subtotalMateriales);
      setSubtotalTrabajadores(respuesta.result.subtotalTrabajadores);
      setBeneficioMateriales(respuesta.result.beneficioMateriales);
      setBeneficioTrabajadores(respuesta.result.beneficioTrabajadores);

      // console.log(
      //   "respuesta.result.subtotalMateriales:",
      //   respuesta.result.subtotalMateriales
      // );
      // console.log(
      //   "respuesta.result.subtotalTrabajadores:",
      //   respuesta.result.subtotalTrabajadores
      // );
      // console.log(
      //   "config.presupuestosBeneficioMateriales:",
      //   config.presupuestosBeneficioMateriales
      // );
      // console.log(
      //   "config.presupuestosBeneficioMO:",
      //   config.presupuestosBeneficioMO
      // );

      // console.log(
      //   "respuesta.result.beneficioMateriales:",
      //   respuesta.result.beneficioMateriales
      // );

      // console.log(
      //   "respuesta.result.beneficioTrabajadores:",
      //   respuesta.result.beneficioTrabajadores
      // );

      let beneficioMateriales = respuesta.result.beneficioMateriales;
      // (respuesta.result.subtotalMateriales *
      //   config.presupuestosBeneficioMateriales) /
      // 100;

      let beneficioTrabajadores = respuesta.result.beneficioTrabajadores;
      // (respuesta.result.subtotalTrabajadores *
      //   config.presupuestosBeneficioMO) /
      // 100;

      let beneficioTotal = beneficioMateriales + beneficioTrabajadores;

      setBeneficio(beneficioTotal);

      setTotal(respuesta.result.total);

      // console.log("items: ", itemsPresupuesto);

      const arrObra = {
        IDObra: obra.IDObra,
        Obra: obra.Obra,
        EsAdmin: obra.EsAdmin,
        Privilegios: obra.Privilegios,
        SubtotalMateriales: config.presupuestosCheckMostrarDetalle
          ? respuesta.result.subtotalMateriales
          : respuesta.result.subtotalMateriales +
            +respuesta.result.beneficioMateriales,
        SubtotalTrabajadores: config.presupuestosCheckMostrarDetalle
          ? respuesta.result.subtotalTrabajadores
          : respuesta.result.subtotalTrabajadores +
            +respuesta.result.beneficioTrabajadores,
        Beneficio: beneficioTotal,
        Total: respuesta.result.total + +beneficioTotal,
      };

      // console.log(arrObra);
      // let objobra = desencriptar(localStorage.getItem("objobra"));

      setObra(arrObra);

      exportar_excel();

      setRefresh(false);

      // console.log(respuesta.result.subtotalMateriales);
      // console.log(respuesta.result.subtotalTrabajadores);
      // console.log(respuesta.result.total);
      //   alert("Tareas actualizadas.");
    } else {
      setMostrarPresupuesto(false);
      console.log("NO TRAE NADA EN listar_presupuesto()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  //   const guardarPresupuesto = async () => {
  //     console.log(items);

  //     const API_ENDPOINT = servidor + `api_presupuestos.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_CUANTIFICACIONES",
  //         lista: items,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.cantidadInsertada) {
  //       console.log("cantidadInsertada: " + respuesta.result.cantidadInsertada);
  //       setItems("");
  //       alert("Tareas actualizadas.");
  //     } else {
  //       console.log("NO TRAE NADA.");
  //       // setState([]);
  //     }
  //   };

  const actualizar_asignacion_de_tarea = async () => {
    // console.log("editarTarea: ", editarTarea);

    // console.log({
    //   operacion: "MODIFICAR_ASIGNACION_DE_TAREA_BTP",
    //   idAsignacion: editarTarea.idAsignacion,
    //   cuantificacion: editarTarea.cuantificacion,
    //   avanceDO: editarTarea.avance,
    // });

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "MODIFICAR_ASIGNACION_DE_TAREA_BTP",
        idAsignacion: editarTarea.idAsignacion,
        cuantificacion: editarTarea.cuantificacion,
        avanceDO: editarTarea.avance,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("items: ", items);

      // Itera sobre cada objeto en el array
      const updatedItems = items.map((item) => {
        let sumaCuantificacion = 0;

        // Si el objeto tiene un array 'detalle', actualízalo
        if (item.detalle) {
          item.detalle = item.detalle.map((detalleItem) => {
            // Si el IDAsignacion coincide, actualiza Cuantificacion y AvanceDO
            if (detalleItem.IDAsignacion === editarTarea.idAsignacion) {
              sumaCuantificacion =
                parseFloat(sumaCuantificacion) +
                parseFloat(editarTarea.cuantificacion);

              // Suma la cuantificación actualizada en el detalle a la cuantificación de la tarea
              // item.Cantidad =
              //   parseFloat(item.Cantidad) +
              //   parseFloat(editarTarea.cuantificacion);

              return {
                ...detalleItem,
                Cuantificacion: editarTarea.cuantificacion,
                AvanceDO: editarTarea.avance,
              };
            } else {
              sumaCuantificacion =
                parseFloat(sumaCuantificacion) +
                parseFloat(detalleItem.Cuantificacion);

              return detalleItem;
            }
          });
        }
        // console.log("sumaCuantificacion: ", sumaCuantificacion);
        item.Cantidad = sumaCuantificacion;
        return item;
      });

      // Actualiza el estado con los nuevos items
      setItems(updatedItems);

      setMensaje({
        text: "Asignación actualizada con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      setEditarTarea(initTarea);
    } else {
      console.log("NO TRAE NADA EN actualizar_asignacion_de_tarea()");

      setMensaje({
        text: "Ocurrió un error al actualizar la asignación.",
        tipo: "error",
      });

      setMostrarMensaje(true);

      // setState([]);
    }

    // setEstado("");
  };

  const fechaHoy = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    // console.log(`${day}/${month}/${year}`);

    let fecha = `${day}/${month}/${year}`;

    return fecha;
  };

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  const exportar_excel = async () => {
    // console.log(texto);
    // console.log("listo presupuesto");

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GENERAR_EL_EXCEL_DE_PRESUPUESTO_BTP",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.archExcel) {
      // console.log("archExcel: " + respuesta.result.archExcel);
      //   setTexto("");

      setArchExcel(servidor + respuesta.result.archExcel);
      setMostrarBotonDescarga(true);
    } else {
      setMostrarBotonDescarga(false);
      setArchExcel("");
      console.log("NO TRAE NADA EN exportar_excel()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  // const updateItem = (updatedItem) => {
  //   setItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.IDAsignacion === updatedItem.IDAsignacion ? updatedItem : item
  //     )
  //   );
  // };

  const handleUpdateItems = (newItem) => {
    // console.log("handleUpdateItems: ", newItem);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.IDTarea === newItem.IDTarea ? newItem : item
      )
    );
  };

  const handleConfig = () => {
    setOpenModalConfig(true);
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Encabezado />
      <br />
      <br />
      {mostrarPresupuesto ? (
        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid item xs={8} border={0}>
            <Typography variant="h6">PRESUPUESTO {fechaHoy()}</Typography>
          </Grid>

          <Grid item xs={12} border={0} display="flex" justifyContent="right">
            <Typography variant="h6">
              <Button onClick={handleConfig}>Configurar</Button>-{" "}
              <Button href={archExcel}>Exportar a Excel</Button>
            </Typography>
          </Grid>

          <Grid item xs={12} border={0} mb={2}>
            <BoxResumenPresupuesto />
          </Grid>

          <Grid item xs={12} border={1}>
            {/* {items.map((row) => ( */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Cod. Tarea</TableCell>
                    <TableCell align="left">Tarea</TableCell>
                    <TableCell align="left">Cantidad</TableCell>
                    <TableCell align="center">Costo Materiales</TableCell>
                    <TableCell align="center">Costo Mano de Obra</TableCell>
                    <TableCell align="center">Subtotal</TableCell>
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => (
                    <Row
                      key={index}
                      row={row}
                      editarTarea={editarTarea}
                      setEditarTarea={setEditarTarea}
                      setEstado={setEstado}
                      onUpdateItems={handleUpdateItems} // Pasamos el callback
                      // updateItem={updateItem} // Pasas la función aquí
                      // actualizaRecurso={actualizaRecurso}
                      // setActualizaRecurso={setActualizaRecurso}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} border={0}></Grid>
          <Grid item xs={12} border={1}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow
                    key="SubtotalMateriales"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Subtotal Materiales:
                      {numberWithCommas(
                        " $ " + Math.round(subtotalMateriales * 100) / 100
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="SubtotalTrabajadores"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Subtotal Trabajadores:
                      {numberWithCommas(
                        " $ " + Math.round(subtotalTrabajadores * 100) / 100
                      )}
                    </TableCell>
                  </TableRow>

                  {config.presupuestosCheckMostrarDetalle ? (
                    <TableRow
                      key="Beneficio"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        {" "}
                        Beneficio:
                        {numberWithCommas(
                          " $ " + Math.round(beneficio * 100) / 100
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}

                  <TableRow
                    key="Total"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">
                      {" "}
                      Total:
                      {numberWithCommas(
                        " $ " + Math.round((total + +beneficio) * 100) / 100
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} border={0}></Grid>

          <Grid item xs={12} border={0} mt={2}>
            <BoxResumenPresupuesto />
          </Grid>

          <Grid item xs={10} border={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/* <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => guardarPresupuesto()}
              >
                Guardar
              </Button>
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      ) : mostrarProgress ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <CuadroImportarTareas />
      )}

      {openModal ? (
        <ModalAsignTarea
          openModal={openModal}
          setOpenModal={setOpenModal}
          tarea={tarea}
          estado={estado}
          setEstado={setEstado}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : (
        ""
      )}

      {openModalConfig ? (
        <ModalConfig
          openModalConfig={openModalConfig}
          setOpenModalConfig={setOpenModalConfig}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PagePresupuestoActual;

function Row(props) {
  const { row, editarTarea, setEditarTarea, setEstado, onUpdateItems } = props;

  const { config } = useContext(ConfigContext);
  const [open, setOpen] = React.useState(false);

  const [tipoDeRecurso, setTipoDeRecurso] = useState("Material");

  const [codTarea, setCodTarea] = useState();
  //   const [codMaterial, setCodMaterial] = useState("");
  //   const [codManoDeObra, setCodManoDeObra] = useState("");
  const [codRecurso, setCodRecurso] = useState("");
  const [recurso, setRecurso] = useState("");
  const [consumo, setConsumo] = useState("");
  const [unidadDelRecurso, setUnidadDelRecurso] = useState("");

  const [fotos, setFotos] = useState([]);
  const [detalle, setDetalle] = useState("");

  // const [editarTarea, setEditarTarea] = useState({
  //   idAsignacion: "",
  //   cuantificacion: "",
  //   unidad: "",
  //   avance: "",
  // });

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalAsign, setOpenModalAsign] = useState(false);
  const [openVerFotos, setOpenVerFotos] = useState(false);

  const { usuario, setUsuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  // const handleOpenAsign = (rowTarea) => {
  //   // setCodManoDeObra(codigoMO);
  //   setTarea(rowTarea);

  //   setOpenModal(true);
  // };

  useEffect(() => {
    let change = fotos;

    // console.log("row.detalle.fotos 1: ", row.detalle.fotos);

    row.detalle.fotos = fotos;
    // console.log("row.detalle.fotos 2: ", row.detalle.fotos);

    // console.log("change: ", change);
  }, [fotos]);

  const handleOpenAltaMat = (codigoTarea) => {
    if (
      chequearPermisos(obra.Privilegios, "PRESUPUESTOS_ALTA_RECURSO") === true
    ) {
      setEstado("ALTA");
      setCodTarea(codigoTarea);
      setTipoDeRecurso("Material");
      setConsumo("");
      setOpenModal(true);

      // console.log(codigoTarea);
    } else {
      alert("No tiene permisos para agregar un recurso.");
    }
  };

  const handleOpenAltaMO = (codigoTarea) => {
    if (
      chequearPermisos(obra.Privilegios, "PRESUPUESTOS_ALTA_RECURSO") === true
    ) {
      setEstado("ALTA");
      setCodTarea(codigoTarea);
      setTipoDeRecurso("Trabajador");
      setConsumo("");
      setOpenModal(true);
    } else {
      alert("No tiene permisos para agregar un recurso.");
    }
  };

  const handleVerFotos = (detalleRow) => {
    if (chequearPermisos(obra.Privilegios, "PRESUPUESTOS_VER_FOTOS") === true) {
      // console.log("obra: ", obra.Privilegios);
      setEstado("VER_FOTOS");
      // console.log("detalleRow: ", detalleRow);
      setFotos(detalleRow.fotos);
      setDetalle(detalleRow);
      setOpenVerFotos(true);
    } else {
      setMensaje({
        text: "No tiene permisos para ver las fotos.",
        tipo: "error",
      });

      setMostrarMensaje(true);
    }
  };

  const handleOpenModif = (detalleRow) => {
    // console.log("IDAsignacion: ", detalleRow.IDAsignacion);
    // console.log("Cuantificacion: ", detalleRow.Cuantificacion);
    // console.log("Unidad: ", detalleRow.Unidad);
    // console.log("AvanceDO: ", detalleRow.AvanceDO);
    // console.log("Privilegios: ", obra.Privilegios);

    if (
      chequearPermisos(
        obra.Privilegios,
        "PRESUPUESTOS_MODIFICACION_CONFIG_TAREA"
      ) === true
    ) {
      setEstado("MODIF");
      setEditarTarea({
        idAsignacion: detalleRow.IDAsignacion,
        cuantificacion: detalleRow.Cuantificacion,
        unidad: detalleRow.Unidad,
        avance: detalleRow.AvanceDO,
      });
      setOpenModal(true);
    } else {
      setMensaje({
        text: "No tiene permisos para modificar la tarea.",
        tipo: "error",
      });

      setMostrarMensaje(true);
    }
  };

  const handleOpenModifMO = (
    codigoTarea,
    codigoRecurso,
    nombreRecurso,
    cantidadConsumo,
    unidadDelRecurso
  ) => {
    if (
      chequearPermisos(
        obra.Privilegios,
        "PRESUPUESTOS_MODIFICACION_RECURSO"
      ) === true
    ) {
      setEstado("MODIF");
      // setCodManoDeObra(codigoMO);
      setCodTarea(codigoTarea);
      setCodRecurso(codigoRecurso);
      setRecurso(nombreRecurso);
      setConsumo(cantidadConsumo);
      setUnidadDelRecurso(unidadDelRecurso);
      setTipoDeRecurso("Trabajador");
      setOpenModal(true);
    } else {
      alert("No tiene permisos para modificar un recurso.");
    }
  };

  const handleDelete = (detalleRow) => {
    // console.log("Privilegios: ", obra.Privilegios);

    if (
      chequearPermisos(
        obra.Privilegios,
        "PRESUPUESTOS_BAJA_ASIGNACION_TAREA"
      ) === true
    ) {
      // console.log("PASA");
      setEstado("ELIMINAR");
      setEditarTarea({
        idAsignacion: detalleRow.IDAsignacion,
        cuantificacion: detalleRow.Cuantificacion,
        unidad: detalleRow.Unidad,
        avance: detalleRow.AvanceDO,
        actualizar: true,
      });
      setOpenModalAsign(true);
    } else {
      setMensaje({
        text: "No tiene permisos para borrar la asignación.",
        tipo: "error",
      });

      setMostrarMensaje(true);
    }
  };

  // useEffect(() => {
  //   props.setActualizaRecurso(!props.actualizaRecurso);
  // }, [estado]);

  return (
    <React.Fragment>
      <TableRow /*sx={{ "& > *": { borderBottom: "unset" } }}*/>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {row.CodTarea}
        </TableCell>
        <TableCell align="left">
          {row.Tarea} {row.Cantidad > 0 ? "" : "[" + row.Unidad + "]"}
        </TableCell>

        <TableCell align="left">
          {row.Cantidad > 0
            ? numberWithCommas(Math.round(row.Cantidad * 100) / 100)
            : ""}{" "}
          {row.Cantidad > 0 ? "[" + row.Unidad + "]" : ""}
        </TableCell>

        <TableCell align="center">
          {row.CostoMateriales > 0
            ? config.presupuestosCheckMostrarDetalle
              ? "$ " +
                numberWithCommas(Math.round(row.CostoMateriales * 100) / 100)
              : "$ " +
                numberWithCommas(
                  Math.round(
                    (+row.CostoMateriales + +row.BeneficioMateriales) * 100
                  ) / 100
                )
            : ""}
        </TableCell>
        <TableCell align="center">
          {row.CostoTrabajadores > 0
            ? config.presupuestosCheckMostrarDetalle
              ? "$ " +
                numberWithCommas(Math.round(row.CostoTrabajadores * 100) / 100)
              : "$ " +
                numberWithCommas(
                  Math.round(
                    (+row.CostoTrabajadores + +row.BeneficioTrabajadores) * 100
                  ) / 100
                )
            : ""}
        </TableCell>
        <TableCell align="center">
          {row.CostoMateriales > 0 || row.CostoTrabajadores > 0
            ? config.presupuestosCheckMostrarDetalle
              ? numberWithCommas(
                  "$ " +
                    Math.round(
                      (+row.CostoMateriales + +row.CostoTrabajadores) * 100
                    ) /
                      100
                )
              : numberWithCommas(
                  "$ " +
                    Math.round(
                      (+row.CostoMateriales +
                        +row.CostoTrabajadores +
                        +row.BeneficioMateriales +
                        +row.BeneficioTrabajadores) *
                        100
                    ) /
                      100
                )
            : ""}
        </TableCell>
        <TableCell align="center">
          {(row.CostoMateriales > 0 || row.CostoTrabajadores > 0) &&
          row.Cantidad > 0
            ? config.presupuestosCheckMostrarDetalle
              ? numberWithCommas(
                  "$ " +
                    Math.round(
                      (+row.CostoMateriales + +row.CostoTrabajadores) *
                        row.Cantidad *
                        100
                    ) /
                      100
                )
              : numberWithCommas(
                  "$ " +
                    Math.round(
                      (+row.CostoMateriales +
                        +row.CostoTrabajadores +
                        +row.BeneficioMateriales +
                        +row.BeneficioTrabajadores) *
                        row.Cantidad *
                        100
                    ) /
                      100
                )
            : ""}
        </TableCell>
      </TableRow>

      {openVerFotos ? (
        <ModalVerFotos
          row={row}
          detalle={detalle}
          openVerFotos={openVerFotos}
          setOpenVerFotos={setOpenVerFotos}
          fotos={fotos}
          setFotos={setFotos}
          onUpdateItems={onUpdateItems} // Pasamos el callback
        />
      ) : (
        ""
      )}

      {openModal ? (
        <ModalModifAsign
          openModal={openModal}
          setOpenModal={setOpenModal}
          editarTarea={editarTarea}
          setEditarTarea={setEditarTarea}
        />
      ) : (
        ""
      )}

      {/* {openModal ? (
        <ModalRecursos
          openModal={openModal}
          setOpenModal={setOpenModal}
          codTarea={codTarea}
          tipoDeRecurso={tipoDeRecurso}
          estado={estado}
          setEstado={setEstado}
          //   codMaterial={codMaterial}
          //   codManoDeObra={codManoDeObra}
          codRecurso={codRecurso}
          recurso={recurso}
          consumo={consumo}
          unidadDelRecurso={unidadDelRecurso}
          setUnidadDelRecurso={setUnidadDelRecurso}
        />
      ) : (
        ""
      )} */}

      {/* {openModalConfirm ? (
        <ModalConfirm
          openModalConfirm={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          codTarea={codTarea}
          codRecurso={codRecurso}
          estado={estado}
          setEstado={setEstado}
        />
      ) : (
        ""
      )} */}

      {openModalAsign ? (
        <ModalDesasignarTarea
          openModalAsign={openModalAsign}
          setOpenModalAsign={setOpenModalAsign}
          setEstado={setEstado}
          idAsignacion={editarTarea.idAsignacion}
        />
      ) : (
        ""
      )}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
              }}
            >
              <Typography variant="h6" gutterBottom component="div">
                Detalle
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Plano</TableCell>
                    <TableCell align="left">Ubicación</TableCell>
                    <TableCell align="left">Cuantificación</TableCell>
                    <TableCell align="left">Unidad</TableCell>
                    <TableCell align="left">Avance</TableCell>
                    <TableCell align="left">Fotos</TableCell>
                    <TableCell align="left">Modificar</TableCell>
                    <TableCell align="left">Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detalle.map((detalleRow) => (
                    <TableRow key={detalleRow.IDPlano}>
                      <TableCell component="th" scope="row" align="left">
                        {detalleRow.Plano}
                      </TableCell>
                      <TableCell align="left">
                        {detalleRow.Departamento} | {detalleRow.Sector} |{" "}
                        {detalleRow.Grupo}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {detalleRow.Cuantificacion}
                      </TableCell>
                      <TableCell align="left">{row.Unidad}</TableCell>
                      <TableCell align="left">
                        {detalleRow.AvanceDO} %
                      </TableCell>

                      <TableCell align="left">
                        {detalleRow.tieneFotos === "SI" ? (
                          <IconButton aria-label="foto">
                            <PhotoCameraOutlinedIcon
                              // fontSize="inherit"
                              sx={{ fontSize: 30 }}
                              onClick={() => handleVerFotos(detalleRow)}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <EditOutlinedIcon
                            // fontSize="inherit"
                            sx={{ fontSize: 30 }}
                            onClick={() => handleOpenModif(detalleRow)}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <HighlightOffOutlinedIcon
                            // fontSize="inherit"
                            sx={{ fontSize: 30 }}
                            onClick={() => handleDelete(detalleRow)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    codTarea: PropTypes.string.isRequired,
    tarea: PropTypes.string,
    cantidad: PropTypes.string,
    costoMateriales: PropTypes.string,
    costoMO: PropTypes.string,
    subtotal: PropTypes.string,
    total: PropTypes.string,
    // categoria: PropTypes.string,
    // precioM2: PropTypes.number.isRequired,
    detalle: PropTypes.arrayOf(
      PropTypes.shape({
        plano: PropTypes.string.isRequired,
        ubicacion: PropTypes.string.isRequired,
        cuantificacion: PropTypes.string.isRequired,
        unidad: PropTypes.string.isRequired,
        avance: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};
