import React, { useRef } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
} from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import { useContext } from "react";
import MensajesContext from "../../../contextos/MensajesContext";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import { useState } from "react";
import Webcam from "react-webcam";
import styled from "@mui/system/styled";
import ObraContext from "../../../contextos/ObraContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import {
  chequearPermisos,
  enviarPosicionHaciaReactNative,
} from "../../../funciones/Funciones";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  //   facingMode: "user",

  //   width: { min: 480 },
  //   height: { min: 720 },
  aspectRatio: 0.6666666667,
  facingMode: "user", // CAMARA FRONTAL
  //   facingMode: { exact: "environment" },  // CAMARA TRASERA
};

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
}));

const ModalDetalleTarea = ({ grupo, tarea, openDialog, setOpenDialog }) => {
  const webcamRef = useRef(null);

  //   // Dentro de tu componente Tarea
  //   const guardarFotoCalled = useRef(false);

  const { screenWidth, isMobile } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { usuario } = useContext(UsuarioContext);
  const { tareasAsignadas, setTareasAsignadas } = useContext(
    ControlDeAvanceContext
  );

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [avanceDO, setAvanceDO] = useState("");
  const [avanceGremio, setAvanceGremio] = useState("");
  const [listaDeFotos, setListaDeFotos] = useState([]);
  const [fotoTomada, setFotoTomada] = useState("");
  const [mostrarCamara, setMostrarCamara] = useState(false);
  const [picture, setPicture] = useState("");
  const [mostrarImagen, setMostrarImagen] = useState("");
  const [mirrored, setMirrored] = useState(false);

  const [contadorIntersticial, setContadorIntersticial] = useState(0);

  const [idAsignacion, setIdAsignacion] = useState("");

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  useEffect(() => {
    // alert("*" + idAsignacion + " === " + tarea.IDAsignacion + "*");

    if (
      fotoTomada !== "" &&
      fotoTomada !== null &&
      idAsignacion === tarea.IDAsignacion
      // &&  !guardarFotoCalled.current
    ) {
      //   alert("|" + tarea.IDAsignacion + "|");
      setIdAsignacion("");
      guardar_foto();
      //   guardarFotoCalled.current = true;
    }
  }, [fotoTomada]);

  //   // Asegúrate de restablecer la referencia cuando sea apropiado
  //   const resetGuardarFotoCalled = () => {
  //     guardarFotoCalled.current = false;
  //     // ... otras operaciones de restablecimiento
  //   };

  useEffect(() => {
    // console.log("mostrar: ", mostrarCamara);

    // if ((!isMobile && webcamRef.current) || isMobile) {

    if (mostrarCamara) {
      capture();
    }

    // }
  }, [mostrarCamara]);

  useEffect(() => {
    // console.log("usuario: ", usuario);
    // console.log("obra: ", obra);

    // console.log("tarea: ", tarea);

    traer_lista_de_fotos();
  }, []);

  const traer_lista_de_fotos = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "TRAER_FOTOS_DE_TAREA",
    //   idAsignacion: tarea.IDAsignacion,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_FOTOS_DE_TAREA",
        idAsignacion: tarea.IDAsignacion,
        // idObra: obra.IDObra,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("fotos: ", respuesta.result.listaFotos);

    if (respuesta.result.listaFotos) {
      setListaDeFotos(respuesta.result.listaFotos);
      // console.log("listaDeFotos: ", respuesta.result.listaDeFotos);
    } else {
      console.log("NO TRAE NADA EN traer_lista_de_fotos()");
      // setState([]);
    }
  };

  function valuetext(value) {
    let obr = localStorage.getItem("objobra");
    // console.log("obra context: ", obra);
    // console.log("obra localstorage: ", desencriptar(obr));

    if (obra.EsAdmin === "1") {
      // console.log("DO");
      setAvanceDO(value);
    } else {
      // console.log("GREMIO");
      setAvanceGremio(value);
    }

    // console.log("value: ", value);

    return `${value} ${tarea.Unidad}`;
  }

  const handleCloseDialog = () => {
    // setDialogValue({
    //   IDGrupo: "",
    //   Grupo: "",
    // })0;
    setOpenDialog(false);
  };

  const handleDesasignarTarea = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("fotoTomada: ", fotoTomada);
    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idFotoDeTarea: ", idFotoDeTarea);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "DESASIGNAR_TAREA",
        idAsignacion: tarea.IDAsignacion,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // Creamos una copia del estado actual
      const nuevasTareasAsignadas = [...tareasAsignadas];

      // console.log("nuevas: ", nuevasTareasAsignadas);

      nuevasTareasAsignadas.forEach((gru) => {
        if (gru.IDGrupo === grupo.IDGrupo) {
          gru.Tareas = gru.Tareas.filter(
            (tar) => tar.IDTarea !== tarea.IDTarea
          );
        }
      });

      // console.log("nuevas 2: ", nuevasTareasAsignadas);

      setTareasAsignadas(nuevasTareasAsignadas);

      setMensaje({
        text: "La tarea ha sido desasignada con éxito!",
        tipo: "error",
      });

      setMostrarMensaje(true);
      // setOpenDialog(false);
      setFotoTomada("");
      setMostrarCamara(false);
    } else {
      console.log("NO TRAE NADA EN handleDesasignarTarea()");
      // setState([]);
    }
  };

  const handleEliminarImagen = async (idFotoDeTarea) => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("fotoTomada: ", fotoTomada);
    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idFotoDeTarea: ", idFotoDeTarea);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ELIMINAR_FOTO_DE_TAREA",
        idFotoDeTarea: idFotoDeTarea,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);

      // setListaDeFotos([
      //   listaDeFotos.filter((imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea),
      // ]);

      let filtro = listaDeFotos.filter(
        (imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea
      );

      setListaDeFotos(filtro);
      // console.log("filtro: ", filtro);

      setMensaje({
        text: "La foto ha sido eliminada con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);
      // setOpenDialog(false);
      setFotoTomada("");
      setMostrarCamara(false);
    } else {
      console.log("NO TRAE NADA EN handleEliminarImagen()");
      // setState([]);
    }
  };

  const capture = React.useCallback(async () => {
    if (isMobile && window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({
      //     accion: "takePhoto",
      //     contenido: {},
      //   })
      // );

      let sum = localStorage.getItem("contIntersticial");

      //   if (sum % 10 === 0) {
      //     // Muestra el banner bonificado cada 10 fotos.
      //     sum = 1;

      //     enviarPosicionHaciaReactNative(isMobile, "banner", {
      //       usuario: usuario,
      //       obra: obra,
      //       tipoDeBanner: "BONIFICADO",
      //       idPublicidad: "ca-app-pub-9747826938175612/9299862830",
      //       idBanner: "ca-app-pub-9747826938175612/8150779805",
      //       sacarFoto: true,
      //     });

      //   } else {
      //     sum++;

      if (sum % 5 === 0) {
        // Muestra el banner intersticial cada 5 fotos.
        enviarPosicionHaciaReactNative(isMobile, "banner", {
          usuario: usuario,
          obra: obra,
          tipoDeBanner: "INTERSTICIAL",
          idPublicidad: "ca-app-pub-9747826938175612/9108291141",
          idBanner: "ca-app-pub-9747826938175612/8150779805",
          sacarFoto: true,
        });
      } else {
        enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});
      }
      //   }

      sum++;
      localStorage.setItem("contIntersticial", sum);

      setContadorIntersticial(sum);
    } else {
      // console.log("webcamRef: ", webcamRef);

      // if (webcamRef.current) {
      const pictureSrc = await webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      setFotoTomada(pictureSrc);
      // console.log("picture: ", pictureSrc);
      // setMostrarCamara(false);

      // } else {
      //   setPicture("");
      //   setMostrarImagen("");

      //   setFotoTomada("");
      // }

      // console.log("pictureSrc: ", pictureSrc);
    }

    // }

    // console.log("pictureSrc", pictureSrc);
  }, [webcamRef]);

  const handleSubmitDialog = (event) => {
    event.preventDefault();

    if (
      chequearPermisos(
        obra.Privilegios,
        "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
      ) === true
    ) {
      guardar_avance();
    } else {
      setMensaje({ text: "No tiene permisos!", tipo: "error" });
      setMostrarMensaje(true);
    }

    // if (dialogValue.Grupo !== "") {
    //   if (
    //     chipData.filter((chip) => chip.Grupo === dialogValue.Grupo).length > 0
    //   ) {
    //     setMensaje({ text: "El grupo ya existe.", tipo: "error" });

    //     setMostrarMensaje(true);
    //   } else {
    //     guardar_grupo();

    //     setValue({
    //       IDGrupo: parseInt(dialogValue.IDGrupo, 10),
    //       Grupo: dialogValue.Grupo,
    //     });
    //     handleClose();
    //   }
    // } else {
    //   setMensaje({ text: "El grupo no puede ser vacío.", tipo: "error" });

    //   setMostrarMensaje(true);
    // }
  };

  const guardar_avance = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // console.log("AvanceDO: ", avanceDO);
    // console.log("usuario: ", usuario);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_AVANCE_DE_TAREA",
        idAsignacion: tarea.IDAsignacion,
        avanceDO: avanceDO,
        avanceGremio: avanceGremio,
        idUsuario: usuario.IDUsuario,
        esAdmin: obra.EsAdmin,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);

      // setTareasAsignadas
      // console.log("tarea: ", tarea);

      // Creamos una copia del estado actual
      const nuevasTareasAsignadas = [...tareasAsignadas];

      // Encuentra el grupo con IDGrupo = 6
      let gru = nuevasTareasAsignadas.find(
        (gru) => gru.IDGrupo === grupo.IDGrupo
      );

      if (gru) {
        // Encuentra la tarea con IDTarea = 7229
        let task = gru.Tareas.find((tar) => tar.IDTarea === tarea.IDTarea);

        if (task) {
          // Actualiza AvanceDO
          if (obra.EsAdmin === "1") {
            task.AvanceDO = avanceDO;
            task.AvanceGremio = avanceDO;
          } else {
            task.AvanceGremio = avanceGremio;
          }
        }
      }

      // console.log("nuevas: ", nuevasTareasAsignadas);
      setTareasAsignadas(nuevasTareasAsignadas);

      setMensaje({ text: "Avance guardado con éxito!", tipo: "success" });

      setMostrarMensaje(true);
      setOpenDialog(false);
    } else {
      console.log("NO TRAE NADA EN guardar_avance()");
      // setState([]);
    }
  };

  const guardar_foto = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("fotoTomada: ", fotoTomada);
    // console.log("idAsignacion: ", tarea.IDAsignacion);
    // console.log("idUsuario: ", usuario.IDUsuario);

    // console.log({
    //   operacion: "GUARDAR_FOTO_DE_AVANCE",
    //   foto: fotoTomada,
    //   anotaciones: "",
    //   idAsignacion: tarea.IDAsignacion,
    //   idUsuario: usuario.IDUsuario,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_FOTO_DE_AVANCE",
        foto: fotoTomada,
        anotaciones: "",
        idAsignacion: tarea.IDAsignacion,
        idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("result: ", respuesta.result.palabrasClaves[0]);

      setListaDeFotos([
        {
          IDFotoDeTarea: respuesta.result.idFoto,
          Foto: fotoTomada,
          Anotaciones: "",
          IDAsignacion: tarea.IDAsignacion,
          IDUsuario: usuario.IDUsuario,
          Fecha: respuesta.result.fecha,
          Publicar: 1,
        },
        ...listaDeFotos,
      ]);

      // console.log("listaDeFotos 2: ", listaDeFotos);

      setMensaje({ text: "Foto guardada con éxito!", tipo: "success" });

      setMostrarMensaje(true);
      // setOpenDialog(false);
      setFotoTomada("");
      setMostrarCamara(false);

      //   resetGuardarFotoCalled();
    } else {
      console.log("NO TRAE NADA EN guardar_foto()");
      // setState([]);
    }
  };

  window.addEventListener("message", function (event) {
    // if (processed) {
    //   return;
    // }
    // processed = true;

    // window.ReactNativeWebView.postMessage("FUERA: " + event);

    // if (event.data.type === "myEventType") {
    //   window.ReactNativeWebView.postMessage("myEventType: " + event.data.data);
    //   alert("MSG: " + event.data.data);
    // }

    // alert("mensaje: ", event.data);

    if (event.data.type === "photo") {
      // window.ReactNativeWebView.postMessage("photo: " + event.data.data);

      // const img = document.createElement("img");
      // img.src = `data:image/jpeg;base64,${event.data.data}`;

      // document.body.appendChild(img);

      const pictureSrc = `data:image/jpeg;base64,${event.data.data}`;
      // alert("pictureSrc: ", pictureSrc);
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      // console.log("pictureSrc: ", pictureSrc);

      setFotoTomada(pictureSrc);

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BANNER",
        idPublicidad: "ca-app-pub-9747826938175612/8150779805",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: false,
      });

      setMostrarCamara(false);
    }
  });

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      // style={{ width: "800px", border: "10px solid #FFFF00" }}
      // style={{
      // border: "10px solid #FFFF00",
      // width: isMobile ? "100%" : "50%",
      // height: isMobile ? "100%" : "50%",
      // }}
    >
      <form
        style={
          {
            // border: "10px solid #FF0000",
            // width: isMobile ? "100%" : "50%",
          }
        }
      >
        <DialogTitle>
          {tarea.Tarea} ({tarea.Unidad})
          <br />
          <Button
            onClick={() => handleDesasignarTarea()}
            startIcon={
              <DeleteIcon
                sx={
                  {
                    // p: 1,
                    // cursor: "pointer",
                    // display: "flex",
                    // alignItems: "center",
                  }
                }
                // onClick={(e) => {
                //   e.preventDefault();
                //   capture();
                // }}
                // onClick={() => handleTomarFoto()}
              />
            }
          >
            Desasignar tarea
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "99%", marginTop: "50px" }}>
            <Slider
              aria-label="Always visible"
              defaultValue={tarea.AvanceGremio}
              getAriaValueText={valuetext}
              step={5}
              marks={marks}
              valueLabelDisplay="on"
            />
          </Box>
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                // border: "4px solid #BBBBBB",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: 300,
                  marginTop: "50px",
                  marginBottom: "50px",
                  // border: "1px solid #FF0000",
                }}
              >
                {!mostrarCamara ? (
                  <Button
                    variant="outlined"
                    startIcon={
                      <CameraAltOutlinedIcon
                        sx={
                          {
                            // p: 1,
                            // cursor: "pointer",
                            // display: "flex",
                            // alignItems: "center",
                          }
                        }
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   capture();
                        // }}
                        // onClick={() => handleTomarFoto()}
                      />
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIdAsignacion(tarea.IDAsignacion);
                      setMostrarCamara(true);
                      // console.log("PASA POR 1");
                      // capture();
                    }}
                  >
                    TOMAR FOTO
                  </Button>
                ) : (
                  ""
                )}

                {!isMobile && mostrarCamara ? (
                  <>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        border: "4px solid #000000",
                      }}
                    >
                      <Grid item style={{ width: "100%" }}>
                        <Webcam
                          width="99%"
                          // height={400}
                          ref={webcamRef}
                          videoConstraints={videoConstraints}
                          mirrored={mirrored}
                          screenshotFormat="image/jpeg"
                          screenshotQuality={0.95}
                          imageSmoothing={true}
                          // audio={true}
                          video="true"
                          //   sx={{
                          //     alignSelf: "flex-start",
                          //     justifyContent: "flex-start",
                          //     display: "flex",
                          //     alignItems: "flex-start",
                          //     float: "left",
                          //     marginLeft: "0px",
                          //   }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <Button
                          variant="outlined"
                          startIcon={
                            <CameraAltOutlinedIcon
                              sx={
                                {
                                  // p: 1,
                                  // cursor: "pointer",
                                  // display: "flex",
                                  // alignItems: "center",
                                }
                              }
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   capture();
                              // }}
                              // onClick={() => handleTomarFoto()}
                            />
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            // setMostrarCamara(true);
                            // console.log("PASA POR 2");

                            capture();
                          }}
                        >
                          CAPTURAR
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={handleSubmitDialog}>Guardar</Button>
          </DialogActions>
          <Grid container spacing={2}>
            {listaDeFotos.map((imagen) => (
              <Grid
                item
                style={{
                  width: "100%",
                  // border: "1px solid #FF0000",
                  marginBottom: "10px",
                }}
              >
                <Item>
                  <img src={imagen.Foto} width="100%" />
                  <br />
                  <Button
                    onClick={() => handleEliminarImagen(imagen.IDFotoDeTarea)}
                    startIcon={
                      <DeleteIcon
                        sx={
                          {
                            // p: 1,
                            // cursor: "pointer",
                            // display: "flex",
                            // alignItems: "center",
                          }
                        }
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   capture();
                        // }}
                        // onClick={() => handleTomarFoto()}
                      />
                    }
                  >
                    Eliminar foto
                  </Button>
                </Item>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button onClick={handleSubmitDialog}>Guardar</Button>
            </DialogActions> */}
      </form>
    </Dialog>
  );
};

export default ModalDetalleTarea;
