import { Button, Chip, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import {
  contieneCodigoLaEntrada,
  desencriptar,
  encriptar,
} from "../../funciones/Funciones";

import Rubros from "../pedidos/Rubros";
import SeleccionDeColor from "./SeleccionDeColor";
import ServidorContext from "../../contextos/ServidorContext";

const AgregarProveedor = ({
  setProveedores,
  proveedores,
  setMostrarFormAgregarProveedor,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [proveedor, setProveedor] = useState({
    text: "",
    valid: false,
    error: "Complete el nombre del proveedor",
  });
  const [contacto, setContacto] = useState({
    text: "",
    valid: false,
    error: "Complete el nombre de la persona de contacto",
  });
  const [whatsapp, setWhatsapp] = useState({
    text: "",
    valid: false,
    error: "Complete el número de Whatsapp",
  });
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [direccion, setDireccion] = useState({
    text: "",
    valid: false,
    error: "",
  });
  // const [rubros, setRubros] = useState({
  //   text: "",
  //   valid: false,
  //   error: "",
  // });
  const [rubros, setRubros] = useState("");
  const [proveedorColor, setProveedorColor] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [proveedorColorNumero, setProveedorColorNumero] = useState({
    text: "",
    valid: false,
    error: "",
  });

  // const [detalleProveedor, setDetalleProveedor] = useState({
  //   nombre: "",
  //   contacto: "",
  //   whatsapp: "",
  //   email: "",
  //   direccion: "",
  //   rubros: "",
  //   color: "orange",
  //   numeroColor: "500",
  //   obras: [],
  // });

  // useEffect(() => {
  //   setDetalleProveedor({ ...detalleProveedor, rubros: rubros });

  //   console.log("detalleProveedor: ", { ...detalleProveedor, rubros: rubros });
  // }, [rubros]);

  const handleProveedor = (e) => {
    let text = e.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Proveedor vacío.
        setProveedor({
          text: text,
          valid: false,
          error: "Complete el nombre del proveedor",
        });
      } else if (text.length < 3) {
        // console.log("Proveedor muy corto.");
        setProveedor({
          text: text,
          valid: false,
          error: "Nombre del proveedor muy corto",
        });
      } else {
        // console.log("Proveedor OK.");
        setProveedor({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setProveedor({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const handleContacto = (e) => {
    let text = e.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Contacto vacío.
        setContacto({
          text: text,
          valid: false,
          error: "Complete el nombre de la persona de contacto",
        });
      } else if (text.length < 3) {
        // console.log("Contacto muy corto.");
        setContacto({
          text: text,
          valid: false,
          error: "Nombre de la persona de contacto muy corto",
        });
      } else {
        // console.log("Contacto OK.");
        setContacto({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setContacto({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  const handleWhatsapp = (e) => {
    let text = e.target.value;

    if (!contieneCodigoLaEntrada(text)) {
      if (text.length == 0) {
        // Whatsapp vacío.
        setWhatsapp({
          text: text,
          valid: false,
          error: "Complete el número de Whatsapp",
        });
      } else if (text.length < 3) {
        // console.log("Whatsapp muy corto.");
        setWhatsapp({
          text: text,
          valid: false,
          error: "Número de Whatsapp muy corto",
        });
      } else {
        // console.log("Whatsapp OK.");
        setWhatsapp({
          text: text,
          valid: true,
          error: "",
        });
      }
    } else {
      setWhatsapp({
        text: "",
        valid: false,
        error: "Entrada incorrecta",
      });
    }
  };

  // const handleAddChip = () => {
  //   // console.log("chipData: ", chipData);

  //   if (palabraClave !== "") {
  //     if (
  //       chipData.filter((chip) => chip.PalabraClave === palabraClave).length > 0
  //     ) {
  //       alert("Ya existe la ubicación");
  //     } else {
  //       guardar_ubicacion();
  //     }
  //   } else {
  //     alert("La ubicación no puede ser vacía.");
  //   }
  // };

  const validarFormulario = () => {
    // chequearSiUsuarioExiste();

    // chequearSiEmailExiste();

    if (proveedor.valid && contacto.valid && whatsapp.valid) {
      //   let objregusu = desencriptar(localStorage.getItem("objregusu"));
      guardar_proveedor();
    } else {
      if (!proveedor.valid) {
        setMensaje({ text: proveedor.error, tipo: "error" });
      } else if (!contacto.valid) {
        setMensaje({ text: contacto.error, tipo: "error" });
      } else if (!whatsapp.valid) {
        setMensaje({ text: whatsapp.error, tipo: "error" });
      } else if (!proveedorColor.valid) {
        setMensaje({ text: proveedorColor.error, tipo: "error" });
      } else {
        setMensaje({ text: "Datos incorrectos", tipo: "error" });
      }

      setMostrarMensaje(true);
    }
  };

  const guardar_proveedor = async () => {
    // console.log("idObra: ", obra.IDObra);
    // console.log("proveedor: ", proveedor.text);
    // console.log("usuario: ", "");
    // console.log("email: ", email.text);
    // console.log("clave: ", "");
    // console.log("direccion: ", direccion.text);
    // console.log("telefono: ", whatsapp.text);
    // console.log("whatsapp: ", whatsapp.text);
    // console.log("contacto: ", contacto.text);
    // console.log("rubros: ", rubros);
    // console.log("proveedorColor: ", proveedorColor);
    // console.log("proveedorColorNumero: ", proveedorColorNumero);

    if (proveedor.valid && whatsapp.valid) {
      const API_ENDPOINT = servidor + `api_proveedores.php`;

      // console.log(codigoPedido);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "GUARDAR_PROVEEDOR",
          idObra: obra.IDObra,
          proveedor: proveedor.text,
          usuario: "",
          email: email.text,
          clave: "",
          direccion: direccion.text,
          telefono: whatsapp.text,
          whatsapp: whatsapp.text,
          contacto: contacto.text,
          rubros: rubros,
          proveedorColor: proveedorColor,
          proveedorColorNumero: proveedorColorNumero,

          // productos: "VOLQUETES",
        }),
      });
      const respuesta = await datos.json();

      // console.log("respuesta: ", respuesta);

      if (respuesta.result.resultado == "OK") {
        setProveedores([...proveedores, respuesta.result.proveedores[0]]);

        setMensaje({ text: "Proveedor agregado con éxito!", tipo: "success" });

        setMostrarMensaje(true);
        setMostrarFormAgregarProveedor(false);
      } else {
        console.log("NO TRAE NADA EN guardar_proveedor()");
        // setState([]);
      }
    }
  };

  // const traer_ubicaciones = async () => {
  //   const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

  //   // console.log(codigoPedido);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "TRAER_PALABRAS_CLAVES",
  //       idObra: obra.IDObra,
  //       esUbicacion: "SI",

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log(respuesta);

  //   if (respuesta.result.palabrasClaves) {
  //     setChipData(respuesta.result.palabrasClaves);
  //   } else {
  //     console.log("NO TRAE NADA EN traer_ubicaciones()");
  //     // setState([]);
  //   }
  // };

  // const handleClick = (chip) => {
  //   // let found = chipSelected.filter(
  //   //   (chip) => chip.IDPalabraClave === idPalabraClave
  //   // );
  //   // console.log("found: ", found);

  //   // let newChip = "";
  //   // if (found.length > 0) {
  //   //   newChip = chipSelected.filter(
  //   //     (chip) => chip.IDPalabraClave !== idPalabraClave
  //   //   );
  //   //   setChipSelected(newChip);
  //   // } else {
  //   //   newChip = { IDPalabraClave: idPalabraClave };
  //   //   setChipSelected([...chipSelected, newChip]);
  //   // }

  //   console.log("chip: ", chip);
  //   setChipSelected([chip]);
  // };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #FF0000",
          width: "100%",
          paddingLeft:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "20px"
              : "",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={2}
        >
          <TextField
            id="txtProveedor"
            label="Nombre del proveedor"
            variant="standard"
            // multiline
            // rows={12}
            // fullWidth
            sx={
              screenWidth === "xs" || screenWidth === "sm"
                ? { width: "95%" }
                : { width: "50%" }
            }
            onChange={handleProveedor}
            value={proveedor.text}
            // defaultValue="Default Value"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={2}
        >
          <TextField
            id="txtContacto"
            label="Persona de contacto"
            variant="standard"
            // multiline
            // rows={12}
            // fullWidth
            sx={
              screenWidth === "xs" || screenWidth === "sm"
                ? { width: "95%" }
                : { width: "50%" }
            }
            onChange={handleContacto}
            value={contacto.text}
            // defaultValue="Default Value"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={2}
        >
          <TextField
            id="txtWhatsapp"
            label="Whatsapp"
            variant="standard"
            // multiline
            // rows={12}
            // fullWidth
            sx={
              screenWidth === "xs" || screenWidth === "sm"
                ? { width: "95%" }
                : { width: "50%" }
            }
            onChange={handleWhatsapp}
            value={whatsapp.text}
            // defaultValue="Default Value"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={3}
        >
          <SeleccionDeColor
            setColor={setProveedorColor}
            color={proveedorColor}
            setColorNumero={setProveedorColorNumero}
            colorNumero={proveedorColorNumero}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={2}
        >
          <Rubros setRubros={setRubros} rubros={rubros} />
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          &nbsp;<Button onClick={() => validarFormulario()}>Confirmar</Button>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  );
};

export default AgregarProveedor;
