import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { Grid, IconButton } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { grey, red } from "@mui/material/colors";
import ModalEliminar from "../general/ModalEliminar";
import ServidorContext from "../../contextos/ServidorContext";

const InvitadosConfig = (props) => {
  const [invitados, setInvitados] = useState([]);

  const [user, setUser] = useState([]);

  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [seccion, setSeccion] = useState("");
  const [estado, setEstado] = useState("");

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);

  useEffect(() => {
    listar_usuarios_invitados();
  }, []);

  useEffect(() => {
    listar_usuarios_invitados();
  }, [estado]);

  const listar_usuarios_invitados = async () => {
    // console.log(texto);
    // console.log(obra);
    // console.log("PASA POR listar_contenido_dashboard()");

    const API_ENDPOINT = servidor + `api_obras.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_USUARIOS_INVITADOS_A_OBRA",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.invitados) {
      //   console.log(respuesta.result.invitados);

      setInvitados(
        respuesta.result.invitados.filter(
          (filtro) => filtro.IDUsuario !== usuario.IDUsuario
        )
      );
    } else {
      setInvitados([]);

      console.log("NO TRAE NADA EN listar_usuarios_invitados()");
      // setState([]);
    }
  };

  const seleccionarInvitado = (invitado) => {
    // console.log(invitado);

    props.setInvitadoElegido(invitado);
  };

  const handleEliminarInvitado = (invitado) => {
    setUser(invitado);
    setSeccion("ELIMINAR_INVITADO");
    setOpenModalEliminar(true);
  };

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: 490, bgcolor: "background.paper" }}>
        {/* <nav aria-label="secondary mailbox folders"> */}
        <List>
          {invitados.map((invitado) => (
            <ListItem key={invitado.IDUsuario} disablePadding>
              <ListItemButton
                component="a"
                //   href="#simple-list"
                // onClick={() => seleccionarInvitado(invitado)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  // spacing={10}
                >
                  <Grid item>
                    <ListItemText
                      primary={
                        invitado.Nombre +
                        " " +
                        invitado.Apellido +
                        " (" +
                        invitado.Email +
                        ")"
                      }
                      onClick={() => seleccionarInvitado(invitado)}
                    />
                  </Grid>
                  <Grid item>
                    {/* <ListItemText primary="X" /> */}
                    <IconButton
                      aria-label="aceptar"
                      size="large"
                      onClick={() => handleEliminarInvitado(invitado)}
                    >
                      <HighlightOffOutlinedIcon sx={{ color: red[400] }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* </nav> */}
      </Box>

      {openModalEliminar ? (
        <ModalEliminar
          openModalEliminar={openModalEliminar}
          setOpenModalEliminar={setOpenModalEliminar}
          estado={estado}
          setEstado={setEstado}
          seccion={seccion}
          setSeccion={setSeccion}
          invitado={user}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default InvitadosConfig;
