import { Box, Grid, IconButton } from "@mui/material";
import React from "react";

const Logo = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          // border: "1px solid #000000",
          height: 43,
          width: 226,
          maxHeight: { xs: 43, md: 64 },
          maxWidth: { xs: 226, md: 339 },
          mt: 1,
        }}
        alt="Bimtracker"
        src="/logo-bimtracker-plus.png"
      />
    </>
  );
};

export default Logo;
