import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { desencriptar, encriptar } from "../funciones/Funciones";

const PrivilegioContext = createContext();

const PrivilegioProvider = ({ children }) => {
  let objobra = desencriptar(localStorage.getItem("objobra"));

  const [privilegios, setPrivilegios] = useState(objobra.Privilegios);

  //   useEffect(() => {
  //     localStorage.setItem("objobra", encriptar(obra));
  //   }, [obra]);

  const [mostrarAlert, setMostrarAlert] = useState(false);

  if (mostrarAlert) {
    setTimeout(() => {
      setMostrarAlert(false);
    }, 3000);
  }

  const data = {
    privilegios,
    setPrivilegios,
    mostrarAlert,
    setMostrarAlert,
  };

  return (
    <PrivilegioContext.Provider value={data}>
      {children}
    </PrivilegioContext.Provider>
  );
};

export { PrivilegioProvider };
export default PrivilegioContext;
