const enviarPosicionHaciaReactNative = (isMobile, accion, objContenido) => {
  if (isMobile && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        accion: accion,
        contenido: objContenido,
      })
    );
  }

  return 1;
};

const enviarMensajeHaciaReactNative = (isMobile, objMensaje) => {
  // let objMensaje = {
  //   codigo: "Aquí va el código del mensaje, por ejemplo: APP_VERSION",
  //   mensaje: "Aquí va el mensaje",
  // };

  if (isMobile && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(objMensaje));
  }

  return true;
};

function contieneCodigoLaEntrada(input) {
  // Esta regex busca cualquier cosa que parezca una etiqueta HTML o JavaScript
  var regex = /<[^>]*>/g;
  return regex.test(input);
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

const chequearPermisos = (privilegios, permiso) => {
  var encontrado;

  // console.log("privilegios: ", privilegios);
  if (privilegios === undefined || privilegios === null) {
    encontrado = false;
  } else {
    var filterData = privilegios.split("|");

    encontrado = false;

    filterData.map((item) => {
      // console.log("|" + item + "|");
      if (item === permiso || item === "TODOS") {
        encontrado = true;
      }
    });
  }

  return encontrado;
};

const encriptar = (toEncode) => {
  // "toEncode" puede ser un texto o un objeto

  let text =
    toEncode != null && toEncode !== "" ? btoa(JSON.stringify(toEncode)) : "";

  return text;
};

const desencriptar = (toDecode) => {
  // "toDecode" puede ser un texto o un objeto
  // console.log(toDecode != null && toDecode !== "");
  let text =
    toDecode != null && toDecode !== "" ? JSON.parse(atob(toDecode)) : "";

  return text;
};

const getLocalItem = (item) => {
  return desencriptar(localStorage.getItem(item));
};

const formatearFecha = (date, format = "yyyy-MM-dd") => {
  // console.log("date: " + date);

  // console.log("dateNew: " + dateNew);

  let dateNew;

  let formato1 = /^\d{2}\/\d{2}\/\d{4}$/;
  // let formato2 = /^[A-Z][a-z]{2} [A-Z][a-z]{2} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} .+$/;

  if (date == "HOY") {
    // console.log("PASA POR 1");
    dateNew = new Date();
  } else if (formato1.test(date)) {
    // console.log("PASA POR 2");
    let partes = date.split("/");
    let fechaObj = new Date(partes[2], partes[1] - 1, partes[0]);
    dateNew = new Date(fechaObj);
    // console.log("PASA POR 1");
    // } else if (formato2.test(date)) {
  } else {
    // const dateString = "2022-01-01 12:00:00";
    // const date = new Date(dateString.replace(" ", "T") + "-03:00");
    // console.log(date + "T12:00:00.000Z");

    // console.log("PASA POR 3");

    dateNew = new Date(date);
    // dateNew = new Date(a.FechaProgramada + "T" + a.HoraProgramada + "-03:00");

    // dateNew = new Date(date + "T12:00:00.000Z");
    // console.log("dateNew: ", dateNew);
    // console.log("PASA POR 2");
  }

  let day = dateNew.getDate();
  let month = dateNew.getMonth() + 1;
  let year = dateNew.getFullYear();

  let mesSeleccionado = month * 1;

  // console.log(date + " | " + day);

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  // console.log(`${day}/${month}/${year}`);

  let fecha = "";

  if (format == "dd/MM/yyyy") {
    fecha = `${day}/${month}/${year}`;
  } else if (format == "texto") {
    let mes = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    fecha = `${day} de ${mes[mesSeleccionado]} de ${year}`;
  } else if (format == "textoResumen") {
    let mes = [
      "",
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ];

    fecha = `${day} ${mes[mesSeleccionado]} ${year}`;
  } else {
    fecha = `${year}-${month}-${day}`;
  }

  return fecha;
};

const formatearHora = (date, format = "HH:mm") => {
  // let date = new Date();

  try {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes === 0 ? "00" : minutes;

    return hours + ":" + minutes;
  } catch (error) {
    let arr = date.split(" ");
    return arr[1];

    // if (error === "TypeError: date.getHours is not a function") {
    // } else {
    //   console.log("NO PASA");
    // }
  }
};

// const { usuario, setUsuario } = useContext(UsuarioContext);

export {
  enviarPosicionHaciaReactNative,
  enviarMensajeHaciaReactNative,
  contieneCodigoLaEntrada,
  numberWithCommas,
  randomColor,
  chequearPermisos,
  encriptar,
  desencriptar,
  formatearFecha,
  formatearHora,
  getLocalItem,
};
