import { Button, ButtonGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import ModalEliminar from "../pages/general/ModalEliminar";
import { chequearPermisos, desencriptar } from "../funciones/Funciones";
import PrivilegioContext from "../contextos/PrivilegiosContext";
import AlertSinPermisos from "./AlertSinPermisos";

const BotoneraABM = (props) => {
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [seccion, setSeccion] = useState("");

  const [estado, setEstado] = useState("");

  const {
    mostrarAlert,
    setMostrarAlert,
    privilegios,
    setPrivilegios,
  } = useContext(PrivilegioContext);

  // const [privilegios, setPrivilegios] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    // console.log(privilegios);

    if (privilegios != "") {
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setPrivilegios(objobra.Privilegios);
    }
  }, []);

  // console.log(props);

  const eliminar = () => {
    setSeccion(props.botones.seccion);

    switch (props.botones.seccion) {
      case "SETTINGS-SOCIEDAD":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_BAJA_SOCIEDAD") === true
        ) {
          setOpenModalEliminar(true);
        } else {
          setMostrarAlert(true);
        }

        break;

      case "SETTINGS-DOCUMENTO":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_BAJA_DOCUMENTO") === true
        ) {
          setOpenModalEliminar(true);
        } else {
          setMostrarAlert(true);
        }

        break;

      default:
        break;
    }
  };

  const modificar = () => {
    switch (props.botones.seccion) {
      case "SETTINGS-SOCIEDAD":
        // alert(props.botones.seccion);
        // console.log(privilegios);
        if (
          chequearPermisos(privilegios, "DOCUMENTACION_MODIF_SOCIEDAD") === true
        ) {
          navigate("/modificarSociedad");
        } else {
          setMostrarAlert(true);
        }

        break;

      case "SETTINGS-DOCUMENTO":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_MODIF_DOCUMENTO") ===
          true
        ) {
          navigate("/modificarDocumento");
        } else {
          setMostrarAlert(true);
        }

        break;

      default:
        break;
    }
  };

  return (
    <>
      <AlertSinPermisos mostrarAlert={mostrarAlert} />
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        {props.botones.alta ? (
          <Button
            color="primary"
            startIcon={<AddOutlinedIcon fontSize="inherit" />}
          >
            Agregar
          </Button>
        ) : (
          ""
        )}
        {props.botones.modif ? (
          <Button
            color="primary"
            startIcon={<EditOutlinedIcon fontSize="inherit" />}
            onClick={() => modificar()}
          >
            Modificar
          </Button>
        ) : (
          ""
        )}
        {props.botones.baja ? (
          <Button
            color="error"
            startIcon={<DeleteOutlinedIcon fontSize="inherit" />}
            onClick={() => eliminar()}
          >
            Eliminar
          </Button>
        ) : (
          ""
        )}
      </ButtonGroup>

      {openModalEliminar ? (
        <ModalEliminar
          openModalEliminar={openModalEliminar}
          setOpenModalEliminar={setOpenModalEliminar}
          estado={estado}
          setEstado={setEstado}
          seccion={seccion}
          setSeccion={setSeccion}
        />
      ) : (
        ""
      )}

      {estado === "BAJA_OK" ? navigate("/documentacion") : ""}
    </>
  );
};

export default BotoneraABM;
