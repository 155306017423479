import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const VolverALogin = (props) => {
  let navigate = useNavigate();

  const handleBack = () => {
    switch (props.pageBack) {
      case "FORMULARIO_LOGIN":
        props.setMostrar("FORMULARIO_LOGIN");
        navigate("/");
        break;

      case "FORMULARIO_REGISTRO":
        props.setMostrar("FORMULARIO_REGISTRO");
        navigate("/registro");
        break;

      case "FORMULARIO_SELECCIONAR_MODULOS":
        props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
        navigate("/seleccionarModulos");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        // onClick={handleAltaPedido}
        aria-label="volver"
        size="large"
      >
        <ArrowBackIcon
          // fontSize="large"
          fontSize="inherit"
          // sx={{ color: "#FFFFFF" }}
          onClick={() => handleBack()}
        />
      </IconButton>
    </>
  );
};

export default VolverALogin;
