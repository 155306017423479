import * as React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const TablaGeneral = ({ data, headerNames, columns }) => {
  // console.log("data", data);

  // Si no se especifican las columnas a mostrar, mostrar todas las columnas
  //   const headers = columns || Object.keys(data[0]);
  const headers = Object.keys(data[0]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {/* {data &&
                data.length > 0 &&
                data.encabezados.map((encabezado) => (
                  <TableCell align={encabezado.align}>
                    {encabezado.titulo}
                  </TableCell>
                ))} */}

              {headers.map((header) => (
                <TableCell key={header}>{headerNames[header]}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {data &&
              data.length > 0 &&
              data.filas.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
            
                  {row.campo.map((camp) => {
                    <TableCell align={camp.align}>{camp.fecha}</TableCell>;
                  })}
                </TableRow>
              ))} */}

            {data.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header) => (
                  <TableCell key={header}>{row[header]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br /> <br />
    </div>
  );
};

export default TablaGeneral;
