import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ListaInvitadosConfig from "./ListaInvitadosConfig";
import MensajesContext from "../../contextos/MensajesContext";
import PrivilegiosConfig from "./PrivilegiosConfig";

const EquipoConfig = () => {
  const initInvitado = { Nombre: "", Apellido: "" };

  const [activeStep, setActiveStep] = React.useState(0);
  const [invitadoElegido, setInvitadoElegido] = useState(initInvitado);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const steps = [
    {
      label:
        "SELECCIONE EL MIEMBRO DEL EQUIPO: " +
        invitadoElegido.Nombre.toUpperCase() +
        " " +
        invitadoElegido.Apellido.toUpperCase(),
      description: (
        <ListaInvitadosConfig setInvitadoElegido={setInvitadoElegido} />
      ),
    },
    {
      label: "OBRAS",
      description: (
        <PrivilegiosConfig
          seccion="OBRAS"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
        // <ObrasConfig
        //   invitadoElegido={invitadoElegido}
        //   setInvitadoElegido={setInvitadoElegido}
        // />
      ),
    },
    {
      label: "PLANIFICACIÓN",
      description: (
        <PrivilegiosConfig
          seccion="PLANIFICACION"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "CONTROL DE AVANCE",
      description: (
        <PrivilegiosConfig
          seccion="CONTROL_DE_AVANCE"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "PEDIDOS",
      description: (
        <PrivilegiosConfig
          seccion="PEDIDOS"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "ORDENES DE SERVICIO",
      description: (
        <PrivilegiosConfig
          seccion="ORDENES_DE_SERVICIO"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },

    {
      label: "MATERIALES",
      description: (
        <PrivilegiosConfig
          seccion="MATERIALES"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "VOLQUETES",
      description: (
        <PrivilegiosConfig
          seccion="VOLQUETES"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "PRESUPUESTOS",
      description: (
        <PrivilegiosConfig
          seccion="PRESUPUESTOS"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
    {
      label: "DOCUMENTOS",
      description: (
        <PrivilegiosConfig
          seccion="DOCUMENTACION"
          invitadoElegido={invitadoElegido}
          setInvitadoElegido={setInvitadoElegido}
        />
      ),
    },
  ];

  const handleNext = () => {
    if (invitadoElegido.IDUsuario != undefined) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setMensaje({
        text: "Seleccione el invitado antes de continuar",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Box sx={{ maxWidth: 600 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">último paso</Typography>
              //   ) : null
              // }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={
                        index === steps.length - 1 ? handleReset : handleNext
                      }
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Finalizar" : "Continuar"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Volver
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default EquipoConfig;
