import React from "react";
import { createContext, useState } from "react";

const HySContext = createContext();

const HySProvider = ({ children }) => {
  const [hys, setHyS] = useState([]);
  const [subcontratos, setSubcontratos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);
  const [modificar, setModificar] = useState({});

  const data = {
    hys,
    setHyS,
    subcontratos,
    setSubcontratos,
    documentos,
    setDocumentos,
    asignaciones,
    setAsignaciones,
    solicitudes,
    setSolicitudes,
    modificar,
    setModificar,
  };

  return <HySContext.Provider value={data}>{children}</HySContext.Provider>;
};

export { HySProvider };
export default HySContext;
