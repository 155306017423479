import { Badge, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  desencriptar,
  encriptar,
  formatearFecha,
} from "../../funciones/Funciones";
import ObraContext from "../../contextos/ObraContext";
import { useContext } from "react";
import ServidorContext from "../../contextos/ServidorContext";

const EnviarPorWhatsapp = ({
  setActualizarEnvioPorWP,
  destinatario,
  mensajeWP,
  parametro,
  index,
  setBoton,
  boton,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);

  // const [boton, setBoton] = useState({
  //   icono: "SEND",
  //   cantidadEnviosPorWhatsapp: destinatario.CantidadEnviosPorWhatsapp,
  //   cantidadRespuestasPorWhatsapp: destinatario.CantidadRespuestasPorWhatsapp,
  //   texto: ` ${destinatario.Nombre} ${destinatario.Apellido}`,
  // });

  const [tipoMensajeWP, setTipoMensajeWP] = useState(mensajeWP.ClaveDeMensaje);
  const [cantidad, setCantidad] = useState(0);
  // const [parametro, setParametro] = useState(
  //   mensajeWP.IDOrdenDeServicio + "|" + destinatario.IDUsuarioDestinatario

  //   // encriptar(
  //   //   mensajeWP.IDOrdenDeServicio + "|" + destinatario.IDUsuarioDestinatario
  //   // )
  // );

  // console.log("param: ", parametro);

  // if (mensajeWP.IDOrdenDeServicio == "170") {
  //   alert(
  //     mensajeWP.IDOrdenDeServicio + "|" + destinatario.IDUsuarioDestinatario
  //   );
  // }

  // if (mensajeWP.IDOrdenDeServicio == "170") {
  //   alert(destinatario.CantidadEnviosPorWhatsapp);
  // }

  // useEffect(() => {
  //   // console.log("destinatario: ", destinatario);
  //   // console.log("mensajeWP: ", mensajeWP);

  //   // let param = encriptar({
  //   //   idOrdenDeServicio: mensajeWP.IDOrdenDeServicio,
  //   //   idUsuario: idUsuario,
  //   // });
  //   // setParametro(param);

  //   // console.log("D: ", destinatario);

  //   if (mensajeWP.ClaveDeMensaje === "ENVIAR_ORDEN_DE_SERVICIO") {
  //     console.log("C: ", destinatario.CantidadEnviosPorWhatsapp);
  //     setCantidad(destinatario.CantidadEnviosPorWhatsapp);
  //   } else if (mensajeWP.ClaveDeMensaje === "FINALIZAR_ORDEN_DE_SERVICIO") {
  //     setCantidad(destinatario.CantidadRespuestasPorWhatsapp);
  //   }

  //   // if (mensajeWP.IDOrdenDeServicio == "170") {
  //   //   console.log("NOMBRE: ", boton.texto);
  //   //   // console.log("APELLIDO: ", destinatario.Apellido);
  //   // }
  // }, []);

  // useEffect(() => {
  //   // if (mensajeWP.IDOrdenDeServicio == 170) {
  //   //   alert(destinatario.Nombre + " CANT: " + cantidad);
  //   // }

  //   if (mensajeWP.IDOrdenDeServicio == 170) {
  //     alert("CANT: ", boton.cantidadEnviosPorWhatsapp);
  //   }
  // }, [cantidad]);

  // useEffect(() => {
  //   console.log("TEXTO: ", boton.texto);
  // }, [boton]);

  // useEffect(() => {
  //   // console.log("cantidad: ", cantidad);

  //   if (mensajeWP.IDOrdenDeServicio == 170) {
  //     alert("CAMBIA: ", cantidad);
  //   }
  // }, [cantidad]);

  // console.log("NUMERO TEL: " + numeroTel);
  // console.log("tipo: " + tipo);

  //   useEffect(() => {
  //     setBoton({
  //       icono: "SEND",
  //       texto: ` ${destinatario.Nombre} ${destinatario.Apellido}`,
  //     });
  //   }, []);

  const URL = "https://wa.me";

  //   let num = "5491158481111";
  let numeroTel = destinatario.Whatsapp.replace(/[^\w\s]/gi, "").replace(
    / /g,
    ""
  );

  let url = `${URL}/${numeroTel}`;

  let fecha = new Date();

  if (mensajeWP) {
    let msg = "";

    if (mensajeWP.ClaveDeMensaje === "ENVIAR_ORDEN_DE_SERVICIO") {
      msg +=
        "Hola " +
        destinatario.Nombre +
        ", \nPor favor resolver la siguiente tarea en *" +
        obra.Obra +
        "*: \n\n";

      msg += "Nro. Tarea: " + mensajeWP.IDOrdenDeServicio + "\n";

      msg +=
        "Fecha: " +
        formatearFecha(fecha, "dd/MM/yyyy") +
        " " +
        fecha.getHours() +
        ":" +
        fecha.getMinutes() +
        "\n";
      msg += "Prioridad: " + mensajeWP.Prioridad + "\n";
      msg += "Ubicación: " + mensajeWP.Ubicacion + "\n\n";

      msg += mensajeWP.Descripcion + "\n\n";

      msg += "Para ver las fotos ingresá al siguiente link: \n";
      msg +=
        "https://plus.bimtracker.com/ordenesDeServicio/" + parametro + "\n\n";
    } else if (mensajeWP.ClaveDeMensaje === "FINALIZAR_ORDEN_DE_SERVICIO") {
      msg +=
        destinatario.Nombre +
        ", \nDoy por *FINALIZADA* la tarea número " +
        mensajeWP.IDOrdenDeServicio +
        " en *" +
        obra.Obra +
        "*: \n\n\n";

      msg +=
        "Fecha: " +
        formatearFecha(fecha, "dd/MM/yyyy") +
        " " +
        fecha.getHours() +
        ":" +
        fecha.getMinutes() +
        "\n";
      msg += "Prioridad: " + mensajeWP.Prioridad + "\n\n";

      msg += mensajeWP.Descripcion + "\n\n";
    }

    msg += "\n";
    msg += "\n";
    // msg += "codigoPedido: " + codigoPedido + "\n";
    // msg += "\n";
    // msg += "\n";

    msg += "Respuesta enviada desde https://plus.bimtracker.com";

    url += `?text=${encodeURI(msg)}`;
  }

  const handleClick = () => {
    if (mensajeWP.ClaveDeMensaje === "ENVIAR_ORDEN_DE_SERVICIO") {
      setCantidad(
        (parseInt(destinatario.CantidadEnviosPorWhatsapp) + 1).toString()
      );

      guardar_envio_por_whatsapp();
    } else if (mensajeWP.ClaveDeMensaje === "FINALIZAR_ORDEN_DE_SERVICIO") {
      setCantidad(
        (parseInt(destinatario.CantidadRespuestasPorWhatsapp) + 1).toString()
      );

      guardar_respuesta_por_whatsapp();
    }

    window.open(url);
  };

  //   return index > 0
  //     ? ` - ${destinatario.Nombre} ${destinatario.Apellido}`
  //     : ` ${destinatario.Nombre} ${destinatario.Apellido}`;

  const guardar_envio_por_whatsapp = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_ENVIO_POR_WHATSAPP",
        idOrdenDeServicioDestinatario:
          destinatario.IDOrdenDeServicioDestinatario,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.respuesta == "OK") {
      setActualizarEnvioPorWP(destinatario.IDOrdenDeServicioDestinatario);

      setBoton({
        icono: "SEND",
        cantidadEnviosPorWhatsapp: (
          parseInt(destinatario.CantidadEnviosPorWhatsapp) + 1
        ).toString(),
        texto: ` ${destinatario.Nombre} ${destinatario.Apellido}`,
      });
    } else {
      console.log("NO TRAE NADA EN guardar_envio_por_whatsapp()");
      // setState([]);
    }
  };

  const guardar_respuesta_por_whatsapp = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_RESPUESTA_POR_WHATSAPP",
        idOrdenDeServicioDestinatario:
          destinatario.IDOrdenDeServicioDestinatario,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.respuesta == "OK") {
      setActualizarEnvioPorWP(destinatario.IDOrdenDeServicioDestinatario);

      setBoton({
        icono: "SEND",
        cantidadRespuestasPorWhatsapp: (
          parseInt(destinatario.CantidadRespuestasPorWhatsapp) + 1
        ).toString(),
        texto: ` ${destinatario.Nombre} ${destinatario.Apellido}`,
      });
    } else {
      console.log("NO TRAE NADA EN guardar_respuesta_por_whatsapp()");
      // setState([]);
    }
  };

  {
    // console.log("Clave: ", mensajeWP.ClaveDeMensaje);
    // console.log("Resp: ", destinatario.CantidadRespuestasPorWhatsapp);
  }
  return (
    <>
      {index > 0 ? " - " : ""}
      <Button
        // variant="contained"
        color={destinatario.Estado === "PENDIENTE" ? "primary" : "success"}
        endIcon={
          boton.icono === "SEND" &&
          ((tipoMensajeWP === "ENVIAR_ORDEN_DE_SERVICIO" &&
            boton.cantidadEnviosPorWhatsapp == 0) ||
            (tipoMensajeWP === "FINALIZAR_ORDEN_DE_SERVICIO" &&
              boton.cantidadRespuestasPorWhatsapp == 0)) ? (
            <SendIcon />
          ) : (
            <>
              {/* {mensajeWP.IDOrdenDeServicio == 170
                ? alert("CANTIDAD: ", cantidad)
                : // alert(
                  //     destinatario.Nombre + ": " + boton.cantidadEnviosPorWhatsapp
                  //     // destinatario.CantidadEnviosPorWhatsapp
                  //   )
                  ""} */}
              <Badge
                badgeContent={
                  // cantidad
                  mensajeWP.ClaveDeMensaje === "ENVIAR_ORDEN_DE_SERVICIO"
                    ? destinatario.CantidadEnviosPorWhatsapp
                    : mensajeWP.ClaveDeMensaje === "FINALIZAR_ORDEN_DE_SERVICIO"
                    ? destinatario.CantidadRespuestasPorWhatsapp
                    : ""
                }
                color={
                  destinatario.Estado === "PENDIENTE" ? "primary" : "success"
                }
              >
                <WhatsAppIcon
                  color={
                    destinatario.Estado === "PENDIENTE" ? "primary" : "success"
                  }
                />
              </Badge>
              &nbsp;
            </>
          )
        }
        onClick={(e) => handleClick()}
      >
        {boton.texto}
      </Button>
    </>
  );
};

export default EnviarPorWhatsapp;
