import {
  FormControl,
  FormControlLabel,
  Grid,
  ImageList,
  ImageListItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ModalPicture from "./ModalPicture";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import EnviarPorWhatsapp from "./EnviarPorWhatsapp";
import ObraContext from "../../contextos/ObraContext";
import { desencriptar } from "../../funciones/Funciones";
import ServidorContext from "../../contextos/ServidorContext";

const PageDetallePublico = (props) => {
  const [ordenDeServicio, setOrdenDeServicio] = useState(null);
  //   const [idOrden, setIdOrden] = useState(null);
  const { id } = useParams();

  const [paramIdOrdenDeServicio, setParamIdOrdenDeServicio] = useState(null);
  const [paramIdUsuario, setParamIdUsuario] = useState(null);

  const [isExpand, setIsExpand] = useState(false);
  const [imagen, setImagen] = useState("");

  const [ods, setOds] = useState([]);
  const [estado, setEstado] = useState("PENDIENTE");
  const [mostrarDestinatarios, setMostrarDestinatarios] = useState(false);

  const [mensajeWP, setMensajeWP] = useState("");
  const [actualizarEnvioPorWP, setActualizarEnvioPorWP] = useState(0);
  const [actualuzarODS, setActualuzarODS] = useState(false);

  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);

  const {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  } = useContext(MensajesContext);

  const { isMobile, screenWidth } = useContext(EstilosContext);

  const showModal = (img) => {
    setIsExpand(true);
    setImagen(img);
    setMostrarModal({ ...mostrarModal, mostrar: true });
  };

  useEffect(() => {
    props.setTitulo("Detalle de Ordenes de servicio");
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    // alert(desencriptar(id));
    // console.log("modal: ", mostrarModal.mostrar);
    setMostrarModal({ ...mostrarModal, mostrar: false });
  }, []);

  //   const history = useHistory();

  useEffect(() => {
    // console.log("params: ", id);
    let parametros = desencriptar(id);

    // console.log("parametros: ", parametros);
    let arrParams = parametros.split("|");

    const idODS = arrParams[0];
    const idUsu = arrParams[1];

    // console.log("idODS: ", idODS);
    // console.log("idUsu: ", idUsu);

    setParamIdOrdenDeServicio(idODS);
    setParamIdUsuario(idUsu);
  }, [id, actualizarEnvioPorWP]);

  useEffect(() => {
    if (paramIdOrdenDeServicio && paramIdUsuario) {
      traer_detalle_orden_de_servicio();
    }
  }, [paramIdOrdenDeServicio, paramIdUsuario]);

  useEffect(() => {
    if (estado === "PENDIENTE") {
      setMostrarDestinatarios(false);
    } else {
      setMostrarDestinatarios(true);
    }

    if (paramIdOrdenDeServicio && paramIdUsuario) {
      actualizar_estado();
    }
    // console.log("estado: ", estado);
  }, [estado]);

  const handleChangeEstado = (idEstado) => {
    // console.log("checked: " + idEstado);

    // console.log("ESTADO: ", estado);

    setEstado(idEstado);
  };

  const actualizar_estado = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_ESTADO_EN_DETALLE_PUBLICO",
        idOrdenDeServicio: paramIdOrdenDeServicio,
        idUsuario: paramIdUsuario,
        estado: estado === "FINALIZADO" ? "A_CONTROLAR" : "PENDIENTE",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado == "OK") {
      // console.log("Estado actualizado: ", respuesta.result.resultado);
    } else {
      console.log("NO TRAE NADA EN actualizar_estado()");
      // setState([]);
    }
  };

  const traer_detalle_orden_de_servicio = async () => {
    const API_ENDPOINT = servidor + `api_ordenesDeServicio.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_DETALLE_PUBLICO_ORDEN_DE_SERVICIO",
        idOrdenDeServicio: paramIdOrdenDeServicio,
        idUsuario: paramIdUsuario,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.orden) {
      //   console.log("respuesta: ", respuesta.result.orden[0]);

      setObra(respuesta.result.orden[0].Obra[0]);
      setOrdenDeServicio(respuesta.result.orden[0]);
    } else {
      console.log("NO TRAE NADA EN traer_detalle_orden_de_servicio()");
      // setState([]);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      {ordenDeServicio ? (
        <>
          <Grid
            container
            // wrap="nowrap"
            // spacing={2}
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            justifyContent="space-between"
            style={{
              fontSize: "20px",
              border: "2px solid #BBBBBB",
              borderRadius: "10px",
              paddingBottom: "10px",
            }}
          >
            {/* <Grid item>
          <Avatar>{props.pedido.idPedido}</Avatar>
        </Grid> */}
            <Grid item sm={12} style={{ fontSize: "20px", padding: "10px" }}>
              <strong>
                Orden de servicio #{ordenDeServicio.IDOrdenDeServicio} -{" "}
                <span
                  style={{
                    color:
                      ordenDeServicio.Prioridad === "ALTA"
                        ? "red"
                        : ordenDeServicio.Prioridad === "MEDIA"
                        ? "orange"
                        : "green",
                  }}
                >
                  PRIORIDAD {ordenDeServicio.Prioridad}
                </span>
              </strong>
            </Grid>

            <Grid item sm={12} style={{ padding: "10px" }}>
              {ordenDeServicio.Descripcion}
            </Grid>
          </Grid>

          <Grid
            container
            // wrap="nowrap"
            // spacing={2}
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            justifyContent="space-between"
            style={{
              fontSize: "20px",
              marginTop: "40px",
              border: "2px solid #BBBBBB",
              borderRadius: "10px",
              paddingBottom: "10px",
            }}
          >
            {/* <Grid item>
          <Avatar>{props.pedido.idPedido}</Avatar>
        </Grid> */}
            <Grid item sm={12} style={{ fontSize: "20px", padding: "10px" }}>
              <strong>Ubicación: </strong>
              {ordenDeServicio.Ubicacion}
            </Grid>
            <Grid item sm={12} style={{ fontSize: "20px", padding: "10px" }}>
              <strong>Palabras claves: </strong>
              {/* <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              > */}
              <span
                style={{
                  color: "GrayText",
                }}
              >
                {ordenDeServicio.PalabrasClaves.length > 0 &&
                  ordenDeServicio.PalabrasClaves.map((item, index) =>
                    index === 0 ? item.PalabraClave : ", " + item.PalabraClave
                  )}
              </span>
              {/* </Typography> */}
            </Grid>
          </Grid>

          <Grid
            container
            // wrap="nowrap"
            // spacing={2}
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            justifyContent="space-between"
            style={{
              fontSize: "20px",
              marginTop: "40px",
              border: "2px solid #BBBBBB",
              borderRadius: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent:
                  screenWidth === "xs" ||
                  screenWidth === "sm" ||
                  screenWidth === "md" ||
                  screenWidth === "lg" ||
                  screenWidth === "xl"
                    ? "left"
                    : "center",
                alignItems: "center",
                //   border: "1px solid #CCCCCC",
              }}
            >
              <FormControl sx={{ pl: 1 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="PENDIENTE"
                    key="PENDIENTE"
                    control={
                      <Radio
                        // checked={index === 0}
                        icon={
                          <RadioButtonUncheckedOutlinedIcon
                            sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                          />
                        }
                        onChange={() => handleChangeEstado("PENDIENTE")}
                        checked={estado === "PENDIENTE"}
                      />
                    }
                    label="PENDIENTE"
                  />

                  <FormControlLabel
                    value="FINALIZADO"
                    key="FINALIZADO"
                    control={
                      <Radio
                        // checked={index === 0}
                        icon={
                          <RadioButtonUncheckedOutlinedIcon
                            sx={{ fontSize: 40 /*color: "#FF0000"*/ }}
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{ fontSize: 40 /*color: "#21AF19"*/ }}
                          />
                        }
                        onChange={() => handleChangeEstado("FINALIZADO")}
                        checked={estado === "FINALIZADO"}
                      />
                    }
                    label="FINALIZADO"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {mostrarDestinatarios ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent:
                    screenWidth === "xs" ||
                    screenWidth === "sm" ||
                    screenWidth === "md" ||
                    screenWidth === "lg" ||
                    screenWidth === "xl"
                      ? "left"
                      : "center",
                  alignItems: "center",
                  //   border: "1px solid #CCCCCC",
                }}
              >
                <span>
                  {ordenDeServicio.Destinatarios &&
                    ordenDeServicio.Destinatarios.map((dest, index) =>
                      ordenDeServicio.EstadoDeLaOrden === "PENDIENTE" ? (
                        <EnviarPorWhatsapp
                          setActualizarEnvioPorWP={setActualizarEnvioPorWP}
                          destinatario={dest}
                          mensajeWP={{
                            IDOrdenDeServicio:
                              ordenDeServicio.IDOrdenDeServicio,
                            Prioridad: ordenDeServicio.Prioridad,
                            Descripcion: ordenDeServicio.Descripcion,
                            ClaveDeMensaje: "FINALIZAR_ORDEN_DE_SERVICIO",
                          }}
                          index={index}
                          key={index}
                        />
                      ) : index > 0 ? (
                        ` - ${dest.Nombre} ${dest.Apellido}`
                      ) : (
                        ` ${dest.Nombre} ${dest.Apellido}`
                      )
                    )}
                </span>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            container
            // wrap="nowrap"
            // spacing={2}
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            justifyContent="space-between"
            style={{
              fontSize: "20px",
              marginTop: "40px",
              border: "2px solid #BBBBBB",
              borderRadius: "10px",
            }}
          >
            <Grid item sm={12} style={{ paddingLeft: "20px" }}>
              <ImageList
                sx={{ width: isMobile ? "90%" : "1024px" }}
                cols={isMobile ? 1 : 3}
              >
                {ordenDeServicio &&
                  ordenDeServicio.Archivos.map((item) => (
                    <ImageListItem key={item.IDOrdenDeServicioArchivo}>
                      <img
                        // src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={item.Archivo}
                        alt={item.TipoDeArchivo}
                        loading="lazy"
                        onClick={() => showModal(item.Archivo)}
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
              <ModalPicture
                mostrarModal={mostrarModal}
                setMostrarModal={setMostrarModal}
                srcImagen={imagen}
                isMobile={isMobile}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PageDetallePublico;
