import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";

const traer_color = (color, numero) => {
  let ret = "";

  //   alert(color);

  switch (color) {
    case "red":
      ret = red[numero];
      break;

    case "pink":
      ret = pink[numero];
      break;

    case "purple":
      ret = purple[numero];
      break;

    case "deepPurple":
      ret = deepPurple[numero];
      break;

    case "indigo":
      ret = indigo[numero];
      break;

    case "blue":
      ret = blue[numero];
      break;

    case "lightBlue":
      ret = lightBlue[numero];
      break;

    case "cyan":
      ret = cyan[numero];
      break;
    case "teal":
      ret = teal[numero];
      break;
    case "green":
      ret = green[numero];
      break;
    case "lightGreen":
      ret = lightGreen[numero];
      break;
    case "lime":
      ret = lime[numero];
      break;
    case "yellow":
      ret = yellow[numero];
      break;
    case "amber":
      ret = amber[numero];
      break;
    case "orange":
      ret = orange[numero];
      break;
    case "deepOrange":
      ret = deepOrange[numero];
      break;
    case "brown":
      ret = brown[numero];
      break;
    case "grey":
      ret = grey[numero];
      break;
    case "blueGrey":
      ret = blueGrey[numero];
      break;
    default:
      ret = blue[numero];
      break;
  }

  // console.log(ret);

  return ret;
};

const array_colores = () => {
  let arrColores = [
    "red",
    "pink",
    "purple",
    "deepPurple",
    "indigo",
    "blue",
    "lightBlue",
    "cyan",
    "teal",
    "green",
    "lightGreen",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deepOrange",
    "brown",
    "grey",
    "blueGrey",
  ];

  return arrColores;
};

export { traer_color, array_colores };
