import React, { useContext, useEffect, useState } from "react";

import EstilosContext from "../../contextos/EstilosContext";
import Item from "../../components/Item";
import { desencriptar } from "../../funciones/Funciones";
import { Box, Button, Grid, Typography } from "@mui/material";
import CartelPlanoActual from "./PagePlano/CartelPlanoActual";
import Imagen from "./PagePlano/Imagen";
import BoxFiltros from "./PagePlano/BoxFiltros";
import ObraContext from "../../contextos/ObraContext";
import Sectores from "./PagePlano/Sectores";
import Departamentos from "./PagePlano/Departamentos";
import PalabrasClaves from "../ordenesDeServicio/PalabrasClaves";
import PageAgregarPalabrasClaves from "../ordenesDeServicio/PageAgregarPalabrasClaves";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import BoxUnidadesFuncionales from "./PagePlano/BoxUnidadesFuncionales";
import BoxSectores from "./PagePlano/BoxSectores";
import { traer_color } from "../../colores/Colores";
import ServidorContext from "../../contextos/ServidorContext";
import ListadoDeTareas from "./PagePlano/ListadoDeTareas";
import BoxGrupos from "./PagePlano/BoxGrupos";
import TituloGrupos from "./PagePlano/TituloGrupos";
import ControlDeAvanceContext from "../../contextos/ControlDeAvanceContext";

const PagePlano = () => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { obra } = useContext(ObraContext);
  const {
    controlDeAvance,
    setControlDeAvance,
    tareasAsignadas,
    setTareasAsignadas,
    tareasFiltradas,
    setTareasFiltradas,
    tareasFiltradasCoincidentes,
    setTareasFiltradasCoincidentes,
  } = useContext(ControlDeAvanceContext);

  const colorTitulo = traer_color("blue", 600);
  const [bgColorTitulo, setBgColorTitulo] = useState(colorTitulo);

  const color = traer_color("blue", 50);
  const [bgColor, setBgColor] = useState(color);

  const plano = desencriptar(localStorage.getItem("plano"));

  const [item, setItem] = useState(desencriptar(localStorage.getItem("plano")));

  const [ufSelected, setUfSelected] = useState("");
  const [localSelected, setLocalSelected] = useState("");
  const [grupoSelected, setGrupoSelected] = useState("");
  // const [tareasAsignadas, setTareasAsignadas] = useState([]);
  // const [tareasFiltradas, setTareasFiltradas] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [gruposNoAsignados, setGruposNoAsignados] = useState([]);

  useEffect(() => {
    // console.log("plano 1: ", plano);

    setControlDeAvance({
      ...controlDeAvance,
      IDPlano: plano.IDPlano,
      Plano: plano.Plano,
    });

    // traer_grupos();
    // console.log("obra: ", obra);

    // return () => {
    //   localStorage.removeItem("plano");
    // };
  }, []);

  useEffect(() => {
    traer_tareas_asignadas();
  }, []);

  useEffect(() => {
    // console.log("tareasAsignadas: ", tareasAsignadas);
    // console.log("tareasFiltradas: ", tareasFiltradas);
    // console.log("GRUPOS:::: ", grupos);

    if (grupos.length > 0) {
      const gruposAsignadosIDs = tareasAsignadas.map((tarea) => tarea.IDGrupo);
      // console.log("gruposAsignadosIDs: ", gruposAsignadosIDs);

      const gruposSinAsignar = grupos.filter(
        (grupo) => !gruposAsignadosIDs.includes(grupo.IDGrupo)
      );

      // console.log("gruposSinAsignar: ", gruposSinAsignar);

      setGruposNoAsignados(gruposSinAsignar);
    } else {
      setGruposNoAsignados([]);
    }

    const coincidencias = tareasFiltradas.filter((tareaFiltrada) =>
      grupos.some((grupo) => grupo.IDGrupo === tareaFiltrada.IDGrupo)
    );

    // console.log("coincidencias: ", coincidencias);

    // console.log("GRU:::: ", grupos);

    setTareasFiltradasCoincidentes(coincidencias);
  }, [grupos, tareasAsignadas, tareasFiltradas]);

  // useEffect(() => {
  //   traer_sectores();
  // }, [ufSelected]);

  // useEffect(() => {
  //   console.log("PASAAAAAAA!!!!: ", gruposNoAsignados);
  // }, [gruposNoAsignados]);

  useEffect(() => {
    // console.log("item: ", item);
    // console.log("UF: ", ufSelected);
    // console.log("sectorSelected: ", localSelected);
    // console.log("grupoSelcted: ", grupoSelected);

    if (
      item &&
      ufSelected !== "" &&
      localSelected !== "" &&
      grupoSelected !== ""
    ) {
      // console.log("PASA POR 1");
      traer_grupos();
      filtrar_tareas();
    }
  }, [ufSelected, localSelected, grupoSelected]);

  useEffect(() => {
    // console.log("ufSelected: ", ufSelected);

    // console.log("controlDeAvance: ", controlDeAvance);

    setControlDeAvance({
      ...controlDeAvance,
      IDDepartamento: ufSelected.IDDepartamento,
      Espacio: ufSelected.Departamento,
      IDSector: "",
      Sector: "",
      IDGrupo: "",
      Grupo: "",
    });

    setLocalSelected("");
    setGrupos([]);

    // console.log("localSelected.IDSector: ", localSelected.IDSector);
    // console.log("PASA POR UFSELECTED");

    if (localSelected.IDSector !== "" && localSelected.IDSector !== undefined) {
      traer_grupos();
      filtrar_tareas();
    }
  }, [ufSelected]);

  useEffect(() => {
    // console.log("localSelected: ", localSelected);
    setControlDeAvance({
      ...controlDeAvance,
      IDSector: localSelected.IDSector,
      Sector: localSelected.Sector,
    });

    if (ufSelected.IDDepartamento !== "") {
      traer_grupos();
      filtrar_tareas();
    }
  }, [localSelected]);

  useEffect(() => {
    // console.log(controlDeAvance.IDGrupo + " === " + grupoSelected.IDGrupo);

    setControlDeAvance({
      ...controlDeAvance,
      IDGrupo: grupoSelected.IDGrupo,
      Grupo: grupoSelected.Grupo,
    });

    if (ufSelected.IDDepartamento !== "" && localSelected.IDSector !== "") {
      traer_grupos();
      filtrar_tareas();
    }
  }, [grupoSelected]);

  useEffect(() => {
    if (ufSelected.IDDepartamento !== "") {
      traer_grupos();
    }

    filtrar_tareas();
    // console.log("tareasAsignadas: ", tareasAsignadas);
    // console.log("ACTUALIZA");
  }, [tareasAsignadas]);

  const traer_tareas_asignadas = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("idPlano: ", item.IDPlano);
    // console.log("idDepartamento: ", ufSelected.IDDepartamento);
    // console.log("idSector: ", localSelected.IDSector);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_TAREAS_ASIGNADAS_A_PLANO",
        // idPlano: item.IDPlano,
        // idDepartamento: ufSelected.IDDepartamento,
        // idSector: localSelected.IDSector,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("tareasAsignadas: ", respuesta.result.tareasAsignadas);

    if (respuesta.result.tareasAsignadas) {
      // console.log("PASAAA");
      setTareasAsignadas(respuesta.result.tareasAsignadas);
    } else {
      console.log("NO TRAE NADA EN traer_tareas_asignadas()");
      setTareasAsignadas([]);
      // setGruposNoAsignados(grupos);
    }
  };

  const filtrar_tareas = () => {
    // console.log("FILTRA DESDE ADENTRO");
    if (ufSelected !== "" && localSelected !== "") {
      // console.log("tareasAsignadas ANTES: ", tareasAsignadas);
      // console.log("ufSelected: ", ufSelected);
      // console.log("localSelected: ", localSelected);
      // console.log("grupoSelected: ", grupoSelected);

      let filtro = tareasAsignadas;

      // console.log("FILTRO 1: ", filtro);

      if (grupoSelected !== "") {
        filtro = filtro.filter(
          (grupo) => Number(grupo.IDGrupo) === Number(grupoSelected.IDGrupo)
        );
      }

      filtro = filtro.map((grupo) => {
        let tareasFiltradas = grupo.Tareas.filter(
          (tarea) =>
            tarea.IDPlano === item.IDPlano &&
            tarea.IDDepartamento === ufSelected.IDDepartamento &&
            tarea.IDSector === localSelected.IDSector
        );
        return { ...grupo, Tareas: tareasFiltradas };
      });

      // console.log("FILTRO 2: ", filtro);

      // let filteredData = filtro.filter((grupo) => {
      //   return grupo.Tareas.some((tarea) => tarea.IDPlano === item.IDPlano);
      // });

      let filteredData = filtro.filter((grupo) => {
        return grupo.IDPlano === item.IDPlano;
      });

      // console.log("FILTRO 3: ", filteredData);

      setTareasFiltradas(filteredData);

      // console.log("tareasAsignadas DESPUES: ", filteredData);
    } else {
      setTareasFiltradas([]);
    }
  };

  const traer_grupos = async () => {
    const API_ENDPOINT = servidor + `api_grupos.php`;

    // console.log("idPlano: ", item.IDPlano);
    // console.log("idDepartamento: ", ufSelected.IDDepartamento);
    // console.log("idSector: ", localSelected.IDSector);

    // console.log(
    //   "query: ",
    //   JSON.stringify({
    //     operacion: "TRAER_GRUPOS",
    //     idObra: obra.IDObra,
    //     idDepartamento: ufSelected.IDDepartamento,
    //     idSector: localSelected.IDSector,

    //     // productos: "VOLQUETES",
    //   })
    // );

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_GRUPOS",
        idObra: obra.IDObra,
        idDepartamento: ufSelected.IDDepartamento,
        idSector: localSelected.IDSector,
        idPlano: plano.IDPlano,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaGrupos 1: ", respuesta.result.listaGrupos);
    // console.log("listaGruposEnCombo 1: ", respuesta.result.listaGruposEnCombo);

    if (respuesta.result.listaGrupos) {
      setGrupos(respuesta.result.listaGrupos);
    } else {
      setGrupos([]);
      console.log("NO TRAE NADA EN traer_grupos()");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          m: 1,
          mt: 2,
          // border: 1,
          height: "auto",
        }}
      >
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item style={{ width: "100%" }}>
            <CartelPlanoActual
              screenWidth={screenWidth}
              nombrePlano={item.Plano}
            />
          </Grid>
          <Grid
            item
            style={{
              width:
                screenWidth === "xs" || screenWidth === "sm" ? "100%" : "60%",
            }}
          >
            <Imagen screenWidth={screenWidth} item={item} />
          </Grid>

          <Grid item style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColorTitulo,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  color: "#FFFFFF",
                  paddingLeft: "10px",
                  /*padding: "15px",*/
                  // border: "1px solid #FF0000",
                }}
              >
                {/* ESPACIOS: */}
                1° FILTRO
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColor,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  width: "100%",
                  paddingLeft: "5px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <BoxUnidadesFuncionales
                  idPlano={item.IDPlano}
                  setUfSelected={setUfSelected}
                />
              </Grid>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColorTitulo,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  color: "#FFFFFF",
                  paddingLeft: "10px",
                  // padding: "15px",
                  // alignItems: "center",
                  // border: "1px solid #FF0000",
                }}
              >
                {/* SECTORES: */}
                2° FILTRO
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColor,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  width: "100%",
                  paddingLeft: "5px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "1px solid #FF0000",
                }}
              >
                <BoxSectores
                  setLocalSelected={setLocalSelected}
                  departamento={ufSelected}
                />
              </Grid>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColorTitulo,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <div
                style={{
                  fontSize: "25px",
                  color: "#FFFFFF",
                  paddingLeft: "10px",
                  // padding: "15px",
                  // alignItems: "center",
                  // border: "1px solid #FF0000",
                }}
              >
                {/* GRUPOS: */}
                3° FILTRO
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ajusta la dirección a columna
                justifyContent: "center",
                width: "100%",
                backgroundColor: bgColor,
                fontSize:
                  screenWidth === "xs" || screenWidth === "sm"
                    ? "26px"
                    : "60px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  width: "100%",
                  paddingLeft: "5px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "1px solid #FF0000",
                }}
              >
                <BoxGrupos
                  setGrupoSelected={setGrupoSelected}
                  grupoSelected={grupoSelected}
                  idDepartamento={ufSelected.IDDepartamento}
                  sector={localSelected}
                  // idPlano={plano.IDPlano}
                />
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            style={{
              width:
                screenWidth === "xs" || screenWidth === "sm" ? "100%" : "40%",
            }}
          >
            &nbsp;
          </Grid>

          <Grid
            item
            style={{
              width:
                screenWidth === "xs" || screenWidth === "sm" ? "100%" : "40%",
            }}
          >
            &nbsp;
          </Grid>
          <Grid
            item
            style={{
              width: "100%",
            }}
          >
            <ListadoDeTareas
              grupos={grupos}
              // setTareasAsignadas={setTareasAsignadas}
              // tareasAsignadas={tareasFiltradas}

              // gruposNoAsignados={gruposNoAsignados}
            />
          </Grid>
          {/* {console.log("gruposNoAsignados: ", gruposNoAsignados)} */}
          {gruposNoAsignados.length > 0
            ? gruposNoAsignados.map((grupo) => (
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    // marginBottom: "4px",
                  }}
                >
                  <TituloGrupos
                    grupo={{
                      IDGrupo: grupo.IDGrupo,
                      Grupo: grupo.Grupo,
                    }}
                  />
                </Grid>
              ))
            : ""}
        </Grid>
      </Box>
    </>
  );
};

export default PagePlano;
