import { Button, Card, CardActions, CardMedia } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { encriptar } from "../../funciones/Funciones";

const CardDePlano = ({ item }) => {
  let navigate = useNavigate();

  const handleVerPlano = () => {
    localStorage.setItem("plano", encriptar(item));

    navigate("/plano");
  };
  return (
    <Card sx={{ width: 345, height: 210, margin: "5px" }}>
      <CardMedia sx={{ height: 140, padding: "5px" }}>
        <div
          style={{
            width: "340px",
            height: "130px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={item.Imagen}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt={`Selected ${item.Plano}`}
          />
        </div>
      </CardMedia>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{ fontSize: "25px", width: "100%" }}
          size="small"
          onClick={() => handleVerPlano()}
        >
          {item.Plano}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardDePlano;
