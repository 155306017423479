import { integerPropType } from "@mui/utils";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TablaDesplegable from "../general/TablaDesplegable";
import ServidorContext from "../../contextos/ServidorContext";
import { useContext } from "react";

const PageDashboardInvitaciones = (props) => {
  const { servidor } = useContext(ServidorContext);
  const [invitaciones, setInvitaciones] = useState([]);
  const [filtro, setFiltro] = useState("");

  useEffect(() => {
    props.setTitulo("Dashboard Invitaciones");
    props.setPagina("");
  }, [props.setTitulo]);

  useEffect(() => {
    listar_invitaciones();
  }, []);

  const listar_invitaciones = async () => {
    const API_ENDPOINT = servidor + `api_usuarios.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_INVITACIONES",
        filtro: filtro,
        // idObra: obra.IDObra,
        // idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.invitaciones) {
      setInvitaciones(respuesta.result.invitaciones);
    } else {
      console.log("NO TRAE NADA EN listar_invitaciones()");
      // setState([]);
    }
  };

  return (
    <div>
      {invitaciones ? (
        <TablaDesplegable data={invitaciones} />
      ) : (
        <span>No hay invitaciones pendientes</span>
      )}

      {/* {invitaciones.map((inv) => (
        <>
          <span>{`${inv.Nombre} ${inv.Apellido}`}</span>
          <br />
        </>
      ))} */}
    </div>
  );
};

export default PageDashboardInvitaciones;
