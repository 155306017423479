import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link, useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/styles"; // "@mui/styles";
import Botonera from "../../components/Botonera";
import { Grid, Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  styled,
  ThemeProvider,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ObraContext, { ObraProvider } from "../../contextos/ObraContext";
import { useContext } from "react";
import PresupuestoContext, {
  PresupuestoProvider,
} from "../../contextos/PresupuestosContext";
import {
  desencriptar,
  encriptar,
  enviarPosicionHaciaReactNative,
  numberWithCommas,
} from "../../funciones/Funciones";
import PrivilegioContext from "../../contextos/PrivilegiosContext";
import ListaInvitaciones from "../../components/ListaInvitaciones";

import PrimerPaso from "./PrimerPaso";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import ServidorContext from "../../contextos/ServidorContext";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },

//   linkStyle: {
//     color: "white",
//     textDecoration: "none",
//   },
// }));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageSelectObra = (props) => {
  const [listadoObras, setListadoObras] = useState([]);
  const { servidor } = useContext(ServidorContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra, setObra } = useContext(ObraContext);
  const { privilegios, setPrivilegios } = useContext(PrivilegioContext);
  // const { presupuesto, setPresupuesto } = useContext(PresupuestoContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);

  useEffect(() => {
    props.setTitulo("Obras");
    props.setPagina("altaObra");

    listar_obras();
  }, [props.setTitulo, obra]);

  useEffect(() => {
    enviarPosicionHaciaReactNative(isMobile, "banner", {
      usuario: usuario,
      obra: obra,
      tipoDeBanner: "BANNER",
      idPublicidad: "ca-app-pub-9747826938175612/8150779805",
      idBanner: "ca-app-pub-9747826938175612/8150779805",
      sacarFoto: false,
    });
  }, []);

  useEffect(() => {
    listar_obras();
  }, [props.invitaciones]);

  useEffect(() => {
    // console.log("screenWidth: ", screenWidth);
  });

  // const classes = useStyles();

  // const obras = [
  //   { IDObra: 1, Obra: "Palestina 714" },
  //   { IDObra: 2, Obra: "Lavalleja 714" },
  // ];

  let theme = createTheme({
    typography: {
      h5: {
        fontWeight: 800,
      },
    },
  });
  theme = responsiveFontSizes(theme);

  let navigate = useNavigate();

  const handleEntrar = (obraClick) => {
    setObra(obraClick);
    setPrivilegios(obraClick.Privilegios);

    localStorage.setItem("objobra", encriptar(obraClick));
    // localStorage.setItem("objobra", obraClick);
    // console.log(obra);
    // console.log(obraClick);

    let path = `/dashboard`;
    navigate(path);
    // navigation.navigate("importarListaPrecios");
  };

  const listar_obras = async () => {
    // console.log(texto);
    // console.log("PASA POR listar_obras()");

    let objusu = desencriptar(localStorage.getItem("objusu"));

    const API_ENDPOINT = servidor + `api_obras.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_OBRAS",
        idUsuario: objusu.IDUsuario,
        // operacion: "LISTAR_PRESUPUESTO",
        //  idObra: obra.IDObra,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.obras) {
      // console.log(respuesta.result.obras);

      setListadoObras(respuesta.result.obras);
    } else {
      setListadoObras([]);
      console.log("NO TRAE NADA EN listar_obras()");
      // setState([]);
    }
  };

  return (
    <div style={{ justifyContent: "center" }}>
      {/* <Botonera />
      <br />
      <br /> */}
      <ListaInvitaciones />
      <br />

      <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            {listadoObras.length > 0 ? (
              listadoObras.map((obraRow) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={obraRow.IDObra}
                  // sx={{ border: "1px solid #0000" }}
                >
                  <Item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">{obraRow.Obra}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleEntrar(obraRow)}
                        >
                          Entrar
                        </Button>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
              ))
            ) : (
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <PrimerPaso texto="CREA TU PRIMERA OBRA" link="/altaObra" />
              </Grid>
            )}
          </Grid>
        </ThemeProvider>
      </Box>
    </div>
  );
};

export default PageSelectObra;
